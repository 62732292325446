import { Box, Text, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

export const ShippingTerms = () => {
  const intl = useFormat({ name: 'common' })

  return (
    <VStack spacing={6} alignItems={'align-start'} p={{ base: 6, md: 0 }}>
      <Text textStyle={'body-50'}>
        {intl.formatMessage({
          id: 'checkout.step2.termsAndConditions.first',
        })}
      </Text>
      <Text textStyle={'body-50'}>
        {intl.formatMessage({
          id: 'checkout.step2.termsAndConditions.second',
        })}
      </Text>
      <Text textStyle={'body-50'}>
        {intl.formatMessage({
          id: 'checkout.step2.termsAndConditions.third',
        })}
      </Text>
      <Box>
        <Text textStyle={'body-50'}>
          {intl.formatMessage({
            id: 'checkout.step2.termsAndConditions.fourth.1',
          })}
        </Text>
        <Text textStyle={'body-50'}>
          {intl.formatMessage({
            id: 'checkout.step2.termsAndConditions.fourth.2',
          })}
        </Text>
        <Text textStyle={'body-50'}>
          {intl.formatMessage({
            id: 'checkout.step2.termsAndConditions.fourth.3',
          })}
        </Text>
      </Box>
    </VStack>
  )
}
