import { Text, Stack, Radio, Box, Button } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'

interface DetailedRichListProps {
  description?: JSX.Element | string
  editOnClick?: () => void
  editButtonLabel?: string
  label?: string
  showDescription?: boolean
  size?: 'Large' | 'Small'
}
interface DetailedRichListRadioProps extends DetailedRichListProps {
  type: 'Radio'
  radioValue: string | number
}
interface DetailedRichListIconProps extends DetailedRichListProps {
  type: 'Icon'
  icon: JSX.Element
}

export const DetailedRichList = (props: DetailedRichListRadioProps | DetailedRichListIconProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <Stack borderBottom={'1px solid #E2E2E2'} paddingBottom={4} maxWidth={props.size === 'Small' ? '343px' : 'full'}>
      <Box>
        {props.type === 'Radio' && (
          <Radio
            size={'lg'}
            value={props.radioValue.toString()}
            borderWidth={'2px'}
            borderColor={'shading.300'}
            backgroundColor={'background'}
            colorScheme={'primary'}
            _checked={{
              borderWidth: '6px',
              borderColor: 'primary',
            }}
          >
            <Text textStyle={'heading-mobile-50'}>{props.label}</Text>
          </Radio>
        )}
        {props.type === 'Icon' && (
          <Text>
            <IoCheckmarkCircleOutline></IoCheckmarkCircleOutline> {props.label}
          </Text>
        )}
        {props.editOnClick && (
          <Button size={'sm'} color={'text'} variant={'ghost'} textDecoration={'underline'} onClick={props.editOnClick}>
            {props.editButtonLabel ?? formatMessage({ id: 'action.edit' })}
          </Button>
        )}
      </Box>
      <Box paddingLeft={5}>
        {typeof props.description === 'string' ? (
          <Text textStyle={'callouts-blockquote-75'}>{props.description}</Text>
        ) : (
          props.description
        )}
      </Box>
    </Stack>
  )
}
