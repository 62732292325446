import { Link as ChakraLink, Stack } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'

export const LinkStack = ({ item, level = 0 }) => {
  const label = item?.label ?? ''
  const href = item.link?.link ?? ''
  const pageHrefName = item.link?.pageFolder?.name ?? ''
  const pageHrefUrl = item.link?.pageFolder?._url ?? ''
  const children = item.children
  const hasChildren = !!children
  const target = item.link.openInNewWindow
  const Linktype = item.link.type

  const linkStyle = {
    fontWeight: 'normal',
    fontSize: '14px',
  }
  return (
    <Stack spacing="2" style={linkStyle}>
      {!hasChildren ? (
        <ChakraLink
          as={NextLinkNoPrefetch}
          href={href}
          p={3}
          display="block"
          color="shading.900"
          fontSize="sm"
          padding={0}
        >
          {label}
        </ChakraLink>
      ) : (
        <>
          <NextLinkNoPrefetch target={target ? 'blank' : ''} href={Linktype === 'link' ? href : pageHrefUrl}>
            {label}
          </NextLinkNoPrefetch>
          {children?.map((item) => (
            <LinkStack key={item.label} item={item} level={level + 1} />
          ))}
        </>
      )}
    </Stack>
  )
}
