import { Variant } from '@Types/product/Variant'
import { HorizontalProductCardEditable, HorizontalProductCardEditableProps } from './horizontal-product-card-editable'
import {
  HorizontalProductCardOutOfStockOrNotEnough,
  HorizontalProductCardOutOfStockOrNotEnoughProps,
} from './horizontal-product-card-out-of-stock-or-not-enough'
import { HorizontalProductCardReadOnly, HorizontalProductCardReadOnlyProps } from './horizontal-product-card-read-only'

export type HorizontalProductCardProps = (
  | HorizontalProductCardEditableProps
  | HorizontalProductCardReadOnlyProps
  | HorizontalProductCardOutOfStockOrNotEnoughProps
) & {
  editable?: boolean
  variant?: Variant
  displayOutOfStock: boolean
  masterProductData?: any
}

export const HorizontalProductCard = (props: HorizontalProductCardProps) => {
  const { editable, variant, quantity, displayOutOfStock = false } = props
  const isInStock = (variant?.isOnStock && variant?.availableQuantity >= quantity) ?? false

  return !isInStock && displayOutOfStock ? (
    <HorizontalProductCardOutOfStockOrNotEnough {...props} />
  ) : editable ? (
    <HorizontalProductCardEditable {...props} />
  ) : (
    <HorizontalProductCardReadOnly {...props} />
  )
}
