import { IndexUiState } from 'instantsearch.js'
import { ALGOLIA_BASE_INDEX, ALGOLIA_FILTERS, ALGOLIA_INDEX_LIST, BRAND_NAME, DEFAULT_SORTBY } from './constants'

export const algoliaIndexNameResolver = ({ locale, sortBy = DEFAULT_SORTBY }: { locale: string; sortBy?: string }) => {
  // this project has configured locales as 'en' while algolia has 'en-US'/'en-CA'
  const indexLocale = locale === 'en' ? 'en-US' : 'en-CA'
  return `${ALGOLIA_BASE_INDEX}_${BRAND_NAME}_${indexLocale}_${sortBy}`
}

export const getSortByFromIndex = (
  indexName: string,
  locale: string,
  indexNameResolver?: (props: { locale: string; sortBy?: string; isLoggedIn?: boolean }) => string,
) => {
  return ALGOLIA_INDEX_LIST.reduce<string | undefined>((acc, { value: sortBy }) => {
    return indexNameResolver?.({ locale, sortBy }) === indexName ? sortBy : acc
  }, undefined)
}

export const getRouteFilters = (filters: IndexUiState['refinementList'] | IndexUiState['numericMenu']) => {
  return Object.entries(filters || {}).reduce<Record<string, string>>((acc, [filter, values]) => {
    const filterAlias = getFilterAttributeUrlAlias(filter)
    if (filterAlias) {
      return {
        ...acc,
        [filterAlias]: typeof values === 'string' ? values : values.join(','),
      }
    }
    return acc
  }, {})
}

interface FilterState {
  numericMenu: {
    [attribute: string]: string
  }
  refinementList: {
    [attribute: string]: string[]
  }
}

export const getAlgoliaStateFilters = (filters: Record<string, string>) => {
  return Object.entries(filters || {}).reduce<FilterState>(
    (acc, [filterAlias, value]) => {
      const fullAttribute = getFilterRawAttribute(filterAlias)
      if (!fullAttribute) return acc
      const { attribute, type } = fullAttribute
      switch (type) {
        case 'list':
          const previousValue = acc.refinementList[attribute] || []
          return {
            ...acc,
            refinementList: {
              ...acc.refinementList,
              [attribute]: [...previousValue, ...value.split(',')],
            },
          }
        case 'numeric':
          return {
            ...acc,
            numericMenu: {
              ...acc.numericMenu,
              [attribute]: value,
            },
          }
      }
    },
    {
      numericMenu: {},
      refinementList: {},
    },
  )
}

const getFilterAttributeUrlAlias = (rawAttribute: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.attribute === rawAttribute)?.urlAlias
}

const getFilterRawAttribute = (attributeAlias: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.urlAlias === attributeAlias)
}
