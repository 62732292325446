import { useState } from 'react'
import { AlertStatus, Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { CustomToast } from 'components/core-ui-controls/CustomToast'
import { useFormat } from 'helpers/hooks/useFormat'
import toast, { Toast } from 'react-hot-toast'
import { useCart } from 'frontastic'

export const CartPromotion = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { data: cart, redeemDiscountCode, removeDiscountCode } = useCart()
  const [currentCode] = cart?.discountCodes ?? [undefined]
  const [code, setCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const launchToast = (t: Toast, status: AlertStatus, message: string) => {
    const onClose = () => toast.remove(t.id)
    return toast.custom(<CustomToast status={status} description={message} onClose={onClose} />, { id: t.id })
  }

  const handleApplyCode = async () => {
    setIsLoading(true)
    try {
      await redeemDiscountCode(code)
      setCode('')
      toast((t) => launchToast(t, 'success', formatMessage({ id: 'cart.coupon.apply.success', values: { code } })))
    } catch (err) {
      toast((t) => launchToast(t, 'error', formatMessage({ id: 'cart.coupon.apply.error', values: { code } })))
    }
    setIsLoading(false)
  }

  const handleRemoveCode = async () => {
    setIsLoading(true)
    try {
      await removeDiscountCode(currentCode)
      toast((t) => launchToast(t, 'success', formatMessage({ id: 'cart.coupon.remove.success', values: { code } })))
    } catch (err) {
      toast((t) => launchToast(t, 'success', formatMessage({ id: 'cart.coupon.remove.error', values: { code } })))
    }
    setIsLoading(false)
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          await handleApplyCode()
        }}
      >
        <InputGroup size="md">
          <Input
            variant={'filled'}
            readOnly={Boolean(currentCode)}
            value={currentCode ? currentCode.code : code}
            onChange={(e) => setCode(e.target.value)}
            placeholder={formatMessage({ id: 'cart.coupon.title' })}
          />
          <InputRightElement w={'fit-content'}>
            {currentCode && (
              <Button variant={'solid'} minW={'4.5rem'} borderLeftRadius={0} onClick={handleRemoveCode}>
                {formatMessage({ id: 'action.remove' })}
              </Button>
            )}
            {!currentCode && (
              <Button variant={'solid'} type="submit" minW={'4.5rem'} borderLeftRadius={0} isLoading={isLoading}>
                {formatMessage({ id: 'cart.coupon.action.apply' })}
              </Button>
            )}
          </InputRightElement>
        </InputGroup>
      </form>
    </>
  )
}
