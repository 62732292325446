import React, { useEffect } from 'react'
import { getIsBrowser } from 'composable/helpers/hooks'
import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general/constants'
import { breadCrumbsBuilder, findPageType } from 'helpers/utils/monetateHelper'

const DOMAIN = process.env.NEXT_PUBLIC_MONETATE_DOMAIN
const NAME = process.env.NEXT_PUBLIC_MONETATE_NAME
const INSTANCE = process.env.NEXT_PUBLIC_MONETATE_INSTANCE

const account = {
  domain: DOMAIN,
  name: NAME,
  instance: INSTANCE,
}

export type cartRow = {
  productId: string
  quantity: number
  unitPrice: string
  sku: string | undefined
}

type MonetateData = {
  setPageType: string
  addBreadcrumbs: string[]
  addCartRows: cartRow[]
}

const Monetate = () => {
  const addAndInitialize = () => {
    const script = document.createElement('script')
    script.src =
      'https://se.monetate.net/js/2/' + account.name + '/' + account.instance + '/' + account.domain + '/custom.js'
    script.async = true
    const scriptSrcVal = script.src
    appendScript(scriptSrcVal, 'monetateScript', FUNCTIONAL_COOKIE_CATEGORY)
    return () => {
      document.head.removeChild(script)
    }
  }

  useEffect(() => {
    addAndInitialize()
  }, [])
  return null
}

const pushMonetateData = async (monetateData: MonetateData) => {
  if (getIsBrowser()) {
    window.monetateQ = window.monetateQ || []
    for (let key in monetateData) {
      window.monetateQ.push([key, monetateData[key]])
    }
    window.monetateQ.push(['trackData'])
  }
}

export const monetateView = async (cartData: any, path: string) => {
  const cartRows = []
  const pageType = findPageType(path)
  const breadCrumbs = breadCrumbsBuilder(path, pageType)
  if (cartData) {
    cartData?.items?.map((row, i) => {
      let cartRow: cartRow = {
        productId: row.productId,
        quantity: row.quantity,
        unitPrice: row.dxlCartGeneralInfo.unitAmount,
        sku: row.sku !== undefined ? row.sku.id : undefined,
      }
      cartRows.push(cartRow)
    })
  }
  await pushMonetateData({
    setPageType: pageType,
    addBreadcrumbs: breadCrumbs,
    addCartRows: cartRows,
  })
}

export default Monetate
