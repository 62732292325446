import { forwardRef, type AnchorHTMLAttributes, type ReactNode } from 'react'
import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import NextLink, { type LinkProps as NextLinkProps } from 'next/link'
import { APP_VERSION } from './general'

type LinkProps = NextLinkProps &
  ChakraLinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps> & {
    children?: ReactNode
  }

export const NextLinkNoPrefetch = forwardRef<HTMLAnchorElement, LinkProps>(function LinkWithRef(
  {
    // Turn next/link prefetching off by default.
    // @see https://github.com/vercel/next.js/discussions/24009
    prefetch = false,
    ...rest
  },
  ref,
) {
  if (APP_VERSION === 'R1') {
    return <ChakraLink {...rest} ref={ref} />
  }
  return <NextLink prefetch={prefetch} {...rest} ref={ref} />
})
