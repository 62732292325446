import { Box, BoxProps, Button, ButtonProps, Image, useBreakpointValue } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import { fontFamilies } from 'composable/chakra/figma-tokens'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'
import { card_DEFAULT_CSS_DESKTOP, card_DEFAULT_CSS_MOBILE } from './RichText-Utils/card-styles'
import { extractPath } from '../../helpers/utils/extract-url'

export interface TextCardProps {
  priority?: boolean
  root?: Omit<BoxProps, 'children'>
  theme?: TextCardTheme
  backgroundColor?: string
  title?: {
    content?: BoxProps
    font?: {
      size?: string
      family?: string
    }
  }
  description?: BoxProps
  button?: ButtonProps & { href?: string }
  image?: {
    src?: string
    alt?: string
    width?: number
    height?: string | null
  }
  textAlign?: TextCardTextAlign
  analyticsTrackingData?: string
  relativeIndex?: number
}

export type TextCardTextAlign = 'left' | 'center' | 'right'
export type TextCardTheme = 'dark' | 'light' | 'highlight'

export const TextCard = ({
  priority = false,
  theme = 'dark',
  textAlign = 'center',
  image,
  description,
  title,
  button,
  root,
  backgroundColor,
  analyticsTrackingData,
  relativeIndex,
}: TextCardProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const alignItems = alignItemsValue[textAlign]
  const { font: titleFont } = title ?? {}

  const headingFontFamily = titleFont?.family ?? 'libre-franklin'
  const headingFontSize = titleFont?.size ?? '400'

  const headingFontStyleSuffix =
    headingFontFamily === 'libre-franklin' ? `-${headingFontSize}` : `-secondary-${headingFontSize}`

  const headingFontStyleToken = {
    desktop: `heading-desktop${headingFontStyleSuffix}`,
    mobile: `heading-mobile${headingFontStyleSuffix}`,
  }

  let titleFontFamily

  if (titleFont?.family === 'antonio' || titleFont?.family === 'libre-franklin') {
    titleFontFamily = titleFont?.family === 'antonio' ? fontFamilies.secondary : fontFamilies.primary
  } else {
    titleFontFamily = titleFont?.family || fontFamilies.primary
  }

  const analyticsLocationIdentifier = extractPath(button?.href ?? '')
  const dataPromotionName =
    analyticsTrackingData && `${analyticsTrackingData} | ${relativeIndex} | ${analyticsLocationIdentifier}`

  return backgroundColor?.toLowerCase() !== 'none' ? (
    <Box
      flexDirection="column"
      justifyContent="center"
      display="flex"
      layerStyle={theme.toLowerCase()}
      alignItems={alignItems}
      textAlign={textAlign}
      px={6}
      py={12}
      {...root}
    >
      {image?.src && (
        <Box mb={2} alignItems={alignItems} display="flex">
          <Image
            src={image.src}
            alt={image?.alt}
            height={image?.height || '4rem'}
            style={{ width: 'auto', maxHeight: '64px' }}
          />
        </Box>
      )}

      {title?.content?.children && (
        <Box
          textStyle={[headingFontStyleToken.mobile, null, null, headingFontStyleToken.desktop]}
          fontFamily={titleFontFamily}
          paddingY={1.5}
          {...title?.content}
        />
      )}

      {description?.children && (
        <Box
          __css={{
            ...(isMobile ? card_DEFAULT_CSS_MOBILE : card_DEFAULT_CSS_DESKTOP),
            'h1, h2, h3, h4, h5, h6': {
              fontFamily: titleFontFamily,
            },
          }}
          textStyle={['callouts-blockquote-75-Default', null, 'body-100']}
          paddingY={1.5}
          {...description}
        />
      )}

      {button?.children && !button?.href && (
        <Button layerStyle={theme.toLowerCase()} variant="ghost" flexGrow={0} mt={2} {...button} />
      )}

      {button?.children && button?.href && (
        <Button
          as={NextLinkNoPrefetch}
          sx={{
            textDecoration: button?.textDecoration,
            '&:hover': { textDecoration: 'underline' },
          }}
          href={button.href ?? ''}
          layerStyle={theme.toLowerCase()}
          variant="ghost"
          flexGrow={0}
          mt={2}
          px={textAlign !== 'center' ? 0 : undefined}
          _hover={{
            bg: 'none',
          }}
          _active={{
            bg: 'none',
          }}
          {...button}
          onClick={() => promoClickUtagLinkEvent(dataPromotionName)}
          data-promotion-name={dataPromotionName}
        />
      )}
    </Box>
  ) : (
    <Box
      flexDirection="column"
      justifyContent="center"
      display="flex"
      layerStyle={theme?.toLowerCase()}
      backgroundColor={'transparent'}
      alignItems={alignItems}
      textAlign={textAlign}
      px={6}
      py={12}
      {...root}
    >
      {image?.src && (
        <Box mb={2} alignItems={alignItems} display="flex">
          <Image src={image.src} alt={image?.alt} height={image?.height || '4rem'} />
        </Box>
      )}

      {title?.content?.children && (
        <Box
          textStyle={[headingFontStyleToken.mobile, null, null, headingFontStyleToken.desktop]}
          fontFamily={titleFontFamily}
          paddingY={1.5}
          {...title?.content}
        />
      )}

      {description?.children && (
        <Box
          __css={{
            ...(isMobile ? card_DEFAULT_CSS_MOBILE : card_DEFAULT_CSS_DESKTOP),
            'h1, h2, h3, h4, h5, h6': {
              fontFamily: titleFontFamily,
            },
          }}
          textStyle="callouts-blockquote-100"
          paddingY={1.5}
          {...description}
        />
      )}

      {button?.children && !button?.href && (
        <Button layerStyle={theme.toLowerCase()} variant="ghost" flexGrow={0} mt={2} {...button} />
      )}

      {button?.children && button?.href && (
        <Button
          as={NextLinkNoPrefetch}
          href={button.href ?? ''}
          layerStyle={theme.toLowerCase()}
          variant="ghost"
          flexGrow={0}
          mt={2}
          px={textAlign !== 'center' ? 0 : undefined}
          _hover={{
            bg: 'none',
          }}
          _active={{
            bg: 'none',
          }}
          {...button}
          textStyle={'body-100'}
          onClick={() => promoClickUtagLinkEvent(dataPromotionName)}
          data-promotion-name={dataPromotionName}
        />
      )}
    </Box>
  )
}

const alignItemsValue: Record<TextCardTextAlign, ButtonProps['alignItems']> = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}
