import { TasticWrapperProps } from 'frontastic'
import { injectDataSources } from 'frontastic/lib/utils/inject-data-sources'
import ErrorBoundary from '../../frontastic/lib/error-boundary'

export function ComposableTasticWrapper(props: TasticWrapperProps) {
  const { tastics, data, dataSources, highlight = false, isMobileDevice = false } = props
  const TasticToRender: React.ElementType = tastics[data.tasticType] || tastics['default']
  // inject all datasources into the proper nodes
  // dataSources null check satisfies TS
  const updatedBlock = dataSources
    ? injectDataSources(data.configuration, dataSources, isMobileDevice)
    : data.configuration

  return (
    <ErrorBoundary>
      <TasticToRender type={data?.tasticType} id={data?.tasticId} data={updatedBlock} pageFolder={props.pageFolder} />
    </ErrorBoundary>
  )
}
