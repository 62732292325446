import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react'
import { CurrencyHelpers } from 'helpers_composable/currencyHelpers'
import { useFormat } from 'helpers_composable/hooks/useFormat'
import { calculateCartCount } from 'helpers_composable/utils/calculateCartCount'
import { CartDrawerEmptyState } from './cart-drawer-empty-state'
import { CartDrawerFooter } from './cart-drawer-footer'
import { CartDrawerSummary } from './cart-drawer-summary'
import { useComposable } from '../../composable-provider'
import { HorizontalProductCard } from '../../horizontal-product-card'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { calculatePoints } from 'frontastic/contexts/atgCartContext/helpers/calculatePoints'

export const CartDrawer = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const [cartTotalItems, setCartTotalItems] = useState(0)
  const [points, setPoints] = useState<number[]>([])
  const { cartDrawer } = useComposable()
  const { formattedCart: cart, removeItem, updateItem, refreshCart: recalculateCart } = useAtgLegacyCart()
  const { userSessionData } = useAtgUser()

  const cartIsEmpty = !cartTotalItems || cart?.cartState !== 'Active'
  const [isUpdating, setIsUpdating] = useState(false)
  const locale = cart?.locale?.language

  useEffect(() => {
    setCartTotalItems(calculateCartCount(cart?.lineItems || []))
  }, [cart])

  useEffect(() => {
    if (userSessionData && cart?.lineItems) {
      const pointsArray = cart?.lineItems?.map((item) => {
        return calculatePoints(item?.totalPrice?.centAmount / 100, userSessionData.currentTierPurchaseRatio)
      })
      setPoints(pointsArray)
    }
  }, [userSessionData, cart?.lineItems])

  const handleUpdate = async (updatePromise: Promise<any>) => {
    setIsUpdating(true)
    try {
      await updatePromise
    } catch (err) {
      console.log(err)
    } finally {
      recalculateCart() // Only Recalculate the Cart after the update is done.
    }
    setIsUpdating(false)
  }

  useEffect(() => {
    recalculateCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer isOpen={cartDrawer.isOpen} placement="right" onClose={cartDrawer.onClose} size={'md'}>
      <DrawerOverlay />
      <DrawerContent maxW={{ base: 375, md: 550 }}>
        <DrawerHeader p={'12px 36px'}>
          <DrawerCloseButton size={'sm'} fontSize={'sm'} left={'xs'} mt={'2px'} />
          <Center h={'24px'} fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight={'1.5rem'}>
            <Text textStyle={'heading-desktop-100'}>
              {formatMessage({ id: 'cart.drawer.titleCount', values: { count: cartTotalItems } })}
            </Text>
          </Center>
        </DrawerHeader>
        <Divider mb={'1em'} />
        <DrawerBody>
          {!locale || !cart || cartIsEmpty ? (
            <CartDrawerEmptyState onClose={cartDrawer.onClose} />
          ) : (
            <Stack spacing={2}>
              <List>
                <Stack spacing={2} divider={<Divider />}>
                  {cart?.lineItems?.map((item, i) => {
                    const {
                      _url,
                      availableDiscount,
                      count,
                      discountedPrice,
                      isDiscounted,
                      lineItemId,
                      name,
                      price,
                      totalDiscountedPrice,
                      totalPrice,
                      variant,
                    } = item

                    const masterProductData = item?.masterData?.current?.masterVariant?.attributes

                    return (
                      <ListItem key={lineItemId}>
                        <HorizontalProductCard
                          availableDiscount={availableDiscount}
                          url={_url}
                          editable
                          columns={3}
                          size="sm"
                          image={{
                            src: variant?.images?.[0],
                            alt: name ?? '',
                            onClickImage: async () => {
                              await router.push(_url)
                              cartDrawer.onClose()
                            },
                          }}
                          name={name || ''}
                          quantity={count}
                          quantityPickerProps={{
                            max: variant?.availableQuantity ?? 99,
                          }}
                          priceCentAmount={price?.centAmount * count}
                          price={CurrencyHelpers.formatForCurrency(price?.centAmount * count)}
                          discountedPrice={
                            isDiscounted
                              ? CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount * count)
                              : undefined
                          }
                          individualUnitPrice={CurrencyHelpers.formatForCurrency(totalPrice.centAmount / count)}
                          totalPriceCentAmount={totalPrice.centAmount}
                          totalPrice={CurrencyHelpers.formatForCurrency(totalPrice.centAmount)}
                          totalDiscountedPrice={
                            totalDiscountedPrice
                              ? CurrencyHelpers.formatForCurrency(totalDiscountedPrice.centAmount)
                              : undefined
                          }
                          variant={variant}
                          onRemove={() => handleUpdate(removeItem(item?.variant?.attributes?.removalId))} // for commercetools, jut use the productId
                          onChangeQuantity={async (val) => {
                            val === 0
                              ? await handleUpdate(removeItem(lineItemId))
                              : await handleUpdate(updateItem(lineItemId, val))
                          }}
                          isLoading={isUpdating}
                          displayOutOfStock={false}
                          masterProductData={masterProductData}
                          points={points[i]}
                        />
                      </ListItem>
                    )
                  })}
                </Stack>
                <Divider mt={4} />
              </List>
              <CartDrawerSummary />
            </Stack>
          )}
        </DrawerBody>
        {cart && !cartIsEmpty && (
          <DrawerFooter p={'0px 20px 16px'} boxShadow={'0px -2px 4px rgba(0, 0, 0, 0.1)'}>
            <CartDrawerFooter />
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
