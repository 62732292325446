import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export const getProductsByIds = async (productIds: string[]) => {
  const payload = {
    productIds,
  }

  return await fetchApiHub(
    '/action/product/getProducts',
    {
      method: 'POST',
    },
    payload,
  )
}
