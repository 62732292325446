import { Button, Text } from '@chakra-ui/react'
import { TextSelectorProps } from '../../types'

export const TextSelector = ({ option, setSelection, isDisabled, isSelected }: TextSelectorProps) => {
  const { value } = option

  return (
    <Button
      key={value}
      p={3}
      minW={12}
      w={'auto'}
      h={'auto'}
      backgroundColor={'surface.primary'}
      borderRadius={'4px'}
      border={'1px solid'}
      borderColor={isSelected ? 'brand.primary' : 'shading.300'}
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setSelection(value)
      }}
      isDisabled={isDisabled}
      color={'text.primary'}
      _hover={{
        borderColor: isSelected ? 'brand.primary' : 'primary.300',
      }}
      _focus={{
        borderColor: 'info.500',
      }}
      _active={{
        borderColor: 'primary.400',
      }}
      _disabled={{
        borderColor: 'surface.muted',
        backgroundColor: 'surface.muted',
        color: 'text.muted',
      }}
    >
      <Text textStyle={'body-100'}>{value}</Text>
    </Button>
  )
}
