import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Cart } from '@Types/cart/Cart'
import { getCartSummaryAmounts } from 'composable'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { HorizontalProductCard, HorizontalProductCardProps } from '../horizontal-product-card'
import { SummaryLineItem } from '../summary-line-item'
import { useAtgLegacyCart } from 'frontastic/contexts'

export interface CheckoutCartSummaryProps {
  hideCheckoutButton?: boolean
  displayTitle?: boolean
  rootProps?: StackProps
  orderSummaryProps?: StackProps
  showCartItems?: boolean
  cartData?: Partial<Cart>
  textOverride?: {
    total: string
  }
}

export const CheckoutCartSummary = ({
  hideCheckoutButton,
  rootProps,
  displayTitle = true,
  showCartItems = false,
}: CheckoutCartSummaryProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { formattedCart: cart } = useAtgLegacyCart()

  const productCartSize = useBreakpointValue({ base: 'sm', md: 'lg' })
  const isDesktop = useBreakpointValue({ base: false, md: true })

  const { subtotal, discounts, total, isShippingMethodSelected, shipping } = getCartSummaryAmounts(cart)

  const numberOfItemsInCart = cart?.lineItems?.length ?? 0

  const handleProceedToCheckout = async () => {
    // await recalculateCart()
    router.push('/checkout')
  }
  const handleProceedToCheckoutAtg = async () => {
    router.push('/checkout')
  }

  return (
    <Stack bgColor={'surface.primary'} p={6} spacing={0} width="full" {...rootProps}>
      {displayTitle && (
        <Text mb={2} textStyle={'heading-desktop-200'}>
          {formatMessage({ id: 'checkout.summary.title' })}
        </Text>
      )}

      {isDesktop && (
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem borderTop="none">
            <AccordionButton px={0} py={3}>
              <Text flex="1" textAlign="left" textStyle={'body-100'}>
                {formatMessage({ id: 'checkout.success.orderSummary.items', values: { count: numberOfItemsInCart } })}
                <AccordionIcon />
              </Text>

              <Text textStyle={'heading-desktop-100'}>{CurrencyHelpers.formatForCurrency(total)}</Text>
            </AccordionButton>
            <Divider />
            <AccordionPanel px={0} py={4}>
              {showCartItems && (
                <Stack spacing={4} divider={<Divider />}>
                  {cart?.lineItems?.map((item: any) => {
                    const { variant, price, discountedPrice, name, count, lineItemId, _url, isDiscounted } = item

                    const masterProductData = item?.masterData?.current?.masterVariant?.attributes

                    return (
                      <HorizontalProductCard
                        key={lineItemId}
                        url={item._url}
                        columns={2}
                        image={{
                          src: variant?.images?.[0],
                          alt: name ?? '',
                          onClickImage: () => router.push(_url),
                        }}
                        name={name}
                        quantity={count}
                        priceCentAmount={price?.centAmount * count}
                        price={CurrencyHelpers.formatForCurrency(price?.centAmount * count)}
                        discountedPrice={
                          isDiscounted
                            ? CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount * count)
                            : undefined
                        }
                        size={productCartSize as HorizontalProductCardProps['size']}
                        variant={variant}
                        displayOutOfStock={false}
                        masterProductData={masterProductData}
                      />
                    )
                  })}
                </Stack>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      <Stack spacing={1} mt={8}>
        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.subtotal' })}
          value={CurrencyHelpers.formatForCurrency(subtotal)}
          textStyle="body-75"
        />

        {isShippingMethodSelected ? (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={CurrencyHelpers.formatForCurrency(shipping)}
            textStyle="body-75"
          />
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={formatMessage({ id: 'cart.summary.calculatedAtCheckout' })}
            textStyle="body-75"
          />
        )}

        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.tax' })}
          value={formatMessage({
            id: 'cart.summary.tax.calculatedAtPOS',
          })}
          textStyle="body-75"
        />

        {!!discounts && (
          <SummaryLineItem
            isDiscount
            label={formatMessage({ id: 'cart.summary.discount' })}
            value={CurrencyHelpers.formatForCurrency(discounts)}
            textStyle="body-75"
          />
        )}

        <Divider mt="2" mb="3" />
        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.estimatedTotal' })}
          value={CurrencyHelpers.formatForCurrency(total) ?? CurrencyHelpers.formatForCurrency(0)}
          textStyle="heading-desktop-100"
        />
      </Stack>

      {!hideCheckoutButton && (
        <Button colorScheme="blue" size="lg" fontSize="md" onClick={handleProceedToCheckoutAtg}>
          {formatMessage({ id: 'action.proceed.checkout' })}
        </Button>
      )}
    </Stack>
  )
}
