import { LineItem } from '@Types/cart/LineItem'
import { Order } from '@Types/cart/Order'

export interface GetOrderTotalsOptions {
  shippingCostPreview?: number
}

const ZERO = 0

const calculateItemDiscount = (item: LineItem) =>
  item.discounts.reduce((acc, discount) => acc + (discount?.discountedAmount?.centAmount ?? ZERO) * item.count, ZERO)

const calculateOrderDiscount = (order?: Order) =>
  order?.lineItems?.reduce((acc, item) => acc + calculateItemDiscount(item), ZERO) || ZERO

const getItemPrice = (item: LineItem) => item.totalPrice?.centAmount

const calculateItemsTotalNet = (order: Order | undefined) =>
  order?.lineItems?.reduce((acc, item) => acc + (getItemPrice(item) || ZERO), ZERO) || ZERO

const getShippingCost = (order: Order | undefined, shippingCostPreview: number) =>
  order?.shippingInfo?.price.centAmount || shippingCostPreview || ZERO

const getTotalTax = (order: Order | undefined) =>
  order?.taxed?.taxPortions?.reduce((acc, tax) => acc + tax.amount.centAmount, 0) || ZERO

export const getOrderTotals = (order: Order | undefined, options?: GetOrderTotalsOptions) => {
  const shippingCostPreview = options?.shippingCostPreview || ZERO
  const itemsTotalNet = calculateItemsTotalNet(order)
  const shipping = getShippingCost(order, shippingCostPreview)
  const totalTax = getTotalTax(order)
  const itemsTotalDiscounts = calculateOrderDiscount(order)
  const netTotal = order?.sum?.centAmount || ZERO

  return {
    subtotal: itemsTotalNet + itemsTotalDiscounts,
    shipping,
    discount: itemsTotalDiscounts,
    tax: totalTax,
    total: netTotal + totalTax + shippingCostPreview,
  }
}
