import { Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

interface ThankYouProps {
  orderId: string
  confirmationEmailAddress?: string | null
}

export const ThankYou = ({ orderId, confirmationEmailAddress }: ThankYouProps) => {
  const intl = useFormat({ name: 'common' })

  return (
    <>
      <Text width="100%" textAlign="center" textStyle={'heading-desktop-300'}>
        {intl.formatMessage({ id: 'checkout.success.title' })}
      </Text>
    </>
  )
}

/* For future reference IF needed. Remove before pushing to PROD if unused --> 

<>
<Text fontSize={{ base: 'lg', lg: 'xxl' }} fontWeight="extrabold" mb="md">
          <Text as="span">{intl.formatMessage({ id: 'checkout.success.info' })}</Text>
          <Text as="span" color="primary">
            {orderId}
          </Text>
</Text>
<Text fontSize={{ base: 'base', lg: 'lg' }} mb="lg">
          {confirmationEmailAddress
            ? intl.formatMessage({
                id: 'checkout.success.orderReceived.withEmail',
                values: { email: confirmationEmailAddress },
              })
            : intl.formatMessage({
                id: 'checkout.success.orderReceived.noEmail',
              })}
</Text>
<Box>
          <Button bg={'text'} color={'white'} onClick={() => router.push('/')} width={{ base: '100%', lg: 'initial' }}>
            {intl.formatMessage({ id: 'action.continueShopping' })}
          </Button>
</Box>
</>

*/
