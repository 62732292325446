import { useRef } from 'react'
import { Button, ButtonProps, Stack } from '@chakra-ui/react'
import { convertShippingToCTAddress, STATES, US_ARMY_STATES_WITH_NAME, InputField, SelectField } from 'composable'
import useExperian from 'composable/components/hooks/useExperian'
import { useFormat } from 'helpers/hooks/useFormat'
import * as yup from 'yup'
import { useAccount } from 'frontastic'
import { SuggestionsDropDown } from './suggestions-drop-down'
import { ExperianAddressType, CheckoutAddressType } from '../../../../helpers/utils/experian-address-utils'
import { FormStatus, SavedShippingAddress } from '../../../types'
import { AddressConfirmationModal } from '../../address-confirmation-modal'
import { useCheckoutForm, UseCheckoutFormProps } from '../../use-checkout-form'
import { Address } from '@Types/account/Address'

type FormDataType = SavedShippingAddress

type ShippingAddressFormProps = Pick<UseCheckoutFormProps<FormDataType>, 'onChange' | 'initialValues'> & {
  cancelButtonProps?: ButtonProps
  showSubmitButton?: boolean
  onClose?: () => void
  setStatus?: React.Dispatch<React.SetStateAction<FormStatus>>
  submitButtonProps?: ButtonProps
  addressConfirmationModal?: boolean
  modalOnClose?: () => void
  checkoutAddress?: CheckoutAddressType
  experianAddress?: ExperianAddressType
  handleStep1Submit?: (overwriteShippingFromModal?: Address) => void
}

export const ShippingAddressForm = ({
  cancelButtonProps,
  initialValues,
  showSubmitButton = false,
  onChange,
  onClose,
  setStatus,
  submitButtonProps,
  addressConfirmationModal,
  modalOnClose,
  checkoutAddress,
  experianAddress,
  handleStep1Submit,
}: ShippingAddressFormProps) => {
  const intl = useFormat({ name: 'common' })
  const { account, updateAddress } = useAccount()

  const { form } = useCheckoutForm<FormDataType>({
    onChange,
    formKey: 'shippingAddressForm',
    yupSchema: shippingAddressFormSchema({ intl }),
    initialValues,
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    watch,
  } = form

  const streetName = watch('street_name')
  const onSubmit = async (data: FormDataType) => {
    try {
      await updateAddress(data)
      onClose?.()
      setStatus?.('success')
    } catch (err) {
      setStatus?.('error')
    }
  }

  const onError = () => setStatus?.('error')

  const content = {
    button: {
      updateAddress: intl.formatMessage({ id: 'action.updateAddress' }),
      cancel: intl.formatMessage({ id: 'action.cancel' }),
    },
  }

  const streetNameRef = useRef(null)

  const setFormValues = (formattedAddress: ExperianAddressType) => {
    if (!formattedAddress) return

    setValue('street_name', formattedAddress.address_line_1)
    setValue('additional_street_info', formattedAddress.address_line_2)
    setValue('city', formattedAddress.locality)
    setValue('state', formattedAddress.region)
    setValue('country', formattedAddress.country)
    setValue('postcode', formattedAddress.postal_code)

    return {
      street_name: formattedAddress.address_line_1,
      additional_street_info: formattedAddress.address_line_2,
      city: formattedAddress.locality,
      state: formattedAddress.region,
      country: formattedAddress.country,
      postcode: formattedAddress.postal_code,
    }
  }

  const {
    suggestions,
    onStreetNameChange,
    handleSuggestionClick,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  } = useExperian({
    setFormValues,
    streetNameRef,
  })

  const getState = () => {
    const cityVal: string = getValues('city')
    if (
      cityVal &&
      typeof cityVal === 'string' &&
      (cityVal.toLowerCase() === 'apo' || cityVal.toLowerCase() === 'fpo' || cityVal.toLowerCase() === 'dpo')
    ) {
      return US_ARMY_STATES_WITH_NAME
    } else {
      return STATES
    }
  }

  return (
    <>
      <AddressConfirmationModal
        addressConfirmationModal={addressConfirmationModal}
        modalOnClose={modalOnClose}
        checkoutAddress={checkoutAddress}
        experianAddress={experianAddress}
        setFormValues={setFormValues}
        handleStep1Submit={handleStep1Submit}
      />

      <form
        style={{ marginTop: '16px' }}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <Stack spacing={{ base: 5, lg: 4 }} direction="column">
          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.firstName',
              })}
              inputProps={register('first_name')}
              error={errors.first_name}
              isRequired
            />
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.lastName',
              })}
              inputProps={register('last_name')}
              error={errors.last_name}
              isRequired
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <SuggestionsDropDown
              streetNameRef={streetNameRef}
              register={register}
              onStreetNameChange={onStreetNameChange}
              setSelectedSuggestionIndex={setSelectedSuggestionIndex}
              selectedSuggestionIndex={selectedSuggestionIndex}
              suggestions={suggestions}
              handleSuggestionClick={handleSuggestionClick}
              setValue={setValue}
              currentStreetName={streetName}
              errors={errors}
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.additionalStreetInfo',
              })}
              inputProps={register('additional_street_info')}
              error={errors.additional_street_info}
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.city',
              })}
              inputProps={register('city')}
              showInfoIcon
              error={errors.city}
              isRequired
            />
            <SelectField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.state',
              })}
              selectProps={{
                defaultValue: '',
                ...register('state'),
              }}
              error={errors.state}
              isRequired
            >
              <option defaultValue={''} disabled value={''}>
                {intl.formatMessage({
                  id: 'action.selectState',
                })}
              </option>
              <>
                {getState().map((state) => {
                  const label = state?.intlId ? intl.formatMessage({ id: state.intlId }) : state.name
                  return (
                    <option title={label} aria-label={label} key={state.code} value={state.code}>
                      {label}
                    </option>
                  )
                })}
              </>
            </SelectField>
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.postcode',
              })}
              inputProps={register('postcode')}
              error={errors.postcode}
              isRequired
            />
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.phoneNumber',
              })}
              inputProps={register('phone_number')}
              error={errors.phone_number}
              isRequired
            />
          </Stack>
        </Stack>

        {showSubmitButton && (
          <Stack width={'full'} mt={8}>
            <Button
              type="submit"
              size={'lg'}
              onClick={() => {
                handleSubmit(onSubmit, onError)()
              }}
              disabled={!isDirty}
              {...submitButtonProps}
            >
              {content.button.updateAddress}
            </Button>
            <Button
              size={'md'}
              color={'text'}
              variant={'ghost'}
              textDecoration={'underline'}
              onClick={() => {
                setStatus?.(undefined)
                onClose?.()
              }}
              {...cancelButtonProps}
            >
              {content.button.cancel}
            </Button>
          </Stack>
        )}
      </form>
    </>
  )
}

const shippingAddressFormSchema = (deps: { intl: { formatMessage: (params: unknown) => string } }) => {
  const { intl } = deps
  return yup.object().shape({
    first_name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    last_name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    street_name: yup
      .string()
      .matches(/^[a-zA-Z0-9// ]*$/, intl.formatMessage({ id: 'validation.address' }))
      .required(intl.formatMessage({ id: 'validation.required' })),

    additional_street_info: yup.string().matches(/^[a-zA-Z0-9// ]*$/, intl.formatMessage({ id: 'validation.address' })),

    city: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, intl.formatMessage({ id: 'validation.address' }))
      .required(intl.formatMessage({ id: 'validation.required' })),

    state: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    postcode: yup
      .string()
      .matches(/^[a-zA-Z0-9 ]*$/, intl.formatMessage({ id: 'validation.address' }))
      .required(intl.formatMessage({ id: 'validation.required' })),

    phone_number: yup
      .string()
      .min(10, intl.formatMessage({ id: 'validation.phone.length', values: { digits: 10 } }))
      .max(10, intl.formatMessage({ id: 'validation.phone.length', values: { digits: 10 } }))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\(\d{2,3}\\)[ \\-]*)|(\d{2,4})[ \\-]*)*?\d{3,4}?[ \\-]*\d{3,4}?$/,
        intl.formatMessage({ id: 'validation.phone' }),
      )
      .required(intl.formatMessage({ id: 'validation.required' })),
  })
}
