import { useMemo, useState } from 'react'
import { Accordion, AccordionProps, ComponentWithAs, Button, Box } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInstantSearch } from 'react-instantsearch-hooks-web'
import { ALGOLIA_FILTERS } from '../../constants'
import { AlgoliaFilterItem } from '../../types'
import { NumericMenu, RefinementList, Slider } from '../filtering'

export const Filters: ComponentWithAs<'div', AccordionProps> = (props) => {
  const { formatMessage } = useFormat({ name: 'common' })

  function returnFilterKeysWithUnifiedValue(bool: boolean | number) {
    const newfiltersExpandState = ALGOLIA_FILTERS.reduce((acc, filter) => {
      acc[filter.attribute] = bool
      return acc
    }, {})
    return newfiltersExpandState
  }

  const [filtersExpandState, setFiltersExpandState] = useState<{ [key: string]: boolean }>(
    returnFilterKeysWithUnifiedValue(false),
  )

  const { results: algoliaResults } = useInstantSearch()

  const isFacetAvailable = (currentFacet: string) => {
    const disjuntiveFacet = !!algoliaResults?.disjunctiveFacets.find(
      (disjunctiveFacet) => disjunctiveFacet.name === currentFacet,
    )
    const facet = !!algoliaResults?.facets.find((facet) => facet.name === currentFacet)

    return disjuntiveFacet || facet
  }

  const areAllAvailableFiltersExpanded = useMemo(() => {
    const allFilters = Object.entries(filtersExpandState).filter(([filter]) => isFacetAvailable(filter))

    const result = allFilters.every(([filter, value]) => value)

    return result
  }, [filtersExpandState, algoliaResults])

  const handleAllFiltersToggle = () => {
    if (areAllAvailableFiltersExpanded) {
      const newfiltersExpandState = returnFilterKeysWithUnifiedValue(false)
      return setFiltersExpandState(newfiltersExpandState)
    } else {
      const newfiltersExpandState = returnFilterKeysWithUnifiedValue(true)
      return setFiltersExpandState(newfiltersExpandState)
    }
  }

  function dynamicFilter(refinement: AlgoliaFilterItem) {
    if (refinement.type === 'numeric') {
      return <NumericMenu key={refinement.attribute} {...refinement} />
    } else if (refinement.type === 'list') {
      return <RefinementList key={refinement.attribute} {...refinement} />
    } else if (refinement.type === 'range') {
      return <Slider key={refinement.attribute} {...refinement} />
    } else return null
  }

  return (
    <>
      <Box mb={3.5} display={{ base: 'none', md: 'block' }}>
        <Button
          variant="link"
          size="sm"
          color="shading"
          textDecoration="underline"
          fontWeight="extrabold"
          onClick={handleAllFiltersToggle}
        >
          {areAllAvailableFiltersExpanded
            ? formatMessage({ id: 'category.filters.action.collapseAll' })
            : formatMessage({ id: 'category.filters.action.expandAll' })}
        </Button>
      </Box>

      {ALGOLIA_FILTERS.map((refinement) => (
        <Accordion
          key={refinement.attribute}
          allowMultiple={true}
          index={filtersExpandState[refinement.attribute] ? [0] : []}
          onChange={() =>
            setFiltersExpandState({
              ...filtersExpandState,
              [refinement.attribute]: !filtersExpandState?.[refinement.attribute],
            })
          }
          {...props}
        >
          {dynamicFilter(refinement)}
        </Accordion>
      ))}
    </>
  )
}
