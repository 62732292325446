import React, { FormEvent, useState } from 'react'
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  useDisclosure,
  Divider,
  Flex,
  Text,
  Center,
  Container,
} from '@chakra-ui/react'
import StoreList from './store-list'
import { StoresResponse } from './types'
import StoreSearch from './store-search'

const StoreFinderDrawer = ({ isOpen, onClose }) => {
  const [storeData, setStoreData] = useState<StoresResponse>()
  const [showNoResults, setshowNoResults] = useState(false)
  const [isStoreSearch, setIsStoreSearch] = useState(false)
  const [locationCoordinate, setlocationCoordinate] = useState('')
  const storeHeading = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
  }
  const handleOnStoreData = (data) => {
    setStoreData(data.storeData)
    setlocationCoordinate(data.addressLocation)
    setshowNoResults(data.showNoResults)
    setIsStoreSearch(data.isStoreSearch)
  }
  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent maxW={{ base: 375, md: 550 }}>
          <DrawerHeader p={'12px 36px'}>
            <DrawerCloseButton size={'sm'} fontSize={'sm'} mt={'2px'} />
            <Center h={'24px'} fontSize={{ base: '1rem', md: '1.25rem' }} lineHeight={'1.5rem'}>
              <Text textStyle={'heading-desktop-100'}>My Store</Text>
            </Center>
          </DrawerHeader>
          <Divider />
          <Container p={'24px 24px 12px'} borderBottom={'1px solid #E2E2E2'}>
            <Box fontSize={14}>
              <StoreSearch shippingType="" onStoreData={handleOnStoreData}></StoreSearch>
              {storeData && storeData?.results?.length > 0 ? (
                <>
                  <Flex fontSize={'12px'}>
                    <Text>Displaying</Text>
                    <Text color={'#76797E'}>&nbsp; {storeData?.results?.length} Stores</Text>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex alignItems={'center'} fontSize={'12px'}>
                    <Text>Displaying</Text>
                    <Text color={'#76797E'}>&nbsp; 0 Stores</Text>
                  </Flex>
                </>
              )}
            </Box>
          </Container>
          <DrawerBody p={{ base: '1rem' }} pl={{ md: '1.5rem' }} pr={{ md: '1.5rem' }}>
            <StoreList
              storesLists={storeData}
              addressLocation={locationCoordinate}
              showNoResults={showNoResults}
              onClose={onClose}
              storeHeading={storeHeading}
              isStoreSearch={isStoreSearch}
            ></StoreList>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default StoreFinderDrawer
