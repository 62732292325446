import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertStatus,
  AlertTitle,
  Box,
  CloseButton,
} from '@chakra-ui/react'

type CustomToastProps = {
  status: AlertStatus
  title?: string
  description?: string
  onClose?: () => void
}

export const CustomToast = ({ status, title, description, onClose, ...props }: CustomToastProps & AlertProps) => {
  return status === 'error' ? (
    <Alert width="356px" status="error" {...props}>
      <AlertIcon />
      <Box>
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
      </Box>
      {onClose && (
        <CloseButton
          alignSelf="center"
          position="relative"
          marginLeft="auto"
          right={-1}
          top={-1}
          onClick={() => onClose()}
        />
      )}
    </Alert>
  ) : (
    <Alert width="356px" status={status} margin="1" {...props}>
      <AlertIcon />
      <Box>
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
      </Box>
      {onClose && (
        <CloseButton
          alignSelf="center"
          position="relative"
          marginLeft="auto"
          right={-1}
          top={-1}
          onClick={() => onClose()}
        />
      )}
    </Alert>
  )
}
