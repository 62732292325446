import { Box, Flex, Heading, Icon } from '@chakra-ui/react'
import React from 'react'
import { IoSearchOutline } from 'react-icons/io5'

const StoreNotFound = () => {
  return (
    <Box p={'var(--sizes-12, 48px) var(--sizes-6, 24px)'} textAlign={'center'}>
      <Box display={'inline-flex'}>
        <Icon as={IoSearchOutline} fontSize={64} />
      </Box>
      <Heading as={'h3'} fontSize={'24px'} fontWeight={'700'} lineHeight={'120%'}>
        No Results Found
      </Heading>
      <Box mb={5} mt={3} fontSize={'16px'}>
        Sorry, we can’t seem to find any stores that match your search criteria.
      </Box>
      <Box pt={2} fontSize={'16px'}>
        Please change your search details and try again.
      </Box>
    </Box>
  )
}

export default StoreNotFound
