import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCart } from '../../../../frontastic'
import { ShippingOption, ShippingOptionsList } from '../../types'

export const useShippingOptionsHandler = () => {
  const {
    shippingMethods: { data: shippingMethods },
  } = useCart()

  const [shippingOptions, setShippingOptions] = useState<ShippingOptionsList>({})

  const [shippingOptionSelected, setShippingOptionSelected] = useState<ShippingOption['id']>()

  const loadShippingOptions = useCallback(async () => {
    if (shippingMethods) {
      const shippingOptionsList: ShippingOptionsList = {}
      for (const shippingOption of shippingMethods) {
        shippingOptionsList[shippingOption.shippingMethodId] = shippingOption as unknown as ShippingOption
      }
      setShippingOptions(shippingOptionsList)
    }
  }, [shippingMethods])

  const selected = useMemo(() => {
    return undefined
    // return shippingOptionSelected
    //   ? {
    //       ...shippingOptions[shippingOptionSelected],
    //       rate: shippingOptions[shippingOptionSelected]?.zoneRates[0]?.shippingRates.find(
    //         (shippingRate) => shippingRate.price.currencyCode === currency,
    //       )?.price,
    //     }
    //   : undefined;
  }, [shippingOptions, shippingOptionSelected])

  const list = useMemo(() => Object.values(shippingOptions), [shippingOptions])

  useEffect(() => {
    loadShippingOptions()
  }, [shippingMethods])

  return {
    list,
    setSelected: (id?: string) => setShippingOptionSelected(id),
    clearSelection: () => setShippingOptionSelected(undefined),
    selected,
  }
}
