import { Box, BoxProps, Button, Image, useBreakpointValue, useTheme } from '@chakra-ui/react'
import { ContentstackBannerPromotion } from '@Types/contentstack'
import { NextLinkNoPrefetch } from 'composable'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

export const BannerPromotion = (props: ContentstackBannerPromotion) => {
  const {
    background_images_group: { background_image_desktop, background_image_mobile },
    styling_group: {
      container_margin_bottom,
      container_margin_top,
      theme,
      background_color,
      custom_background_color,
      content_alignment = 'left',
      container_size,
    },
    primary_call_to_action_group: { button_label, href },
    secondary_call_to_action_group: { secondary_button_label, secondary_href },
    analyticsTrackingData,
    relativeIndex,
    isMobileDevice,
  } = props

  const getTheme = useTheme()
  const analyticsRelativeIndex = relativeIndex ?? 1
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const minHeight: BoxProps['minHeight'] = ['110px']
  const image = isMobile ? background_image_mobile : background_image_desktop

  const containerBackgroundColor =
    background_color === 'custom' && custom_background_color
      ? custom_background_color
      : theme === 'light'
      ? 'white'
      : 'surface.inverse'

  const containerSize = container_size === 'full' ? '100%' : getTheme.breakpoints[container_size]

  const secondaryButtonProps = {
    variant: theme === 'light' ? 'outline' : 'outline-alt',
    backgroundColor: 'transparent',
    size: 'lg',
    href: secondary_href,
  }

  const primaryButtonProps = {
    variant: theme === 'light' ? 'solid' : 'solid-alt',
    size: 'lg',
    href,
  }

  const getDataPromotionName = (href, index) => {
    const analyticsLocationIdentifier = extractPath(href ?? '')
    const dataPromotionName =
      analyticsTrackingData && `${analyticsTrackingData} | ${index ?? 1} | ${analyticsLocationIdentifier}`
    return dataPromotionName
  }

  return (
    <Box
      data-promotion-name={getDataPromotionName(href, analyticsRelativeIndex)}
      minHeight={minHeight}
      maxWidth={containerSize}
      width="100%"
      position="relative"
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems="center"
      margin={'0 auto'}
      mt={!!container_margin_top ? container_margin_top : 0}
      mb={!!container_margin_bottom ? container_margin_bottom : 0}
      py={{ base: isMobile ? 10 : 6 }}
      px={{ base: 10 }}
      backgroundColor={containerBackgroundColor}
      {...(!button_label &&
        href && {
          as: NextLinkNoPrefetch,
          href,
          cursor: 'pointer',
        })}
    >
      <Box
        display="flex"
        flex={3}
        justifyContent={getContentAlignment(content_alignment)}
        {...(isMobile && { width: '100%' })}
      >
        <Image
          src={image?.url}
          alt={image?.description || image?.title || 'Banner Promotion Image'}
          style={{ height: '100%' }}
        />
      </Box>

      {(!!button_label || !!secondary_button_label) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={{ base: 4 }}
          {...(!isMobile && { px: { base: 5 }, flexWrap: 'wrap' })}
          {...(isMobile && { pt: { base: 8 }, width: '100%' })}
        >
          {!!button_label && (
            <Button
              as={NextLinkNoPrefetch}
              href={href ?? ''}
              onClick={() => {
                promoClickUtagLinkEvent(getDataPromotionName(href, analyticsRelativeIndex))
              }}
              aria-label={`${button_label}`}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'clip',
                width: isMobile ? '100%' : 'auto',
                textDecoration: 'none',
              }}
              {...primaryButtonProps}
            >
              {button_label}
            </Button>
          )}

          {!!secondary_button_label && (
            <Button
              as={NextLinkNoPrefetch}
              href={secondary_href ?? ''}
              onClick={() => {
                promoClickUtagLinkEvent(
                  getDataPromotionName(
                    secondary_href,
                    !!button_label ? analyticsRelativeIndex + 1 : analyticsRelativeIndex,
                  ),
                )
              }}
              aria-label={`${secondary_button_label}`}
              style={{
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'clip',
                width: isMobile ? '100%' : 'auto',
                textDecoration: 'none',
              }}
              {...secondaryButtonProps}
            >
              {secondary_button_label}
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

const getContentAlignment = (contentAlignment: string) => {
  if (contentAlignment === 'center') {
    return 'center'
  }

  if (contentAlignment === 'right') {
    return 'flex-end'
  }

  return 'left'
}
