import { useEffect, useState } from 'react'
import { Box, HStack, Icon } from '@chakra-ui/react'
import { IoChevronForward, IoChevronBack } from 'react-icons/io5'
import { useRouter } from 'next/router'

export const GlobalStickyNavBar = () => {
  const router = useRouter()
  const [history, setHistory] = useState<string[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isHomePage, setIsHomePage] = useState(router.asPath === '/' ? true : false)
  const [isCheckoutSuccessPage, setIsCheckoutSuccessPage] = useState(
    router.asPath.includes('/checkout/success') ? true : false,
  )

  const handleGoBack = () => {
    if (currentIndex > 0 && !isHomePage && !isCheckoutSuccessPage) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
      router.back()
    }
  }

  const handleGoForward = () => {
    if (currentIndex < history.length - 1) {
      const newIndex = currentIndex + 1
      setCurrentIndex(newIndex)
      router.push(history[newIndex])
    }
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const index = history.indexOf(url)

      if (index === -1) {
        // New page added to history
        setHistory((prevHistory) => [...prevHistory.slice(0, currentIndex + 1), url])

        setCurrentIndex((prevIndex) => prevIndex + 1)
      } else if (index < currentIndex) {
        // Navigated back in history

        setCurrentIndex(index)
      } else if (index > currentIndex) {
        // Navigated forward in history
        setHistory((prevHistory) => [...prevHistory.slice(0, index + 1)])

        setCurrentIndex(index)
      }

      // Check if we are on the home page
      setIsHomePage(url === '/')
      setIsCheckoutSuccessPage(url.includes('/checkout/success'))
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, history, currentIndex])

  return (
    <Box position="sticky" top={0} zIndex={100} bg="brand.muted">
      <HStack margin="auto" maxW="container.2xl" px={{ base: 4, lg: 10 }} py={2} height={{ base: '56px', lg: '64px' }}>
        <Box
          p={{ base: '8px', lg: '12px' }}
          _hover={{
            backgroundColor: currentIndex > 0 && !isHomePage && !isCheckoutSuccessPage ? 'shading.200' : '',
          }}
          borderRadius="base"
        >
          <Icon
            as={IoChevronBack}
            boxSize={6}
            onClick={handleGoBack}
            cursor={currentIndex > 0 && !isHomePage && !isCheckoutSuccessPage ? 'pointer' : 'not-allowed'}
            opacity={currentIndex > 0 && !isHomePage && !isCheckoutSuccessPage ? '1' : '0.5'}
          />
        </Box>
        <Box
          p={{ base: '8px', lg: '12px' }}
          _hover={{
            backgroundColor: currentIndex < history.length - 1 ? 'shading.200' : '',
          }}
          borderRadius="base"
        >
          <Icon
            as={IoChevronForward}
            boxSize={6}
            onClick={handleGoForward}
            cursor={currentIndex < history.length - 1 ? 'pointer' : 'not-allowed'}
            opacity={currentIndex < history.length - 1 ? '1' : '0.5'}
          />
        </Box>
      </HStack>
    </Box>
  )
}
