import { Text, Stack, RadioGroup, Alert, AlertIcon } from '@chakra-ui/react'

import { EditableContentCard, DetailedRichList } from './'
import { useAccount } from 'frontastic'
import { useFormat } from 'helpers/hooks/useFormat'

export const SubscriptionsForm = () => {
  // Mocked Component
  const { formatMessage } = useFormat({ name: 'common' })

  const { account: customer } = useAccount()

  if (!customer) return <></>

  const subscriptionsOptions = [
    {
      value: 'week',
      label: formatMessage({
        id: 'account.dashboard.subscriptions.option1.label',
      }),
      radioLabel: formatMessage({
        id: 'account.dashboard.subscriptions.option1.radioLabel',
      }),
      description: formatMessage({
        id: 'account.dashboard.subscriptions.option1.description',
      }),
    },
    {
      value: 'month',
      label: formatMessage({
        id: 'account.dashboard.subscriptions.option2.label',
      }),
      radioLabel: formatMessage({
        id: 'account.dashboard.subscriptions.option2.radioLabel',
      }),
      description: formatMessage({
        id: 'account.dashboard.subscriptions.option2.description',
      }),
    },
    {
      value: 'none',
      label: formatMessage({
        id: 'account.dashboard.subscriptions.option3.label',
      }),
      radioLabel: formatMessage({
        id: 'account.dashboard.subscriptions.option3.radioLabel',
      }),
      description: formatMessage({
        id: 'account.dashboard.subscriptions.option3.description',
      }),
    },
  ]

  return (
    <EditableContentCard
      size="Large"
      title={formatMessage({
        id: 'account.dashboard.subscriptions.title',
      })}
      readOnly={<Text>{subscriptionsOptions[0].label}</Text>}
      updateButtonLabel={formatMessage({
        id: 'action.updateSubscription',
      })}
      edit={
        <Stack>
          <Text marginBottom={8}>
            {formatMessage({
              id: 'account.dashboard.subscriptions.subTitle',
            })}
          </Text>
          <RadioGroup defaultValue={subscriptionsOptions[0].value}>
            <Stack spacing={5} direction="column">
              {subscriptionsOptions.map((item) => (
                <DetailedRichList
                  key={item.value}
                  description={item.description}
                  label={item.label}
                  radioValue={item.value}
                  type="Radio"
                />
              ))}
            </Stack>
          </RadioGroup>
          <Alert status="warning">
            <AlertIcon />
            {formatMessage({ id: 'featureNotImplemented' })}
          </Alert>
        </Stack>
      }
    />
  )
}
