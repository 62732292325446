/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { AspectRatio, Box, HStack, Link, Stack, StackProps, Icon, useToken, Container } from '@chakra-ui/react'
import { NextLinkNoPrefetch, getImageAsset } from 'composable'
import { IoChevronForward, IoChevronBack, IoImagesOutline, IoPlayCircleOutline } from 'react-icons/io5'
import { Carousel, CarouselSlide, useCarousel } from './carousel'
import { PDPVideo } from './pdp/components/pdp-video/index'

export interface GalleryProps {
  imagesAndVideos?: ImagesAndVideos[]
  href?: string
  aspectRatio?: number
  rootProps?: StackProps
  style?: GalleryStyle
  selectedSwatch?: string
  onClick?: (number) => void
  notifyCurrentSlideIndex?: (index: number) => void
}

export type ImagesAndVideos = {
  id?: string
  src?: string
  alt?: string
  type?: 'image' | 'video'
}

export type GalleryStyle = 'slide-show' | 'blog' | undefined

export const Gallery = (props: GalleryProps) => {
  const [textPrimary, brandSecondary] = useToken('colors', ['text.primary', 'brand.secondary'])
  const { imagesAndVideos, aspectRatio = 4 / 3, rootProps } = props
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    if (props?.notifyCurrentSlideIndex) {
      props?.notifyCurrentSlideIndex(currentSlide)
    }
  }, [currentSlide])

  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
  })
  const hasPrevious = currentSlide !== 0
  const hasNext = currentSlide < imagesAndVideos.length - 1

  if (!imagesAndVideos?.length) {
    return (
      <Stack spacing="4" {...rootProps}>
        <ElementLinkHandler href={props.href}>
          <AspectRatio ratio={aspectRatio} width="100%" bg="gray.200">
            <Container>
              <IoImagesOutline size={'48px'} color={brandSecondary} />
            </Container>
          </AspectRatio>
        </ElementLinkHandler>
      </Stack>
    )
  }

  return (
    <Stack
      key={`gallery-${props?.selectedSwatch}`} // This is to force a re-render when the selectedSwatch changes. This is needed to corrently render the video scripts
      width="100%"
      borderRadius="base"
      overflow="hidden"
      spacing={{ base: 6, lg: 10 }}
    >
      <Stack width="100%" borderRadius="base" overflow="hidden" position="relative">
        <Box
          display={{ base: 'none', lg: imagesAndVideos?.length === 1 ? 'none' : 'flex' }}
          justifyContent="space-between"
          zIndex={50}
          position="absolute"
          top="50%"
          pl={4}
        >
          <Icon
            as={IoChevronBack}
            boxSize={12}
            p={{ base: '8px', lg: '12px' }}
            _hover={{
              backgroundColor: hasPrevious ? 'shading.200' : '',
            }}
            backgroundColor="shading.200"
            borderRadius="base"
            onClick={() => slider.current?.prev()}
            cursor={hasPrevious ? 'pointer' : 'not-allowed'}
            opacity={hasPrevious ? '1' : '0.5'}
            aria-label="Previous Slide"
          />
        </Box>
        <Box
          display={{ base: 'none', lg: imagesAndVideos?.length === 1 ? 'none' : 'flex' }}
          justifyContent="space-between"
          zIndex={50}
          position="absolute"
          top="50%"
          pr={4}
          right={0}
        >
          <Icon
            as={IoChevronForward}
            boxSize={12}
            p={{ base: '8px', lg: '12px' }}
            _hover={{
              backgroundColor: hasNext ? 'shading.200' : '',
            }}
            backgroundColor="shading.200"
            borderRadius="base"
            onClick={() => slider.current?.next()}
            cursor={hasNext ? 'pointer' : 'not-allowed'}
            opacity={hasNext ? '1' : '0.5'}
            aria-label="Next Slide"
          />
        </Box>

        <Carousel ref={ref}>
          {imagesAndVideos?.map((media, i) => {
            if (media.type === 'video') {
              return (
                <CarouselSlide key={`carousel-${i}`} cursor={'pointer'}>
                  <ElementLinkHandler href={props.href}>
                    <AspectRatio
                      ratio={aspectRatio}
                      transition="all 200ms"
                      opacity={currentSlide === i ? 1 : 0.5}
                      _hover={{ opacity: 1 }}
                    >
                      <PDPVideo videoUrl={media.src} id={i} />
                    </AspectRatio>
                  </ElementLinkHandler>
                </CarouselSlide>
              )
            } else {
              const { serverurl, asset, optionalModifier } = getImageAsset(media.src)

              const imageSrc = serverurl + asset + '?$smain$'
              const imageAlt = media.alt && media.alt !== '' ? media.alt : `Product image ${i + 1}`
              const imageAriaLabel = media.alt && media.alt !== '' ? media.alt : `Product image ${i + 1}`
              const imageOnClick = () => props?.onClick(currentSlide)

              return (
                <CarouselSlide key={`carousel-${i}`} cursor={'zoom-in'}>
                  <ElementLinkHandler href={props.href}>
                    <AspectRatio
                      ratio={aspectRatio}
                      transition="all 200ms"
                      opacity={currentSlide === i ? 1 : 0.5}
                      _hover={{ opacity: 1 }}
                    >
                      <Image
                        src={imageSrc}
                        alt={imageAlt}
                        aria-label={imageAriaLabel}
                        role="img"
                        layout="fill"
                        objectFit="contain"
                        priority={i === 0}
                        onClick={imageOnClick}
                        quality={100}
                      />
                    </AspectRatio>
                  </ElementLinkHandler>
                </CarouselSlide>
              )
            }
          })}
        </Carousel>
      </Stack>

      {imagesAndVideos?.length > 1 && (
        <HStack overflowX="scroll" width="fit-content" alignSelf="center">
          {imagesAndVideos?.map((media, index) => (
            <AspectRatio
              ratio={1}
              key={index}
              cursor="pointer"
              boxSize={12}
              border="2px solid"
              borderColor={currentSlide === index ? 'text.primary' : 'surface.border'}
              borderRadius="base"
            >
              {media.type === 'video' ? (
                <Box position="relative" width="100%" height="100%" onClick={() => slider.current?.moveToIdx(index)}>
                  <Image
                    src={media.src ? `${media.src}?$sthumb$` : null}
                    alt={`Product Video ${index + 1}`}
                    aria-label={`Product Video ${index + 1}`}
                    layout="fill"
                    objectFit="cover"
                    quality={100}
                  />
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    backgroundColor="black"
                    opacity="0.2"
                    zIndex="1"
                  />
                  <Icon
                    as={IoPlayCircleOutline}
                    boxSize={6}
                    color="white"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="2"
                  />
                </Box>
              ) : (
                <Image
                  key={index}
                  src={media.src ? `${media.src}?$sthumb$` : null}
                  alt={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                  aria-label={media.alt && media.alt !== '' ? media.alt : `Product image ${index + 1}`}
                  role={'img'}
                  width={12}
                  height={12}
                  objectFit="fill"
                  onClick={() => slider.current?.moveToIdx(index)}
                />
              )}
            </AspectRatio>
          ))}
        </HStack>
      )}
    </Stack>
  )
}

const ElementLinkHandler = (props: { children: JSX.Element; href?: string }) => {
  return props.href ? (
    <Link as={NextLinkNoPrefetch} href={props.href}>
      {props.children}
    </Link>
  ) : (
    props.children
  )
}
