import { useRouter } from 'next/router'
import { Box, Link as ChakraLink } from '@chakra-ui/react'
import { useMegaMenu, MEGA_MENU_ITEM, MEGA_MENU_COVER_CARD } from 'composable'
import { AnimatePresence, motion } from 'framer-motion'
import { MegaMenuContentColumns } from './MegaMenuContentColumns'
import { MAXIMUM_MEGA_MENU_GROUP_CHILDREN } from '../general'
import { Entry } from '../hooks/useContentstack'

interface MegaMenuItemProps {
  item: Entry
}

export type MegaMenuLayoutType = 'linksOnly' | 'cardsOnly' | 'mixed' | null

export interface LayoutConfig {
  columns: number
  type?: MegaMenuLayoutType
  hasDoubleCardsColumn: boolean
}

export const MegaMenuItem = ({ item }: MegaMenuItemProps) => {
  const router = useRouter()

  const label = item?.label ?? ''
  const href = item?.href ?? ''

  const children =
    item?.children?.length > MAXIMUM_MEGA_MENU_GROUP_CHILDREN
      ? item?.children?.slice(0, MAXIMUM_MEGA_MENU_GROUP_CHILDREN)
      : item?.children
  const hasChildren = children?.length > 0

  const { linkProps, rootProps, motionProps, renderContent, childrenLinkProps } = useMegaMenu({
    item: {
      href,
      hasChildren,
      children,
    },
    routerPush: router.push,
  })

  const getLayoutConfig = (children: Entry[]): LayoutConfig => {
    let hasDoubleCardsColumn = false

    let isLinksOnly = true
    let cardsOnly = true
    let columns = 0

    children?.forEach((curr: Entry) => {
      if (curr?.children.length === 0) return

      columns++

      if (curr?.children[0]?._content_type_uid !== MEGA_MENU_ITEM) {
        isLinksOnly = false
      }
      if (curr?.children[0]?._content_type_uid !== MEGA_MENU_COVER_CARD) {
        cardsOnly = false
      }

      if (
        curr?.children[0]._content_type_uid === MEGA_MENU_COVER_CARD &&
        curr?.children?.length > 1 &&
        curr?.children[1]._content_type_uid === MEGA_MENU_COVER_CARD
      ) {
        hasDoubleCardsColumn = true
      }
    })

    let layoutType = (isLinksOnly ? 'linksOnly' : cardsOnly ? 'cardsOnly' : 'mixed') as MegaMenuLayoutType

    return {
      columns: columns,
      type: children?.length > 0 ? layoutType : null,
      hasDoubleCardsColumn,
    }
  }

  const layoutConfig = getLayoutConfig(children)

  return (
    item && (
      <Box {...rootProps}>
        <ChakraLink
          height="full"
          lineHeight="normal"
          position="relative"
          href={href}
          py={2}
          px={4}
          tabIndex={0}
          display="block"
          boxSizing="border-box"
          textStyle={'body-75'}
          onFocus={linkProps?.onFocus}
          whiteSpace="nowrap"
          {...linkProps}
          _hover={{
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '2px',
              left: 0,
              right: 0,
              height: '2px',
              backgroundColor: 'var(--semantic-brand-primary, #000)',
            },
            textStyle: 'heading-desktop-75',
          }}
        >
          {label}
        </ChakraLink>
        <AnimatePresence>
          {renderContent && (
            <motion.div {...motionProps}>
              <Box
                alignSelf={'stretch'}
                background="white"
                boxShadow={'0px 8px 16px -8px rgba(43, 49, 57, 0.20)'}
                px={10}
                py={10}
              >
                <MegaMenuContentColumns
                  items={children}
                  linkProps={childrenLinkProps}
                  layoutConfig={layoutConfig}
                  onClose={(e) => rootProps?.onMouseLeave(e)}
                />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    )
  )
}
