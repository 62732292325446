/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'
import { convertBillingToCTAddress, convertShippingToCTAddress, shippingAddressInSync } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import _debounce from 'lodash.debounce'
import _merge from 'lodash.merge'
import { useAccount, useCart } from 'frontastic'
import { fetchFormattedExperianClosestSuggestion } from 'frontastic/actions/experian'
import { GuestForm } from './Forms/guest-form'
import { ShippingSection } from './shipping-section'
import { isCheckoutAddressSameAsRecommended } from '../../../helpers/utils/experian-address-utils'
import { Section, SectionHeader } from '../../section'
import { useCheckout } from '../checkout-provider'
import { Address } from '@Types/account/Address'
import { US_ARMY_STATES } from 'composable'
import { useAtgLegacyCart } from 'frontastic/contexts'

export interface Step1Props {
  onSubmit: () => void
}

export const Step1 = ({ onSubmit }: Step1Props) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { formattedCart: cart } = useAtgLegacyCart()
  const [addressSetByPosBroker, setAddressSetByPosBroker] = useState(false)
  const { account } = useAccount()
  const {
    checkoutState,
    setCheckoutState,
    validation,
    validationResults,
    savedShippingAddresses,
    isLoadingStep1,
    setIsLoadingStep1,
    addressConfirmationModal,
    setAddressConfirmationModal,
    addressConfirmationDecision,
    recommendedAddress,
    setRecommendedAddress,
  } = useCheckout()

  let formsToValidate = ['guestForm']
  if (account) {
    if (savedShippingAddresses.selected || checkoutState?.shipping_address?.additional_address_info == 'shipToStore') {
      formsToValidate = ['guestForm']
    } else {
      formsToValidate = ['guestForm', 'shippingAddressForm']
    }
  } else if (checkoutState?.shipping_address?.additional_address_info == 'shipToStore') {
    formsToValidate = ['guestForm']
  } else {
    formsToValidate = ['guestForm', 'shippingAddressForm']
  }

  const formIsInvalid = formsToValidate.some((form) => !validationResults[form])

  const step1Complete = useMemo(
    () => !formIsInvalid /*&& !!cart?.shippingInfo?.shippingMethodId*/,
    [formIsInvalid, cart?.shippingInfo],
  )

  const validateAllForms = async () => {
    await Promise.all(formsToValidate.map((form) => validation.run(form)))
  }

  const handleSubmit = async (overwriteShippingFromModal?: Address) => {
    await validateAllForms()

    if (!step1Complete) {
      return
    }

    let shippingAddress = savedShippingAddresses.selected || convertShippingToCTAddress(checkoutState.shipping_address)

    let billingAddress = checkoutState.config.billingIsShipping
      ? convertBillingToCTAddress(checkoutState.shipping_address)
      : convertBillingToCTAddress(checkoutState.billing_address)

    if (overwriteShippingFromModal) {
      shippingAddress = {
        ...cart?.shippingAddress,
        firstName: checkoutState.shipping_address.first_name,
        lastName: checkoutState.shipping_address.last_name,
        phone: checkoutState.shipping_address.phone_number,
        streetName: overwriteShippingFromModal.streetName,
        city: overwriteShippingFromModal.city,
        postalCode: overwriteShippingFromModal.postalCode,
        state: overwriteShippingFromModal.state,
        country: overwriteShippingFromModal.country,
      }

      billingAddress = checkoutState.config.billingIsShipping
        ? {
            ...cart?.shippingAddress,
            firstName: checkoutState.shipping_address.first_name,
            lastName: checkoutState.shipping_address.last_name,
            phone: checkoutState.shipping_address.phone_number,
            streetName: overwriteShippingFromModal.streetName,
            city: overwriteShippingFromModal.city,
            postalCode: overwriteShippingFromModal.postalCode,
            state: overwriteShippingFromModal.state,
            country: overwriteShippingFromModal.country,
          }
        : convertBillingToCTAddress(checkoutState.billing_address)
    }

    setIsLoadingStep1(true)

    try {
      /*await updateCart({
        account: {
          email: checkoutState.customer.email ?? account?.email,
        },
        shipping: shippingAddress,
        billing: billingAddress,
        addressSetByPosBroker: addressSetByPosBroker,
      })*/
    } catch (err) {
      console.log(err)
    }

    setIsLoadingStep1(false)
    onSubmit()
  }

  const handleAddress = async () => {
    let proceed: boolean
    const checkoutStateAddress = checkoutState?.shipping_address
    const experianSuggestedAddress = await fetchFormattedExperianClosestSuggestion(checkoutStateAddress)
    const isAddressesSame = await isCheckoutAddressSameAsRecommended(checkoutStateAddress, experianSuggestedAddress)
    const isUSArmyBaseOrState = US_ARMY_STATES.includes(checkoutState?.shipping_address?.state)

    if (!isAddressesSame && !isUSArmyBaseOrState) {
      setRecommendedAddress(experianSuggestedAddress)
      setAddressConfirmationModal(true)

      return (proceed = false)
    } else {
      return (proceed = true)
    }
  }

  const handleStep1Submit = async () => {
    if (
      checkoutState.shipping_address.additional_address_info == 'shipToStore' ||
      addressConfirmationDecision ||
      addressSetByPosBroker
    ) {
      handleSubmit()
    } else {
      handleAddress().then((res) => {
        if (res === true) {
          handleSubmit()
        }
      })
    }
  }

  const handleShippingAddressUpdate = async () => {
    setIsLoadingStep1(true)

    const shippingAddress =
      savedShippingAddresses.selected || convertShippingToCTAddress(checkoutState.shipping_address)

    const billingAddress = checkoutState.config.billingIsShipping
      ? convertBillingToCTAddress(checkoutState.shipping_address)
      : convertBillingToCTAddress(checkoutState.billing_address)

    /*await updateCart({
      account: {
        email: checkoutState.customer.email ?? account?.email,
      },
      shipping: shippingAddress,
      billing: billingAddress,
      addressSetByPosBroker: addressSetByPosBroker,
    })*/

    setIsLoadingStep1(false)
  }

  useEffect(() => {
    const saveShippingAddress = setTimeout(async () => {
      const isCartInSync = shippingAddressInSync(
        { ...cart, locale: cart.locale || { language: 'en' }, cartId: cart.cartId || '' },
        checkoutState.shipping_address,
      )

      let shouldUpdateShippingAddress: boolean
      if (checkoutState.shipping_address.additional_address_info == 'shipToStore') {
        shouldUpdateShippingAddress = !isCartInSync
      } else {
        shouldUpdateShippingAddress =
          (savedShippingAddresses?.selected?.addressId === checkoutState.shipping_address.id ||
            validationResults['shippingAddressForm'] === true) &&
          !isCartInSync
      }

      if (shouldUpdateShippingAddress) {
        await handleShippingAddressUpdate()
      }
    }, 1000)

    const debounce = () => clearTimeout(saveShippingAddress)

    return debounce
  }, [savedShippingAddresses.selected, validationResults['shippingAddressForm'], checkoutState.shipping_address])

  useEffect(() => {
    if (addressSetByPosBroker !== cart?.addressSetByPosBroker) setAddressSetByPosBroker(cart?.addressSetByPosBroker)
  }, [cart?.addressSetByPosBroker])

  return (
    <Stack spacing={{ base: 2, lg: 6 }}>
      <Section>
        <SectionHeader
          hasRequiredFields
          title={formatMessage({
            id: 'checkout.guestSection.title',
          })}
        />
        <GuestForm
          initialValues={checkoutState.customer}
          onChange={({ data, isValid }) => {
            if (!isValid) return
            setCheckoutState((state) => {
              return {
                ...state,
                customer: data,
              }
            })
          }}
        />
      </Section>

      <ShippingSection
        addressConfirmationModal={
          checkoutState.shipping_address.additional_address_info != 'shipToAddress' &&
          checkoutState.shipping_address.additional_address_info != 'shipToStore' &&
          addressConfirmationModal &&
          recommendedAddress &&
          !addressConfirmationDecision &&
          !addressSetByPosBroker
        }
        modalOnClose={() => {
          setAddressConfirmationModal(false)
        }}
        checkoutAddress={checkoutState.shipping_address}
        experianAddress={recommendedAddress}
        handleStep1Submit={handleSubmit}
        setAddressSetByPosBroker={setAddressSetByPosBroker}
      />

      <Box textAlign="right" px={{ base: 4, md: 0 }}>
        <Button
          px={4}
          py={2.5}
          fontSize="base"
          w={{ base: 'full', md: 'fit-content' }}
          minW={'205px'}
          variant={'solid'}
          onClick={() => handleStep1Submit()}
          isDisabled={!step1Complete || !cart}
          isLoading={!cart || isLoadingStep1}
        >
          {formatMessage({ id: 'action.continuePayment' })}
        </Button>
      </Box>
    </Stack>
  )
}
