import { useEffect } from 'react'
import { useBreakpointValue } from '@chakra-ui/react'
import {
  BORDER_FREE_WELCOME_SCRIPT_URL,
  BRANCH_IO_SCRIPT_KEY,
  FUNCTIONAL_COOKIE_CATEGORY,
} from 'composable/components/general'
import { useAtgUser } from 'frontastic/contexts'

export const ThirdPartyScripts = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { userSessionData } = useAtgUser()
  let borderFreeScriptUrl = BORDER_FREE_WELCOME_SCRIPT_URL
  const countryCode = userSessionData?.dxlCountry

  const isWelcome = () => {
    if (document.cookie.length > 0) {
      if (document.cookie.indexOf('wlcme') != -1) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  const addBranchIOScript = () => {
    let text =
      '(function (b, r, a, n, c, h, _, s, d, k) {' +
      'if (!b[n] || !b[n]._q) {' +
      'for (; s < _.length;) c(h, _[s++]);' +
      'd = r.createElement(a);' +
      'd.async = 1;' +
      'd.src = "https://cdn.branch.io/branch-latest.min.js";' +
      'k = r.getElementsByTagName(a)[0];' +
      'k.parentNode.insertBefore(d, k);' +
      'b[n] = h' +
      '}' +
      '})(window, document, "script", "branch", function (b, r) {' +
      'b[r] = function () {' +
      'b._q.push([r, arguments])' +
      '}' +
      '}, {' +
      '_q: [],' +
      '_v: 1' +
      '}, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);'

    text += "branch.init('" + BRANCH_IO_SCRIPT_KEY + "');"
    appendScriptTag('branchIOScript', text, FUNCTIONAL_COOKIE_CATEGORY)
  }

  useEffect(() => {
    addBranchIOScript()
  }, [])

  useEffect(() => {
    appendScript('https://code.jquery.com/jquery-3.7.1.min.js', 'jQuery-script')
  }, [])

  useEffect(() => {
    if (!document.querySelector('#borderFreeScript') && !isWelcome() && countryCode && !isMobile) {
      borderFreeScriptUrl = borderFreeScriptUrl + countryCode
      appendScript(borderFreeScriptUrl, 'borderFreeScript')
    }
  }, [countryCode])

  return <></>
}
