import { BoxProps, Flex, Text, TextProps } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

interface SectionHeaderProps {
  hasRequiredFields?: boolean
  requiredMarkText?: string
  requiredMarkTextProps?: TextProps
  textProps?: TextProps
  boxProps?: BoxProps
  title?: string | JSX.Element
  marginBottom?: number | 6
}

const RequiredMark = ({
  requiredMarkText,
  requiredMarkTextProps,
}: Pick<SectionHeaderProps, 'requiredMarkTextProps' | 'requiredMarkText'>) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const defaultText = formatMessage({
    id: 'section.required',
  })

  return (
    <Text textStyle={{ base: 'body-50', md: 'body-75' }} color={'text-muted'} {...requiredMarkTextProps}>
      {requiredMarkText ?? defaultText}
    </Text>
  )
}

export const SectionHeader = ({
  hasRequiredFields,
  requiredMarkText,
  requiredMarkTextProps,
  textProps,
  boxProps,
  title,
  marginBottom,
}: SectionHeaderProps) => {
  const parsedMarginBottom = marginBottom ? { base: 4, lg: marginBottom } : { base: 4, lg: 6 }

  return (
    <Flex justify="space-between" marginBottom={parsedMarginBottom} {...boxProps}>
      {title && (
        <Text textStyle={{ base: 'heading-mobile-200', md: 'heading-desktop-200' }} {...textProps}>
          {title}
        </Text>
      )}
      {hasRequiredFields && (
        <RequiredMark requiredMarkText={requiredMarkText} requiredMarkTextProps={requiredMarkTextProps} />
      )}
    </Flex>
  )
}
