import { useEffect, useState } from 'react'
import { Divider, Stack } from '@chakra-ui/react'
import { PAYMENT_METHOD_STRIPE } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart, useStripe } from 'frontastic/provider/frontastic'
import { CheckoutDetailItem } from './checkout-detail-item'
import { useCheckout, useCheckoutSteps } from './checkout-provider'

export const PaymentDetails = () => {
  const intl = useFormat({ name: 'common' })
  const { checkoutState, payment, paymentHandler } = useCheckout()
  const { goTo } = useCheckoutSteps()
  const { data: cart } = useCart()

  const offlinePaymentDetails = [
    intl.formatMessage({
      id: 'checkout.paymentSection.offlinePayment',
    }),
  ]

  const formatBillingDetails = () => {
    if (checkoutState.config.billingIsShipping) {
      return [intl.formatMessage({ id: 'checkout.billingSection.sameAsShipping' })]
    } else {
      const firstName = cart?.billingAddress?.firstName ?? ''
      const lastName = cart?.billingAddress?.lastName ?? ''
      const streetNumber = cart?.billingAddress?.streetNumber ?? ''
      const streetName = cart?.billingAddress?.streetName ?? ''
      const city = cart?.billingAddress?.city ?? ''
      const state = cart?.billingAddress?.state ?? ''
      const postalCode = cart?.billingAddress?.postalCode ?? ''
      const country = cart?.billingAddress?.country ?? ''
      return [`${firstName} ${lastName}`, `${streetNumber} ${streetName}`, `${city}, ${state}, ${postalCode}`]
    }
  }

  return (
    <Stack spacing={4} divider={<Divider />}>
      {paymentHandler.selected && (
        <CheckoutDetailItem
          title={intl.formatMessage({
            id: 'checkout.success.orderDetails.paymentMethod',
          })}
          details={offlinePaymentDetails}
        />
      )}
      {Object.values(cart?.billingAddress ?? {}).some((val) => !!val) && (
        <CheckoutDetailItem
          title={intl.formatMessage({ id: 'checkout.billingSection.title' })}
          details={formatBillingDetails()}
          onClickEdit={() => goTo('billing')}
        />
      )}
    </Stack>
  )
}
