import { SearchIcon } from '@chakra-ui/icons'
import { HStack, Text, useBreakpointValue, Box, Link } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { APP_CONTEXT } from '../general'
import MyStore from '../mystore/my-store'

export const MegaMenuDetailsBar = () => {
  const { data: cart } = useCart()
  const { formatMessage } = useFormat({ name: 'common' })
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const orderID = cart?.orderNumber ?? ''

  return (
    <HStack
      maxW="container.2xl"
      w={'100%'}
      m="auto"
      px={{ base: 4, lg: 10 }}
      py={2}
      spacing={8}
      bg="brand.muted"
      justifyContent={isMobile ? 'space-between' : ' '}
    >
      <HStack spacing={1} cursor="pointer">
        <MyStore viewType={'drawer'} shippingType={{ shipToStore: true }} showMyStoreTitle={true}></MyStore>
      </HStack>
      {!!orderID && (
        <HStack spacing={1}>
          <Text textStyle="body-75">{formatMessage({ id: 'megamenu.details.orderID' })}</Text>
          <Text textStyle={{ base: 'heading-mobile-75', lg: 'heading-desktop-75' }}>{orderID}</Text>
        </HStack>
      )}
      {!isMobile && APP_CONTEXT === 'STS' ? (
        <Box display={'flex'} gap={'4px'} justifyContent={'center'} alignItems={'center'}>
          <SearchIcon width={'16px'} height={'16px'} />
          <Link fontSize={'14px'} textStyle="body-75" href={'/order-search'}>
            {formatMessage({ id: 'megamenu.details.orderSearch' })}
          </Link>
        </Box>
      ) : (
        ''
      )}
    </HStack>
  )
}
