import { useState } from 'react'
import { Stack, VStack } from '@chakra-ui/react'
import { SideBarNavMenuGeneric } from './sidebar-generic-nav-menu'
import { ContentstackRichText } from '../contentstack'

interface SidebarNavProps {
  activeItem?: any
  size: 'Small' | 'Large'
  state?: 'Expanded' | 'Collapsed'
  title: string
  items: any[]
}

export const SidebarNavGeneric = ({ activeItem, size, title, items }: SidebarNavProps) => {
  const [activeItemIdx, setActiveItemIdx] = useState<number>(0)

  const setActiveIdxHandler = (activeIdx: number) => {
    setActiveItemIdx(activeIdx)
  }

  return (
    <>
      {size === 'Small' && (
        <Stack alignItems={'self-start'}>
          <ContentstackRichText key={`rich_text_${title}`} {...activeItem?.data} styles={{ px: 0, py: 0 }} />
        </Stack>
      )}
      {size === 'Large' && (
        <Stack alignItems={'self-start'} pt={12} pr={6} pb={12} pl={24} gap={'20px'}>
          <VStack spacing={'0'} width={'full'} alignItems={'self-start'}>
            <SideBarNavMenuGeneric
              items={items}
              activeItem={activeItem}
              activeItemIdx={activeItemIdx}
              setActiveIdx={setActiveIdxHandler}
            />
          </VStack>
        </Stack>
      )}
    </>
  )
}
