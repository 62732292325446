import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackCoverCard as ContentstackContentstackCoverCardProps } from '@Types/contentstack'
import { CoverCard } from '../cms-components'
import { ObjectFit } from '../cms-components/types'
export interface ComponentCoverCardProps extends ContentstackContentstackCoverCardProps {
  root?: Omit<BoxProps, 'children'>
  onClickHandler?: () => void
}

export const ContentstackCoverCard = (props: ComponentCoverCardProps) => {
  const {
    background_images_group: { background_image_desktop, background_image_mobile, image_desktop_fit },
    overlay_images_group: { overlay_image_desktop, overlay_image_mobile, overlay_image_height },
    title_group: { cover_card_title, cover_card_title_font_family, cover_card_title_font_size },
    styling_group: {
      content_alignment,
      theme,
      content_positioning,
      container_margin_bottom,
      container_margin_top,
      container_size,
      display_opacity_overlay,
    },
    eyebrow,
    content,
    href,
    root,
    onClickHandler,
    analyticsTrackingData,
    isMobileDevice,
  } = props

  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const backgroundImageProps = {
    src: isMobile ? background_image_mobile?.url : background_image_desktop?.url,
    alt: isMobile
      ? background_image_mobile?.description || background_image_mobile?.title || cover_card_title
      : background_image_desktop?.description || background_image_desktop?.title || cover_card_title,
    objectFit: (image_desktop_fit as ObjectFit) ?? 'cover',
  }

  const overlayImageProps = {
    src: isMobile ? overlay_image_mobile?.url : overlay_image_desktop?.url,
    alt: isMobile
      ? overlay_image_mobile?.description || overlay_image_mobile?.title || 'Image overlay Logo'
      : overlay_image_desktop?.description || overlay_image_desktop?.title || 'Image overlay Logo',
    height: overlay_image_height,
  }

  return (
    <CoverCard
      isMobileDevice={isMobileDevice}
      image={backgroundImageProps}
      overlayImage={overlayImageProps}
      eyebrow={{
        children: eyebrow ?? '',
      }}
      title={{
        font: {
          size: cover_card_title_font_size ?? '',
          family: cover_card_title_font_family ?? '',
        },
        children: cover_card_title ?? '',
      }}
      description={{
        children: content ? <Box dangerouslySetInnerHTML={{ __html: content }} /> : undefined,
      }}
      href={href ?? ''}
      contentAlignment={content_alignment as any}
      contentPositioning={content_positioning as any}
      containerMarginBottom={container_margin_bottom as any}
      containerMarginTop={container_margin_top as any}
      containerSize={container_size as any}
      theme={theme as any}
      displayOpacityOverlay={display_opacity_overlay}
      root={root}
      onClick={onClickHandler}
      analyticsTrackingData={analyticsTrackingData}
      relativeIndex={props.relativeIndex}
    />
  )
}
