import { Box, Container, Grid, GridItem, Skeleton } from '@chakra-ui/react'
import { useCheckout } from './checkout-provider'
import { Section } from '../section'
import { useCart } from 'frontastic'
import { use, useEffect, useMemo, useState } from 'react'
import { OutOfStockModal } from '../out-of-stock-modal'

interface CheckoutGridProps {
  currentStep: string
  mobileCartSummary: React.ReactElement
  desktopCartSummary: React.ReactElement
  step1: React.ReactElement
  step2: React.ReactElement
  review: React.ReactElement
}

export const CheckoutGrid = ({
  currentStep,
  mobileCartSummary,
  desktopCartSummary,
  step1,
  step2,
  review,
}: CheckoutGridProps) => {
  const { order } = useCheckout()
  const orderPlaced = Boolean(order)
  const { data: cart, removeItem, updateItem } = useCart()

  const getOutOrReducedStockItems = () => {
    return cart?.lineItems?.filter((item) => item?.count > item?.variant?.availableQuantity) ?? []
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const outofStockOrReducedStockItems = useMemo(() => getOutOrReducedStockItems(), [cart])

  return (
    <>
      {!orderPlaced && mobileCartSummary}

      {outofStockOrReducedStockItems.length > 0 && (
        <OutOfStockModal
          outofStockOrReducedStockItems={outofStockOrReducedStockItems}
          totalCartItems={cart?.lineItems?.length}
        />
      )}

      <Container
        mb={'auto'}
        maxW="container.2xl"
        py={{ base: 4, md: 12 }}
        px={{ base: 0, sm: 0, md: 4, lg: 8, xl: 12 }}
      >
        <Grid
          templateColumns={{
            base: '1fr',
            sm: '1fr',
            md: '1fr 1fr',
            lg: '2fr 1fr',
            xl: 'auto 505px',
          }}
          gap={8}
        >
          <GridItem>
            <Skeleton isLoaded={!orderPlaced}>
              {currentStep === 'step1' && step1}
              {currentStep === 'step2' && step2}
              {currentStep === 'review' && review}
            </Skeleton>
          </GridItem>

          <GridItem>
            <Skeleton isLoaded={!orderPlaced}>{desktopCartSummary}</Skeleton>
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}
