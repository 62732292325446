import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general'
import React, { useEffect } from 'react'

const SalesFloorChat = () => {
  useEffect(() => {
    addAndInitialise()
  }, [])

  const getScriptUrl = () => {
    if (window?.location?.hostname == 'www.dxl.com') {
      return '/scripts/salesfloor_prod.js'
    } else {
      return '/scripts/salesfloor_stage.js'
    }
  }

  const addAndInitialise = () => {
    const url = getScriptUrl()
    const script = document.createElement('script')
    script.src = url
    script.async = true
    const scriptSrcVal = script.src
    appendScript(scriptSrcVal, 'salesfloorscript', FUNCTIONAL_COOKIE_CATEGORY)
    return () => {
      document.head.removeChild(script)
    }
  }

  return <></>
}

export default SalesFloorChat
