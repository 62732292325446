import { Box, ListItem, UnorderedList, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

export const PasswordRequirements = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  return (
    <Box textStyle={{ base: 'callouts-blockquote-75-Default', md: 'callouts-blockquote-75' }}>
      <Text>
        {formatMessage({
          id: 'account.dashboard.password.requirements.line1',
        })}
      </Text>
      <UnorderedList paddingLeft={1}>
        <ListItem>
          {formatMessage({
            id: 'account.dashboard.password.requirements.line2',
          })}
        </ListItem>
        <ListItem>
          {formatMessage({
            id: 'account.dashboard.password.requirements.line3',
          })}
        </ListItem>
      </UnorderedList>
    </Box>
  )
}
