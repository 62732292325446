import { FunctionComponent } from 'react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  HStack,
  Button,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useRefinementList } from 'react-instantsearch-hooks-web'
import { DEFAULT_INITIAL_REFINEMENTS_LIMIT, DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT } from '../../constants'
import { RefinementListProps } from '../../types'

export const RefinementList: FunctionComponent<RefinementListProps> = (props) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const {
    translationKey,
    limit = DEFAULT_INITIAL_REFINEMENTS_LIMIT,
    showMoreLimit = DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT,
  } = props
  const { items, refine, canToggleShowMore, isShowingMore, toggleShowMore } = useRefinementList({
    ...props,
    limit,
    showMoreLimit,
    showMore: true,
  })

  if (items && items.length === 0) return null

  return (
    <AccordionItem w="full" border="none">
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton px={0} py={3} borderBottom="1px solid var(--chakra-colors-gray-200)">
              <Box flex="1" textAlign="left" textStyle={'body-75'}>
                {formatMessage({ id: translationKey })}
              </Box>
              {isExpanded ? <MinusIcon fontSize="xs" /> : <AddIcon fontSize="xs" />}
            </AccordionButton>
          </Heading>
          <AccordionPanel px={0.5}>
            <UnorderedList listStyleType="none" mx={0}>
              {items.map((item) => (
                <ListItem key={item.label} mb={2}>
                  <HStack>
                    <Checkbox
                      colorScheme="shading"
                      id={item.label}
                      isChecked={item.isRefined}
                      onChange={() => refine(item.value)}
                    />
                    <HStack flexGrow={1} justify="space-between" textStyle={'body-75'}>
                      <Text as={'label'} htmlFor={item.label} color="secondary.900">
                        {item.label}
                      </Text>
                      <Text color="shading.600">{item.count}</Text>
                    </HStack>
                  </HStack>
                </ListItem>
              ))}
            </UnorderedList>
            {canToggleShowMore && (
              <Button
                variant="link"
                size="xs"
                mt={{ base: 5, lg: 'sm' }}
                color="shading"
                textDecoration="underline"
                fontWeight="extrabold"
                onClick={toggleShowMore}
              >
                {isShowingMore
                  ? formatMessage({ id: 'category.filters.action.viewLess' })
                  : formatMessage({ id: 'category.filters.action.viewMore' })}
              </Button>
            )}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
