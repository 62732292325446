import {
  ContentstackPageContainer as ContentstackPageContainerProps,
  ContentstackTeamLogoGrid as ContentstackTeamLogoGridProps,
  ContentstackBannerSplit as ContentstackBannerSplitProps,
  ContentstackBannerFull as ContentstackBannerFullProps,
  ContentstackBannerPromotion as ContentstackBannerPromotionProps,
  ContentstackBannerTextOnly as ContentstackBannerTextOnlyProps,
  ContentstackArticleCard as ContentstackArticleCardProps,
  ContentstackCoverCard as ContentstackCoverCardProps,
  ContentstackGridComponent as ContentstackGridComponentProps,
  ContentstackTextCard as ContentstackTextCardProps,
  ContentstackRichText as ContentstackRichTextProps,
  ContentstackVideoCard as ContentstackVideoCardProps,
  ContentstackProductSlider as ContentstackProductSliderProps,
  ContentstackCTConnector as ContentstackCTConnectorProps,
  ContentstackAccordion as ContentstackAccordionProps,
} from '@Types/contentstack'
import { CmsComponentError, CmsComponentUnknown } from '../../cms-components'
import { ContentstackAccordion } from '../accordion'
import { ContentstackArticleCard } from '../article-card'
import { ContentstackBannerFull } from '../banner-full'
import { ContentstackBannerPromotion } from '../banner-promotion'
import { ContentstackBannerSplit } from '../banner-split'
import { ContentstackBannerTextOnly } from '../banner-text-only'
import { ContentstackGenericConnector } from '../commerce-generic-connector'
import { ContentstackCoverCard } from '../cover-card'
import { ContentstackGridComponent } from '../grid-component'
import { ContentstackProductList } from '../product-slider'
import { ContentstackRichText } from '../rich-text'
import { ContentstackTeamLogoGrid } from '../team-logo-grid'
import { ContentstackTextCard } from '../text-card'
import { ContentstackVideoCard } from '../video-card'

interface PageContentItemsProps {
  content: ContentstackPageContainerProps['page_content'][number]
}

export const ContentItemByType = ({ content }: PageContentItemsProps) => {
  if (!content) return null
  if (content._errors_) {
    if (process.env.NODE_ENV === 'production') {
      return null
    }
    return (
      <CmsComponentError
        key={content.uid}
        id={content.uid}
        contentType={content._content_type_uid}
        errors={content._errors_}
      />
    )
  }

  switch (content?._content_type_uid) {
    case 'component_accordion':
      return <ContentstackAccordion {...(content as ContentstackAccordionProps)} />
    case 'component_banner_full':
      return <ContentstackBannerFull {...(content as ContentstackBannerFullProps)} />
    case 'component_banner_split':
      return <ContentstackBannerSplit {...(content as ContentstackBannerSplitProps)} />
    case 'component_banner_text_only':
      return <ContentstackBannerTextOnly {...(content as ContentstackBannerTextOnlyProps)} />
    case 'component_banner_promotion':
      return <ContentstackBannerPromotion {...(content as ContentstackBannerPromotionProps)} />
    case 'component_grid':
      return <ContentstackGridComponent {...(content as ContentstackGridComponentProps)} />
    case 'component_team_logo_grid':
      return <ContentstackTeamLogoGrid {...(content as ContentstackTeamLogoGridProps)} />
    case 'component_article_card':
      return <ContentstackArticleCard {...(content as ContentstackArticleCardProps)} />
    case 'component_cover_card':
      return <ContentstackCoverCard {...(content as ContentstackCoverCardProps)} />
    case 'component_text_card':
      return <ContentstackTextCard {...(content as ContentstackTextCardProps)} />
    case 'component_video':
      return <ContentstackVideoCard {...(content as ContentstackVideoCardProps)} />
    case 'rich_text':
      return <ContentstackRichText {...(content as ContentstackRichTextProps)} />
    case 'ct_product_slider':
      return <ContentstackProductList {...(content as ContentstackProductSliderProps)} />
    case 'ct_connector':
      return <ContentstackGenericConnector {...(content as ContentstackCTConnectorProps)} />
    default: {
      if (process.env.NODE_ENV === 'production') return null
      return <CmsComponentUnknown id={content.uid} contentType={content._content_type_uid} />
    }
  }
}
