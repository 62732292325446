import { mutate } from 'swr'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export interface CheckoutAddressType {
  id: string
  additional_address_info: string
  additional_street_info: string
  first_name: string
  last_name: string
  phone_number: string
  street_name: string
  street_number: string
  city: string
  state: string
  postcode: string
  country: string
}

export interface ExperianAddressType {
  address_line_1: string
  address_line_2: string
  address_line_3: string
  country: string
  locality: string
  postal_code: string
  postal_code_extended?: string
  region: string
  confidence?: string
}

export const fetchExperianSuggestions = async (address: string) => {
  const res = await fetchApiHub('/action/experian/fetchSuggestions', { method: 'POST' }, { address })
  await mutate('/action/experian/fetchSuggestions', res)
  return res
}

export const formatExperianAddress = async (globalAddressKey: string) => {
  const res = await fetchApiHub('/action/experian/formatAddress', { method: 'POST' }, { globalAddressKey })
  await mutate('/action/experian/formatAddress', res)
  return res
}

export const fetchFormattedExperianClosestSuggestion = async (checkoutAddress: CheckoutAddressType) => {
  const res = await fetchApiHub('/action/experian/fetchClosestSuggestion', { method: 'POST' }, { checkoutAddress })
  await mutate('/action/experian/fetchClosestSuggestion', res)
  return res
}
