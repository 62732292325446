import { Box, BoxProps } from '@chakra-ui/react'
interface SectionProps {
  boxProps?: BoxProps
  children: React.ReactNode
  padding?: number
}

export const Section = ({ boxProps, children, padding }: SectionProps) => {
  const parsedPadding = padding ? padding : { base: 6, lg: 8 }

  return (
    <Box
      direction={'column'}
      alignItems={'flex-start'}
      backgroundColor={'background'}
      padding={parsedPadding}
      {...boxProps}
    >
      {children}
    </Box>
  )
}
