import { useEffect } from 'react'
import { Box, Button, Stack } from '@chakra-ui/react'
import { Section, SectionHeader } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { ShippingTerms } from './shipping-terms'
import { useCheckout, useCheckoutSteps } from '../checkout-provider'
import { DeliveryOptionsDetails } from '../delivery-options-details'
import { PaymentDetails } from '../payment-details'

export interface ReviewOrderProps {
  onSubmit: () => void
}

export const ReviewOrder = ({ onSubmit }: ReviewOrderProps) => {
  const intl = useFormat({ name: 'common' })
  const { isLoading, paymentHandler, setAddressConfirmationDecision } = useCheckout()
  const { goTo } = useCheckoutSteps()

  const handleSubmit = () => {
    setAddressConfirmationDecision(false)
    onSubmit()
  }

  useEffect(() => {
    if (!paymentHandler.selected) {
      goTo('billing')
    }
  }, [])

  return (
    <Stack spacing={{ base: 2, lg: 6 }}>
      <Section padding={6}>
        <SectionHeader title={intl.formatMessage({ id: 'checkout.customerSection.title' })} />
        <DeliveryOptionsDetails />
      </Section>
      <Section padding={6}>
        <SectionHeader
          title={intl.formatMessage({
            id: 'checkout.paymentSection.title',
          })}
        />
        <PaymentDetails />
      </Section>

      <ShippingTerms />

      <Box textAlign="right" px={{ base: 4, md: 0 }}>
        <Button
          px={4}
          py={2.5}
          fontSize="base"
          w={{ base: 'full', md: 'fit-content' }}
          minW={'205px'}
          variant={'solid'}
          onClick={() => handleSubmit()}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          {intl.formatMessage({ id: 'action.placeOrder' })}
        </Button>
      </Box>

      {/* TODO : Logic and UI for this section */}
      {/*If your order contains multiple items, they may be shipped separately. If you have requested alterations on
      any item in your order, then an additional 1-2 days will be added to delivery time including orders within Next
      Day and 2nd day delivery. */}
    </Stack>
  )
}
