import { ReactNode } from 'react'
import { Text, HStack } from '@chakra-ui/react'

interface SummaryLineItemProps {
  label: string
  value?: string
  textStyle?: string
  children?: ReactNode
  isDiscount?: boolean
}

export const SummaryLineItem = (props: SummaryLineItemProps) => {
  const { label, value, textStyle, children, isDiscount } = props

  return (
    <HStack justify="space-between" mt={1}>
      <Text textStyle={textStyle}>{label}</Text>
      {value ? (
        <Text textStyle={textStyle} color={isDiscount ? 'danger.600' : undefined} textAlign="right">
          {isDiscount && '-'}
          {value}
        </Text>
      ) : (
        children
      )}
    </HStack>
  )
}
