import { useMemo, useState } from 'react'
import { PaymentMethodHandlerState, PaymentMethod } from '../../types'

export const usePaymentHandler = (): PaymentMethodHandlerState => {
  const [paymentMethods, setPaymentMethods] = useState<Record<string, PaymentMethod>>({})
  const [selectedKey, setSelectedKey] = useState<string>('cash')
  const [paymentMethodExtraData, setPaymentMethodExtraData] = useState<Record<string, any>>()

  // const [onCaptureMutations, setOnCaptureMutations] = useState<Record<string, OnCapturePaymentDetailsMutation>>({});
  // const [onConfirmOrderMutations, setOnConfirmOrderMutations] = useState<Record<string, OnConfirmOrderMutation>>({});

  const selected = selectedKey
    ? {
        ...paymentMethods[selectedKey],
        // onPaymentDetailsCapture: onCaptureMutations[selectedKey],
        // onConfirmOrder: onConfirmOrderMutations[selectedKey],
      }
    : undefined

  const list = useMemo(() => Object.values(paymentMethods), [paymentMethods])

  return {
    register: (paymentMethod: PaymentMethod) =>
      setPaymentMethods((state) => ({
        ...state,
        [paymentMethod.key]: paymentMethod,
      })),
    select: setSelectedKey,
    selected,
    list,
    // setOnCapturePaymentDetails: (key: string, mutation: OnCapturePaymentDetailsMutation) => {
    //   setOnCaptureMutations((state) => ({
    //     ...state,
    //     [key]: mutation,
    //   }));
    // },
    // setOnConfirmOrder: (key: string, mutation: OnConfirmOrderMutation) => {
    //   setOnConfirmOrderMutations((state) => ({
    //     ...state,
    //     [key]: mutation,
    //   }));
    // },
    paymentMethodExtraData,
    setPaymentMethodExtraData,
  }
}
