import { useMemo } from 'react'
import { Box, GridItem, SimpleGrid } from '@chakra-ui/react'
import { ContentstackTeamLogoCard } from '@Types/contentstack'
import { TeamLogoCard, UiContainer } from 'composable'
import { fontFamilies, sizes } from 'composable/chakra/figma-tokens'
import { ThemeScheme } from './types'
import { calculateFontStyles } from '../contentstack/utils'

export interface TeamLogoGridProps {
  items: ContentstackTeamLogoCard[]
  backgroundColor?: string
  containerMarginTop?: string | number
  containerMarginBottom?: string | number
  containerSize?: string
  containerColumnsNumber?: number
  containerContentAlignment?: string
  containerColumnWidth?: string
  containerEyebrow?: string
  containerTitle?: string
  containerDescription?: string
  headingAlignment?: string
  titleFontFamily?: string
  titleFontSize?: number
}

export const TeamLogoGrid = ({
  items,
  containerColumnsNumber = 12,
  containerContentAlignment = 'center',
  backgroundColor = 'white',
  containerMarginBottom = 0,
  containerMarginTop = 0,
  containerSize = 'full',
  containerColumnWidth = '1/12',
  containerEyebrow,
  containerTitle,
  containerDescription,
  headingAlignment,
  titleFontFamily,
  titleFontSize,
}: TeamLogoGridProps) => {
  const columnWidth = useMemo(() => {
    switch (containerColumnWidth) {
      case '1/6':
        return 2
      case '1/4':
        return 3
      case '1/3':
        return 4
      default:
        return 1
    }
  }, [containerColumnWidth])

  const alignment: any = headingAlignment || 'center'
  const fontTitle: any = titleFontFamily || 'Libre Franklin'
  const fontSize: any = titleFontSize || 700

  const {
    fontSize: _titleFontSize,
    fontWeight: titleFontWeight,
    lineHeight: titleLineHeight,
  } = calculateFontStyles(fontSize)

  return (
    <UiContainer
      size={containerSize}
      marginTop={containerMarginTop || 0}
      marginBottom={containerMarginBottom || 0}
      bgColor={backgroundColor}
      p={{ base: 4, md: 16 }}
    >
      {containerEyebrow && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="14px"
          fontWeight={700}
          lineHeight="14px"
          letterSpacing="0.04em"
          textAlign={alignment}
          marginBottom={sizes[3]}
        >
          {containerEyebrow}
        </Box>
      )}
      {containerTitle && (
        <Box
          fontFamily={fontTitle}
          fontSize={_titleFontSize}
          fontWeight={titleFontWeight}
          lineHeight={titleLineHeight}
          textAlign={alignment}
          marginBottom={sizes[3]}
        >
          {containerTitle}
        </Box>
      )}
      {containerDescription && (
        <Box
          fontFamily={fontFamilies.primary}
          fontSize="20px"
          fontWeight={400}
          lineHeight="24px"
          textAlign={alignment}
          marginBottom={sizes[9]}
        >
          {containerDescription}
        </Box>
      )}
      <SimpleGrid
        spacingX={6}
        spacingY={4}
        templateColumns={{
          base: `repeat(${containerColumnsNumber > 3 ? 3 : containerColumnsNumber}, 1fr)`,
          md: `repeat(${containerColumnsNumber > 6 ? 6 : containerColumnsNumber}, 1fr)`,
          xl: `repeat(${containerColumnsNumber}, 1fr)`,
        }}
      >
        {items?.map((item, index) => {
          return (
            <TeamLogoCard
              key={index}
              image={{
                src: item.image?.url ?? '',
                alt: item.image?.description || item.image?.title || item.link_label,
              }}
              label={{
                content: item.link_label ?? '',
                font: {
                  size: item.team_logo_card_link_font_size ?? '',
                  family: item.team_logo_card_link_font_size ?? '',
                },
              }}
              link={{
                href: item.link_href ?? '',
                whiteSpace: 'normal',
              }}
              theme={(item.theme as ThemeScheme) ?? 'light'}
              alignment={containerContentAlignment}
              gridItemProps={{
                colSpan: columnWidth,
              }}
            />
          )
        })}
      </SimpleGrid>
    </UiContainer>
  )
}
