import { Configure } from 'react-instantsearch-hooks-web'
import { CATEGORY_SEARCH_FIELDS } from '../../constants'
import { useGridLayout } from '../../hooks'

interface AlgoliaConfigurationProps {
  query: string
  isSearchPage?: boolean
}

export const AlgoliaConfiguration = (props: AlgoliaConfigurationProps) => {
  const { query, isSearchPage = false } = props
  const { hitsPerPage } = useGridLayout()

  const productsAlgoliaConfigurationProps = () =>
    isSearchPage ? { query: query } : { filters: `categories: ${query}` }

  return (
    <Configure
      hitsPerPage={hitsPerPage}
      query={isSearchPage ? query : undefined}
      restrictSearchableAttributes={isSearchPage ? [] : CATEGORY_SEARCH_FIELDS}
      facetingAfterDistinct
      {...productsAlgoliaConfigurationProps()}
    />
  )
}
