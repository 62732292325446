import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Text,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

interface SelectFieldProps {
  selectProps: SelectProps
  label?: string
  callToAction?: JSX.Element
  error?: FieldError
  formLabelProps?: FormLabelProps
  isRequired?: boolean
  children: JSX.Element | JSX.Element[]
  caption?: string
  errorStyle?: FormErrorMessageProps
}

export const SelectField = ({
  selectProps,
  label,
  callToAction,
  error,
  formLabelProps,
  isRequired = false,
  children,
  caption,
  errorStyle,
}: SelectFieldProps) => {
  const { name } = selectProps

  if (!name) return null

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired} display="flex" flexDirection="column">
      <Flex>
        {label && (
          <FormLabel
            textStyle="heading-desktop-75"
            fontWeight="bold"
            requiredIndicator={<Text as="span">*</Text>}
            mb={2}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        {callToAction}
      </Flex>
      <Select {...selectProps}>{children}</Select>
      <FormErrorMessage mt={2} {...errorStyle}>
        {error?.message}
      </FormErrorMessage>
      {caption && <FormHelperText fontSize="xs">{caption}</FormHelperText>}
    </FormControl>
  )
}
