import { useEffect, useState } from 'react'
import Image from 'next/image'
import { Box, HStack, Text, useBreakpointValue } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import { core, semantic } from 'composable/chakra/figma-tokens'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5'
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from '../carousel'

export interface categoryProps {
  href: string
  imageUrl: string
  name: string
}

export interface categorySliderProps {
  displayImages?: boolean
  categoryList?: Array<categoryProps>
}

export const LocalSlider = ({ displayImages = true, categoryList }: categorySliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [showSlider, setShowSlider] = useState(false)
  const [ref, slider] = useCarousel({
    slideChanged: (slider) => setCurrentSlide(slider.track.details.rel),
    destroyed: () => setCurrentSlide(0),
    loop: true,
    mode: 'free',
    ...slidersBreakpoints[displayImages.toString()],
  })
  const isMobile = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    const isSlidable = categoryList?.length > (isMobile ? 1 : 5)
    setShowSlider(isSlidable)
  }, [isMobile, categoryList])

  if (!categoryList?.length) {
    return null
  }

  const renderCategory = (category: categoryProps, displayImage: boolean) => {
    const renderCart = () => (
      <Box
        display={'flex'}
        alignContent={'center'}
        textAlign={'center'}
        flexDir={'column'}
        {...boxStyles[displayImage.toString()]}
      >
        {displayImage && (
          <Box h={{ base: '167px', md: '200px' }} position="relative" overflow={'hidden'}>
            <Image layout="fill" objectFit="cover" src={category.imageUrl} alt={category.name} />
          </Box>
        )}

        <Text {...textStyles[displayImage.toString()]}>{category.name}</Text>
      </Box>
    )
    return (
      <CarouselSlide key={category.name}>
        {category.href ? <NextLinkNoPrefetch href={category.href}>{renderCart()}</NextLinkNoPrefetch> : renderCart()}
      </CarouselSlide>
    )
  }

  return (
    <Box py={{ base: 0, md: 10 }} maxW="container.2xl" mx="auto" mb={{ base: '26px', md: 0 }} position={'relative'}>
      {showSlider && (
        <Box display="flex" justifyContent="end" mb="26px" hideBelow={'sm'}>
          <CarouselIconButton
            width="48px"
            height="48px"
            background="shading.100"
            borderRadius="4px"
            mr="8px"
            onClick={() => slider.current?.prev()}
            icon={<IoChevronBackOutline />}
            aria-label="Previous Slide"
            position="absolute"
            top={{ base: '35%', md: '40%' }}
            left="0"
            marginLeft="sm"
            zIndex={2}
          />
          <CarouselIconButton
            width="48px"
            height="48px"
            background="shading.100"
            borderRadius="4px"
            onClick={() => slider.current?.next()}
            icon={<IoChevronForwardOutline />}
            aria-label="Next Slide"
            position="absolute"
            top={{ base: '35%', md: '40%' }}
            zIndex={2}
            marginRight="sm"
          />
        </Box>
      )}

      <Carousel mb="26px" ref={ref}>
        {categoryList.map((category) => {
          return renderCategory(category, displayImages)
        })}
      </Carousel>

      {categoryList.length > 1 && showSlider && (
        <HStack position="relative" width="full" justify="center" display={'flex'} gap={0}>
          {categoryList.map((_, idx) => (
            <Box
              flexBasis={'100%'}
              w="0"
              tabIndex={0}
              aria-label={`Go to category ${idx + 1}`}
              cursor="pointer"
              key={idx}
              height="2px"
              bg={currentSlide === idx ? 'text' : 'shading.300'}
              onClick={() => slider.current?.moveToIdx(idx)}
              onKeyPress={() => slider.current?.moveToIdx(idx)}
            />
          ))}
        </HStack>
      )}
    </Box>
  )
}

const textStyles = {
  true: {
    textStyle: 'body-75',
    fontWeight: 'bold',
    mt: '12px',
  },
  false: {
    textStyle: 'heading-desktop-100',
    fontWeight: 700,
    color: semantic.text['primary-inverse'],
    textAlign: 'center',
    display: 'flex',
    alignSelf: 'center',
  },
}

const boxStyles = {
  true: {
    w: '100%',
  },
  false: {
    background: core.danger['danger-600'],
    borderRadius: '4px',
    h: { base: '108px', md: '143px' },
    w: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: '24px',
  },
}

const slidersBreakpoints = {
  true: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 6,
          spacing: 16,
        },
      },
    },
  },
  false: {
    slides: {
      origin: 0,
      perView: 2,
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 48em)': {
        slides: {
          origin: 0,
          perView: 4,
          spacing: 16,
        },
      },
      '(min-width: 62em)': {
        slides: {
          origin: 0,
          perView: 5,
          spacing: 16,
        },
      },
      '(min-width: 80em)': {
        slides: {
          origin: 0,
          perView: 5.5,
          spacing: 16,
        },
      },
    },
  },
}
