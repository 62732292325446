import { useEffect, useRef, useState } from 'react'
import { Box, Checkbox, Stack, Text } from '@chakra-ui/react'
import { STATES, COUNTRIES, InputField, SelectField } from 'composable'
import useExperian from 'composable/components/hooks/useExperian'
import { useFormat } from 'helpers/hooks/useFormat'
import * as yup from 'yup'
import { ExperianAddressType } from '../../../helpers/utils/experian-address-utils'
import { CheckoutStateType } from '../../types'
import { useCheckout } from '../checkout-provider'
import { SuggestionsDropDown } from '../step1/Forms/suggestions-drop-down'
import { useCheckoutForm, UseCheckoutFormProps } from '../use-checkout-form'
import { set } from 'lodash'

type FormDataType = CheckoutStateType['billing_address']

type ShippingAddressFormProps = Pick<UseCheckoutFormProps<FormDataType>, 'onChange' | 'initialValues'>

export const BillingAddressForm = ({ initialValues, onChange }: ShippingAddressFormProps) => {
  const intl = useFormat({ name: 'common' })
  const { form } = useCheckoutForm<FormDataType>({
    onChange,
    formKey: 'billingAddressForm',
    yupSchema: billingAddressFormSchema({ intl }),
    initialValues,
  })

  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = form

  const streetName = watch('street_name')

  const { checkoutState, checkoutStateInitial, setCheckoutState } = useCheckout()
  const [billingIsShipping, setBillingIsShipping] = useState(checkoutState.config.billingIsShipping)
  const { shipping_address, billing_address } = checkoutState

  const streetNameRef = useRef(null)

  const setFormValues = (formattedAddress: ExperianAddressType) => {
    if (!formattedAddress) return

    setValue('street_name', formattedAddress.address_line_1)
    setValue('additional_street_info', formattedAddress.address_line_2)
    setValue('city', formattedAddress.locality)
    setValue('state', formattedAddress.region)
    setValue('country', formattedAddress.country)
    setValue('postcode', formattedAddress.postal_code)
  }

  const {
    suggestions,
    onStreetNameChange,
    handleSuggestionClick,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  } = useExperian({
    setFormValues,
    streetNameRef,
  })

  useEffect(() => {
    if (billingIsShipping) {
      setCheckoutState({
        ...checkoutState,
        billing_address: shipping_address,
      })
    } else {
      const billAddress = billing_address.first_name ? billing_address : checkoutStateInitial.billing_address
      setCheckoutState({
        ...checkoutState,
        billing_address: billAddress,
      })
    }
  }, [checkoutState.config.billingIsShipping])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      {shipping_address.additional_address_info != 'shipToStore' && (
        <Checkbox
          size="lg"
          marginBottom="sm"
          isChecked={billingIsShipping}
          onChange={() => {
            setBillingIsShipping(!billingIsShipping)
            setCheckoutState({
              ...checkoutState,
              config: {
                ...checkoutState.config,
                billingIsShipping: !billingIsShipping,
              },
            })
          }}
        >
          {intl.formatMessage({
            id: 'checkout.billingSection.sameAsShipping',
          })}
        </Checkbox>
      )}
      {billingIsShipping && Object.values(shipping_address).some((val) => !!val) && (
        <Box fontSize="sm">
          <Text>{`${shipping_address.first_name ?? ''} ${shipping_address.last_name ?? ''}`}</Text>
          <Text>{`${shipping_address.street_number ?? ''} ${shipping_address.street_name ?? ''}`}</Text>
          <Text>
            {`${shipping_address.city ?? ''}, ${shipping_address.state ?? ''}, ${shipping_address.postcode ?? ''}`}
          </Text>
        </Box>
      )}

      {!billingIsShipping && (
        <Stack spacing={4} direction="column">
          <Stack direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.firstName',
              })}
              inputProps={register('first_name')}
              error={errors.first_name}
              isRequired
            />
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.lastName',
              })}
              inputProps={register('last_name')}
              error={errors.last_name}
              isRequired
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <SuggestionsDropDown
              streetNameRef={streetNameRef}
              register={register}
              onStreetNameChange={onStreetNameChange}
              setSelectedSuggestionIndex={setSelectedSuggestionIndex}
              selectedSuggestionIndex={selectedSuggestionIndex}
              suggestions={suggestions}
              handleSuggestionClick={handleSuggestionClick}
              setValue={setValue}
              currentStreetName={streetName}
              errors={errors}
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.additionalStreetInfo',
              })}
              inputProps={register('additional_street_info')}
              error={errors.additional_street_info}
            />
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.city',
              })}
              inputProps={register('city')}
              showInfoIcon
              error={errors.city}
              isRequired
            />
            <SelectField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.state',
              })}
              selectProps={{
                defaultValue: '',
                ...register('state'),
              }}
              error={errors.state}
              isRequired
            >
              <option defaultValue={''} disabled value={''}>
                {intl.formatMessage({
                  id: 'action.selectState',
                })}
              </option>
              <>
                {STATES.map((state) => {
                  const label = state.intlId ? intl.formatMessage({ id: state.intlId }) : state.name
                  return (
                    <option title={label} aria-label={label} key={state.code} value={state.code}>
                      {label}
                    </option>
                  )
                })}
              </>
            </SelectField>
          </Stack>

          <Stack spacing={4} direction={{ base: 'column', lg: 'row' }}>
            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.postcode',
              })}
              inputProps={register('postcode')}
              error={errors.postcode}
              isRequired
            />

            <InputField
              label={intl.formatMessage({
                id: 'checkout.shippingAddressForm.label.phoneNumber',
              })}
              inputProps={register('phone_number')}
              error={errors.phone_number}
              isRequired
            />
          </Stack>
        </Stack>
      )}
    </form>
  )
}

const billingAddressFormSchema = (deps: { intl: { formatMessage: (params: any) => string } }) => {
  const { intl } = deps
  return yup.object().shape({
    first_name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    last_name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    street_name: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    additional_street_info: yup.string(),

    city: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    state: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    postcode: yup.string().required(intl.formatMessage({ id: 'validation.required' })),

    phone_number: yup.string().required(intl.formatMessage({ id: 'validation.required' })),
  })
}
