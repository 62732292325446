import React from 'react'
import {
  ContentTypeUid,
  ContentstackBannerSplit as ContentstackBannerSplitProps,
  ContentstackBannerFull as ContentstackBannerFullProps,
  ContentstackAccordion as ContentstackAccordionProps,
  ContentstackBannerTextOnly as ContentstackBannerTextOnlyProps,
  ContentstackPageHeader as ContentstackPageHeaderProps,
  ContentstackArticleCard as ContentstackArticleCardProps,
  ContentstackCoverCard as ContentstackCoverCardProps,
  ContentstackGridComponent as ContentstackGridComponentProps,
  ContentstackTextCard as ContentstackTextCardProps,
  ContentstackRichText as ContentstackRichTextProps,
  ContentstackMegaMenu as ContentstackMegaMenuProps,
  ContentstackFooterBanner as ContentstackFooterBannerProps,
  ContentstackBrandsList as ContentstackBrandsListProps,
  ContentstackVideoCard as ContentstackVideoCardProps,
  ContentstackArticleCardSlider as ContentstackArticleCardSliderProps,
  ContentstackFooter as ContentstackFooterProps,
  ContentstackCertonaSlider as ContentstackCertonaSliderProps,
  ContentstackBannerAnnouncement as ContentstackBannerAnnouncementProps,
  ContentstackTeamLogoGrid as ContentstackTeamLogoGridProps,
  ContentstackTeamLogoCard as ContentstackTeamLogoCardProps,
  ContentstackGenericSideMenuPage as ContentstackGenericSideMenuPageProps,
  ContentstackProductSlider as ContentstackProductSliderProps,
  ContentstackCTConnector as ContentstackCTConnectorProps,
  ContentstackBannerPromotion as ContentstackBannerPromotionProps,
  ContentstackPageContainer as ContentstackPageContainerProps,
  ContentstackBannerSlider as ContentstackBannerSliderProps,
} from '@Types/contentstack'
import {
  ContentstackArticleCard,
  ContentstackAccordion,
  ContentstackBannerFull,
  ContentstackBannerSplit,
  ContentstackBannerTextOnly,
  ContentstackPageHeader,
  ContentstackCoverCard,
  ContentstackGridComponent,
  ContentstackRichText,
  ContentstackTextCard,
  ContentstackMegaMenu,
  ContentstackVideoCard,
  ContentstackFooter,
  ContentstackBannerAnnouncement,
  ContentstackGenericSideMenuPage,
  ContentstackBannerPromotion,
  UiContainer,
  ContentstackFooterBanner,
  ContentstackTeamLogoGrid,
  ContentstackProductList,
  ContentstackGenericConnector,
  ContentstackPageContainer,
  ContentstackBannerSlider,
  ContentstackCertonaSlider,
} from 'composable'
import { ContentstackArticleCardSlider } from 'composable/components/contentstack/article-card-slider'
import { ContentstackBrandsList } from 'composable/components/contentstack/brands-list'

export type ContentstackComponentEntryProps =
  | (ContentstackAccordionProps & { content_type: ContentTypeUid.Accordion })
  | (ContentstackBannerFullProps & { content_type: ContentTypeUid.BannerFull })
  | (ContentstackBannerSplitProps & { content_type: ContentTypeUid.BannerSplit })
  | (ContentstackBannerTextOnlyProps & { content_type: ContentTypeUid.BannerTextOnly })
  | (ContentstackPageHeaderProps & { content_type: ContentTypeUid.PageHeader })
  | (ContentstackArticleCardProps & { content_type: ContentTypeUid.ArticleCard })
  | (ContentstackBrandsListProps & { content_type: ContentTypeUid.BrandsList })
  | (ContentstackCoverCardProps & { content_type: ContentTypeUid.CoverCard })
  | (ContentstackGridComponentProps & { content_type: ContentTypeUid.GridComponent })
  | (ContentstackTextCardProps & { content_type: ContentTypeUid.TextCard })
  | (ContentstackRichTextProps & { content_type: ContentTypeUid.RichText })
  | (ContentstackMegaMenuProps & { content_type: ContentTypeUid.MegaMenu })
  | (ContentstackFooterBannerProps & { content_type: ContentTypeUid.FooterBanner })
  | (ContentstackVideoCardProps & { content_type: ContentTypeUid.ComponentVideo })
  | (ContentstackArticleCardSliderProps & { content_type: ContentTypeUid.ArticleCardSlider })
  | (ContentstackFooterProps & { content_type: ContentTypeUid.Footer })
  | (ContentstackBannerAnnouncementProps & { content_type: ContentTypeUid.BannerAnnouncement })
  | (ContentstackTeamLogoCardProps & { content_type: ContentTypeUid.TeamLogoCard })
  | (ContentstackTeamLogoGridProps & { content_type: ContentTypeUid.TeamLogoGrid })
  | (ContentstackGenericSideMenuPageProps & { content_type: ContentTypeUid.RichTextNavigationList })
  | (ContentstackProductSliderProps & { content_type: ContentTypeUid.CTProductSlider })
  | (ContentstackCTConnectorProps & { content_type: ContentTypeUid.CTConnector })
  | (ContentstackBannerPromotionProps & { content_type: ContentTypeUid.BannerPromotion })
  | (ContentstackCertonaSliderProps & { content_type: ContentTypeUid.CertonaSlider })
  | (ContentstackPageContainerProps & { content_type: ContentTypeUid.PageContainer })
  | (ContentstackBannerSliderProps & { content_type: ContentTypeUid.BannerSlider })

interface ContentstackEntryProps {
  data: {
    entryData: {
      dataSource: ContentstackComponentEntryProps
    }
    data_promotion_name?: string
    data_monetate_banner?: boolean
  }
}

const getDesktopContainerWidth = (props: ContentstackComponentEntryProps) => {
  if (props?.content_type !== 'component_banner_promotion') {
    return props?.desktop_container_width ? props?.desktop_container_width : props?.container_size
  }

  return props?.styling_group ? props?.styling_group.container_size : props?.container_size
}

const getDesktopContainerMargins = (props: ContentstackComponentEntryProps) => {
  if (props?.content_type !== 'component_banner_promotion') {
    return {
      marginTop: props?.container_margin_top,
      marginBottom: props?.container_margin_bottom,
    }
  }

  return {
    marginTop: props?.styling_group?.container_margin_top,
    marginBottom: props?.styling_group?.container_margin_bottom,
  }
}

const ContentstackEntryTastic = (data: ContentstackEntryProps) => {
  const props = data?.data?.entryData?.dataSource
  const desktopContainerWidth = getDesktopContainerWidth(props)
  const containerMargins = getDesktopContainerMargins(props)
  const analyticsTrackingData = data?.data?.data_promotion_name || undefined
  const monetateBanner = data?.data?.data_monetate_banner || undefined

  return (
    <UiContainer
      size={desktopContainerWidth}
      marginTop={containerMargins?.marginTop}
      marginBottom={containerMargins?.marginBottom}
    >
      {props?.content_type === 'component_banner_full' && (
        <ContentstackBannerFull {...props} analyticsTrackingData={analyticsTrackingData} />
      )}

      {props?.content_type === 'component_accordion' && <ContentstackAccordion {...props} />}

      {props?.content_type === 'component_banner_split' && (
        <ContentstackBannerSplit {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'component_banner_text_only' && <ContentstackBannerTextOnly {...props} />}
      {props?.content_type === 'component_page_header' && <ContentstackPageHeader {...props} />}
      {props?.content_type === 'component_article_card' && <ContentstackArticleCard {...props} />}
      {props?.content_type === 'component_brands_list' && <ContentstackBrandsList {...props} />}
      {props?.content_type === 'component_cover_card' && (
        <ContentstackCoverCard {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'component_grid' && (
        <ContentstackGridComponent {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'component_text_card' && (
        <ContentstackTextCard {...props} analyticsTrackingData={analyticsTrackingData} />
      )}

      {props?.content_type === 'component_banner_promotion' && (
        <ContentstackBannerPromotion {...props} analyticsTrackingData={analyticsTrackingData} />
      )}

      {props?.content_type === 'rich_text' && (
        <ContentstackRichText {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'mega_menu' && <ContentstackMegaMenu {...props} />}
      {props?.content_type === 'component_footer_banner' && (
        <ContentstackFooterBanner {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'component_footer' && <ContentstackFooter {...props} />}
      {props?.content_type === 'component_video' && (
        <ContentstackVideoCard {...props} analyticsTrackingData={analyticsTrackingData} />
      )}

      {props?.content_type === 'component_article_card_slider' && (
        <ContentstackArticleCardSlider {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
      {props?.content_type === 'component_rich_text_navigation_list' && <ContentstackGenericSideMenuPage {...props} />}
      {props?.content_type === 'component_team_logo_grid' && <ContentstackTeamLogoGrid {...props} />}
      {props?.content_type === 'component_banner_announcement' && (
        <ContentstackBannerAnnouncement
          {...props}
          analyticsTrackingData={analyticsTrackingData}
          monetateBanner={monetateBanner}
        />
      )}
      {props?.content_type === 'ct_product_slider' && <ContentstackProductList {...props} />}
      {props?.content_type === 'ct_connector' && <ContentstackGenericConnector {...props} />}
      {props?.content_type === 'certona_slider' && <ContentstackCertonaSlider {...props} />}
      {props?.content_type === 'component_page_container' && <ContentstackPageContainer {...props} />}
      {props?.content_type === 'component_banner_slider' && (
        <ContentstackBannerSlider {...props} analyticsTrackingData={analyticsTrackingData} />
      )}
    </UiContainer>
  )
}

export default ContentstackEntryTastic
