import { UseStripe } from '@Types/payment/stripe'
import { UseAccount } from './UseAccount'
import { UseAdyen } from './UseAdyen'
import { UseCart } from './UseCart'
import { UseProduct } from './UseProduct'
import { UseWishlist } from './UseWishlist'
import {
  addAddress,
  changePassword,
  confirm,
  getAccount,
  login,
  logout,
  register,
  removeAddress,
  requestConfirmationEmail,
  requestPasswordReset,
  resetPassword,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  update,
  updateAddress,
} from '../../actions/account'
import { createSession } from '../../actions/adyen'
import {
  addItem,
  addPaymentByInvoice,
  cartItems,
  checkout,
  getCheckoutOrder,
  getOrder,
  getProjectSettings,
  getShippingMethods,
  orderHistory,
  redeemDiscountCode,
  removeDiscountCode,
  removeItem,
  setShippingMethod,
  updateCart,
  updateItem,
  recalculateCart,
  updateCartItems,
  setInstoreCart,
  initializeCart,
  confirmCart,
} from '../../actions/cart'
import { getProductsByIds } from '../../actions/product'
import { addToWishlist, getWishlist, removeLineItem, updateLineItem } from '../../actions/wishlist'

export interface FrontasticState {
  useCart: UseCart
  useAccount: UseAccount
  useProduct: UseProduct
  useWishlist: UseWishlist
  useAdyen: UseAdyen
  useStripe?: UseStripe
}

export const getFrontasticState = (): FrontasticState => {
  return {
    useCart: {
      ...cartItems(),
      addItem,
      updateCart,
      removeItem,
      updateItem,
      shippingMethods: getShippingMethods(),
      setShippingMethod,
      checkout,
      orderHistory,
      getProjectSettings,
      redeemDiscountCode,
      removeDiscountCode,
      getOrder,
      getCheckoutOrder,
      addPaymentByInvoice,
      recalculateCart,
      updateCartItems,
      setInstoreCart,
      initializeCart,
      confirmCart,
    },
    useAccount: {
      ...getAccount(),
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
    },
    useProduct: {
      getProductsByIds,
    },
    useWishlist: {
      ...getWishlist(),
      addToWishlist,
      removeLineItem,
      updateLineItem,
    },
    useAdyen: {
      createSession,
    },
  }
}
