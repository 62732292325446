import React, { createContext, useState, useEffect } from 'react'
import { Cart } from '@Types/cart/Cart'
import { LineItem } from '@Types/cart/LineItem'
import {
  fetchAtgLegacyCart,
  updateItemLegacyCart,
  removeItemLegacyCart,
  moveItemToWishlist,
} from './helpers/httpRequests'
import { AtgLegacyCart, AtgLegacyCartItem } from './interfaces/AtgLegacyCartData'
import convertToCents from './helpers/convertToCents'

interface IAtgCartContext {
  cartData: AtgLegacyCart
  refreshCart: () => void
  formattedCart: Partial<Cart> | undefined
  updateItem: (itemId: string, quantity: number) => Promise<void>
  removeItem: (itemId: string) => Promise<void>
  moveToWishList: (commerceItems, productId, quantity, skuId) => Promise<void>
}
export const AtgCartContext = createContext({
  cartData: null,
  formattedCart: null,
  refreshCart: () => {},
  updateItem: async (itemId: string, quantity: number) => {},
  removeItem: async (itemId: string) => {},
  moveToWishList: async (commerceItems, productId, quantity, skuId) => {},
} as IAtgCartContext)

export const AtgCartProvider = ({ children }) => {
  const [cartData, setCartData] = useState<AtgLegacyCart>()
  const [formattedCart, setFormattedCart] = useState<Partial<Cart> | undefined>()

  useEffect(() => {
    refreshCart()
  }, [])

  const refreshCart = async () => {
    const fetchCart = await fetchAtgLegacyCart()
    if (!!fetchCart && fetchCart.items?.length > 0) {
      const reversedItems = fetchCart.items.sort((a, b) => {
        const dateA = new Date(a.additionalLineItemAttributes.commerceItem.timeAdded)
        const dateB = new Date(b.additionalLineItemAttributes.commerceItem.timeAdded)
        return Math.floor(dateB.getTime() / 1000) - Math.floor(dateA.getTime() / 1000)
      }) // ATG is returning the cart reversed, so we need to reverse it back
      setCartData({ ...fetchCart, items: reversedItems })
      setFormattedCart(formatCart({ ...fetchCart, items: reversedItems }))
    } else {
      setCartData(fetchCart)
      setFormattedCart(formatCart(fetchCart))
    }
  }

  const formatCart = (cart: AtgLegacyCart) => {
    const lineItems: LineItem[] = cart?.items?.map((item: AtgLegacyCartItem) => {
      return {
        lineItemId: item?.id,
        masterData: {
          current: {
            masterVariant: {
              attributes: [
                { name: 'segment2Label', value: 'Size' },
                { name: 'segment3Label', value: 'Inseam' },
                { name: 'ecommSize', value: item?.sku?.displayName },
              ],
            },
          },
        },
        productId: item?.productId,
        productSlug: item?.product?.displayName,
        name: item?.productDisplayName,
        type: item?.commerceItemClassType,
        count: item?.quantity,
        price: { centAmount: convertToCents(item?.priceInfo?.amount), currencyCode: cart?.totals?.currencyCode }, // Price of a single item
        discountedPrice: {
          centAmount: convertToCents(item?.priceInfo?.amount),
          currencyCode: cart?.totals?.currencyCode,
        }, // Discounted price per item
        isDiscounted: item?.priceInfo?.discounted,
        discounts: [],
        totalPrice: {
          centAmount: convertToCents(item?.priceInfo?.rawTotalPrice),
          currencyCode: cart?.totals?.currencyCode,
        },
        totalDiscountedPrice: {
          centAmount: convertToCents(item?.priceInfo?.salePrice),
          currencyCode: cart?.totals?.currencyCode,
        },
        variant: {
          images: [
            item?.commerceItemClassType === 'giftCardCommerceItem'
              ? `//images.dxl.com/is/image/CasualMale/p${item?.productId}`
              : `//images.dxl.com/is/image/CasualMale/p${item?.productId}${item?.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.catalogRef?.imageColorCode}?$product$`,
          ],
          prices: [
            {
              custom: {
                currencyCode: cart?.totals?.currencyCode,
                centAmount: convertToCents(item?.priceInfo?.listPrice),
              },
            },
          ],
          sku: item?.sku?.id,
          attributes: {
            removalId: `${item?.id}:${item?.additionalLineItemAttributes?.relationShipId}`,
            ecommColor: item?.sku?.colorName,
            itemNumber: item?.productId,
            segment2: item?.additionalLineItemAttributes?.sizes?.[0],
            segment3: item?.additionalLineItemAttributes?.sizes?.[1],
          },
        },
        isGift: null,
        _url: item?.links?.[0]?.href,
        availableDiscount: null,
      }
    })
    const formattedCart: Partial<Cart> | undefined = {
      cartId: '',
      cartVersion: '',
      cartState: 'Active',
      lineItems,
      email: '',
      birthday: null,
      shippingInfo: null,
      availableShippingMethods: [], // Available shipping methods for this cart
      shippingAddress: {},
      billingAddress: {},
      sum: { centAmount: convertToCents(cart?.totals?.total), currencyCode: cart?.totals?.currencyCode },
      payments: null,
      discountCodes: null,
      taxed: null,
      locale: {
        language: 'en',
      },
    }

    return formattedCart
  }

  const updateItem = async (itemId: string, quantity: number) => {
    const res = await updateItemLegacyCart({ itemId, quantity })
  }

  const removeItem = async (itemId: string) => {
    await removeItemLegacyCart({ itemId })
  }

  const moveToWishList = async (commerceItems, productId, quantity, skuId) => {
    await moveItemToWishlist({ commerceItems, productId, quantity, skuId })
  }

  return (
    <AtgCartContext.Provider value={{ cartData, refreshCart, updateItem, removeItem, moveToWishList, formattedCart }}>
      {children}
    </AtgCartContext.Provider>
  )
}

export const useAtgLegacyCart = () => {
  const cartContext = React.useContext(AtgCartContext)

  if (!cartContext) {
    throw new Error('userAtgLegacyCart must be used within a AtgCartProvider')
  }

  return cartContext
}
