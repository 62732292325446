import { useEffect, useState } from 'react'
import { Box, Divider, RadioGroup, Stack } from '@chakra-ui/react'
import { CheckboxField, getCheckoutAddress } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { useAccount } from 'frontastic'
import { ShippingAddressForm } from './Forms/shipping-address-form'
import { ShippingAddressOption } from './shipping-address-option'
import { useCheckout } from '../checkout-provider'

export const ShippingAddressSelector = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const {
    checkoutState,
    checkoutStateInitial,
    setCheckoutState,
    validation,
    savedShippingAddresses: { setSelected, clearSelection, selected },
    isLoadingStep1,
    addressConfirmationModal,
  } = useCheckout()
  const [saveAddressCheckboxValue, setSaveAddressCheckboxValue] = useState(false)
  const { account } = useAccount()

  useEffect(() => {
    if (selected) {
      setCheckoutState((prev) => ({
        ...prev,
        shipping_address: getCheckoutAddress(selected),
      }))
    }
  }, [selected])

  return (
    <RadioGroup
      name="shipping-address"
      defaultValue={selected?.addressId ?? 'new'}
      value={selected?.addressId ?? 'new'}
      isDisabled={isLoadingStep1}
    >
      <Stack spacing="md" divider={<Divider />}>
        {account?.addresses?.map((savedShippingAddress) => (
          <ShippingAddressOption
            address={savedShippingAddress}
            key={savedShippingAddress.addressId}
            title={`${savedShippingAddress.firstName} ${savedShippingAddress.lastName}`}
            onEdit={() => {
              setSelected(savedShippingAddress.addressId)
            }}
            onSelect={() => {
              if (isLoadingStep1) return
              setSelected(savedShippingAddress.addressId)
            }}
          />
        ))}
        <ShippingAddressOption
          isNewAddress
          address={checkoutStateInitial.shipping_address}
          title={formatMessage({
            id: 'checkout.shippingAddressForm.title.newShippingAddress',
          })}
          onSelect={() => {
            checkoutState.shipping_address = {
              ...checkoutStateInitial.shipping_address,
            }
            clearSelection()
          }}
        />
        {!selected && (
          <>
            <ShippingAddressForm
              addressConfirmationModal={addressConfirmationModal}
              initialValues={checkoutState.shipping_address}
              onChange={({ data, isValid }) => {
                if (!isValid) return
                validation.run('shippingAddressForm')
                setCheckoutState((state) => {
                  return {
                    ...state,
                    shipping_address: data,
                  }
                })
              }}
            />

            <Box mt="sm">
              <CheckboxField
                content={formatMessage({
                  id: 'checkout.shippingForm.saveAddress',
                })}
                checkboxProps={{
                  isChecked: saveAddressCheckboxValue,
                  onChange: ({ target }) => {
                    setSaveAddressCheckboxValue(!saveAddressCheckboxValue)
                    setCheckoutState((state) => {
                      return {
                        ...state,
                        config: {
                          ...state.config,
                          saveNewAddressToMyAccount: Boolean(target.checked),
                        },
                      }
                    })
                  },
                  name: 'save-address',
                }}
              />
            </Box>
          </>
        )}
      </Stack>
      <Divider mt="md" />
    </RadioGroup>
  )
}
