import { useState } from 'react'
import { useRouter } from 'next/router'
import { Container, Divider, Flex, Stack, Text, Button, useBreakpointValue, List, ListItem } from '@chakra-ui/react'
import { useIsBrowser, getCartSummaryAmounts } from 'composable'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { calculateCartCount } from 'helpers/utils/calculateCartCount'
import { useCart } from 'frontastic'
import { CartEmptyState } from './cart-empty-state'
import { CartSummary } from './cart-summary'
import { HorizontalProductCard, HorizontalProductCardProps } from '../horizontal-product-card'
import { useAtgLegacyCart } from 'frontastic/contexts'

type CartPageProps = {
  disablePromotion: boolean
}

export const CartPage = ({ disablePromotion }: CartPageProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const isBrowser = useIsBrowser()
  const router = useRouter()
  const { formattedCart: cart, updateItem, removeItem, refreshCart: recalculateCart } = useAtgLegacyCart()
  const productCartSize = useBreakpointValue({ base: 'sm', md: 'lg' })
  const title = formatMessage({ id: 'cart.title' })
  const [isUpdating, setIsUpdating] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleUpdate = async (updatePromise: Promise<any>) => {
    setIsUpdating(true)
    try {
      await updatePromise
    } catch (err) {
      console.log(err)
    } finally {
      await recalculateCart()
    }
    setIsUpdating(false)
  }

  const handleProceedToCheckout = async () => {
    await recalculateCart()
    router.push('/checkout')
  }

  if (!isBrowser) {
    return null
  }
  const locale = cart?.locale?.language
  const cartTotalItems = calculateCartCount(cart?.lineItems)

  const cartIsEmpty = !locale || cartTotalItems === 0 || cart?.cartState !== 'Active'
  const { total } = getCartSummaryAmounts(cart)

  return (
    <Container maxW="container.2xl" py={{ base: 4, md: 8 }} px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}>
      <Flex gap={{ base: '0.5rem', md: '0.625rem' }} alignItems={'center'}>
        <Text as="h2" textStyle={'heading-desktop-300'}>
          {title}
        </Text>
        <Text alignSelf={'flex-end'} textStyle={{ base: 'body-100', md: 'body-500' }} color={'shading.600'}>
          {cart &&
            formatMessage({
              id: 'cart.titleCount',
              values: { count: cartTotalItems },
            })}
        </Text>
      </Flex>

      <Stack w={'full'} maxW={'full'} display={{ sm: 'none' }} mb={4} hidden={cartIsEmpty}>
        <Flex textStyle={'body-75'} justify="space-between" mb={3} mt={4}>
          <Text textColor={'text-muted'}>{formatMessage({ id: 'cart.summary.estimatedTotal' })}</Text>
          <Text>{cart && CurrencyHelpers.formatForCurrency(total)}</Text>
        </Flex>

        <Button
          w={{ base: 'full' }}
          maxW={{ base: 'full' }}
          variant={'solid'}
          size={'lg'}
          textStyle={'heading-desktop-100-300'}
          onClick={handleProceedToCheckout}
        >
          {formatMessage({ id: 'action.proceed.checkout' })}
        </Button>
      </Stack>

      {
        // TODO: Add isLoading state
        // isLoading ? (
        //   <CartLoadingState />
        // ) :
        cartIsEmpty ? (
          <CartEmptyState />
        ) : (
          <Flex w="100%" direction={{ base: 'column', lg: 'row' }}>
            <List width={'full'}>
              <Stack
                width={'full'}
                maxW={'full'}
                spacing={{ base: 1, md: 4 }}
                divider={<Divider />}
                borderBottom={'1px solid var(--chakra-colors-gray-200)'}
                borderTop={{ md: '1px solid var(--chakra-colors-gray-200)' }}
                my={{ base: 0, md: '20px' }}
                py={{ base: 0, md: '24px' }}
              >
                {cart?.lineItems?.map((item) => {
                  const {
                    _url,
                    availableDiscount,
                    count,
                    discountedPrice,
                    isDiscounted,
                    lineItemId,
                    name,
                    price,
                    totalDiscountedPrice,
                    totalPrice,
                    variant,
                  } = item

                  const masterProductData = item?.masterData?.current?.masterVariant?.attributes

                  return (
                    <ListItem key={lineItemId} pb={{ base: 2, md: 0 }}>
                      <HorizontalProductCard
                        availableDiscount={availableDiscount}
                        editable
                        url={_url}
                        columns={4}
                        size={productCartSize as HorizontalProductCardProps['size']}
                        image={{
                          src: variant?.images?.[0],
                          alt: name ?? '',
                          onClickImage: () => router.push(_url),
                        }}
                        name={name || ''}
                        quantity={count}
                        quantityPickerProps={{
                          max: variant?.availableQuantity ?? 99,
                        }}
                        price={CurrencyHelpers.formatForCurrency(price?.centAmount * count)}
                        discountedPrice={
                          isDiscounted
                            ? CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount * count)
                            : undefined
                        }
                        totalPrice={CurrencyHelpers.formatForCurrency(totalPrice.centAmount)}
                        totalDiscountedPrice={
                          totalDiscountedPrice
                            ? CurrencyHelpers.formatForCurrency(totalDiscountedPrice.centAmount)
                            : undefined
                        }
                        variant={variant}
                        onRemove={() => handleUpdate(removeItem(lineItemId))}
                        onChangeQuantity={async (val) => {
                          // TODO: need update QuantityPicker to allow for direct input
                          val === 0
                            ? await handleUpdate(removeItem(lineItemId))
                            : await handleUpdate(updateItem(lineItemId, val))
                        }}
                        isLoading={isUpdating}
                        displayOutOfStock={true}
                        masterProductData={masterProductData}
                      />
                    </ListItem>
                  )
                })}
              </Stack>
            </List>
            <Flex
              as={'aside'}
              justify={'flex-end'}
              w={{ base: 'full' }}
              maxW={{ base: 'full', md: '400px', xl: '450px' }}
              ml={{ base: 'auto', lg: '3rem' }}
              mt={{ base: 10, md: 0 }}
            >
              <CartSummary disablePromotion={disablePromotion} isButtonProcessing={isProcessing} />
            </Flex>
          </Flex>
        )
      }
    </Container>
  )
}
