import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Accordion as ChackraAccordion,
  AccordionProps as ChackraAccordionProps,
  AccordionButton,
  AccordionButtonProps as ChackraAccordionButtonProps,
  AccordionItem,
  AccordionItemProps as ChackraAccordionItemProps,
  AccordionPanel,
  AccordionPanelProps as ChackraAccordionPanelProps,
  Box,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'

type DefaultStyleItemType = {
  fontSize: string
  height: string
}

type DefaultStylesTypes = {
  small: DefaultStyleItemType
  medium: DefaultStyleItemType
  large: DefaultStyleItemType
}

const DefaultStyles: DefaultStylesTypes = {
  small: {
    fontSize: 'sm',
    height: '10',
  },
  medium: {
    fontSize: 'base',
    height: '14',
  },
  large: {
    fontSize: 'lg',
    height: '16',
  },
}

export interface AccordionProps {
  accordionButtonProps?: ChackraAccordionButtonProps
  accordionItemProps?: ChackraAccordionItemProps
  accordionPanelProps?: ChackraAccordionPanelProps
  accordionProps?: ChackraAccordionProps
  items?: AccordionItemProps[]
  showLeftIcon?: boolean
  showRightIcon?: boolean
  size?: AccordionSize
}

export type AccordionItemProps = {
  label: string
  content?: string
  isDisabled?: boolean
}

export type AccordionSize = 'small' | 'medium' | 'large'

export const Accordion = ({
  accordionButtonProps,
  accordionItemProps,
  accordionPanelProps,
  accordionProps,
  items = [],
  showLeftIcon = false,
  showRightIcon = true,
  size = 'medium',
}: AccordionProps) => {
  if (!items || items.length === 0) {
    return null
  }

  const renderLeftIcon = (fontSize: string) => <AddIcon fontSize={fontSize} mr={4} />

  const renderRightIcon = (isExpanded: boolean, fontSize: string) => {
    return isExpanded ? <MinusIcon fontSize={fontSize} /> : <AddIcon fontSize={fontSize} />
  }

  const renderUnorderedList = (content) => {
    if (!Array.isArray(content) || content.length === 0) {
      return null
    }

    return (
      <UnorderedList>
        {content.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </UnorderedList>
    )
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <ChackraAccordion allowToggle width="100%" marginTop={0} {...accordionProps}>
        {items.map((item, index) => {
          const label = item?.label ?? ''
          const content = item?.content
          const isDisabled = item?.isDisabled ?? false
          const fontSize = DefaultStyles[size].fontSize
          const itemHeight = DefaultStyles[size].height

          return (
            <AccordionItem isDisabled={isDisabled} key={index} {...accordionItemProps}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton height={itemHeight} {...accordionButtonProps}>
                      {showLeftIcon && renderLeftIcon(fontSize)}
                      <Box flex="1" textAlign="left" fontSize={fontSize}>
                        {label}
                      </Box>
                      {showRightIcon && renderRightIcon(isExpanded, fontSize)}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    pb={4}
                    {...accordionPanelProps}
                    textStyle={'body-75'}
                    color={'text'}
                    {...(!Array.isArray(content) && { dangerouslySetInnerHTML: { __html: content } })}
                  >
                    {renderUnorderedList(content)}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          )
        })}
      </ChackraAccordion>
    </Box>
  )
}
