import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useBreakpointValue } from '@chakra-ui/react'
import { CustomToast } from 'components/core-ui-controls/CustomToast'
import toast, { Toast } from 'react-hot-toast'
import { useCart } from 'frontastic'
import { BagSummary } from './bag-summary'
import { CheckoutCartSummary } from './checkout-cart-summary'
import { CheckoutGrid } from './checkout-grid'
import { useCheckoutSteps } from './checkout-provider'
import { Step1 } from './step1'
import { Step2 } from './step2'
import { ReviewOrder } from './step3'
import { useComposable } from '../composable-provider'

export const CheckoutSteps = () => {
  const [isPlacingOrder, setIsPlacingOrder] = useState(false)
  const { step, move } = useCheckoutSteps()
  const hideSummary = useBreakpointValue({ base: false, md: true })
  const router = useRouter()
  const { data: cart, confirmCart } = useCart()
  const handlePlaceOrder = async () => {
    setIsPlacingOrder(true)
    try {
      if (cart.cartId) {
        const confirmedCart = await confirmCart(cart.cartId)
        router.push(`/checkout/success?cartId=${confirmedCart.cartId}`)
      }
    } catch (e: any) {
      const launchToast = (t: Toast) => {
        const onClose = () => toast.remove(t.id)
        return toast.custom(<CustomToast status="error" title={e.message} onClose={onClose} />, { id: t.id })
      }
      toast(launchToast)
    }
  }

  return (
    <CheckoutGrid
      currentStep={step.key}
      mobileCartSummary={<BagSummary />}
      desktopCartSummary={
        <CheckoutCartSummary
          hideCheckoutButton
          displayTitle
          orderSummaryProps={{ bg: 'white', p: '0' }}
          showCartItems={hideSummary}
          textOverride={{ total: 'cart.summary.total' }}
        />
      }
      step1={<Step1 onSubmit={() => move()} />}
      step2={<Step2 onSubmit={() => move()} />}
      review={<ReviewOrder onSubmit={() => handlePlaceOrder()} />}
    />
  )
}
