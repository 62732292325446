import { useState } from 'react'
import { FormControl, FormLabel, HStack, Input, Stack, Text, FormErrorMessage } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'
import { IoLocationOutline } from 'react-icons/io5'

type NewValue = string | { global_address_key: string; text: string } | false

export const SuggestionsDropDown = ({
  streetNameRef,
  register,
  onStreetNameChange,
  setSelectedSuggestionIndex,
  selectedSuggestionIndex,
  suggestions,
  handleSuggestionClick,
  setValue,
  currentStreetName,
  errors,
}) => {
  const intl = useFormat({ name: 'common' })
  const { onChange, ...rest } = register('street_name')
  const [newValue, setNewValue] = useState<NewValue>(false)
  const error = errors.street_name

  useOnClickOutside(streetNameRef, () => {
    updateNewValue()
  })

  function handleSuggestionSelection(suggestion: { global_address_key: string; text: string }) {
    const streetName = suggestion.text.split(',')[0]
    setNewValue(streetName)
    handleSuggestionClick(suggestion.global_address_key)
  }

  function updateNewValue() {
    if (newValue && newValue !== currentStreetName) {
      setValue('street_name', newValue)
    }
  }

  return (
    <FormControl ref={streetNameRef} isRequired isInvalid={Boolean(error)}>
      <FormLabel
        textStyle={'heading-desktop-75'}
        fontWeight="extrabold"
        requiredIndicator={<Text as="span">*</Text>}
        mb={2}
      >
        {intl.formatMessage({
          id: 'checkout.shippingAddressForm.label.streetName',
        })}
      </FormLabel>
      <Input
        {...rest}
        onChange={(e) => {
          const value = e.target.value
          onStreetNameChange(value)
          setNewValue(value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowUp') {
            e.preventDefault()
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0))
          } else if (e.key === 'ArrowDown') {
            e.preventDefault()
            setSelectedSuggestionIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length - 1))
          } else if (e.key === 'Enter') {
            e.preventDefault()
            if (selectedSuggestionIndex !== -1) {
              const selectedSuggestion = suggestions[selectedSuggestionIndex]
              handleSuggestionSelection(selectedSuggestion)
            }
          }
        }}
        isRequired
      />
      <FormErrorMessage mt={2}>{error?.message}</FormErrorMessage>

      {suggestions.length > 0 && (
        <Stack border="1px solid" borderRadius="md" borderColor="shading.400" mt={2}>
          {suggestions?.map((suggestion, i) => (
            <HStack
              key={i}
              px={4}
              py={2}
              cursor={suggestion.global_address_key ? 'pointer' : 'not-allowed'}
              onClick={
                suggestion.global_address_key
                  ? () => {
                      handleSuggestionSelection(suggestion)
                    }
                  : undefined
              }
              _hover={{ backgroundColor: 'surface.highlight' }}
              _focus={{ backgroundColor: 'surface.highlight' }}
              bg={selectedSuggestionIndex === i && 'surface.highlight'}
            >
              <IoLocationOutline />
              <Text flex={1} fontSize="14px">
                {suggestion.text}
              </Text>
            </HStack>
          ))}
        </Stack>
      )}
    </FormControl>
  )
}
