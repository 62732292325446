import { Button, ButtonProps, Text, TextProps, Link } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from '../link'

interface SidebarGenericItemProps {
  href: string
  children?: React.ReactNode
  label?: string
  rootProps?: Omit<ButtonProps, 'children'>
  state?: 'Default' | 'Hover' | 'Active'
  textProps?: TextProps
  onClick?: (number) => void
}

export const SidebarGenericItem = ({
  href,
  children,
  label,
  rootProps,
  state = 'Default',
  textProps,
  onClick,
}: SidebarGenericItemProps) => {
  const isActive = state === 'Active'
  return (
    <NextLinkNoPrefetch href={href} passHref>
      <Button
        role={'link'}
        alignItems={'center'}
        borderRadius={'6px'}
        color={'text'}
        display={'flex'}
        height={{ base: '37px', md: ' 53px' }}
        isActive={isActive}
        justifyContent={'flex-start'}
        textDecoration={'none'}
        variant={'ghost'}
        width={'full'}
        _hover={{
          background: 'highlight',
        }}
        onClick={onClick}
        {...rootProps}
      >
        {children ? (
          children
        ) : (
          <Text
            textStyle={isActive ? 'figma-linkHeading-75' : 'heading-desktop-75'}
            {...(isActive && { fontWeight: '700' })}
            {...textProps}
          >
            {label}
          </Text>
        )}
      </Button>
    </NextLinkNoPrefetch>
  )
}
