import React from 'react'
import { Box } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'
import { TextCard } from '../cms-components'

export const FooterBanner = ({ items, analyticsTrackingData }) => {
  return (
    <Box
      width={'100%'}
      height={'auto'}
      padding={{
        base: '0px',
        '2xl': '0px var(--chakra-space-20) 0px var(--chakra-space-20)',
        xl: '0px var(--chakra-space-4) 0px var(--chakra-space-4)',
      }}
      gap={{ base: '0px', '2xl': '20px', md: 'var(--chakra-space-4)' }}
      bg="text.primary"
      flexDirection={{ base: 'column', md: 'row' }}
      display={'flex'}
    >
      {items?.map((item, index) => {
        const analyticsLocationIdentifier = extractPath(item?.cta_href ?? '')
        const dataPromotionName =
          analyticsTrackingData && `${analyticsTrackingData} | ${index} | ${analyticsLocationIdentifier}`

        return (
          <Box
            key={`Footer-item-${index}`}
            as={NextLinkNoPrefetch}
            onClick={() => promoClickUtagLinkEvent(dataPromotionName)}
            href={item?.cta_href}
            minWidth={{ base: '100%', xl: '172px', md: 'auto' }}
            height={'auto'}
            mx="auto"
          >
            <TextCard
              root={{
                height: 'full',
              }}
              image={{
                src: item?.image?.url ?? '',
                alt: item?.title ?? '',
              }}
              button={{
                children: item?.cta_label ?? '',
                href: item?.cta_href ?? '',
                whiteSpace: 'normal',
                textDecoration: 'none',
                textStyle: 'body.100',
                bgColor: 'none',
                color: 'light.100',
              }}
              textAlign={item?.text_align as any}
              theme={item?.theme as any}
            />
          </Box>
        )
      })}
    </Box>
  )
}
