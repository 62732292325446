import { AlgoliaFilterItem } from './types'

export const DEFAULT_SORTBY = 'newest'
export const DEFAULT_INITIAL_REFINEMENTS_LIMIT = 6
export const DEFAULT_SHOW_MORE_REFINEMENTS_LIMIT = 10
export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? ''
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? ''

export const ALGOLIA_FILTERS: AlgoliaFilterItem[] = [
  {
    type: 'list',
    attribute: 'department.name',
    urlAlias: 'department',
    translationKey: 'category.refinements.department',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'brand',
    urlAlias: 'brand',
    translationKey: 'category.refinements.brand',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'facetColors',
    urlAlias: 'facetColors',
    translationKey: 'category.refinements.facetColors',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'size',
    urlAlias: 'size',
    translationKey: 'category.refinements.size',
    operator: 'or',
  },
  {
    type: 'range',
    attribute: 'price.centAmount',
    urlAlias: 'price',
    translationKey: 'category.refinements.price.centAmount',
    operator: 'and',
  },
  {
    type: 'list',
    attribute: 'features',
    urlAlias: 'features',
    translationKey: 'category.refinements.features',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'performanceFeatures',
    urlAlias: 'performanceFeatures',
    translationKey: 'category.refinements.performanceFeatures',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'sleeveLength',
    urlAlias: 'sleeveLength',
    translationKey: 'category.refinements.sleeveLength',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'PantFit',
    urlAlias: 'PantFit',
    translationKey: 'category.refinements.PantFit',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'CollarTypes',
    urlAlias: 'CollarTypes',
    translationKey: 'category.refinements.CollarTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'graphic',
    urlAlias: 'graphic',
    translationKey: 'category.refinements.graphic',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'hatTypes',
    urlAlias: 'hatTypes',
    translationKey: 'category.refinements.hatTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'jacketStyle',
    urlAlias: 'jacketStyle',
    translationKey: 'category.refinements.jacketStyle',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'JacketWeight',
    urlAlias: 'JacketWeight',
    translationKey: 'category.refinements.JacketWeight',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'league',
    urlAlias: 'league',
    translationKey: 'category.refinements.league',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'material',
    urlAlias: 'material',
    translationKey: 'category.refinements.material',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'occasion',
    urlAlias: 'occasion',
    translationKey: 'category.refinements.occasion',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'PackageQty',
    urlAlias: 'PackageQty',
    translationKey: 'category.refinements.PackageQty',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'ShirtTypes',
    urlAlias: 'ShirtTypes',
    translationKey: 'category.refinements.ShirtTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'shortsInseam',
    urlAlias: 'shortsInseam',
    translationKey: 'category.refinements.shortsInseam',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'sockHeight',
    urlAlias: 'sockHeight',
    translationKey: 'category.refinements.sockHeight',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'style',
    urlAlias: 'style',
    translationKey: 'category.refinements.style',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'accessoryTypes',
    urlAlias: 'accessoryTypes',
    translationKey: 'category.refinements.accessoryTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'pantShortTypes',
    urlAlias: 'pantShortTypes',
    translationKey: 'category.refinements.pantShortTypes',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'printsPatterns',
    urlAlias: 'printsPatterns',
    translationKey: 'category.refinements.printsPatterns',
    operator: 'or',
  },
  {
    type: 'list',
    attribute: 'Types',
    urlAlias: 'Types',
    translationKey: 'category.refinements.Types',
    operator: 'or',
  },
]

export const CATEGORY_SEARCH_FIELDS = [
  // Not needed for now, just use Algolia config dashboard
]

export const ALGOLIA_INDEX_LIST = [
  { label: 'Name', value: 'nameAsc' },
  { label: 'Best Selling', value: 'bestseller' },
  { label: 'Newest Arrivals', value: `newest` },
  { label: 'Price (High to Low)', value: 'priceDesc' },
  { label: 'Price (Low to High)', value: 'priceAsc' },
  { label: 'Discount (High to Low)', value: 'discountDesc' },
]

export const LOCALES = [
  { languageCode: 'en', countryLanguageCode: 'en-US', currency: 'USD' },
  { languageCode: 'en', countryLanguageCode: 'en-CA', currency: 'CAD' },
  { languageCode: 'fr', countryLanguageCode: 'fr-CA', currency: 'CAD' },
] as const

export const SELLER_FILTER_FIELD = 'attributes.normalized.seller'

export const DEFAULT_RANKING_OPTIONS = ['typo', 'geo', 'words', 'filters', 'proximity', 'attribute', 'exact']

export const PRIMARY_INDEX_SETTINGS = {
  attributesForFaceting: [
    'brand',
    'size',
    'facetColors',
    'price.centAmount',
    'department',
    'features',
    'performanceFeatures',
    'sleeveLength',
    'PantFit',
  ],
  searchableAttributes: [
    'name',
    'categories',
    'brand',
    'size',
    'facetColors',
    'categories',
    'department',
    'features',
    'performanceFeatures',
    'sleeveLength',
    'PantFit',
  ],
  ranking: [...DEFAULT_RANKING_OPTIONS, 'desc(timestampCreated)'],
}

export const REPLICAS = [
  {
    name: 'newest',
    ranking: 'desc(timestampCreated)',
  },
  {
    name: 'priceDesc',
    ranking: 'desc(price.centAmount)',
  },
  {
    name: 'priceAsc',
    ranking: 'asc(price.centAmount)',
  },
  {
    name: 'nameAsc',
    ranking: 'asc(name)',
  },
  {
    name: 'bestseller',
    ranking: 'desc(quantity_sold)',
  },
  {
    name: 'discountDesc',
    value: 'desc(discountAmount)',
  },
]
