const pageType = (relativePath: string) => {
  switch (relativePath) {
    case 'static':
      return 'static'
    case 'c':
      return 'plp'
    case 'p':
      return 'pdp'
    case '':
      return 'index'
    default:
      return 'error'
  }
}

export const findPageType = (routerUrlPath: string) => {
  const relativeUrlPathList = routerUrlPath.split('/').slice(1)
  const page = pageType(relativeUrlPathList[0])
  return page
}

export const breadCrumbsBuilder = (routerUrlPath: string, pageType: string) => {
  const urlFragmentsList = routerUrlPath?.split('/').slice(1)
  if (urlFragmentsList) {
    switch (pageType) {
      case 'static':
        return staticPageBreadCrumbBuilder(urlFragmentsList)
      case 'index':
        return ['home']
    }
  }
  return []
}

export const staticPageBreadCrumbBuilder = (urlFragmentsList: string[]) => {
  const staticPageFragmentsList = urlFragmentsList
    .slice(1)
    .map((i) => i.split('#').flat())
    .flat()
  return staticPageFragmentsList
}
