import { Address } from '@Types/account/Address'
import { CheckoutStateType } from 'composable/components/types'
import { useCart } from 'frontastic'

export const getCheckoutAddress = (ctAddress?: Address) => {
  return {
    additional_street_info: ctAddress?.additionalStreetInfo ?? '',
    additional_address_info: ctAddress?.additionalAddressInfo ?? '',
    city: ctAddress?.city ?? '',
    country: ctAddress?.country ?? '',
    first_name: ctAddress?.firstName ?? '',
    id: ctAddress?.addressId ?? 'new',
    last_name: ctAddress?.lastName ?? '',
    phone_number: ctAddress?.phone ?? '',
    postcode: ctAddress?.postalCode ?? '',
    state: ctAddress?.state ?? '',
    street_name: ctAddress?.streetName ?? '',
    street_number: ctAddress?.streetNumber ?? '',
  }
}

export const convertShippingToCTAddress = (address: Partial<CheckoutStateType['shipping_address']>): Address => {
  return {
    addressId: address.id,
    additionalStreetInfo: address.additional_street_info,
    additionalAddressInfo: address.additional_address_info,
    city: address.city,
    country: address.country,
    firstName: address.first_name,
    lastName: address.last_name,
    phone: address.phone_number,
    postalCode: address.postcode,
    state: address.state,
    streetName: address.street_name,
    streetNumber: address.street_number,
  }
}

export const convertBillingToCTAddress = (address: CheckoutStateType['billing_address']): Address => {
  return {
    addressId: address.id,
    city: address.city,
    country: address.country,
    firstName: address.first_name,
    lastName: address.last_name,
    postalCode: address.postcode,
    state: address.state,
    streetName: address.street_name,
    streetNumber: address.street_number,
    phone: address.phone_number,
  }
}

export const shippingAddressInSync = (
  cart: ReturnType<typeof useCart>['data'] | undefined,
  shipping_address: CheckoutStateType['shipping_address'] | undefined,
): boolean => {
  if (!cart || !shipping_address) return false
  const shippingAddress = cart.shippingAddress ?? {} // extract the relevant properties from cart?.shippingAddress

  return (
    shippingAddress.addressId === shipping_address.id &&
    shippingAddress.additionalAddressInfo === shipping_address.additional_address_info &&
    shippingAddress.firstName === shipping_address.first_name &&
    shippingAddress.lastName === shipping_address.last_name &&
    shippingAddress.phone === shipping_address.phone_number &&
    shippingAddress.streetName === shipping_address.street_name &&
    shippingAddress.streetNumber === shipping_address.street_number &&
    shippingAddress.city === shipping_address.city &&
    shippingAddress.state === shipping_address.state &&
    shippingAddress.postalCode === shipping_address.postcode &&
    shippingAddress.country === shipping_address.country &&
    shippingAddress.additionalStreetInfo === shipping_address.additional_street_info
  ) // compare each field of the extracted shippingAddress object with the corresponding field in shipping_address
}

export const isBillingDifferentThanShipping = (shipping_address: Address, billing_address: Address) => {
  if (!shipping_address || !billing_address) return false

  if (
    !billing_address.firstName ||
    !billing_address.lastName ||
    !billing_address.streetName ||
    !billing_address.city ||
    !billing_address.state ||
    !billing_address.postalCode
  ) {
    return false
  }

  return (
    shipping_address.firstName !== billing_address.firstName ||
    shipping_address.lastName !== billing_address.lastName ||
    shipping_address.streetName !== billing_address.streetName ||
    shipping_address.city !== billing_address.city ||
    shipping_address.state !== billing_address.state ||
    shipping_address.postalCode !== billing_address.postalCode
  )
}
