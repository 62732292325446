import { Box, Divider } from '@chakra-ui/react'
import { CheckoutFooter } from './checkout-footer'
import { CheckoutHeader } from './checkout-header'
import { MegaMenuDetailsBar } from 'composable/components/mega-menu'

interface CheckoutLayoutProps {
  headerLogoUrl?: string
  children: React.ReactElement
}

export const CheckoutLayout = ({ headerLogoUrl, children }: CheckoutLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" bg={'shading.100'}>
      <CheckoutHeader headerLogoUrl={headerLogoUrl} />
      <Box width="100%" bg="brand.muted" borderBottom="1px" borderBottomColor="surface.highlight">
        <MegaMenuDetailsBar />
      </Box>
      {children}
      <Divider />
    </Box>
  )
}
