import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  Text,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Button,
  FormControl,
  InputGroup,
  InputRightAddon,
  Input,
  FormHelperText,
  FormErrorMessage,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { spacing } from 'composable/chakra/figma-tokens'
import {
  TARGETING_COOKIE_CATEGORY,
  EPSILON_SCRIPT_URL,
  ATG_BACKEND_ENDPOINT_DOMAIN,
  MERKLE_URL,
} from 'composable/components/general'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink, utagView, constructUtagData } from 'helpers/tealiumHelper'
import { useAtgUser, useAtgLegacyCart } from 'frontastic/contexts'
import CountrySelector from 'frontastic/tastics/country-selector'
import { monetateView } from 'frontastic/tastics/monetate'
import ApplePlayIcon from './applePlayIcon'
import { ForterScript } from './forter-script'
import { GooglePlayIcon } from './googlePlayIcon'
import OrderStatusModal from '../account/pages/account-dashboard/components/order-status'
import { Entry } from '../hooks/useContentstack'
import { invokeYottaaPubSubEvent } from '../invokeYottaaPubSubEvent'

declare var bt

export interface DXLFooterProps {
  items: Entry[]
  privacy_policy: any
}
type listItems = {
  lable: string
  title: string
}
interface EpsilonConfig {
  dtm_fid: number
  dtm_promo_id: number
  dtm_user_id: string | null
  dtm_email_hash: string | null
  dtmc_loc: string
  cachebuster: string
}
const COOKIE_SETTINGS = 'Cookies Settings'

export const DXLFooter = ({ items, privacy_policy }: DXLFooterProps) => {
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)
  const categorized = []
  const router = useRouter()
  const path = router.asPath
  const { userDetails, userSessionData } = useAtgUser()
  const [isUtagViewCalled, setIsUtagViewCalled] = useState(false)
  const staticPathRegex = /^\/static\/[^/]+$/
  useEffect(() => {
    const epsilonConfig: EpsilonConfig = {
      dtm_fid: 5419,
      dtm_promo_id: 1,
      dtm_user_id: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : null,
      dtm_email_hash: userDetails?.profile?.['email'] ? userDetails?.profile?.['email'] : null,
      dtmc_loc: window.location.href,
      cachebuster: new Date().getTime().toString(),
    }
    const scriptSrcVal = generateEpsilonScriptSrc(epsilonConfig, EPSILON_SCRIPT_URL)
    appendScript(scriptSrcVal, 'epsilonHomepage', TARGETING_COOKIE_CATEGORY)

    appendScript(MERKLE_URL, 'merkuryTagId', TARGETING_COOKIE_CATEGORY)
    return () => {
      removeScriptById('epsilonHomepage')
      removeScriptById('merkuryTagId')
    }
  }, [])

  useEffect(() => {
    if (userDetails && userSessionData) {
      if (!isUtagViewCalled) {
        const utag_data = constructUtagData(path, userDetails, userSessionData)
        utagView(utag_data, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
        setIsUtagViewCalled(true)
      }
    }
  }, [userDetails, userSessionData, path])

  const closeOrderStatusModal = () => {
    setIsOrderStatusModalOpen(false)
  }

  const generateEpsilonScriptSrc = (epsilonConfig: EpsilonConfig, epsilonScriptUrl: string): string => {
    const queryParams = Object.entries(epsilonConfig)
      .map(([key, value]) => {
        if (value !== null && value !== undefined) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
        }
        return null
      })
      .filter((param) => param !== null)
      .join('&')

    return `${ATG_BACKEND_ENDPOINT_DOMAIN}${epsilonScriptUrl}${queryParams}`
  }

  const removeScriptById = (scriptId: string): void => {
    const scriptElement = document.getElementById(scriptId)
    if (scriptElement) {
      document.head.removeChild(scriptElement)
    }
  }

  items?.forEach((item) => {
    if (item?.title) {
      const { title, children } = item
      if (!categorized[title]) {
        categorized[title] = []
      }
      categorized[title].push({ title: title, children: children })
    }
  })

  const { formatMessage } = useFormat({ name: 'common' })
  const [email, setEmail] = useState('')
  const [formTouched, setFormTouched] = useState(false)

  const NewLetterFormSchema = (deps: { formatMessage: (params: { id: string }) => string }) => {
    const { formatMessage } = deps
    return yup.object().shape({
      email: yup
        .string()
        .required(formatMessage({ id: 'footer.newsletter.emailrequired' }))
        .matches(
          /^[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
          formatMessage({ id: 'footer.newsletter.invalidemail' }),
        ),
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string; password: string }>({
    resolver: yupResolver(NewLetterFormSchema({ formatMessage })),
    mode: 'all',
  })

  const handleEmailChange = (e) => {
    setFormTouched(true)
    setEmail(e?.target?.value)
  }

  const handleSubmitEmail = async () => {
    if (formTouched && Object?.keys(errors)?.length === 0) {
      router?.push(`/emailsignup?email=${email}&src=website_footer`)
      let properties = {
        signup_source: 'footer',
        source: 'website',
        opt_status: 'I',
        preferred_freq: 'D',
        promo_opt_in: 'true',
        xl_rewards_opt_in: 'true',
        email: email,
        contacts: [
          {
            type: 'email',
            value: email,
          },
        ],
      }
      bt('track', 'signed_up', properties)
    }
  }
  const handleLinkCLicks = (e) => {
    const anchorText = e.target?.innerText
    const href = (e.target?.href).toLowerCase()
    if (href && href.indexOf('orderstatus') > -1) {
      e.preventDefault()
      setIsOrderStatusModalOpen(true)
    }

    if (anchorText.toLowerCase().indexOf('chat') > -1) {
      e.preventDefault()
      const win: any = window
      if (win.openChat) {
        win.openChat()
        if (win.openChat) {
          win.openChat()
        }
      }
    }

    if (staticPathRegex.test(path)) {
      utagLink([
        'link',
        {
          tealium_event: 'nav_footer_level3',
          nav_link: anchorText,
        },
      ])
    }
  }
  /* Once a common footer is created for PLP, PDP and other pages, we will rewrite the following yottaa event invocation */
  const { cartData } = useAtgLegacyCart()

  useEffect(() => {
    invokeYottaaPubSubEvent('index', path)
  }, [path])

  useEffect(() => {
    monetateView(cartData, path)
  }, [cartData, path])

  const cookieRef = (element: Element) => {
    element?.classList.add('ot-sdk-show-settings')
    element?.setAttribute('id', 'onetrust-button')
  }

  return (
    <>
      <Box
        as="nav"
        id="footer"
        aria-label="DXL Footer"
        position="relative"
        zIndex="3"
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="shading.100"
        color="secondary.900"
        mx={{ base: '-1rem', lg: 'auto' }}
      >
        <Box
          width={{ base: '100%', '2xl': 'container.2xl' }}
          display={'flex'}
          flexDirection={{ base: 'column', md: 'column', lg: 'row' }}
          pt={{ base: 8, xl: 12 }}
          pb={{ base: 4, sm: 0 }}
          px={{ base: 4, lg: 8, xl: 12, '2xl': 24 }}
          borderWidth="1px 0px 0px 0px"
          borderColor="shading.100"
          borderBlockStart={'none'}
          gap={7}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
            gap={{ base: '0', md: 'var(--chakra-space-6)' }}
            borderBottomStyle={{ base: 'solid', md: 'none' }}
            borderBottomWidth={{ base: '1px', md: '0' }}
            borderBottomColor={{ base: 'secondary-100', md: 'none' }}
          >
            {Object.entries(categorized).map(([title, children], index) => (
              <>
                <Accordion
                  allowToggle
                  width="100%"
                  marginTop="0 !important"
                  display={{ base: 'flex', md: 'none' }}
                  flexDirection={'column'}
                >
                  <AccordionItem borderColor={index === 0 ? 'transparent' : 'var(--chakra-colors-chakra-border-color)'}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            display={'flex'}
                            gap={2}
                            padding={'var(--chakra-space-3,12px) 0px var(--chakra-space-3,12px) 0px'}
                          >
                            <Text textStyle={'body-75'} flex="1" textAlign="left" color={'primary'}>
                              {title}
                            </Text>
                            {isExpanded ? <MinusIcon fontSize="xs" /> : <AddIcon fontSize="12px" />}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          padding={'var(--chakra-space-4) 0px var(--chakra-space-4) 0px'}
                          width={'100%'}
                          gap={6}
                          display={'flex'}
                          flexDirection={'column'}
                        >
                          {children[0]?.children.map((item) => (
                            <Link
                              key={index}
                              href={item.href}
                              onClick={handleLinkCLicks}
                              fontSize={'sm'}
                              color={'secondary.900'}
                              _hover={{ color: 'secondary.900' }}
                            >
                              {item.label}
                            </Link>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </Accordion>
                <Box
                  flexDirection="column"
                  gap={5}
                  key={index}
                  display={{ base: 'none', md: 'flex' }}
                  minWidth={{ base: 'auto', '2xl': '190', xl: '174', lg: '131', md: '166' }}
                >
                  <Text fontWeight={'semiBold'} textStyle={'heading-desktop-75'} color={'secondary.900'} tabIndex={0}>
                    {title}
                  </Text>
                  <Box
                    gap={5}
                    flexDirection="column"
                    display={{ base: 'none', md: 'flex' }}
                    width={{ base: 'full', lg: 'auto' }}
                  >
                    {children?.[0]?.children.map((item, i) =>
                      item.title === COOKIE_SETTINGS ? (
                        <Link
                          key={i}
                          ref={cookieRef}
                          fontSize={'sm'}
                          color={'secondary.900'}
                          _hover={{ color: 'secondary.900' }}
                          target={item.target}
                          tabIndex={0}
                          href="#"
                        >
                          {item.label}
                        </Link>
                      ) : (
                        <Link
                          key={i}
                          onClick={handleLinkCLicks}
                          href={item.href}
                          fontSize={'sm'}
                          color={'secondary.900'}
                          _hover={{ color: 'secondary.900' }}
                          target={item.target}
                          tabIndex={0}
                        >
                          {item.label}
                        </Link>
                      ),
                    )}
                  </Box>
                </Box>
              </>
            ))}
          </Box>

          <VStack
            justifyContent={{ base: 'normal', md: 'space-between' }}
            display={'flex'}
            flexDirection={{ base: 'column', md: 'row', lg: 'column' }}
            gap={6}
            alignItems={{ base: 'center', md: 'end', lg: 'start' }}
            padding={0}
          >
            <Box
              w={{ base: 'full', md: 'auto' }}
              minH={'103px'}
              display={'flex'}
              flexDirection={'column'}
              gap={9}
              m={0}
            >
              <form onSubmit={handleSubmit(handleSubmitEmail)}>
                <FormControl isInvalid={Boolean(errors?.email)}>
                  <Box mb={4}>
                    <Text fontWeight={'semiBold'} textStyle={'heading-desktop-75'} color={'text.primary'} m={0}>
                      {formatMessage({ id: 'footer.newsletter.label' })}
                    </Text>
                  </Box>
                  <Box height={'66px'}>
                    <InputGroup
                      display={'flex'}
                      flexDirection={'row'}
                      height={'40px'}
                      color={'surface.primary'}
                      border={'1px solid shading.500'}
                      borderRadius={1.5}
                      mb={2}
                    >
                      <Input
                        type="email"
                        placeholder={formatMessage({ id: 'footer.newsletter.placeholder' })}
                        onInput={handleEmailChange}
                        width={'auto'}
                        fontWeight={'normal'}
                        fontSize={'md'}
                        lineHeight={'24px'}
                        textColor={'text.primary'}
                        px={4}
                        py={2}
                        _hover={{ borderColor: '<color>' }}
                        flex="1"
                        {...register('email')}
                      />
                      <InputRightAddon p={0}>
                        <Button
                          type="submit"
                          width={'109px'}
                          color={'brand.primary'}
                          fontSize={'md'}
                          fontWeight={'semibold'}
                          lineHeight={'24px'}
                          textColor={'surface.primary'}
                          _hover={{ bg: '<color>' }}
                          borderRadius={'0px 6px 6px 0px'}
                          px={4}
                          py={2}
                        >
                          {formatMessage({ id: 'footer.newsletter.subscribe' })}
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                    {errors && <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>}
                    <FormHelperText textStyle={'body-50'} tabIndex={0}>
                      {formatMessage({ id: 'footer.newsletter.signingup' })}{' '}
                      <Link textStyle={'link-50'} href={privacy_policy.href} textDecoration="underline">
                        {privacy_policy.title}
                      </Link>
                    </FormHelperText>
                  </Box>
                </FormControl>
              </form>
              <CountrySelector />
            </Box>
            <Box
              maxW={{ base: 'auto', sm: '100%', md: 'auto' }}
              display={'flex'}
              height={spacing[10]}
              gap={{ base: 6, md: 4 }}
              flexDirection={'row'}
              alignItems={'end'}
            >
              <Button
                _active={{ bg: 'secondary.400' }}
                _hover={{ bg: 'secondary.100' }}
                target="_blank"
                bg={'none'}
                padding={'0'}
                as={Link}
                href="https://apps.apple.com/us/app/dxl-big-tall/id1245963543"
                aria-label="Download on the App Store"
              >
                <ApplePlayIcon width={'120px'} height={spacing[10]} />
              </Button>
              <Button
                _active={{ bg: 'secondary.400' }}
                _hover={{ bg: 'secondary.100' }}
                target="_blank"
                bg={'none'}
                padding={'0'}
                as={Link}
                href="https://play.google.com/store/apps/details?id=com.dxl.android&hl=en&pli=1"
                aria-label="Get it on Google Play"
              >
                <GooglePlayIcon width={'135px'} height={spacing[10]} />
              </Button>
            </Box>
          </VStack>
        </Box>
      </Box>
      <ForterScript></ForterScript>
      {isOrderStatusModalOpen && <OrderStatusModal isOpen={isOrderStatusModalOpen} onClose={closeOrderStatusModal} />}
    </>
  )
}
