import { ReactNode } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonProps,
  Container,
  Grid,
  GridItem,
  HStack,
  VStack,
  useBreakpointValue,
  AspectRatio,
} from '@chakra-ui/react'
import { CartState } from '@Types/cart/Cart'
import { BrandLogo, NextLinkNoPrefetch } from 'composable'
import { BsTruck } from 'react-icons/bs'
import { IoPersonCircleOutline, IoHeartOutline, IoStorefrontOutline } from 'react-icons/io5'
import { CartIcon } from './cart-icon'
import { APP_CONTEXT } from './general'
import { LangSwitchProps } from './lang-switch'
import { MegaMenuDetailsBar } from './mega-menu/MegaMenuDetailsBar'

interface BaseHeaderDesktopProps {
  accountDashboardButtonText: string
  accountDashboardUrl: string
  brandLogo?: any
  cartQuantity?: number
  homeUrl: string
  isLoggedIn: boolean
  loginButtonText: string
  loginUrl: string
  logout: () => void
  logoutButtonText: string
  myAccountButtonProps?: ButtonProps
  onOpenAccountDrawer?: () => void
  onOpenCartDrawer?: () => void
  onOpenMegaDrawer?: () => void
  searchComponent?: ReactNode
  userName?: string
  cartState?: CartState
}

export type HeaderDesktopProps = BaseHeaderDesktopProps & LangSwitchProps

export const HeaderDesktop = ({
  accountDashboardButtonText,
  accountDashboardUrl,
  brandLogo,
  cartQuantity,
  homeUrl,
  isLoggedIn,
  loginButtonText,
  loginUrl,
  logout,
  logoutButtonText,
  myAccountButtonProps,
  onOpenAccountDrawer,
  onOpenCartDrawer,
  onOpenMegaDrawer,
  searchComponent,
  userName,
  cartState,
  ...langSwitchProps
}: HeaderDesktopProps) => {
  const router = useRouter()
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const logoAspectRatio = brandLogo?.media ? Number(brandLogo.media.width) / Number(brandLogo.media.height) : 4 / 1
  const isCartPage = router.asPath === '/cart'
  const gridTemplateColumns =
    APP_CONTEXT === 'STS' ? { base: '1fr 5fr 1fr', xl: '1fr 950px 1fr' } : { base: '1fr 4fr 1fr', xl: '1fr 5fr 1fr' }

  return (
    <Box>
      <Container maxW="100rem" px={{ base: 4, lg: 10 }} py={3}>
        {isMobile ? (
          <VStack>
            <HStack width="100%">
              <HStack flex={1}>
                <Button
                  name="menu"
                  aria-label="menu"
                  width={'40px'}
                  minWidth={'auto'}
                  variant="unstyled"
                  onClick={() => onOpenMegaDrawer?.()}
                >
                  <HamburgerIcon width={'26px'} height={'26px'} />
                </Button>

                <Image
                  src={brandLogo.media.file}
                  alt={brandLogo.title.en_US ?? 'Brand Logo'}
                  priority
                  objectFit="contain"
                  width={80}
                  height={45}
                  onClick={() => router.push(homeUrl)}
                />
              </HStack>

              <Button
                id={'my-shopping-bag'}
                name={'my shopping bag'}
                width={'40px'}
                minWidth={'auto'}
                variant="unstyled"
                onClick={() => !isCartPage && onOpenCartDrawer?.()}
                opacity={isCartPage ? 0.5 : 1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                aria-label={`${cartQuantity} items in your shopping bag`}
              >
                <CartIcon cartQuantity={cartQuantity} />
              </Button>
            </HStack>

            {searchComponent}
          </VStack>
        ) : (
          <Grid gridTemplateColumns={gridTemplateColumns} gap="8">
            <GridItem display="flex" flexDirection="column" justifyContent="center">
              {brandLogo && (
                <Box as={NextLinkNoPrefetch} href={homeUrl ?? '/'} position="relative">
                  <AspectRatio maxW="75px" ratio={logoAspectRatio} cursor="pointer">
                    <BrandLogo
                      src={brandLogo.media.file}
                      alt={brandLogo.title?.en_US ?? ''}
                      style={{ objectFit: 'contain' }}
                      fill
                      sizes="20vw"
                    />
                  </AspectRatio>
                </Box>
              )}
            </GridItem>

            <GridItem role={'search'}>{searchComponent}</GridItem>

            <GridItem>
              <HStack justifyContent="flex-end">
                {APP_CONTEXT !== 'STS' && (
                  <>
                    <Button
                      variant="ghost"
                      textDecoration="none"
                      name="order-status"
                      size="sm"
                      aria-label={'Order Status'}
                      minW={'40px'}
                      leftIcon={<IoStorefrontOutline size="26px" color="#111111" />}
                      onClick={() => null} // todo
                      {...myAccountButtonProps}
                    >
                      Stores
                    </Button>

                    <Button
                      variant="ghost"
                      textDecoration="none"
                      name="order-status"
                      size="sm"
                      aria-label={'Order Status'}
                      minW={'40px'}
                      leftIcon={<BsTruck size="26px" color="#111111" />}
                      onClick={() => null} // todo
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      {...myAccountButtonProps}
                    >
                      Order Status
                    </Button>

                    <Button
                      variant="ghost"
                      textDecoration="none"
                      size="sm"
                      name="my account"
                      aria-label={'My account'}
                      minW={'40px'}
                      onClick={() => onOpenAccountDrawer?.()}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      {...myAccountButtonProps}
                    >
                      {isLoggedIn && userName && userName !== '' ? (
                        <Box
                          borderRadius={'50%'}
                          background={'text'}
                          color={'background'}
                          textStyle={'callouts-blockquote-50'}
                          fontWeight={'700'}
                          height={'26px'}
                          width={'26px'}
                          padding={1}
                        >
                          {userName}
                        </Box>
                      ) : (
                        <IoPersonCircleOutline size="26px" color="#111111" />
                      )}
                    </Button>
                    <Button
                      variant="ghost"
                      textDecoration="none"
                      size="sm"
                      name="wishlist"
                      aria-label={'Wishlist'}
                      minW={'40px'}
                      leftIcon={<IoHeartOutline size="26px" color="#111111" />}
                      iconSpacing={0}
                      onClick={() => null} // todo
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    />
                  </>
                )}
                <Button
                  id={'my-shopping-bag'}
                  name={'my shopping bag'}
                  width={'40px'}
                  minWidth={'auto'}
                  variant="unstyled"
                  onClick={() => !isCartPage && onOpenCartDrawer?.()}
                  disabled={isCartPage}
                  cursor={isCartPage ? 'not-allowed' : 'pointer'}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  aria-label={`${cartQuantity} items in your shopping bag`}
                >
                  <CartIcon cartQuantity={cartState === 'Active' ? cartQuantity : 0} />
                </Button>
              </HStack>
            </GridItem>
          </Grid>
        )}
      </Container>

      {isMobile && <MegaMenuDetailsBar />}
      {/*
      On Desktop, the MegaMenuDetailsBar is rendered in the MegaMenu component.
      On Mobile, the MegaMenuDetailsBar is rendered in the HeaderDesktop component.
      */}
    </Box>
  )
}
