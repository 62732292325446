import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Heading, Button, Text, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackBannerSplit as ContentstackBannerSplitProps } from '@Types/contentstack'
import { useFormat } from 'helpers/hooks/useFormat'
import { NextSeo } from 'next-seo'
import { ContentstackBannerSplit } from './contentstack'
import { NOT_MATCH_PAGE_CONTENT_TYPE, NOT_MATCH_PAGE_TITLE } from './general'
import useContentstack from './hooks/useContentstack'

export const NoMatchPage = () => {
  const [data, setData] = useState<ContentstackBannerSplitProps>()
  const intl = useFormat({ name: 'common' })
  const title = intl.formatMessage({ id: 'noMatchPage.title' })

  const { getEntryByName } = useContentstack()

  useEffect(() => {
    getPageContent()
  }, [])

  const getPageContent = async () => {
    const result = await getEntryByName(NOT_MATCH_PAGE_CONTENT_TYPE, NOT_MATCH_PAGE_TITLE)
    setData(result as ContentstackBannerSplitProps)
  }

  if (!data) return null

  return (
    <Box py={{ base: 6, md: 16 }} px={{ base: 0, md: 24 }} display={'flex'} justifyContent={'center'}>
      <NextSeo title={title} noindex nofollow />
      <ContentstackBannerSplit {...data} />
    </Box>
  )
}
