import { Box, Flex, HStack } from '@chakra-ui/react'
import { IoCheckmark } from 'react-icons/io5'
import { CheckoutStepLink } from './checkout-step-link'
import { useCheckoutSteps } from '../checkout-provider'

export const CheckoutSteps = () => {
  const { goTo, step, steps } = useCheckoutSteps()

  return (
    <Flex m={{ base: 'none', md: 'auto' }} flex={{ base: 1, md: 1 }}>
      <HStack spacing={4} width="100%" justifyContent="space-between">
        {steps.map((s) => {
          if (!s.id || !step.id) return
          const isPrevious = step.id > s.id
          const isPreviousOrEqual = step.id >= s.id

          return (
            <Box as={'span'} display="inline-flex" key={s.id}>
              {isPrevious && (
                <Box margin="auto">
                  <IoCheckmark size={20} />
                </Box>
              )}
              <CheckoutStepLink
                isPrevious={isPrevious}
                active={step.key === s.key}
                title={isPrevious ? s.name : `${s.id}.  ${s.name}`}
                onClick={() => goTo(s.hash)}
                isAllowed={s.isAllowed && isPreviousOrEqual}
              />
            </Box>
          )
        })}
      </HStack>
    </Flex>
  )
}
