import { Button, Flex, Text, VStack } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable/components/link'

interface OrderHistoryEmptyProps {
  title: string
  description: string
  buttonLabel: string
  buttonHref: string
  buttonOnClick?: (() => void) | undefined
}

export const EmptyPage = ({ title, description, buttonLabel, buttonHref, buttonOnClick }: OrderHistoryEmptyProps) => {
  return (
    <Flex bg={'shading.100'} p={8} height={'xl'} justifyContent={'center'} alignItems={'center'}>
      <VStack spacing={4}>
        <Text textStyle={'heading-desktop-300'}>{title}</Text>

        <Text textStyle={'callouts-blockquote-100'}>{description}</Text>
        <Button
          variant={'solid'}
          type="submit"
          fontSize={'heading-mobile-50'}
          fontWeight={'bold'}
          onClick={buttonOnClick}
        >
          <NextLinkNoPrefetch href={buttonHref} passHref>
            {buttonLabel}
          </NextLinkNoPrefetch>
        </Button>
      </VStack>
    </Flex>
  )
}
