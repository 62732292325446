import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { CartLoadingState } from '../cart'
import { CartEmptyState } from '../cart/cart-empty-state'
import { Section } from '../section'

interface CartSummaryAccordionProps {
  isLoading: boolean
  isEmpty: boolean
  cartItemsQuantity?: number
  formattedCartTotalPrice: string
  children: React.ReactElement
}

export const CartSummaryAccordion = ({
  isLoading,
  isEmpty,
  cartItemsQuantity,
  formattedCartTotalPrice,
  children,
}: CartSummaryAccordionProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const title = formatMessage({
    id: cartItemsQuantity ? 'cart.summary.titleCount' : 'cart.drawer.title',
    values: { count: cartItemsQuantity },
  })

  return (
    <Accordion allowToggle display={{ base: 'block', md: 'none' }} bg={'surface.primary'}>
      <AccordionItem>
        <AccordionButton>
          <Text flex="1" textAlign="left" textStyle={'body-75'}>
            {title} <AccordionIcon />
          </Text>
          <Text textStyle={'heading-desktop-100'}>{formattedCartTotalPrice}</Text>
        </AccordionButton>
        <AccordionPanel p={'0'}>
          {isLoading ? <CartLoadingState /> : isEmpty ? <CartEmptyState /> : <Section>{children}</Section>}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
