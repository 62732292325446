import { useState } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  ModalFooter,
  useDisclosure,
  Link,
} from '@chakra-ui/react'
import { ContentstackModalProps } from '@Types/contentstack'

export const ContentstackModal = (props: ContentstackModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [iframeUrl, setIframeUrl] = useState('')

  const handleOpen = () => {
    if (props.modal_or_launch_in_a_new_tab) {
      // opens the link as a pop up
      window.open(props.link.href, 'newwin', 'height=600px,width=600px')
    } else {
      // opens the link as a modal
      setIframeUrl(props.link.href)
      onOpen()
    }
  }

  return (
    <>
      {props.button_or_link ? (
        <Button
          backgroundColor="#000"
          _hover={{ backgroundColor: '#595959' }}
          fontSize=".875rem"
          border="1px"
          borderRadius="1"
          type="submit"
          height={'45px'}
          _disabled={{ opacity: '0.65' }}
          onClick={handleOpen}
        >
          {props.title}
        </Button>
      ) : (
        <Link
          _hover={{ color: 'black', textDecoration: 'none' }}
          fontSize={'14px'}
          textDecoration={'none'}
          title={props.title}
          tabIndex={0}
          onClick={handleOpen}
        >
          {props.title}
        </Link>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        size={props.modal_rendering_sizes}
      >
        <ModalOverlay />
        <ModalContent mx={2} my={4} borderRadius="4px" borderColor="black">
          <ModalHeader p={'20px 34.132px'} fontSize="1.25rem" fontWeight="400" lineHeight="1.2" textAlign="center">
            {props.link.title}
          </ModalHeader>
          <ModalCloseButton size={'lg'} _hover={{ backgroundColor: 'none !important', opacity: '0.7' }} />
          <Box p={'0px 34.132px'}>
            <Divider borderColor="gray.800" />
          </Box>

          <ModalBody p={'10px 34.132px'}>
            <iframe id="iframe-modal" src={iframeUrl} width="100%" height="600px" style={{ border: 'none' }} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
