import { useState } from 'react'
import ContentstackLivePreview from '@contentstack/live-preview-utils'
import { IStackSdk } from '@contentstack/live-preview-utils/dist/src/utils/types'
import * as Contentstack from 'contentstack'

const API_KEY = process.env.NEXT_PUBLIC_CONTENTSTACK_API_KEY
const ACCESS_KEY = process.env.NEXT_PUBLIC_CONTENTSTACK_ACCESS_KEY
const ENVIRONMENT = process.env.NEXT_PUBLIC_CONTENTSTACK_ENVIRONMENT
const USE_LIVE_PREVIEW = process.env.NEXT_PUBLIC_CONTENTSTACK_USE_LIVE_PREVIEW === 'true' || false
export const LIVE_PREVIEW_TOKEN = USE_LIVE_PREVIEW ? process.env.NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_TOKEN : ''
const LIVE_PREVIEW_HOST = USE_LIVE_PREVIEW ? process.env.NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_HOST : ''
const BRANCH_NAME = process.env.NEXT_PUBLIC_CONTENTSTACK_BRANCH_NAME

export interface Entry {
  uid: string
  title: string
  label: string
  href: string
  description: string
  imagesCollection: any
  children?: Entry[]
  alternateUrl?: string
  image?: {
    url: string
  }
  _content_type_uid: string
  cta_label?: string
  cta_href?: string
  text_card_title_font_size?: string
  text_card_title_font_family?: string
  text_card_title?: string
  text_align?: string
  theme?: string
  target?: Entry[]
}
export interface BAEntry {
  uid: string
  title?: string
  text?: string
  link_1?: { title: string; href: string }
  link_2?: { title: string; href: string }
  image?: string
  tooltip_content?: string
  children?: Entry[]
  _content_type_uid: string
}

const configFromEnv = () => ({
  api_key: API_KEY ?? '',
  delivery_token: ACCESS_KEY ?? '',
  environment: ENVIRONMENT ?? '',
  branch: BRANCH_NAME ?? '',
  live_preview: {
    enable: USE_LIVE_PREVIEW,
    host: LIVE_PREVIEW_HOST ?? 'rest-preview.contentstack.com',
    preview_token: LIVE_PREVIEW_TOKEN,
  },
  fetchOptions: { debug: true },
})

const initializeContentstackClient = async (config: Partial<Contentstack.Config> = configFromEnv()) => {
  const contentstackConfig: Contentstack.Config = {
    api_key: config.api_key ?? '',
    delivery_token: config.delivery_token ?? '',
    environment: config.environment ?? '',
  }

  if (config.live_preview?.enable) {
    contentstackConfig.live_preview = config.live_preview
  }

  if (config.branch === BRANCH_NAME) {
    contentstackConfig.branch = BRANCH_NAME
  }

  const contentstackStack = Contentstack.Stack(contentstackConfig) as Contentstack.Stack & IStackSdk
  await initializeContentstackLivePreview(contentstackStack, config.live_preview?.enable ?? false)
  return contentstackStack
}

interface LivePreviewData {
  init?: boolean
  content_type_uid?: string
  entry_uid?: string
  enable: boolean
  host?: string
  preview_token?: string
  hash?: string
  live_preview?: string
}

const initializeContentstackLivePreview = async (
  stackSdk: Contentstack.Stack & IStackSdk,
  isLivePreviewEnabled: boolean,
) => {
  return ContentstackLivePreview.init({ stackSdk, enable: isLivePreviewEnabled, editButton: { enable: false } })
}

export const api = await initializeContentstackClient()

const updateLivePreviewDetails = (data: LivePreviewData) => {
  return {
    content_type_uid: data?.content_type_uid,
    entry_uid: data?.entry_uid,
  }
}

const useContentstack = () => {
  const [isLoading, setIsLoading] = useState(false)

  const livePreview = updateLivePreviewDetails(api.live_preview)

  const getEntryByUid = async (contentTypeUid: string, entryUid: string) => {
    setIsLoading(true)
    try {
      return (await api
        .ContentType(contentTypeUid)
        .Entry(entryUid)
        .includeReference(['children', 'children.children', 'children.children.children'])
        .includeEmbeddedItems()
        .includeContentType()
        .language('en-us')
        .toJSON()
        .includeEmbeddedItems()
        .fetch()) as Entry
    } catch (err) {
      console.log(`Error in Contentstack getEntryByUid -  contentTypeUid: ${contentTypeUid} - entryUid: ${entryUid}`)
    } finally {
      setIsLoading(false)
    }
  }

  const getEntryByName = async (contentTypeUid: string, entryName: string) => {
    setIsLoading(true)
    try {
      return await api.ContentType(contentTypeUid).Query().where('title', entryName).toJSON().findOne()
    } catch (err) {
      console.log(
        `Error in Contentstack getEntryByName -  contentTypeUid: ${contentTypeUid} - entryName: ${entryName}`,
        err,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const getEntryBySlug = async (contentTypeUid: string, slug: string) => {
    setIsLoading(true)
    try {
      return await api
        .ContentType(contentTypeUid)
        .Query()
        .where('slug', slug)
        .includeReference(['article_item', 'content'])
        .includeEmbeddedItems()
        .toJSON()
        .findOne()
    } catch (err) {
      console.log(`Error in Contentstack getEntryByName -  contentTypeUid: ${contentTypeUid} - slug: ${slug}`, err)
    } finally {
      setIsLoading(false)
    }
  }

  return { getEntryByUid, getEntryByName, getEntryBySlug, livePreview, isLoading }
}

export default useContentstack
