import { Box, Image, Text, Flex, useToken } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import {
  getProductAttribute,
  getProductAttributes,
  getAttributeFromMasterVariant,
} from 'composable/helpers/utils/product-card-utils'
import { parseAttributeArrayIntoString } from 'composable/helpers/utils/string-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoImagesOutline } from 'react-icons/io5'
import { HorizontalProductCardCommon } from './types'

export type HorizontalProductCardReadOnlyProps = HorizontalProductCardCommon

export const HorizontalProductCardReadOnly = (props: HorizontalProductCardReadOnlyProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const {
    columns = 3,
    size = 'lg',
    image,
    name,
    price,
    discountedPrice,
    quantity,
    variant,
    url,
    masterProductData,
  } = props
  const [brandSecondary] = useToken('colors', ['brand.secondary'])

  const { src: imageSrc, alt: imageAlt = name } = image ?? {}
  const segment2Label = masterProductData?.find(({ name }) => name === 'segment2Label')?.value
  const segment3Label = masterProductData?.find(({ name }) => name === 'segment3Label')?.value
  const productAttributes = getProductAttributes(variant, segment2Label, segment3Label) ?? []
  const parsedProductAttributes = parseAttributeArrayIntoString(productAttributes) ?? ''
  const productItemNumber = getProductAttribute({ variant, attribute: 'itemNumber' })
  const brand = getAttributeFromMasterVariant({ masterProductData, attribute: 'brand' })

  const labels = {
    quantity: formatMessage({
      id: 'cart.item.quantity',
    }),
    itemPrice: formatMessage({
      id: 'cart.item.price',
    }),
    totalPrice: formatMessage({
      id: 'cart.item.totalPrice',
    }),
    remove: formatMessage({ id: 'action.remove' }),
    addToWishlist: formatMessage({
      id: 'action.addToWishlist',
    }),
  }

  const quantityLabel = labels.quantity ?? ''
  const itemPriceLabel = labels.itemPrice ?? ''

  const cardOptions = {
    2: {
      sm: {
        imageSize: {
          width: '80px',
          height: '106px',
        },
        quantity: { type: 'text', label: true },
        itemPrice: { type: 'text', label: true },
        contentColumn: {
          paddingTop: 0,
          paddingRight: 4,
        },
      },
      lg: {
        imageSize: {
          width: '100px',
          height: '133px',
        },
        quantity: { type: 'text', label: true },
        itemPrice: { type: 'text', label: true },
        contentColumn: {
          paddingTop: 0,
        },
      },
    },
    3: {
      sm: {
        imageSize: {
          width: '88px',
          height: '117px',
        },
        quantity: { type: 'text', label: true },
        itemPrice: { type: 'text', label: true },
        contentColumn: {
          paddingTop: 2,
        },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        quantity: { type: 'text', label: true },
        itemPrice: { type: 'column', label: true },
        contentColumn: {
          paddingTop: 4,
        },
      },
    },
    4: {
      sm: {
        imageSize: {
          width: '88px',
          height: '117px',
        },
        quantity: { type: 'text', label: true },
        itemPrice: { type: 'column', label: false },
        contentColumn: {
          paddingTop: 2,
        },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        quantity: { type: 'column', label: true },
        itemPrice: { type: 'column', label: true },
        contentColumn: {
          paddingTop: 4,
        },
      },
    },
  }

  const imageSize = cardOptions[columns][size].imageSize
  const quantityOptions = cardOptions[columns][size].quantity
  const itemPriceOptions = cardOptions[columns][size].itemPrice
  const contentColumn = cardOptions[columns][size]?.contentColumn

  return (
    <Box>
      <Flex gap={4}>
        <Flex
          aspectRatio={3 / 4}
          width={imageSize.width}
          height={imageSize.height}
          onClick={image?.onClickImage}
          cursor={image?.onClickImage ? 'pointer' : undefined}
        >
          {imageSrc ? (
            <Image src={imageSrc} alt={imageAlt} fit="contain" width={'full'} height={'full'} />
          ) : (
            <Flex justify={'center'} align={'center'} width={'full'} height={'full'} bgColor={'brand.muted'}>
              <IoImagesOutline size={'40px'} color={brandSecondary} />
            </Flex>
          )}
        </Flex>
        <Flex {...contentColumn} grow={1}>
          <Flex direction="column" flex={1}>
            <Text textStyle={'body-75'} color={'text-muted'}>
              {brand}
            </Text>
            <Text
              as={NextLinkNoPrefetch}
              href={url}
              textStyle={'body-100'}
              _hover={{ textDecoration: 'none', color: 'text-muted' }}
            >
              {name}
            </Text>
            {itemPriceOptions.type === 'text' && (
              <Flex>
                {discountedPrice && (
                  <Text mr={1} textStyle={'figma-strikethrough-75'}>
                    {discountedPrice}
                  </Text>
                )}
                <Text textStyle={'body-75'} color={discountedPrice ? 'danger.600' : 'text'}>
                  {price}
                </Text>
              </Flex>
            )}
            {quantityOptions.type === 'text' && (
              <Text mt={1} textStyle={'body-50'} color={'text-muted'}>
                {quantityOptions.label && `${quantityLabel}: `} {quantity}
              </Text>
            )}
            <Box textStyle={'body-50'} color={'text-muted'} style={{ wordWrap: 'break-word' }}>
              <Text>
                {productItemNumber ? `Item #: ${productItemNumber}` : ``}, {parsedProductAttributes}
              </Text>
            </Box>
          </Flex>
          {quantityOptions.type === 'column' && (
            <Box textAlign={'center'} mr={6}>
              {quantityOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {quantityLabel}
                </Text>
              )}
              <Text textStyle={'body-75'}>{quantity}</Text>
            </Box>
          )}
          {itemPriceOptions.type === 'column' && (
            <Box textAlign={'end'} mr={6}>
              {itemPriceOptions.label && (
                <Text mb={1} color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {itemPriceLabel}
                </Text>
              )}
              <Box>
                <Text textStyle={'body-75'} color="danger.600">
                  {discountedPrice}
                </Text>
                <Text textStyle={discountedPrice ? 'figma-strikethrough-75' : 'body-75'}>{price}</Text>
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
