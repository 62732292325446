import { FIND_MINE_APPLICATION_ID, FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general'
import React, { useEffect } from 'react'

interface CompleteTheLooksProps {
  productInStock: boolean
  productColorName: string
  productId: string
  productPrice: string
  productOnSale: boolean
}

const CompleteTheLook: React.FC<CompleteTheLooksProps> = ({
  productInStock,
  productColorName,
  productId,
  productPrice,
  productOnSale,
}) => {
  useEffect(() => {
    const findmineScript = 'https://js.findmine.com/' + FIND_MINE_APPLICATION_ID + '.module.js'
    appendScript(findmineScript, 'findMineScriptCTL', FUNCTIONAL_COOKIE_CATEGORY, () => {
      const win: any = window
      const intrvl = setInterval(() => {
        if (win?.FindMine) {
          clearInterval(intrvl)
          loadContainers()
        }
      }, 200)
    })
  }, [])

  useEffect(() => {
    loadContainers()
  }, [productColorName])

  const loadContainers = () => {
    const win: any = window
    const obj = {
      application: FIND_MINE_APPLICATION_ID,
      product_in_stock: productInStock,
      product_color_id: productColorName.toLowerCase(),
      product_id: productId,
      product_price: productPrice,
      product_on_sale: productOnSale,
      return_pdp_item: true,
    }
    win.FindMine?.match(obj)
    setTimeout(() => {
      applyCustomCss()
    }, 1000)
  }

  const applyCustomCss = () => {
    const container = document.getElementById('findmine-app-container')
    const anchorTags = container?.querySelectorAll('a')

    const handleMouseEnter = (event) => {
      event.target.style.color = 'black'
    }

    const handleMouseLeave = (event) => {
      event.target.style.color = ''
    }

    anchorTags.forEach((anchor) => {
      anchor.addEventListener('mouseenter', handleMouseEnter)
      anchor.addEventListener('mouseleave', handleMouseLeave)
    })
  }

  return <div id="findmine-app-container"></div>
}

export default CompleteTheLook
