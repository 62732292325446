import { Flex, Select, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useRouter } from 'next/router'
import { useSortBy, UseSortByProps } from 'react-instantsearch-hooks-web'

interface SortByProps extends UseSortByProps {
  indexNameResolver: (props: { locale: string; sortBy?: string }) => string
}

export const SortBy = (props: SortByProps) => {
  const { items, indexNameResolver } = props
  const router = useRouter()
  const { formatMessage } = useFormat({ name: 'common' })
  const { currentRefinement, refine } = useSortBy(props)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = e.target.value
    refine(selectValue)
  }

  return (
    <Flex direction="column">
      <Text as="label" htmlFor="indexSortSelect" fontSize="sm">
        {formatMessage({ id: 'category.filters.sortBy' })}
      </Text>
      <Select mt={1} id="indexSortSelect" fontSize="base" value={currentRefinement} onChange={(e) => handleChange(e)}>
        {items.map((item) => (
          <option
            style={{
              fontWeight: item.value === currentRefinement ? 'bold' : '',
            }}
            key={item.value}
            value={indexNameResolver({
              locale: router.locale ?? router.defaultLocale,
              sortBy: item.value,
            })}
          >
            {item.label}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
