import { Fragment, ReactNode } from 'react'
import { Box, Container, SimpleGrid, Spacer, Stack } from '@chakra-ui/react'
import { MegaMenuItem, NextLinkNoPrefetch } from 'composable'
import { LinkStack } from './link-stack'

export interface FooterProps {
  parentMenuItems?: MegaMenuItem[]
  homeUrl: string
  brandLogo?: ReactNode
  tagline?: string
  copyrightText?: string
}

export const Footer = ({ parentMenuItems, homeUrl, brandLogo, tagline, copyrightText }: FooterProps) => {
  return (
    <Box borderTopWidth="1px" height={{ base: 'auto', md: '80px' }}>
      <Spacer height={{ base: '24px', lg: '64px' }} />
      <Box bg="#000" color="#fff">
        <Container
          as="footer"
          id="site-footer"
          maxW="container.2xl"
          role="contentinfo"
          px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}
          py={{ base: 6 }}
        >
          <Stack
            as="nav"
            justify="end"
            align="start"
            direction={{ base: 'column', lg: 'row' }}
            py={{ base: '6', md: '0' }}
            spacing="2"
            alignItems="center"
            gap="var(--chakra-space-4)"
          >
            <Stack spacing={{ base: '6' }} align="start" flex="1" width={{ base: 'full', lg: 'auto' }}>
              {brandLogo && (
                <Box as={NextLinkNoPrefetch} href={homeUrl} position="relative">
                  {brandLogo}
                </Box>
              )}
            </Stack>

            {parentMenuItems?.map((item, idx) => {
              return (
                <Fragment key={item.label}>
                  <SimpleGrid key={`${idx}-${item.label}`} display="block" width={{ base: 'full', lg: 'auto' }}>
                    <LinkStack item={item} level={1} />
                  </SimpleGrid>
                </Fragment>
              )
            })}
          </Stack>
        </Container>
      </Box>
    </Box>
  )
}
