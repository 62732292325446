import { Box, Image, Text, Flex, Stack, Select, useToken, Link, Button } from '@chakra-ui/react'
import { QuantityPickerProps } from 'composable'
import { getProductAttributes } from 'composable/helpers/utils/product-card-utils'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoImagesOutline } from 'react-icons/io5'
import { LegacyHorizontalProductCardCommon } from './types'
import { parseAttributes } from '../../utils/legacy-cart'

export interface LegacyHorizontalProductCardEditableProps extends LegacyHorizontalProductCardCommon {
  totalPrice?: string
  totalDiscountedPrice?: string
  onChangeQuantity?: (val: number) => any
  quantityPickerProps?: QuantityPickerProps
  warranty?: {
    label: string
    value: string
  }
  isInStock?: boolean
  rewardPoints?: number
  customizationCommerceItem?: string
  dxlSaleMessage?: string
}

export const LegacyHorizontalProductCardEditable = (props: LegacyHorizontalProductCardEditableProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const [brandSecondary] = useToken('colors', ['brand.secondary'])

  const {
    columns = 3,
    size = 'lg',
    image,
    name,
    price,
    discountedPrice,
    totalPrice,
    totalDiscountedPrice,
    quantity,
    variant,
    onRemove,
    onChangeQuantity,
    onAddToWishlist,
    priceCentAmount,
    totalPriceCentAmount,
    url,
    masterProductData,
    availableDiscount,
    rewardPoints,
    customizationCommerceItem,
    dxlSaleMessage,
  } = props

  const { src: imageSrc, alt: imageAlt = name } = image ?? {}

  const segment2Label = masterProductData?.find(({ name }) => name === 'segment2Label')?.value
  const segment3Label = masterProductData?.find(({ name }) => name === 'segment3Label')?.value
  const productAttributes = getProductAttributes(variant, segment2Label, segment3Label) ?? []
  const parsedProductAttributes = parseAttributes(productAttributes) ?? ''

  const labels = {
    quantity: formatMessage({
      id: 'cart.item.quantity',
    }),
    itemPrice: formatMessage({
      id: 'cart.item.price',
    }),
    totalPrice: formatMessage({
      id: 'cart.item.totalPrice',
    }),
    remove: formatMessage({ id: 'action.remove' }),
    removeFromBag: formatMessage({ id: 'action.removeFromBag' }),
    addToWishlist: formatMessage({
      id: 'action.addToWishlist',
    }),
    outOfStock: formatMessage({
      id: 'cart.item.outOfStock',
    }),
  }
  const itemPriceLabel = labels.itemPrice ?? ''
  const removeLabel = labels.remove ?? ''
  const itemTotalPriceLabel = labels.totalPrice ?? ''

  const cardOptions = {
    3: {
      sm: {
        imageSize: {
          width: '112.5px',
          height: '148.13px',
        },
        details: { props: { marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 4, marginTop: 3 } },
        itemPrice: { type: 'text', label: true, props: { order: 2 } },
        contentColumn: {
          paddingTop: 4,
          paddingRight: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 3,
            marginTop: 3,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 4, marginTop: 3 } },
        itemPrice: { type: 'text', label: true, props: { order: 2 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
    4: {
      sm: {
        imageSize: {
          width: '80px',
          height: '106px',
        },
        details: { props: { order: 2, marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
        itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 2,
            marginTop: 1,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
        itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 4, gap: 3 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
    5: {
      sm: {
        imageSize: {
          width: '80px',
          height: '106px',
        },
        details: { props: { order: 2, marginTop: 1 } },
        quantity: { type: 'text', label: false, props: { order: 3, marginTop: 3, marginBottom: 2 } },
        itemPrice: { type: 'text', label: true, props: { position: 'absolute', bottom: 10, right: 4 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'icon' },
      },
      lg: {
        imageSize: {
          width: '145px',
          height: '193px',
        },
        details: {
          props: {
            order: 2,
            marginTop: 1,
          },
        },
        quantity: { type: 'column', label: true, props: { order: 0, marginRight: 6, gap: 2, alignItems: 'end' } },
        itemPrice: { type: 'column', label: true, props: { order: 1, alignItems: 'end', marginRight: 6, gap: 3 } },
        itemtotalPrice: { type: 'column', label: true, props: { order: 2, alignItems: 'end', marginRight: 4, gap: 3 } },
        contentColumn: {
          paddingTop: 4,
        },
        remove: { type: 'text' },
      },
    },
  }

  const imageSize = cardOptions[columns][size].imageSize
  const itemPriceOptions = cardOptions[columns][size].itemPrice
  const itemtotalPriceOptions = cardOptions[columns][size].itemtotalPrice
  const detailsOptions = cardOptions[columns][size].details
  const contentColumn = cardOptions[columns][size]?.contentColumn
  const defaultQuantityDropDownArray =
    quantity > 10 ? [...Array(11).keys()].slice(1).concat(quantity) : [...Array(11).keys()].slice(1)

  return (
    <Box position={'relative'}>
      <Flex gap={4}>
        <Flex
          aspectRatio={2 / 4}
          width={imageSize.width}
          height={imageSize.height}
          onClick={image?.onClickImage}
          cursor={image?.onClickImage ? 'pointer' : undefined}
        >
          {imageSrc ? (
            <Image src={imageSrc} alt={imageAlt} fit="contain" width={'full'} height={'full'} />
          ) : (
            <Flex justify={'center'} align={'center'} width={'full'} height={'full'} bgColor={'brand.muted'}>
              <IoImagesOutline size={'40px'} color={brandSecondary} />
            </Flex>
          )}
        </Flex>

        <Flex {...contentColumn} grow={1}>
          <Flex direction="column" flex={1}>
            <Link
              href={url}
              textStyle={'body-100'}
              color={'text-muted'}
              fontSize="0.8125rem"
              _hover={{ textDecoration: 'none', color: 'text-muted' }}
            >
              {name}
            </Link>

            <Box
              {...detailsOptions?.props}
              textStyle={'body-75'}
              fontSize="0.8125rem"
              style={{ wordWrap: 'break-word' }}
            >
              <Text
                tabIndex={0}
                dangerouslySetInnerHTML={{
                  __html: parsedProductAttributes || '',
                }}
              />
            </Box>

            {itemPriceOptions.type === 'text' && (
              <Flex>
                {price && (
                  <Text fontSize="0.8125rem" textStyle={'body-50'} pt={{ base: 2 }} mb={{ base: 1 }} tabIndex={0}>
                    <strong>{price}</strong>
                  </Text>
                )}
                {!discountedPrice && totalPriceCentAmount !== priceCentAmount && (
                  <Text fontSize={'14px'} textStyle={'body-50'} color="danger.600" tabIndex={0}>
                    {totalPrice}
                  </Text>
                )}
              </Flex>
            )}
            {!!customizationCommerceItem && (
              <Flex>
                <Text textStyle={'body-50'} fontSize="0.8125rem" mb={{ base: 1 }} tabIndex={0}>
                  {customizationCommerceItem}
                </Text>
              </Flex>
            )}
            {!!dxlSaleMessage && (
              <Flex>
                <Text textStyle={'body-50'} fontSize="0.8125rem" tabIndex={0}>
                  {dxlSaleMessage}
                </Text>
              </Flex>
            )}
            {availableDiscount && <Text fontSize={'12px'}>{availableDiscount?.description}</Text>}
            {rewardPoints && (
              <Box display="flex" mt={1}>
                <Box backgroundColor="#e4b756" color="#000000" display="flex" alignItems="center">
                  <Text fontSize="13px" fontWeight="700" padding=".3rem .6rem" tabIndex={0}>
                    +{rewardPoints} POINTS
                  </Text>
                </Box>
              </Box>
            )}
          </Flex>

          {itemPriceOptions.type === 'column' && (
            <Stack {...itemPriceOptions?.props}>
              {itemPriceOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'} tabIndex={0}>
                  {itemPriceLabel}
                </Text>
              )}
              <Box>
                <Text textStyle={'body-75'} color="danger.600" tabIndex={0}>
                  {discountedPrice}
                </Text>
                <Text textStyle={discountedPrice ? 'figma-strikethrough-75' : 'body-75'} tabIndex={0}>
                  {price}
                </Text>
              </Box>
            </Stack>
          )}
          {itemtotalPriceOptions?.type === 'column' && (
            <Stack {...itemtotalPriceOptions?.props}>
              {itemtotalPriceOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'} tabIndex={0}>
                  {itemTotalPriceLabel}
                </Text>
              )}
              <Box>
                <Text textStyle={'body-75'} color="danger.600" tabIndex={0}>
                  {totalDiscountedPrice}
                </Text>
                <Text textStyle={totalDiscountedPrice ? 'figma-strikethrough-75' : 'body-75'} tabIndex={0}>
                  {totalPrice}
                </Text>
              </Box>
            </Stack>
          )}
        </Flex>
      </Flex>

      <Box display="flex" flexDirection="row" alignItems="center" mt={{ base: 5 }}>
        <Box justifyContent="flex-start">
          <Select
            width="80px"
            onChange={(event) => onChangeQuantity(Number(event.target.value))}
            borderRadius={0}
            borderColor="#000000"
            fontSize="0.8125rem"
          >
            {defaultQuantityDropDownArray.map((x) => (
              <option value={x} key={`${variant.sku}_${x}`} selected={quantity === x}>
                {x}
              </option>
            ))}
          </Select>
        </Box>

        <Box display="flex" flex={1} justifyContent="center">
          <Link
            _hover={{ color: 'gray' }}
            textStyle={'body-75'}
            fontWeight="300"
            fontSize="0.8125rem"
            textDecoration="underline"
            cursor="pointer"
            onClick={onRemove}
            href="#"
            tabIndex={0}
          >
            {removeLabel}
          </Link>
        </Box>

        <Box display="flex" flex={1} justifyContent="flex-end">
          <Link
            _hover={{ color: 'gray' }}
            textStyle={'body-75'}
            fontWeight="300"
            textDecoration="underline"
            cursor="pointer"
            onClick={onAddToWishlist}
            href="#"
            tabIndex={0}
          >
            Move to Wish List
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
