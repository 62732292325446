import { Radio, RadioProps } from '@chakra-ui/react'

const borderWidth = {
  sm: '4px',
  md: '6px',
  lg: '8px',
}

export const CoreRadio = ({ size, ...props }: { size: string } & RadioProps) => {
  return (
    <Radio
      size={size}
      _checked={{
        borderWidth: borderWidth[size],
        borderColor: 'brand.primary',
        _hover: {
          color: 'white',
          backgroundColor: 'white',
          borderColor: 'brand.secondary',
        },
      }}
      {...props}
    />
  )
}
