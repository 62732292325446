import { useEffect } from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  Collapse,
  HStack,
  Radio,
  RadioProps,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { SectionHeader } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'

export type FormStatus = 'success' | 'error' | undefined

export interface EditableContentCardProps {
  action1?: string
  action2?: string
  alertExpireTime?: number
  deleteFn?: () => void
  edit?: JSX.Element
  editModeOn?: boolean
  editTitle?: string
  isDirty?: boolean
  onCancel?: undefined | (() => void)
  onSubmit?: () => void
  radioProps?: RadioProps
  radioText?: string
  readOnly?: JSX.Element
  resetEditForm?: () => void
  setStatus?: React.Dispatch<React.SetStateAction<FormStatus>>
  showAction1?: boolean
  showAction2?: boolean
  showRadio?: boolean
  size: 'Large' | 'Small'
  status?: FormStatus
  title?: string
  updateButtonLabel?: string
}

// TODO: refactor - remove all those onCancel onSumit to buttonProps
// ie: submitButtonProps, cancelButtonProps etc...
export const EditableContentCard = ({
  action1, // Edit
  action2, // Delete
  alertExpireTime = 1000,
  deleteFn,
  edit,
  editModeOn = false,
  editTitle,
  isDirty,
  onCancel,
  onSubmit,
  radioProps,
  radioText,
  readOnly,
  resetEditForm,
  setStatus,
  showAction1 = true,
  showAction2 = true,
  showRadio,
  size = 'Large',
  status,
  title,
  updateButtonLabel,
}: EditableContentCardProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: editModeOn,
  })
  useEffect(() => {
    if (status === 'success' && !editModeOn)
      setTimeout(() => {
        onClose()
      }, alertExpireTime)
  }, [status, onClose, alertExpireTime])

  const content = {
    button: {
      cancel: formatMessage({ id: 'action.cancel' }),
      delete: action2 ?? formatMessage({ id: 'action.delete' }),
      edit: action1 ?? formatMessage({ id: 'action.edit' }),
      submit: updateButtonLabel ?? formatMessage({ id: 'action.update' }),
    },
    alert: {
      error: formatMessage({ id: 'account.dashboard.submit.error' }),
      success: formatMessage({ id: 'account.dashboard.submit.success' }),
    },
  }

  const EditButton = () => (
    <Button
      size={'sm'}
      color={'text'}
      variant={'ghost'}
      textDecoration={'underline'}
      aria-label={`${content.button.edit} ${title}`}
      onClick={() => {
        setStatus?.(undefined)
        onOpen()
      }}
    >
      {content.button.edit}
    </Button>
  )
  const DeleteButton = ({ onClick }: { onClick: () => void }) => (
    <Button
      size={'sm'}
      color={'text'}
      variant={'ghost'}
      onClick={onClick}
      aria-label={`${content.button.delete} ${title}`}
      textDecoration={'underline'}
    >
      {content.button.delete}
    </Button>
  )
  const CancelSubmitButtons = () => (
    <ButtonGroup justifyContent="flex-end" size="sm" width={'full'}>
      <Button
        size="lg"
        variant="outline"
        type="reset"
        onClick={() => {
          resetEditForm?.()
          onClose()
          onCancel?.()
        }}
      >
        {content.button.cancel}
      </Button>
      <Button
        size="lg"
        variant="solid"
        type="submit"
        disabled={!onSubmit || (isDirty !== undefined && !isDirty)}
        onClick={() => onSubmit?.()}
      >
        {content.button.submit}
      </Button>
    </ButtonGroup>
  )

  return (
    <VStack
      maxWidth={size === 'Large' ? 'full' : '335px'}
      direction={'column'}
      spacing={4}
      padding={'32px 24px 40px'}
      bg={'shading.100'}
      alignItems={'stretch'}
    >
      {/* Edit Mode */}
      {edit && (
        <Collapse in={isOpen} unmountOnExit animateOpacity>
          <VStack paddingTop={4} paddingX={1} spacing={4} alignItems={'stretch'}>
            <SectionHeader
              boxProps={{ height: 8, marginBottom: 'none' }}
              hasRequiredFields
              title={editTitle ?? title}
              textProps={{
                as: 'h5',
                width: 'fit-content',
              }}
            />
            {status === 'success' && <Alert status="success">{content.alert.success}</Alert>}
            {status === 'error' && <Alert status="error">{content.alert.error}</Alert>}
            <form>
              <VStack width="full" alignItems={'stretch'} marginBottom={6}>
                {edit}
              </VStack>
              <CancelSubmitButtons />
            </form>
          </VStack>
        </Collapse>
      )}

      {/* readOnly Mode */}
      {readOnly && (
        <VStack spacing={4} alignItems={'stretch'} display={isOpen ? 'none' : 'initial'}>
          <HStack width={'full'}>
            <Text as="h5" textStyle={{ base: 'heading-mobile-200', md: 'heading-desktop-200' }} width={'full'}>
              {title}
            </Text>
            <ButtonGroup>
              {showAction1 && edit && <EditButton />}
              {showAction2 && deleteFn && <DeleteButton onClick={deleteFn} />}
            </ButtonGroup>
          </HStack>

          {status === 'success' && <Alert status="success">{content.alert.success}</Alert>}

          <VStack align={'flex-start'}>{readOnly}</VStack>
          {showRadio && (
            <Radio size={'md'} defaultChecked={false} background={'background'} {...radioProps}>
              <Text textStyle={'callouts-blockquote-75'}>{radioText}</Text>
            </Radio>
          )}
        </VStack>
      )}
    </VStack>
  )
}
