import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { CartSummary } from '../cart/cart-summary'
import { CartSummaryAccordion } from './cart-summary-accordion'
import { getCartSummaryAmounts } from 'composable/helpers'
import { useAtgLegacyCart } from 'frontastic/contexts'

export const BagSummary = () => {
  const { formattedCart: cart } = useAtgLegacyCart()
  const { total } = getCartSummaryAmounts(cart)

  return (
    <CartSummaryAccordion
      // TODO: add loading state
      // isLoading={isLoading}
      isLoading={false}
      isEmpty={cart?.lineItems?.length === 0}
      cartItemsQuantity={cart?.lineItems?.length}
      formattedCartTotalPrice={CurrencyHelpers.formatForCurrency(total ?? 0)}
    >
      <CartSummary
        textOverride={{ total: 'cart.summary.total' }}
        hideCheckoutButton
        displayTitle={false}
        orderSummaryProps={{ bg: 'white', p: '0' }}
        showCartItems
      />
    </CartSummaryAccordion>
  )
}
