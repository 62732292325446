import { useState } from 'react'
import { useRouter } from 'next/router'
import { Stack, Box, Button, Alert } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { PasswordField } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { useAccount } from 'frontastic'

const PASSWORD_MIN_LENGTH = 8
export const ResetPasswordForm = () => {
  const router = useRouter()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const { resetPassword } = useAccount()
  const { formatMessage } = useFormat({ name: 'common' })

  const resetToken = router.query.t?.toString() ?? ''

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    password: string
    passwordRepeat: string
  }>({
    resolver: yupResolver(ResetPasswordFormSchema()),
    mode: 'onTouched',
  })

  if (!resetToken) {
    return null
  }

  // const renderForm = !customerReset.isSuccess && !customerReset.isError

  return (
    <form
      role={'form'}
      aria-label={formatMessage({ id: 'account.reset.label.password' })}
      onSubmit={handleSubmit((data: any) => {
        try {
          resetPassword(resetToken, data?.password)
          setShowSuccess(true)
        } catch (e) {
          setShowError(true)
        }
      })}
    >
      <Stack spacing={4} direction="column">
        <PasswordField
          label={formatMessage({ id: 'account.reset.label.password' })}
          inputProps={register('password')}
          error={errors.password}
        />
        <PasswordField
          label={formatMessage({
            id: 'account.reset.label.passwordRepeat',
          })}
          inputProps={register('passwordRepeat')}
          error={errors.passwordRepeat}
        />
      </Stack>

      <Box mt="30px" display="flex" justifyContent="center">
        <Button type="submit">{formatMessage({ id: 'account.reset.action.submit' })}</Button>
      </Box>

      {showError && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Alert mt={12} status="error">
            {formatMessage({ id: 'account.reset.error' })}
          </Alert>
          <Button mt={12} type="submit" onClick={() => router.push('/account')}>
            {formatMessage({ id: 'action.continue' })}
          </Button>
        </Box>
      )}

      {showSuccess && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Alert mt={12} status="success">
            {formatMessage({ id: 'account.reset.success' })}
          </Alert>
          <Button mt={12} type="submit" onClick={() => router.push('/account')}>
            {formatMessage({ id: 'action.login' })}
          </Button>
        </Box>
      )}
    </form>
  )
}

export const ResetPasswordFormSchema = () => {
  const intl = useFormat({ name: 'common' })

  return yup.object().shape({
    password: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.passwordRequired' }))
      .min(PASSWORD_MIN_LENGTH, intl.formatMessage({ id: 'validation.passwordMinLength' }))
      .matches(/[a-z]/, intl.formatMessage({ id: 'validation.passwordLowercase' }))
      .matches(/[A-Z]/, intl.formatMessage({ id: 'validation.passwordUppercase' }))
      .matches(/[0-9]/, intl.formatMessage({ id: 'validation.passwordNumbers' })),

    passwordRepeat: yup
      .string()
      .required(intl.formatMessage({ id: 'validation.passwordRepeat' }))
      .oneOf([yup.ref('password')], intl.formatMessage({ id: 'validation.passwordRepeatMatches' })),
  })
}
