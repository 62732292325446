import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Button, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { useComposable } from '../../composable-provider'
import { CartPromotion, CartPromotionAccordion } from '../cart-promotion'
import { getCartSummaryAmounts } from 'composable/helpers'
import { useAtgLegacyCart } from 'frontastic/contexts'

interface CartDrawerFooterItemProps {
  label: string
  value?: string
  children?: ReactNode
}

const CartDrawerFooterItem = (props: CartDrawerFooterItemProps) => {
  const { label, value, children } = props
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color="gray.600">
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  )
}

export const CartDrawerFooter = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { cartDrawer } = useComposable()
  const { formattedCart: cart, refreshCart: recalculateCart } = useAtgLegacyCart()
  const { total } = getCartSummaryAmounts(cart)
  const initializedByStoreMobileDevice = cart?.initializedByStoreMobileDevice

  const handleProceedToCheckout = async () => {
    await recalculateCart()
    router.push('/checkout')
  }

  return (
    <Stack spacing="6" width="full">
      <Stack spacing="4">
        {/* <CartPromotionAccordion
          isOpen={Boolean(cart?.discountCodes?.[0]?.code)}
          hideBorderTop
          showDiscount={
            <CartDrawerFooterItem
              label={formatMessage({
                id: 'cart.summary.discount',
              })}
            />
          }
        >
          <CartPromotion />
        </CartPromotionAccordion> */}
      </Stack>
      <Flex justify="space-between" align="center" wrap={'wrap'}>
        <VStack align="left" spacing="0">
          <Text textStyle={{ base: 'eyebrow-50', md: 'eyebrow-75' }} color={'text-muted'}>
            {formatMessage({ id: 'cart.summary.estimatedTotalShort' })}
          </Text>
          <Text textStyle={{ base: 'heading-desktop-75', md: 'heading-desktop-200' }}>
            {cart && CurrencyHelpers.formatForCurrency(total)}
          </Text>
        </VStack>
        <HStack flexGrow={9999} justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              router.push('/cart').then(() => {
                cartDrawer.onClose()
              })
            }}
            color={'primary'}
            variant={'outline'}
            textStyle={{ base: 'ui-button-75', md: 'ui-button-200' }}
            width={{ base: '89px', md: '132px' }}
            height={{ base: 8, md: 12 }}
          >
            {formatMessage({ id: 'action.viewBag' })}
          </Button>

          {!initializedByStoreMobileDevice && (
            <Button
              onClick={async () => {
                await handleProceedToCheckout()
                cartDrawer.onClose()
              }}
              variant={'solid'}
              textStyle={{ base: 'ui-button-75', md: 'ui-button-200' }}
              width={{ base: '89px', md: '132px' }}
              height={{ base: 8, md: 12 }}
            >
              {formatMessage({ id: 'action.checkout' })}
            </Button>
          )}
        </HStack>
      </Flex>
    </Stack>
  )
}
