import { useEffect, useState } from 'react'
import { Box, Skeleton } from '@chakra-ui/react'
import { ContentstackTextCard as ContentstackTextCardProps } from '@Types/contentstack'
import { TextCard } from '../cms-components'
import useContentstack from '../hooks/useContentstack'

export const ContentstackTextCard = ({
  text_card_title,
  text_card_title_font_size,
  text_card_title_font_family,
  content,
  image,
  cta_label,
  cta_href,
  text_align,
  theme,
  image_height,
  cta,
  background_color,
  analyticsTrackingData,
  relativeIndex,
}: ContentstackTextCardProps) => {
  const [ctaFields, setCtaFeilds] = useState(undefined)
  const { getEntryByUid, isLoading } = useContentstack()
  useEffect(() => {
    const fetchCta = async () => {
      const ctaData = await getEntryByUid('custom_link', cta?.[0]?.uid)
      setCtaFeilds(ctaData)
    }
    try {
      fetchCta()
    } catch (e) {
      console.info(e)
    }
  }, [])

  if (isLoading) return <Skeleton />

  return (
    <TextCard
      root={{
        height: 'full',
      }}
      image={{
        src: image?.url ?? '',
        alt: image?.description || image?.title || text_card_title,
        height: image_height ?? '',
      }}
      title={{
        font: {
          size: text_card_title_font_size ?? '',
          family: text_card_title_font_family ?? '',
        },
        content: {
          children: text_card_title ?? '',
        },
      }}
      description={{
        children: content ? <Box dangerouslySetInnerHTML={{ __html: content }} /> : undefined,
      }}
      button={{
        children: ctaFields?.link_label ?? cta_label ?? '',
        href: ctaFields?.link_href ?? cta_href ?? '',
        whiteSpace: 'normal',
      }}
      textAlign={text_align as any}
      theme={theme as any}
      backgroundColor={background_color ?? ''}
      analyticsTrackingData={analyticsTrackingData}
      relativeIndex={relativeIndex}
    />
  )
}
