import { ReactNode } from 'react'
import router from 'next/router'
import { VStack, Button, Heading, HStack, Text } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import { availableFonts } from 'composable/chakra/theme/foundations/typography'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInstantSearch } from 'react-instantsearch-hooks-web'

interface NoResultsBoundaryProps {
  catchOn: 'unfiltered-search-only' | 'filtered-search-only'
  children: ReactNode
  isSearchPage: boolean
}

export const NoResultsBoundary = ({ catchOn, children, isSearchPage }: NoResultsBoundaryProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { results, indexUiState } = useInstantSearch()

  // get refinements from the indexUiState, the useCurrentRefinements hooks is not SSR ready
  const hasRefinementListRefinements = Object.entries(indexUiState?.refinementList ?? {}).length > 0
  const hasNumericMenuRefinements = Object.entries(indexUiState?.numericMenu ?? {}).length > 0
  const isFiltered = hasRefinementListRefinements || hasNumericMenuRefinements

  const isLoading = results.__isArtificial
  const noResults = results.nbHits === 0
  const shouldCatch =
    (catchOn === 'unfiltered-search-only' && !isFiltered) || (catchOn === 'filtered-search-only' && isFiltered)

  // @ts-ignore
  if (results.renderingContent?.redirect?.url) {
    // @ts-ignore
    window.location.href = results.renderingContent.redirect.url
  }

  if (isSearchPage && results.nbHits === 1 && !isLoading) {
    if (isSearchPage && results.hits.length === 1) {
      router.push(`/p/${results.hits[0].slug}`)
      return
    }
  }

  if (noResults && shouldCatch && !isLoading) {
    return (
      <VStack textAlign="center" spacing={0} height="100%">
        <Heading
          color="color.text.primary"
          fontFamily={availableFonts.primary}
          fontSize={{ base: '6xl', lg: '7xl' }}
          fontWeight={700}
          mb={6}
        >
          {catchOn === 'unfiltered-search-only' &&
            formatMessage({ id: 'category.noResults.unfiltered.heading', values: { query: results.query } })}
          {catchOn === 'filtered-search-only' && formatMessage({ id: 'category.noResults.filtered.heading' })}
        </Heading>

        <Text color="color.text.primary" fontSize="md" mb={{ base: 4, lg: 6 }}>
          {catchOn === 'unfiltered-search-only' && formatMessage({ id: 'category.noResults.unfiltered.text' })}
          {catchOn === 'filtered-search-only' && formatMessage({ id: 'category.noResults.filtered.text' })}
        </Text>

        <Text color="color.text.primary" fontSize="md" fontWeight={700} mb={4}>
          {formatMessage({ id: 'category.noResults.needHelp' })}
        </Text>

        <HStack justifyContent="center" spacing={2}>
          <NextLinkNoPrefetch href={`tel:${formatMessage({ id: 'category.noResults.phoneNumber' })}`}>
            <Button variant="outline" color="color.brand.primary" borderRadius="md" borderColor="color.brand.primary">
              {formatMessage({ id: 'category.noResults.callUsButton' })}
            </Button>
          </NextLinkNoPrefetch>
          <NextLinkNoPrefetch href={`mailto:${formatMessage({ id: 'category.noResults.email' })}`}>
            <Button variant="outline" color="color.brand.primary" borderRadius="md" borderColor="color.brand.primary">
              {formatMessage({ id: 'category.noResults.emailUsButton' })}
            </Button>
          </NextLinkNoPrefetch>
        </HStack>
      </VStack>
    )
  }

  return <>{children}</>
}
