import React, { useEffect } from 'react'

const Tealium = () => {
  useEffect(() => {
    var scriptElement = document.createElement('script')

    scriptElement.textContent = `
        (function(a,b,c,d){
        a='//tags.tiqcdn.com/utag/dxl/spa/'+((window.location.hostname.indexOf('www')===0)?'prod':'dev')+'/utag.js';
        b=document;
        c='script';
        d=b.createElement(c);
        d.src=a;
        d.type='text/java'+c;
        d.async=true;
        a=b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d,a);
        })();
        `
    document.head.appendChild(scriptElement)
  }, [])

  return <></>
}

export default Tealium
