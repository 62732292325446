import { Box, BoxProps, Button, ButtonProps, StackProps, Stack, Image, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackImage } from '@Types/contentstack'
import { NextLinkNoPrefetch } from 'composable'
import { fontFamilies } from 'composable/chakra/figma-tokens'
import { sizes } from 'composable/chakra/figma-tokens'
import { extractPath } from 'composable/helpers/utils/extract-url'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'
import { banner_DEFAULT_CSS_MOBILE, banner_DEFAULT_CSS_DESKTOP } from './RichText-Utils/banner-styles'
import { BaseProps } from './types'

export interface BannerTextProps extends BaseProps {
  root?: BoxProps
  eyebrow?: BoxProps
  title?: {
    content?: BoxProps
    font?: {
      size?: string
      family?: string
    }
  }
  body?: BoxProps
  ctaButtonBox?: StackProps
  ctaButtonPrimary?: ButtonProps & { href?: string }
  ctaButtonSecondary?: ButtonProps & { href?: string }
  ctaLinkBox?: BoxProps
  ctaLinkItems?: (ButtonProps & { href?: string })[]
  overlay_image_mobile?: ContentstackImage | null
  overlay_image_desktop?: ContentstackImage | null
  overlay_image_height?: string
  content_alignment?: string
  analyticsTrackingData?: string
  relativeIndex?: number
}

export const BannerText = ({
  root,
  eyebrow,
  title,
  body,
  ctaButtonBox,
  ctaButtonPrimary,
  ctaButtonSecondary,
  ctaLinkBox,
  ctaLinkItems,
  overlay_image_mobile,
  overlay_image_desktop,
  content_alignment,
  overlay_image_height,
  analyticsTrackingData,
  isMobileDevice,
}: BannerTextProps) => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  const { children: ctaButtonBoxChildren, ...ctaButtonBoxProps } = ctaButtonBox ?? {}
  const renderCtaButtonBox = Boolean(
    ctaButtonBox?.children || ctaButtonPrimary?.children || ctaButtonSecondary?.children,
  )

  const renderCtaLinkBox = Boolean(ctaLinkBox?.children || ctaLinkItems?.length)
  const { children: ctaLinkBoxChildren, ...ctaLinkBoxProps } = ctaLinkBox ?? {}

  const { font: titleFont } = title ?? {}

  const headingFontFamily = titleFont?.family ?? 'libre-franklin'
  const headingFontSize = titleFont?.size ?? '400'

  const headingFontStyleSuffix =
    headingFontFamily === 'libre-franklin' ? `-${headingFontSize}` : `-secondary-${headingFontSize}`

  const headingFontStyleToken = {
    desktop: `heading-desktop${headingFontStyleSuffix}`,
    mobile: `heading-mobile${headingFontStyleSuffix}`,
  }

  let titleFontFamily

  if (titleFont?.family === 'antonio' || titleFont?.family === 'libre-franklin') {
    titleFontFamily = titleFont?.family === 'antonio' ? fontFamilies.secondary : fontFamilies.primary
  } else {
    titleFontFamily = titleFont?.family || fontFamilies.primary
  }

  const overlayImage = useBreakpointValue({
    base: overlay_image_mobile,
    lg: overlay_image_desktop,
  })

  const getDataPromotionName = (href, index) => {
    const analyticsLocationIdentifier = extractPath(href ?? '')
    const dataPromotionName =
      analyticsTrackingData && `${analyticsTrackingData} | ${index} | ${analyticsLocationIdentifier}`
    return dataPromotionName
  }

  const getLinksRelativeIndex = () => {
    if (ctaButtonPrimary?.children && ctaButtonSecondary?.children) {
      return 2
    } else if (ctaButtonPrimary?.children || ctaButtonSecondary?.children) {
      return 1
    }
    return 1
  }

  return (
    <Box {...root} gap={sizes[6]}>
      {overlayImage?.url && (
        <Box display="flex" justifyContent={isMobile ? 'center' : content_alignment?.toLowerCase()}>
          <Image
            src={overlayImage?.url}
            alt={overlayImage?.description || overlayImage?.title || 'Banner Logo Image'}
            height={overlay_image_height || '4rem'}
          />
        </Box>
      )}
      <Stack gap={1} tabIndex={0} aria-live="polite">
        {eyebrow?.children && <Box {...eyebrow} />}

        {title?.content?.children && (
          <Box
            textStyle={[headingFontStyleToken.mobile, null, null, headingFontStyleToken.desktop]}
            fontFamily={titleFontFamily}
            {...title?.content}
          />
        )}

        {body?.children && (
          <Box
            {...body}
            __css={{
              ...(isMobile ? banner_DEFAULT_CSS_MOBILE : banner_DEFAULT_CSS_DESKTOP),
              'h1, h2, h3, h4, h5, h6': {
                fontFamily: titleFontFamily,
              },
            }}
          />
        )}
      </Stack>

      {renderCtaButtonBox && (
        <Stack
          width="full"
          direction={{ base: 'column', sm: 'row' }}
          display="flex"
          flexWrap="wrap"
          gap={4}
          {...ctaButtonBoxProps}
        >
          {ctaButtonBox?.children ? (
            <Box>{ctaButtonBox?.children}</Box>
          ) : (
            <>
              {ctaButtonPrimary?.children && (
                <Button
                  data-promotion-name={getDataPromotionName(ctaButtonPrimary.href ?? '', '1')}
                  as={NextLinkNoPrefetch}
                  onClick={() => promoClickUtagLinkEvent(getDataPromotionName(ctaButtonPrimary.href ?? '', '1'))}
                  href={ctaButtonPrimary.href ?? ''}
                  aria-label={`${ctaButtonPrimary.children}`}
                  style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'clip',
                    width: isMobile ? '100%' : 'auto',
                    textDecoration: 'none',
                  }}
                  size={'lg'}
                  {...ctaButtonPrimary}
                />
              )}
              {ctaButtonSecondary?.children && (
                <Button
                  data-promotion-name={getDataPromotionName(
                    ctaButtonSecondary.href ?? '',
                    ctaButtonPrimary?.children ? '2' : '1',
                  )}
                  as={NextLinkNoPrefetch}
                  href={ctaButtonSecondary.href ?? ''}
                  onClick={() =>
                    promoClickUtagLinkEvent(
                      getDataPromotionName(ctaButtonSecondary.href ?? '', ctaButtonPrimary?.children ? '2' : '1'),
                    )
                  }
                  aria-label={`${ctaButtonSecondary.children}`}
                  style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'clip',
                    width: isMobile ? '100%' : 'auto',
                    textDecoration: 'none',
                  }}
                  size={'lg'}
                  {...ctaButtonSecondary}
                />
              )}
            </>
          )}
        </Stack>
      )}

      {renderCtaLinkBox && (
        <Stack width="full" direction="row" display="flex" flexWrap="wrap" spacing={4} {...ctaButtonBoxProps}>
          {ctaLinkBox?.children ? (
            <Box>{ctaLinkBox?.children}</Box>
          ) : (
            <>
              {ctaLinkItems?.map((buttonProps, i) => {
                const { href = '', ...__buttonProps } = buttonProps
                const analyticsLocationIdentifier = extractPath(href ?? '')
                const linkRelativeIndex = getLinksRelativeIndex()
                const dataPromotionName =
                  analyticsTrackingData &&
                  `${analyticsTrackingData} | ${linkRelativeIndex + 1 + i} | ${analyticsLocationIdentifier}`

                return (
                  <Button
                    data-promotion-name={dataPromotionName}
                    key={`Link-button-${i}`}
                    as={NextLinkNoPrefetch}
                    href={href}
                    onClick={() => promoClickUtagLinkEvent(dataPromotionName)}
                    aria-label={`Link Button: ${buttonProps.children}`}
                    style={{
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'clip',
                      width: isMobile ? 'calc(50% - 0.5rem)' : 'auto',
                      textDecoration: 'none',
                    }}
                    {...__buttonProps}
                  />
                )
              })}
            </>
          )}
        </Stack>
      )}
    </Box>
  )
}
