import dynamic from 'next/dynamic'
import { useComposable } from '../composable-provider'
import { Entry } from '../hooks/useContentstack'

const DynamicMegaDrawer = dynamic(() => import('./MegaDrawer').then((el) => el.MegaDrawer))

export interface MegaDrawerProps {
  items: Entry[]
}

export const MegaDrawer = ({ items }: MegaDrawerProps) => {
  const { megaDrawer } = useComposable()

  return megaDrawer.isOpen ? <DynamicMegaDrawer items={items} /> : null
}
