import { useRouter } from 'next/router'
import { Box, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoBagOutline } from 'react-icons/io5'

export const CartEmptyState = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()

  return (
    <Box
      p={'md'}
      textAlign={'center'}
      height={'376px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
    >
      <IoBagOutline aria-hidden size="32px" color="primary" />

      <Text textStyle={'heading-desktop-300'} mt={4} mb={3}>
        {formatMessage({ id: 'cart.emptyState.title' })}
      </Text>

      <Text textStyle={'body-100'}>{formatMessage({ id: 'cart.emptyState.subtitle' })}</Text>

      <Text
        mt={6}
        textStyle={'ui-buttonGhost-100'}
        style={{
          textDecorationLine: 'underline',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => {
          router.push('/') // TODO: Send to PLP (?)
        }}
      >
        {formatMessage({ id: 'action.startShopping' })}
      </Text>
    </Box>
  )
}
