import { Box, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackImage } from '@Types/contentstack'
import { BannerImage, BannerImageProps } from './banner-image'
import { BannerText, BannerTextProps } from './banner-text'
import { BaseProps } from './types'

enum LayoutProportions {
  equals = '50-50',
  unbalanced = '60-40',
  unvalancedInverted = '40-60',
  superUnbalnaced = '70-30',
  superUnbalancedInverted = '30-70',
}

export enum TextInputHint {
  SingleLine = 'SingleLine',
  MultiLine = 'MultiLine',
}

type BannerSplitTheme = 'dark' | 'light' | 'custom'

const getLayoutSizes = (layout: LayoutProportions) => {
  const sizes = layout?.split('-')

  const firstElementSize = Number(sizes[0]) / 10
  const secondElementSize = Number(sizes[1]) / 10

  return { firstElementSize, secondElementSize }
}

const overlayBackgroundValue: Record<BannerSplitTheme, string> = {
  light: 'rgba(0, 0, 0, 0.4)',
  dark: 'rgba(255, 255, 255, 0.2)',
  custom: 'rgba(1, 255, 255, 0.2)',
}

export interface BannerSplitProps extends BaseProps {
  priority?: boolean
  image?: BannerImageProps
  overlayImage?: {
    imageDesktop?: ContentstackImage
    imageMobile?: ContentstackImage
    overlay_image_height?: string | null
  }
  text?: BannerTextProps
  inverted?: boolean
  invertedMobile?: boolean
  layout?: string
  theme?: BannerSplitTheme
  overlayBackground?: string
  backgroundColor?: string
  headingTextColor?: string
  textColor?: string
  analyticsTrackingData?: string
  relativeIndex?: number
}

export const BannerSplit = ({
  priority = false,
  text,
  image,
  overlayImage,
  inverted,
  invertedMobile,
  layout = LayoutProportions.equals,
  theme = 'light',
  overlayBackground = overlayBackgroundValue[theme],
  backgroundColor,
  headingTextColor,
  textColor,
  analyticsTrackingData,
  isMobileDevice,
}: BannerSplitProps) => {
  const { title } = text ?? {}
  const { font: titleFont } = title ?? {}
  const { firstElementSize, secondElementSize } = getLayoutSizes(layout as LayoutProportions)

  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  let isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport
  const isInverted = (!isMobile && inverted) || (isMobile && invertedMobile)

  return (
    <Box
      position="relative"
      display="flex"
      flexWrap="wrap-reverse"
      alignItems="stretch"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Box display="flex" flex={isInverted ? secondElementSize : firstElementSize} order={isInverted ? 1 : 0}>
        <BannerImage
          isMobileDevice={isMobileDevice}
          priority={priority}
          root={{
            display: 'flex',
            width: '100%',
            ...image?.root,
          }}
          {...image}
        />
      </Box>
      <Box display="flex" flex={isInverted ? firstElementSize : secondElementSize} order={isInverted ? 0 : 1}>
        <BannerText
          isMobileDevice={isMobileDevice}
          analyticsTrackingData={analyticsTrackingData}
          root={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            px: [6, 6, 6, 10, 16],
            py: [6, 6, 6, 10, 16],
            width: '100%',
            backgroundColor: [backgroundColor],
            ...text?.root,
          }}
          eyebrow={{
            textStyle: ['eyebrow-50', null, 'eyebrow-75'],
            textColor: textColor,
            ...text?.eyebrow,
          }}
          title={{
            font: titleFont,
            content: {
              as: 'h2',
              textColor: [headingTextColor, null, headingTextColor, null, headingTextColor],
              ...text?.title?.content,
            },
          }}
          overlay_image_desktop={overlayImage.imageDesktop}
          overlay_image_mobile={overlayImage.imageMobile}
          overlay_image_height={overlayImage.overlay_image_height}
          body={{
            textStyle: [
              'callouts-blockquote-75-Default',
              'callouts-blockquote-75-Default',
              'callouts-blockquote-75-Default',
              'callouts-blockquote-75-Default',
              'body-200',
            ],
            textColor: [textColor, null, textColor, null, textColor],
            ...text?.body,
          }}
          ctaButtonPrimary={{
            variant: theme === 'dark' ? 'solid-alt' : 'solid',
            width: ['100%', '100%', 'auto'],
            ...text?.ctaButtonPrimary,
            backgroundColor: textColor,
          }}
          ctaButtonSecondary={{
            variant: theme === 'dark' ? 'outline-alt' : 'outline',
            width: ['100%', '100%', 'auto'],
            ...text?.ctaButtonSecondary,
            textColor: textColor,
            borderColor: textColor,
            backgroundColor: 'none',
          }}
        />
      </Box>
    </Box>
  )
}
