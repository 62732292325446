import { Box, Container, useBreakpointValue } from '@chakra-ui/react'
import { jsonToHtml } from '@contentstack/json-rte-serializer'
import { ContentstackRichText as ContentstackRichTextProps, JsonRteChild } from '@Types/contentstack'
import { ContentstackAccordion } from './accordion'
import { ContentstackArticleCard } from './article-card'
import { ContentstackBannerFull } from './banner-full'
import { ContentstackBannerSplit } from './banner-split'
import { ContentstackBannerTextOnly } from './banner-text-only'
import { ContentstackCoverCard } from './cover-card'
import { ContentstackGridComponent } from './grid-component'
import { ContentstackModal } from './modal'
import { ContentstackTeamLogoGrid } from './team-logo-grid'
import { ContentstackTextCard } from './text-card'
import { ComponentRichText } from '../cms-components'
import { renderOption } from '../cms-components/RichText-Utils/render-options'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const preprocessForJsonToHtml = (json: any): any => {
  if (!json?.children?.length) {
    return json
  }

  return {
    ...json,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: json.children.map((child: any) => {
      if (child.text && (child['font-color'] || child['font-family'])) {
        let customTag = '<customize'

        if (child['font-color']) {
          customTag += ` color="${child['font-color']}"`
        }

        if (child['font-family']) {
          customTag += ` fontFamily="${child['font-family']}"`
        }

        if (child['font-size']) {
          customTag += ` fontSize="${child['font-size']}px"`
        }

        customTag += `>${child.text}</customize>`

        return {
          text: customTag,
        }
      }

      return child
    }),
  }
}

export const ContentstackRichText = (data: ContentstackRichTextProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { headings_font_family, analyticsTrackingData } = data

  const findEmbeddedItem = (uid: string, type_uid: string) => {
    return data._embedded_items.json_rte.find((d) => d._content_type_uid == type_uid && d.uid == uid)
  }

  const recursivelyRender = (json_rte: JsonRteChild) => {
    if (json_rte?.children?.length > 0) {
      return json_rte.children.map((block, index) => {
        if (block.type === 'reference') {
          switch (block.attrs['content-type-uid']) {
            case 'component_accordion':
            case 'component_article_card':
            case 'component_cover_card':
            case 'component_banner_full':
            case 'component_banner_split':
            case 'component_banner_text_only':
            case 'component_grid':
            case 'component_text_card':
            case 'component_team_logo_grid':
            case 'component_modal':
              return renderAsComponent(block, block.attrs['content-type-uid'])
            case 'sys_assets':
              return (
                <ComponentRichText
                  analyticsTrackingData={analyticsTrackingData}
                  headings_font_family={headings_font_family}
                >
                  {jsonToHtml(block, renderOption)}
                </ComponentRichText>
              )
            case 'custom_link':
              return (
                <ComponentRichText
                  analyticsTrackingData={analyticsTrackingData}
                  headings_font_family={headings_font_family}
                >
                  {jsonToHtml(block, renderOption)}
                </ComponentRichText>
              )
            default:
              break
          }
        }

        if (
          block.type &&
          (block.type.startsWith('h') ||
            block.type === 'p' ||
            block.type === 'blockquote' ||
            block.type === 'table' ||
            block.type === 'ul' ||
            block.type === 'ol')
        ) {
          const preprocessedData = preprocessForJsonToHtml(block)
          return (
            <ComponentRichText
              analyticsTrackingData={analyticsTrackingData}
              key={index}
              headings_font_family={headings_font_family}
            >
              {jsonToHtml(preprocessedData, renderOption)}
            </ComponentRichText>
          )
        }

        return recursivelyRender(block)
      })
    }
    return (
      <ComponentRichText analyticsTrackingData={analyticsTrackingData} headings_font_family={headings_font_family}>
        {json_rte && jsonToHtml(json_rte, renderOption)}
      </ComponentRichText>
    )
  }

  const renderAsComponent = (block: JsonRteChild, componentType: string) => {
    const entryData = findEmbeddedItem(block.attrs['entry-uid'], block.attrs['content-type-uid'])
    let ComponentToRender
    let setAccordionPadding = false

    //TODO: add new CS components here to embedded them to rich text
    switch (componentType) {
      case 'component_accordion':
        ComponentToRender = ContentstackAccordion
        setAccordionPadding = true
        break
      case 'component_article_card':
        ComponentToRender = ContentstackArticleCard
        break
      case 'component_cover_card':
        ComponentToRender = ContentstackCoverCard
        break
      case 'component_banner_full':
        ComponentToRender = ContentstackBannerFull
        break
      case 'component_text_card':
        ComponentToRender = ContentstackTextCard
        break
      case 'component_banner_split':
        ComponentToRender = ContentstackBannerSplit
        break
      case 'component_banner_text_only':
        ComponentToRender = ContentstackBannerTextOnly
        break
      case 'component_grid':
        ComponentToRender = ContentstackGridComponent
        break
      case 'component_team_logo_grid':
        ComponentToRender = ContentstackTeamLogoGrid
        break
      case 'component_modal':
        ComponentToRender = ContentstackModal
        break
      default:
        break
    }
    return (
      <Box maxW="900px" mx={'auto'} py={setAccordionPadding ? `0px !important` : 4}>
        <ComponentToRender {...entryData} />
      </Box>
    )
  }

  return (
    <Container maxW="container.xl" px={isMobile ? 4 : 100} py={isMobile ? 4 : 16} {...data?.styles}>
      {recursivelyRender(data.json_rte)}
    </Container>
  )
}
