import React, { HtmlHTMLAttributes, useEffect } from 'react'
import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general'

const SurflyChat = () => {
  const hideChatBubble = () => {
    const intervalId = setInterval(() => {
      const elements: any = document.querySelectorAll('.ie-div-position-customer-chat')
      if (elements.length > 0) {
        clearInterval(intervalId)
        const element = elements[0]
        element.style.display = 'none'
      }
    }, 500)
  }
  useEffect(() => {
    initializeSurflyChat()

    addChatObj()
  }, [])

  const addChatObj = () => {
    var scriptElement = document.createElement('script')

    // Define the function
    scriptElement.textContent = `
          window.openChat = function() {
            window.open('https://home-c36.nice-incontact.com/inContact/ChatClient/ChatClient.aspx?poc=43f6a29f-b5a7-4c60-ae30-2e72e59698cb&bu=4600462',
            'icPatronChatWin',
             'location=no,height=630,menubar=no,status-no,width=410');
          };
        `
    document.head.appendChild(scriptElement)
  }

  const initializeSurflyChat = () => {
    const win: any = window
    const surflyWidgetKey = win?.surflyWidgetKey
    if (typeof surflyWidgetKey == 'undefined') {
      const script = document.createElement('script')
      script.src = '/scripts/surfly_chat.js'
      script.async = true
      const scriptSrcVal = script.src
      appendScript(scriptSrcVal, 'surflyChatScript', FUNCTIONAL_COOKIE_CATEGORY, hideChatBubble)
      return () => {
        document.head.removeChild(script)
      }
    }
  }

  return <></>
}

export default SurflyChat
