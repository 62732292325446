/* eslint-disable jsx-a11y/aria-proptypes */
import { HStack, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRange } from 'react-instantsearch-hooks-web'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { SliderProps } from '..'

export const CustomPriceSlider = (props: SliderProps) => {
  const [values, setValues] = useState<number[]>([])
  const [defaultValues, setDefaultValues] = useState<number[]>([])

  const didMountRef = useRef(false)
  const { range, refine, start } = useRange({
    ...props,
  })

  const onChange = (newValues: number[]) => {
    const minDifference = 1000

    const [currentMin, currentMax] = values
    const [newMin, newMax] = newValues

    if (newMax - newMin < minDifference) {
      if (newMin === currentMin) {
        setValues([currentMin, Math.min(currentMin + minDifference, range.max as number)])
      } else {
        setValues([Math.max(currentMax - minDifference, range.min as number), currentMax])
      }
    } else {
      setValues(newValues)
    }
  }

  const onChangeEnd = (values: number[]) => {
    const minInCents = values[0]
    const maxInCents = values[1]

    refine([Number.isFinite(minInCents) ? minInCents : undefined, Number.isFinite(maxInCents) ? maxInCents : undefined])
  }

  const handleValues = useCallback(() => {
    if (range.min === range.max && range.min !== 0) {
      // If min and max are same ( meaning all products on that hits have same price), then we create an adjusted
      const adjustedMin = range.min - 1000 < 0 ? 0 : range.min - 1000
      const adjustedMax = range.max + 1000
      setDefaultValues([adjustedMin, adjustedMax])
      setValues([adjustedMin, adjustedMax])
    } else {
      // Use the actual range from Algolia when min and max are not equal
      setDefaultValues([range.min as number, range.max as number])
      setValues([range.min as number, range.max as number])
    }
  }, [range.max, range.min])

  useEffect(() => {
    if (range.max) {
      handleValues()
    }
  }, [range.max, range.min, handleValues])

  useEffect(() => {
    if (didMountRef.current) {
      if (!isFinite(start[0] as number) && !isFinite(start[1] as number)) {
        return handleValues()
      } else if (!isFinite(start[0] as number)) {
        setValues([range.min || 0, values[1]])
      } else if (!isFinite(start[1] as number)) {
        setValues([values[0], range.max as number])
      }
    }
    didMountRef.current = true
  }, [start, handleValues])

  return (
    defaultValues?.length > 0 && (
      <>
        <HStack justify="space-between" mt={6}>
          <Text textStyle="body-75">
            {CurrencyHelpers.formatForWholeNumberCurrency({ centAmount: values[0], currencyCode: 'USD' })}
          </Text>
          <Text textStyle="body-75">
            {CurrencyHelpers.formatForWholeNumberCurrency({ centAmount: values[1], currencyCode: 'USD' })}
          </Text>
        </HStack>

        <HStack px={3} pb={3} mt={3}>
          {defaultValues?.length > 0 && (
            <RangeSlider
              aria-label={['min', 'max']}
              min={defaultValues?.[0]}
              max={defaultValues?.[1]}
              step={1000}
              value={values}
              defaultValue={defaultValues}
              onChange={onChange}
              onChangeEnd={onChangeEnd}
            >
              <RangeSliderTrack bg="surface.muted">
                <RangeSliderFilledTrack bg="brand.primary" />
              </RangeSliderTrack>
              <RangeSliderThumb
                outlineOffset={0}
                index={0}
                boxSize={6}
                bgColor="surface.primary"
                border="1px solid"
                borderColor="surface.highlight"
                _hover={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10)',
                }}
                sx={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10)',
                }}
              />
              <RangeSliderThumb
                outlineOffset={0}
                index={1}
                boxSize={6}
                bgColor="surface.primary"
                border="1px solid"
                borderColor="surface.highlight"
                _hover={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10)',
                }}
                sx={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.10)',
                }}
              />
            </RangeSlider>
          )}
        </HStack>
      </>
    )
  )
}
