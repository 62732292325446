import { FC } from 'react'
import { Box, Alert, AlertTitle, AlertDescription, AlertIcon, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import parse from 'html-react-parser'

interface NoMatchPageProps {
  type: string
  pageName: string
}

export const NoComponentMatch: FC<NoMatchPageProps> = ({ type, pageName }) => {
  const intl = useFormat({ name: 'common' })
  const title = intl.formatMessage({ id: 'noComponentMatch.title' })
  const description = intl.formatMessage({ id: 'noComponentMatch.description', values: { type, pageName } })

  return (
    <Box py={{ base: 6, md: 16 }} px={{ base: 0, md: 24 }} display={'flex'} justifyContent={'center'}>
      <Alert status="error" maxW={'container.sm'}>
        <AlertIcon />
        <VStack alignItems={'left'} gap={4} my={2}>
          <AlertTitle fontSize="md">{title}</AlertTitle>
          <AlertDescription>{parse(description)}</AlertDescription>
        </VStack>
      </Alert>
    </Box>
  )
}
