import { Address } from '@Types/account/Address'
import { Cart } from '@Types/cart/Cart'
import { LineItem } from '@Types/cart/LineItem'

export const getCartSummaryAmounts = (cart: Partial<Cart> | undefined) => {
  const subtotal = calculateCartSubtotal(cart)
  let discounts = calculateCartDiscounts(cart)
  const taxes = calculateCartTaxes(cart)
  const isShippingMethodSelected = cart?.shippingInfo?.shippingMethodId
  const shipping = getShippingMethodRate(cart)
  const cartSum = cart?.sum?.centAmount
  const finalDiscount = subtotal - cartSum

  discounts = finalDiscount > discounts ? finalDiscount : discounts

  return {
    subtotal,
    discounts,
    taxes,
    isShippingMethodSelected,
    shipping,
    total: cartSum + shipping,
    /* 
    Excluding taxes from grand total. grand total will be calculated at POS (STS-177)
    */
  }
}

export const calculateCartSubtotal = (cart: Partial<Cart> | undefined) => {
  const cartSubtotal = cart?.lineItems?.reduce((accumulator, current) => {
    const price = current?.price?.centAmount
    const discountedPrice = current?.discountedPrice?.centAmount

    const { isDiscounted } = getLineItemAmounts(current)

    const currentTotal = (isDiscounted ? discountedPrice : price) * current.count

    return accumulator + currentTotal
  }, 0)

  return cartSubtotal
}

export const calculateCartDiscounts = (cart: Partial<Cart> | undefined) => {
  const cartDiscounts = cart?.lineItems?.reduce((accumulator, current) => {
    const discountedPrice = current?.discountedPrice?.centAmount
    const price = current?.price?.centAmount

    const { isDiscounted } = getLineItemAmounts(current)

    if (isDiscounted) {
      const perItemDiscount = price - discountedPrice
      const currentTotalDiscount = current.count * perItemDiscount
      return accumulator + currentTotalDiscount
    } else {
      return accumulator
    }
  }, 0)

  return cartDiscounts
}

export const calculateCartTaxes = (cart: Partial<Cart> | undefined) => {
  return cart?.taxed?.taxPortions?.reduce((a, b) => a + b.amount.centAmount, 0) ?? 0
}

export function formatAddress(address: Address) {
  return {
    name: `${address?.firstName ?? ''} ${address?.lastName ?? ''}`,
    addressLine1: `${address?.streetNumber ?? ''} ${address?.streetName ?? ''}`,
    addressLine2: `${address?.city ?? ''}, ${address?.state ?? ''}, ${address?.postalCode ?? ''}`,
  }
}

export const getCartProductBySlug = ({ productSlug, cart }: { productSlug: string; cart?: Cart }) => {
  return cart?.lineItems?.find((lineItem) => lineItem.productSlug === productSlug)
}

export const getCartProductByVariantSku = ({ variantSku, cart }: { variantSku: string; cart?: Cart }) => {
  return cart?.lineItems?.find((lineItem) => lineItem.variant?.sku === variantSku)
}

export const getShippingMethodRate = (cart: Partial<Cart> | undefined) => {
  /* 
  packages/savethesale/backend/commerce-commercetools/utils/CartFetcher.ts returns promise of <Cart>
  But this <Cart> interface is defined by frontastic modules and we cannot add items in it to fetch more data from the cart.
  Essentially, we need cart.shippingInfo.shippingRate.price to calculate shipping cost. There is no way to fetch `shippingRate` from the cart.shippingInfo since <Cart> interface is not in our control.

  But we do have full [] of shippingOptions returns from this Cart.
  */

  const selectedShippingMethodID = cart?.shippingInfo?.shippingMethodId
  const selectedShippingMethodInfo = cart?.availableShippingMethods?.find(
    (method) => method.shippingMethodId === selectedShippingMethodID,
  )

  return selectedShippingMethodInfo?.rates?.[0]?.price?.centAmount || 0
}

export const getLineItemAmounts = (lineItem: LineItem) => {
  const { price, discountedPrice } = lineItem
  const isDiscounted = price?.centAmount < discountedPrice?.centAmount

  return {
    isDiscounted: isDiscounted,
    price: price?.centAmount,
    discountedPrice: isDiscounted ? discountedPrice?.centAmount : undefined,
  }
}
