import { useRouter } from 'next/router'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Box,
  Divider,
  useBreakpointValue,
  Link,
  VStack,
  Center,
} from '@chakra-ui/react'
import { LineItem } from '@Types/cart/LineItem'
import { NextLinkNoPrefetch } from 'composable'
import { semantic } from 'composable/chakra/figma-tokens'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { HorizontalProductCard, HorizontalProductCardProps } from './horizontal-product-card'

interface OutOfStockModalProps {
  outofStockOrReducedStockItems: Array<LineItem> | []
  totalCartItems: number
}

export const OutOfStockModal = ({ outofStockOrReducedStockItems, totalCartItems }: OutOfStockModalProps) => {
  const router = useRouter()
  const productCartSize = useBreakpointValue({ base: 'sm', md: 'lg' })
  const { formatMessage } = useFormat({ name: 'common' })
  const { updateCartItems } = useCart()

  const isAllCartItemsOutOfStock =
    outofStockOrReducedStockItems.length === totalCartItems &&
    outofStockOrReducedStockItems.every((item: any) => item.variant.availableQuantity === 0)

  const handleUpdate = async () => {
    await updateCartItems(outofStockOrReducedStockItems)
  }

  return (
    <Modal
      isOpen={outofStockOrReducedStockItems?.length > 0}
      onClose={() => {}}
      size={{ base: 'xs', lg: 'sm' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent mx={16}>
        <VStack width="100%">
          <Box width="100%" p={2} borderBottom="1px solid" borderBottomColor={semantic.surface.border}>
            <Center>
              <Text textStyle={'heading-desktop-100'} textAlign="center">
                {isAllCartItemsOutOfStock
                  ? formatMessage({ id: 'checkout.all.outOfStock.title' })
                  : formatMessage({ id: 'checkout.some.outOfStock.title' })}
              </Text>
            </Center>
          </Box>

          <VStack width="100%" p={6} spacing={4} alignItems="flex-start">
            <Text textStyle={'body-100'} width="100%">
              {isAllCartItemsOutOfStock
                ? formatMessage({ id: 'checkout.all.outOfStock.message' })
                : formatMessage({ id: 'checkout.some.outOfStock.message' })}
            </Text>

            {outofStockOrReducedStockItems.map((item: LineItem, i: number) => {
              const { variant, price, discountedPrice, name, count, lineItemId, _url } = item

              const masterProductData = item?.masterData?.current?.masterVariant?.attributes

              return (
                <VStack key={i} width="100%" alignItems="flex-start">
                  <Box width="100%" opacity={variant.availableQuantity === 0 ? '0.6' : ''}>
                    <HorizontalProductCard
                      key={lineItemId}
                      url={_url}
                      columns={2}
                      image={{
                        src: variant?.images?.[0],
                        alt: name ?? '',
                        onClickImage: () => router.push(_url),
                      }}
                      name={name}
                      quantity={count}
                      price={CurrencyHelpers.formatForCurrency(price?.centAmount)}
                      discountedPrice={
                        discountedPrice ? CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount) : undefined
                      }
                      size={productCartSize as HorizontalProductCardProps['size']}
                      variant={variant}
                      displayOutOfStock={false}
                      masterProductData={masterProductData}
                    />
                  </Box>
                  <Divider mb={2} />
                  <Text textStyle={'body-75'} color={variant.availableQuantity === 0 ? semantic.text.danger : ''}>
                    {formatMessage({
                      id: 'cart.item.outOfStock.quantity',
                      values: { quantity: variant.availableQuantity },
                    })}
                  </Text>
                </VStack>
              )
            })}
          </VStack>

          <VStack width="100%" px={6} py={4}>
            {isAllCartItemsOutOfStock ? (
              <Button
                w={{ base: 'full' }}
                variant={'solid'}
                size={{ base: 'lg', lg: 'xl' }}
                textStyle={'ui-button-100'}
                onClick={() => router.push('/cart')}
                px={4}
                py={2.5}
              >
                {formatMessage({
                  id: 'action.backToCart',
                })}
              </Button>
            ) : (
              <>
                <Button
                  w={{ base: 'full' }}
                  variant={'solid'}
                  size={{ base: 'lg', lg: 'xl' }}
                  textStyle={'ui-button-100'}
                  onClick={handleUpdate}
                  px={4}
                  py={2.5}
                >
                  {formatMessage({
                    id: 'checkout.some.outOfStock.action.title',
                  })}
                </Button>

                <Link
                  as={NextLinkNoPrefetch}
                  href={'/cart'}
                  textStyle={'ui-buttonGhost-100'}
                  fontWeight="extrabold"
                  textDecoration="underline"
                  px={4}
                  py={2.5}
                >
                  {formatMessage({ id: 'action.backToCart' })}
                </Link>
              </>
            )}
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  )
}
