import { Box, Button, Card, CardBody, Center, Flex, Heading, Icon, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { phoneMask } from './utils'
import StoreTiming from './store-timing'
import Toast from 'react-hot-toast'
import { Channel } from '@Types/channel/Channel'
import { IoCallOutline, IoLocationOutline, IoTimeOutline } from 'react-icons/io5'

export interface StoreListItemProps {
  storeData: Channel
  idx?: number
  distance: number
  storeHeading?: {}
  selectedStoreOnly?: boolean
  onHandleStore: any
}

const StoreListItem = ({
  storeData,
  idx,
  distance,
  storeHeading,
  selectedStoreOnly,
  onHandleStore,
}: StoreListItemProps) => {
  const handelStore = (store) => {
    onHandleStore(store)
  }

  const storeDetailsBox = {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--sizes-3, 12px)',
    alignSelf: 'stretch',
  }
  const cardBodyText = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
  }
  const cardBorder = {
    borderRadius: 'var(--radii-lg, 8px)',
    border: '1px solid #E7EAED',
    boxShadow: 'none',
  }
  return (
    <>
      <Card
        style={cardBorder}
        mb={'12px'}
        border={selectedStoreOnly ? '2px !important' : ''}
        borderColor={selectedStoreOnly ? '#000' : ''}
        mt={selectedStoreOnly ? '8px' : '0'}
      >
        <CardBody
          gap={'8px'}
          display={'flex'}
          alignItems={'flex-start'}
          flexDirection={'column'}
          p={{ base: '20px' }}
          style={cardBodyText}
          width={'100%'}
          color={'text.primary'}
        >
          <Flex justifyContent={'space-between'} alignItems={'first baseline'} width={'100%'}>
            <Box display={'flex'} alignItems={'first baseline'}>
              <Text style={storeHeading} color={'text.primary'}>
                {storeData?.address?.city}
              </Text>
              <Text ml={1} fontSize={'14px'} color={'#76797E'}>
                ({storeData?.custom?.fields?.lastname})
              </Text>
            </Box>
            <Text fontSize={'14px'} color={'#76797E'}>
              {distance} mi
            </Text>
          </Flex>
          <Flex mt={2} maxW={'90%'} width={'100%'}>
            <Box pr={2} width={'100%'}>
              <Flex mb={'12px'} style={storeDetailsBox}>
                <Box minWidth={'16px'}>
                  <Icon as={IoLocationOutline} fontSize={17} />
                </Box>
                <Text>
                  {storeData?.address?.streetName}, {storeData?.address?.city}, {storeData?.address?.state},{' '}
                  {storeData?.address?.postalCode}
                </Text>
              </Flex>
              <Flex mb={'12px'} style={storeDetailsBox}>
                <Box minWidth={'16px'}>
                  <Icon as={IoCallOutline} fontSize={17} />
                </Box>
                <Box>
                  <Text>{phoneMask(storeData?.address?.phone)}</Text>
                </Box>
              </Flex>
              <Flex gap={'12px'} mb={!selectedStoreOnly ? '16px' : '0'}>
                <Box minWidth={'16px'}>
                  <Icon as={IoTimeOutline} fontSize={17} />
                </Box>
                <Box w={'100%'}>
                  <StoreTiming key={idx} storeTiming={storeData?.custom?.fields?.storeHours} index={idx}></StoreTiming>
                </Box>
              </Flex>
              {!selectedStoreOnly && (
                <Button
                  type="submit"
                  bgColor="#000"
                  onClick={() => {
                    handelStore(storeData)
                  }}
                  p={'10px 8px'}
                  fontSize={'12px'}
                  height={'auto'}
                >
                  Set as My Store
                </Button>
              )}
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </>
  )
}

export default StoreListItem
