import { Box, Heading } from '@chakra-ui/react'
import React, { useState } from 'react'
import { storesDistance } from './utils'
import Toast from 'react-hot-toast'
import StoreNotFound from './store-not-found'
import StoreListItem from './store-list-item'

const StoreList = ({ storesLists, addressLocation, showNoResults, onClose, storeHeading, isStoreSearch }) => {
  let selectedStoreD: any
  let selectedStoreData = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedStoreData') : null
  if (selectedStoreData) {
    selectedStoreD = JSON.parse(selectedStoreData)
  }

  const [selectedStore, setSelectedStore] = useState(selectedStoreD)
  const handelStore = (store) => {
    const storeData = { ...store, addressLocation }
    let selectedStoreData = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedStoreData') : null
    if (selectedStoreData) {
      selectedStoreD = JSON.parse(selectedStoreData)
      setSelectedStore(selectedStoreD)
      setTimeout(() => {
        Toast.success(`Store ${selectedStoreD?.address?.city} set succesfull`)
      }, 300)
    }
    onClose(storeData)
  }

  return (
    <>
      {selectedStore && selectedStore != null && (
        <Box>
          <Heading
            style={storeHeading}
            height={'32px'}
            display={'flex'}
            alignItems={'stretch'}
            justifyContent={'flex-end'}
            flexDirection={'column'}
            color={'text.primary'}
          >
            My Store
          </Heading>
          <StoreListItem
            storeData={selectedStore}
            distance={
              selectedStore.addressLocation !== null && selectedStore.addressLocation?.lat
                ? +storesDistance(
                    selectedStore.addressLocation.lat,
                    selectedStore.addressLocation.lng,
                    selectedStore?.geoLocation?.coordinates[1],
                    selectedStore?.geoLocation?.coordinates[0],
                  ).toFixed(0)
                : 0
            }
            storeHeading={storeHeading}
            selectedStoreOnly={true}
            onHandleStore={handelStore}
          />
        </Box>
      )}
      {storesLists && storesLists?.results?.length > 0 ? (
        <>
          <Heading
            style={storeHeading}
            height={'32px'}
            display={'flex'}
            alignItems={'stretch'}
            justifyContent={'flex-end'}
            flexDirection={'column'}
            mb={'8px'}
            color={'text.primary'}
          >
            Nearby Stores
          </Heading>
          {storesLists.results?.map((store, idx) => {
            const storeD = storesDistance(
              addressLocation.lat,
              addressLocation.lng,
              store?.geoLocation?.coordinates[1],
              store?.geoLocation?.coordinates[0],
            ).toFixed(0)
            return (
              <>
                {store?.id !== selectedStore?.id ? (
                  <StoreListItem
                    key={idx}
                    storeData={store}
                    distance={+storeD}
                    storeHeading={storeHeading}
                    selectedStoreOnly={false}
                    onHandleStore={handelStore}
                  />
                ) : null}
              </>
            )
          })}
        </>
      ) : (
        <>{(isStoreSearch || showNoResults) && <StoreNotFound></StoreNotFound>}</>
      )}
    </>
  )
}

export default StoreList
