import { Box, Link, Stack, Text } from '@chakra-ui/react'
import { CheckboxField } from 'composable/components/forms'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCheckout } from '../checkout-provider'
import MyStore from 'composable/components/mystore/my-store'
import { useEffect, useState } from 'react'
import { getCookieByName, phoneMask } from 'composable/components/mystore/utils'
import { getStore } from 'frontastic/actions/channel'

export const ShopToStoreAddress = ({ initialValues, callBack }) => {
  const intl = useFormat({ name: 'common' })
  const { checkoutStateInitial, setCheckoutState } = useCheckout()
  const [openStoreDrawer, setOpenStoreDrawer] = useState(false)
  const [deliveryType, setDeliveryType] = useState('shipToAddress')
  const deliveryTypeArr = ['shipToAddress', 'shipToStore']
  const linkStyle = {
    fontWeight: '700',
    color: 'var(--semantic-text-primary, #15191C)',
    textDecoration: 'underline',
  }

  useEffect(() => {
    const type = initialValues?.additional_address_info == deliveryTypeArr[1] ? deliveryTypeArr[1] : deliveryTypeArr[0]
    checkoutStateInitial.shipping_address = initialValues
    setCheckoutState((state) => {
      return {
        ...state,
        shipping_address: checkoutStateInitial.shipping_address,
        config: {
          ...checkoutStateInitial.config,
          billingIsShipping: type == deliveryTypeArr[1] ? false : true,
        },
      }
    })
    setDeliveryType(type)
    callBack(type)
  }, [initialValues])

  const setSelectedStore = (storeInfo, deliveryType) => {
    checkoutStateInitial.shipping_address = {
      id: initialValues.id,
      additional_address_info: deliveryType,
      first_name: storeInfo?.custom?.fields?.firstname ? storeInfo?.custom?.fields?.firstname : '',
      last_name: storeInfo?.custom?.fields?.lastname ? storeInfo?.custom?.fields?.lastname : '',
      phone_number: storeInfo?.address?.phone ? storeInfo?.address?.phone : '',
      street_name: storeInfo?.address?.streetName ? storeInfo?.address?.streetName : '',
      street_number: storeInfo?.address?.streetNumber ? storeInfo?.address?.streetNumber : '',
      city: storeInfo?.address?.city ? storeInfo?.address?.city : '',
      state: storeInfo?.address?.state ? storeInfo?.address?.state : '',
      postcode: storeInfo?.address?.postalCode ? storeInfo?.address?.postalCode : '',
      country: storeInfo?.address?.country ? storeInfo?.address?.country : '',
      additional_street_info: storeInfo?.address?.streetNumber ? storeInfo?.address?.streetNumber : '',
    }
    setCheckoutState((state) => {
      return {
        ...state,
        shipping_address: checkoutStateInitial.shipping_address,
        config: {
          ...checkoutStateInitial.config,
          billingIsShipping: false,
        },
      }
    })
    setDeliveryType(deliveryType)
    callBack(deliveryType)
  }

  const openStorePopup = () => {
    alert('TODO: Not in scope of this ticket')
  }

  const handleOpenStoreCallBack = (obj) => {
    setOpenStoreDrawer(false)
    if (obj) {
      setSelectedStore(obj, deliveryTypeArr[1])
    }
  }

  const handleShipToStoreCheckBox = ({ target }) => {
    let type = deliveryTypeArr[0]
    if (target.checked) {
      type = deliveryTypeArr[1]
      loadNearestStore(type)
    } else {
      setOpenStoreDrawer(false)
      setSelectedStore({}, type)
    }
  }

  const loadNearestStore = (deliveryType) => {
    const guestLocation = getCookieByName('GuestLocation')
    if (guestLocation && guestLocation !== null && guestLocation !== undefined) {
      let obj = {
        lat: guestLocation.split('|')[1],
        lng: guestLocation.split('|')[2],
        radius: '75',
        shipToStore: true,
      }
      getStore(obj).then((res) => {
        if (res?.results?.length > 0) {
          const storeInfo = res?.results[0]
          setSelectedStore(storeInfo, deliveryType)
        } else {
          setSelectedStore({}, deliveryTypeArr[0])
        }
      })
    }
  }

  return (
    <>
      <Stack flexDirection={'column'}>
        <CheckboxField
          content={intl.formatMessage({
            id: 'checkout.shippingForm.shipToStoreCheckbox.title',
          })}
          checkboxProps={{
            isChecked: deliveryType == 'shipToStore',
            onChange: handleShipToStoreCheckBox,
            name: 'deliveryType',
          }}
        />
        {deliveryType == 'shipToStore' && checkoutStateInitial?.shipping_address?.city && (
          <>
            <Box>
              <Text>{`${checkoutStateInitial?.shipping_address?.city} (${checkoutStateInitial?.shipping_address?.last_name})`}</Text>
              <Text>{`${checkoutStateInitial?.shipping_address?.street_name}, ${checkoutStateInitial?.shipping_address?.city}, ${checkoutStateInitial?.shipping_address?.state}, ${checkoutStateInitial?.shipping_address?.postcode}`}</Text>
              <Text> {`${phoneMask(checkoutStateInitial?.shipping_address?.phone_number)}`}</Text>
            </Box>
            <Link onClick={openStorePopup} style={linkStyle}>
              {intl.formatMessage({ id: 'checkout.shippingForm.changeStore.title' })}
            </Link>
          </>
        )}
      </Stack>
      <MyStore
        viewType="popup"
        pageName="checkout"
        callBack={handleOpenStoreCallBack}
        shippingType={{ shipToStore: true }}
        showMyStoreTitle={false}
        openStoreDrawer={openStoreDrawer}
      ></MyStore>
    </>
  )
}
