import React, { FormEvent, useEffect, useState } from 'react'
import { Box, Button, SelectField, SimpleGrid, FormControl, FormLabel, Input } from '@chakra-ui/react'
import fetch from 'unfetch'
import { getStore } from 'frontastic/actions/channel'
const StoreSearch = ({ onStoreData, shippingType }) => {
  let distances = [
    { value: '25', viewValue: '25 miles' },
    { value: '50', viewValue: '50 miles' },
    { value: '100', viewValue: '100 miles' },
    { value: '200', viewValue: '200 miles' },
  ]
  const [city, setCity] = useState('')
  const [distance, setDistance] = useState('25')
  const GOOGLE_API_KEY = 'AIzaSyAEQhwvM3Emv4FUKMxF6ZiXVQFuSu1Euvc'

  const formLabel = {
    color: 'var(--semantic-text-primary, #15191C)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
  }
  const isError = city === ''
  let addressLocation: any
  const getLatLng = async (city: string) => {
    let geoMapUrl = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_API_KEY

    geoMapUrl = `${geoMapUrl}&address=${city}`

    const res = await fetch(geoMapUrl, {
      method: 'GET',
    })
    const data = await res.json()
    if (data.status == 'OK') {
      addressLocation = {}
      addressLocation = data.results[0].geometry.location
      findStoresNearBy(distance)
    } else if (data.status == 'ZERO_RESULTS') {
      onStoreData({ storeData: {}, addressLocation: null, showNoResults: true, isStoreSearch: true })
    } else {
      onStoreData({ storeData: {}, addressLocation: null, showNoResults: true, isStoreSearch: true })
    }
  }

  const findStoresNearBy = (distance) => {
    if (addressLocation) {
      let obj = {
        ...shippingType,
        lat: addressLocation.lat,
        lng: addressLocation.lng,
        radius: distance,
      }
      getStore(obj).then((res) => {
        const resObj = { storeData: res }
        onStoreData({ ...resObj, addressLocation: addressLocation, showNoResults: false, isStoreSearch: true })
      })
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()
    getLatLng(city)
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ md: 2, sm: 1, xs: 1 }} spacing={2}>
          <Box>
            <FormControl>
              <FormLabel style={formLabel}>City, State or Zip Code*</FormLabel>
              <Input
                className="border bg-transparent font-normal"
                borderColor={'#CACFD7'}
                style={{ borderRadius: '6px', width: '100%' }}
                required
                placeholder="Location"
                type="text"
                onChange={(e) => {
                  setCity(e.target.value)
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isInvalid={isError}>
              <FormLabel style={formLabel}>Distance in Miles*</FormLabel>
              <SelectField
                w="100%"
                borderColor={'#CACFD7'}
                required
                placeholder="Miles"
                onChange={(e) => setDistance(e.target.value)}
                style={{ borderRadius: '6px', fontWeight: 'normal', paddingBottom: '0.36rem' }}
              >
                {distances.map((value, index) => {
                  return (
                    <option key={index} value={value.value}>
                      {value.viewValue}
                    </option>
                  )
                })}
              </SelectField>
            </FormControl>
          </Box>
        </SimpleGrid>
        <Button type="submit" bgColor="#000" my={'16px'} width="100%">
          Find Stores
        </Button>
      </form>
    </>
  )
}

export default StoreSearch
