import React, { useEffect, useMemo } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ChakraProvider } from '@chakra-ui/react'
import { YottaaSnippet } from 'components/headless/Yottaa'
import { AccountDrawer, ComposableProvider, theme, Datadog, CartDrawer } from 'composable'
import { ThirdPartyScripts } from 'composable/components/dxl-footer/thirdPartyScripts'
import { APP_VERSION } from 'composable/components/general'
import { OneTrust } from 'composable/components/general/components/OneTrust'
import { appWithTranslation } from 'next-i18next'
import { sdk } from 'sdk'
import { FrontasticProvider } from 'frontastic'
import { AtgUserProvider, AtgCartProvider } from 'frontastic/contexts'
import SalesFloorChat from 'frontastic/tastics/sales-floor-chat'

import 'tailwindcss/tailwind.css'
import '../styles/app.css'
import '../styles/themes/default.css'
import '../styles/themes/theme1.css'
import '../styles/themes/theme2.css'
import '../styles/themes/theme3.css'
import '../styles/components/slider.css'
import '../styles/components/default-loader.css'
import SurflyChat from 'frontastic/tastics/surfly-chat'
import '../styles/atg/main.css'
import Tealium from 'frontastic/tastics/tealium'
import { LegacyCartDrawer } from '../atg/components/legacy-cart-drawer'
import Monetate from 'frontastic/tastics/monetate'

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const path = router.asPath

  useEffect(() => {
    if (window.Yo && path == '/') {
      window.Yo.pubsub.publish({ topic: 'rum/spa/transition/start', message: {} })
    }
  }, [path])

  const Drawer = APP_VERSION === 'R1' ? LegacyCartDrawer : CartDrawer

  sdk.configureForNext(router.locale as string)
  return (
    <FrontasticProvider>
      <Datadog />
      <AtgUserProvider>
        <AtgCartProvider>
          <ComposableProvider>
            <ChakraProvider theme={theme}>
              <OneTrust />
              <YottaaSnippet />
              <Monetate />
              <Component {...pageProps} />
              <AccountDrawer />
              <Drawer />
              <ThirdPartyScripts />
            </ChakraProvider>
          </ComposableProvider>
          <Tealium />
          <SalesFloorChat />
          {/* <ZetaComponent /> */}
          <SurflyChat />
        </AtgCartProvider>
      </AtgUserProvider>
    </FrontasticProvider>
  )
}

export default appWithTranslation(FrontasticStarter)
