import { useEffect, useState } from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react'
import { useComposable } from 'composable/components/composable-provider'
import { NextLinkNoPrefetch } from 'composable/components/link'
import { useFormat } from 'helpers/hooks/useFormat'

interface PdpBreadcrumbProps {
  category: any
  title: string
  slug: string
}

export const PdpBreadcrumb = ({ category, title, slug }: PdpBreadcrumbProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>()
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { path } = useComposable()

  const intl = useFormat({ name: 'common' })
  useEffect(() => {
    const Home = {
      label: intl.formatMessage({ id: 'product.breadcrumbs.home' }),
      link: '/',
    }
    const ProductFallback = {
      label: title,
      link: `/p/${slug}`,
    }

    if (category?.breadcrumbs) {
      const parsedBreadcrumbs = category.breadcrumbs.map((item: any) => ({
        label: item?.label,
        link: item.link !== '/' ? path.getPLP(item.link) : path.getHome(),
      }))
      setBreadcrumbs([Home, ...parsedBreadcrumbs])
    } else {
      setBreadcrumbs([Home, ProductFallback])
    }
  }, [])

  if (!breadcrumbs) return null

  return (
    <ChakraBreadcrumb
      overflowX={isMobile ? 'scroll' : undefined}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      color={'text-muted'}
    >
      {breadcrumbs?.map((item) => {
        return (
          <BreadcrumbItem as={NextLinkNoPrefetch} href={item?.link} key={item?.link} textStyle={'body-75'}>
            <BreadcrumbLink
              as={Text}
              whiteSpace={'nowrap'}
              _hover={{
                textStyle: 'link-75',
              }}
            >
              {item?.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </ChakraBreadcrumb>
  )
}
