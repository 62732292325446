import { IconButton } from '@chakra-ui/react'
import { GridLayoutOption, useGridLayout } from '../../hooks'

export const LayoutButton = ({ layout, icon }: { layout: GridLayoutOption; icon: JSX.Element }) => {
  const { currentLayout, changeLayout, showLayoutControl } = useGridLayout()
  return (
    <IconButton
      minW={10}
      display={showLayoutControl(layout) ? 'flex' : 'none'}
      aria-label={layout}
      fontSize="xl"
      icon={icon}
      onClick={() => {
        changeLayout(layout)
      }}
      opacity={currentLayout === layout ? 1 : 0.4}
      mx={1}
      color="text"
      variant="ghost"
      mr={'2px'}
      ml={'2px'}
      borderRadius={0}
    />
  )
}
