import { useEffect, useMemo, useState } from 'react'
import NextRouter, { useRouter } from 'next/router'
import { Box, Container, Flex, Heading, Spacer, Text } from '@chakra-ui/react'
import algoliasearch from 'algoliasearch/lite'
import { useFormat } from 'helpers/hooks/useFormat'
import { createInstantSearchRouterNext } from 'react-instantsearch-hooks-router-nextjs'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import {
  CurrentRefinements,
  InfiniteHits,
  SortBy,
  AlgoliaConfiguration,
  Breadcrumb,
  Filters,
  FiltersModal,
  GridLayoutControls,
  ItemCount,
  NoResultsBoundary,
} from './components'
import { ALGOLIA_INDEX_LIST } from './constants'
import { GridLayoutProvider } from './hooks'
import { createInfiniteHitsSessionStorageCustomCache } from './infinite-hits-cache'
import { NavigationBar } from './navigation-bar'
import { PageHeader } from './page-header'
import { getStateMapping } from './router'
import { algoliaIndexNameResolver } from './utils'
import { ContentstackRichText as ContentstackRichTextProps } from '../../../../../_shared/types/contentstack'
import useContentstack from '../hooks/useContentstack'
import { ContentstackRichText } from '../contentstack'

const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '',
  searchApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
}

const basePathnamesWithCacheEnabled = ['search', 'c']
let sessionStorageCache = createInfiniteHitsSessionStorageCustomCache(basePathnamesWithCacheEnabled)
const searchClient = algoliasearch(algoliaConfig.appId, algoliaConfig.searchApiKey)
const categoryIndex = searchClient.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_CATEGORY_INDEX)
const RICH_TEXT = 'rich_text'

export interface ProductListingPageProps {
  query: string
  isSearchPage?: boolean
}

export const ProductListingPage = ({ isSearchPage, query }: ProductListingPageProps) => {
  const [category, setCategory] = useState<any>()
  const [displayImages, setDisplayImages] = useState(true)
  const [filters, setFilters] = useState<string[]>([])
  const [endPageRichText, setEndPageRichText] = useState<ContentstackRichTextProps>()
  const router = useRouter()
  const { getEntryBySlug } = useContentstack()

  useEffect(() => {
    try {
      if (typeof (window as any)?.POWERREVIEWS == 'undefined') {
        let script = document.createElement('script')
        script.src = '//ui.powerreviews.com/stable/4.1/ui.js'
        script.id = 'powerReviewScript'
        document.head.appendChild(script)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    const appliedFilters = Object.keys(router?.query || [])
    setFilters(appliedFilters.filter((filter) => filter !== 'slug' && filter !== 'sort'))
  }, [router.query])

  useEffect(() => {
    if (query) {
      findEndPageData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const findEndPageData = async () => {
    const response = await getEntryBySlug(RICH_TEXT, query)
    setEndPageRichText(response)
  }

  useEffect(() => {
    for (let i = 0; i < category?.localNavigation.length; i++) {
      if (!category?.localNavigation[i].imageUrl) {
        setDisplayImages(false)
        break
      } else if (i === category?.localNavigation.length - 1) {
        setDisplayImages(true)
      }
    }
  }, [category?.localNavigation])

  useEffect(() => {
    findCategory({ categoryId: query })
  }, [query])

  const findCategory = async ({ categoryId }) => {
    try {
      const results = await categoryIndex.search('', {
        filters: `objectID:${categoryId}`,
      })

      if (results?.hits?.length > 0) {
        setCategory(results.hits[0])
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { formatMessage } = useFormat({ name: 'common' })
  const algoliaIndex = algoliaIndexNameResolver({ locale: router.locale ?? router.defaultLocale })

  const algoliaStateMapping = useMemo(() => {
    return getStateMapping({
      indexName: algoliaIndex,
      locale: router.locale ?? router.defaultLocale,
      isSearchPage,
      indexNameResolver: algoliaIndexNameResolver,
      ignorePaging: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale, algoliaIndex, isSearchPage, getStateMapping])

  return (
    <Container
      aria-live="polite"
      aria-busy={true}
      maxW="100rem"
      minH={['auto', 'auto', 'container.sm']}
      px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}
      my={[4, 6, 16]}
    >
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndex}
        routing={{
          router: createInstantSearchRouterNext({ singletonRouter: NextRouter }),
          stateMapping: algoliaStateMapping,
        }}
      >
        <GridLayoutProvider>
          <AlgoliaConfiguration query={query} isSearchPage={isSearchPage} />

          <NoResultsBoundary catchOn="unfiltered-search-only" isSearchPage={isSearchPage}>
            <Box mt={{ base: 6, lg: 16 }} mb={{ base: 2, lg: 3 }}>
              <Breadcrumb query={query} isSearchPage={isSearchPage} items={category?.breadcrumbs} />
            </Box>

            {query && (
              <Heading
                as="h1"
                fontSize={{ base: 'xl', lg: 'xxxxl' }}
                mb={{ base: 9, lg: 'xl' }}
                fontWeight="extrabold"
                textTransform={isSearchPage ? 'none' : 'capitalize'}
              >
                {isSearchPage
                  ? formatMessage({
                      id: 'category.search.resultsFor',
                      values: { query },
                    })
                  : formatMessage({ id: 'category.search.shop', values: { query } })}
              </Heading>
            )}

            <PageHeader query={query} />
            <NavigationBar query={query} displayImages />

            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="stretch">
              <Box flexBasis={72} flexShrink={0} display={{ base: 'none', lg: 'initial' }}>
                <Text as="h2" textStyle={'heading-desktop-300'} mb={3}>
                  {formatMessage({ id: 'category.filters.refineBy' })}
                </Text>
                <Filters />
              </Box>

              <Flex direction="column" w="full" ml={{ base: 0, lg: 6 }} mt={{ base: 4, lg: 0 }}>
                <Flex align="center" alignItems="end">
                  <ItemCount infiniteHitsProps={{ cache: sessionStorageCache }} />
                  <Spacer />
                  <GridLayoutControls />

                  <Box ml={8} display={{ base: 'none', lg: 'initial' }}>
                    <SortBy items={ALGOLIA_INDEX_LIST} indexNameResolver={algoliaIndexNameResolver} />
                  </Box>
                </Flex>

                <Flex id="mobileView" mt={5} display={{ base: 'flex', lg: 'none' }}>
                  <Flex w="50%" direction="column" px={2} pl={0}>
                    <FiltersModal infiniteHitsProps={{ cache: sessionStorageCache }} />
                  </Flex>
                  <Box w="50%" px={2}>
                    <SortBy items={ALGOLIA_INDEX_LIST} indexNameResolver={algoliaIndexNameResolver} />
                  </Box>
                </Flex>

                <CurrentRefinements />

                <Box mt={{ base: 8, lg: 10 }} mb={6}>
                  <NoResultsBoundary catchOn="filtered-search-only" isSearchPage={isSearchPage}>
                    <InfiniteHits cache={sessionStorageCache} />
                  </NoResultsBoundary>
                </Box>
              </Flex>
            </Flex>
            {endPageRichText && !filters?.length && (
              <ContentstackRichText {...endPageRichText} desktop_container_width="900px" />
            )}
          </NoResultsBoundary>
        </GridLayoutProvider>
      </InstantSearch>
    </Container>
  )
}
