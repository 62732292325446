import { Box, Center, useBreakpointValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { GOOGLE_SIGNIN_ACCOUNT_ID } from '../general'
declare const google
const GoogleSignInSignUp = ({ buttonText, handleLogin }) => {
  const [isGoogleSDKLoaded, setIsGoogleSDKLoaded] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })
  useEffect(() => {
    const win: any = window
    const loadGoogleSDK = () => {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.id = 'google-client-script'
      script.onload = () => setIsGoogleSDKLoaded(true)
      document.body.appendChild(script)
    }
    setTimeout(() => {
      loadGoogleSDK()
    }, 500)

    return () => {
      win?.google?.accounts?.id?.cancel()
      document?.getElementById('google-client-script')?.remove()
    }
  }, [])

  useEffect(() => {
    if (isGoogleSDKLoaded) {
      initGsiClient('googleSignInBtn', buttonText, handleLogin)
    }
  }, [isGoogleSDKLoaded])

  const initGsiClient = (btnId, text, callback = (googleUser) => {}) => {
    google?.accounts?.id?.initialize({
      client_id: GOOGLE_SIGNIN_ACCOUNT_ID,
      callback: callback,
    })
    google?.accounts?.id?.prompt()

    google.accounts.id.renderButton(document.getElementById(btnId), {
      width: isMobile ? '300px' : '800px',
      height: '80px',
      longtitle: true,
      size: 'large',
      text: text,
      theme: 'outline',
    })
  }

  return (
    <Center>
      <Box>
        <div
          id="googleSignInBtn"
          aria-label="Sign in with google"
          className="g-signin2 content"
          data-onsuccess="onSignIn"
          data-theme="dark"
        ></div>
      </Box>
    </Center>
  )
}

export default GoogleSignInSignUp
