import React, { createContext, useContext } from 'react'
import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react'

export interface ComposableContextInterface {
  cartDrawer: UseDisclosureReturn
  megaDrawer: UseDisclosureReturn
  accountDrawer: UseDisclosureReturn
  path: {
    getHome: () => string
    getPDP: (params: { slug: string }) => string
    getPLP: (params: { slug: string }) => string
  }
}

const ComposableContext = createContext<ComposableContextInterface | undefined>(undefined)

export type ComposableProviderProps = Partial<ComposableContextInterface> & {
  children: JSX.Element | JSX.Element[]
}

export const ComposableProvider = ({ children }: ComposableProviderProps) => {
  const cartDrawer = useDisclosure()
  const megaDrawer = useDisclosure()
  const accountDrawer = useDisclosure()

  const path = React.useRef<ComposableContextInterface['path']>({
    getHome: () => '/',
    getPDP: (params) => `/p/${params}`,
    getPLP: (params) => `/c/${params}`,
  })

  return (
    <ComposableContext.Provider
      value={{
        cartDrawer,
        megaDrawer,
        accountDrawer,
        path: path.current,
      }}
    >
      {children}
    </ComposableContext.Provider>
  )
}

export const useComposable = () => {
  const context = useContext(ComposableContext)
  if (context === undefined) {
    throw new Error('useComposable must be used within a ComposableProvider')
  }
  return context
}
