import { useEffect, useRef } from 'react'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import { availableFonts } from 'composable/chakra/theme/foundations/typography'
import {
  applyMobileSpecificTransformations,
  revertMobileSpecificTransformations,
  cleanUpStylesAfterWindowResize,
  extractTableData,
  reformatTableData,
  generateNewTableHtml,
} from './RichText-Utils/helpers'
import { DEFAULT_CSS_MOBILE, DEFAULT_CSS_DESKTOP } from './RichText-Utils/richtext-styles'
import { promoClickUtagLinkEvent } from 'helpers/tealiumHelper'

const RichTextFonts = {
  Antonio: availableFonts.secondary,
  'Libre Franklin': availableFonts.primary,
}

const applyCustomStyles = (htmlContent: string) => {
  const customTagRegex =
    /&lt;customize(\s+color="([^"]+)")?(\s+fontFamily="([^"]+)")?(\s+fontSize="([^"]+)")?&gt;([^<]+)&lt;\/customize&gt;/g

  let styledContent = htmlContent.replace(customTagRegex, (_, __, color, ___, fontFamily, ____, fontSize, text) => {
    const decodedText = text.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    let styles = ''

    if (color) styles += `color: ${color}; `
    if (fontFamily) styles += `font-family: ${RichTextFonts[fontFamily] ?? availableFonts.primary}; `
    if (fontSize) styles += `font-size: ${fontSize}; `

    return `<span style="${styles}">${decodedText}</span>`
  })

  const tableHeaderRegex = /<th dir="ltr">(.*?)<\/th>/gs
  styledContent = styledContent.replace(tableHeaderRegex, (_, content) => {
    return `<td>${content}</td>`
  })

  const figureRegex = /<figure class="(.*?)">(.*?)<\/figure>/gs
  styledContent = styledContent.replace(figureRegex, (_, className, content) => {
    return `<figure class="${className}">${content}</figure>`
  })

  const listRegex = /<ol>(.*?)<\/ol>|<ul>(.*?)<\/ul>/gs
  styledContent = styledContent.replace(listRegex, (_, olContent, ulContent) => {
    if (olContent) {
      return `<ol>${olContent.replace(/<li dir="ltr">(.*?)<\/li>/gs, '<li>$1</li>')}</ol>`
    }
    if (ulContent) {
      return `<ul>${ulContent.replace(/<li dir="ltr">(.*?)<\/li>/gs, '<li>$1</li>')}</ul>`
    }
    return _
  })

  const hrRegex = /<div data-type='hr'.*?>.*?<\/div>/g
  styledContent = styledContent.replace(hrRegex, () => {
    return `<hr/>`
  })

  return styledContent
}

const mobileTableParse = (styledContent) => {
  const tableRegex = /<table>(.*?)<\/table>/gs
  let newContent = styledContent

  const tables = [...styledContent.matchAll(tableRegex)]
  tables.forEach((tableMatch) => {
    const tableHtml = tableMatch[0]
    const tableData = extractTableData(tableHtml)
    const { headers, formattedData } = reformatTableData(tableData)
    const newTableHtml = generateNewTableHtml(headers, formattedData)

    newContent = newContent.replace(tableHtml, newTableHtml)
  })

  return newContent
}

export const ComponentRichText = ({
  children,
  headings_font_family,
  analyticsTrackingData,
}: {
  children: string
  headings_font_family?: string
  analyticsTrackingData?: string
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const tableHeadersRef = useRef(null)
  const tableRowsRef = useRef(null)
  const headerFontFamily = headings_font_family === 'antonio' ? 'Antonio' : 'Libre Franklin'

  useEffect(() => {
    const tablesWithHeaders = document.querySelectorAll('table:has(thead th)')

    tableHeadersRef.current = Array.from(tablesWithHeaders)
      .map((table) => Array.from(table.querySelectorAll('thead th')).map((th) => th.textContent))
      .flat()

    const headers = tableHeadersRef.current ?? []

    tableRowsRef.current = Array.from(tablesWithHeaders).flatMap((table) =>
      Array.from(table.querySelectorAll('tbody tr')),
    )

    if (isMobile) {
      applyMobileSpecificTransformations(tableRowsRef.current, headers)
    } else {
      revertMobileSpecificTransformations(tableRowsRef.current)
    }

    return () => {
      cleanUpStylesAfterWindowResize(tableRowsRef.current, isMobile)
    }
  }, [isMobile])

  if (isMobile && tableHeadersRef.current && tableRowsRef.current) {
    applyMobileSpecificTransformations(tableRowsRef.current, tableHeadersRef.current)
  }

  if (!children) return null

  let styledHtmlContent = applyCustomStyles(children)
  if (isMobile) {
    styledHtmlContent = mobileTableParse(styledHtmlContent)
  }
  const handleClick = (e) => {
    if (e.target?.attributes?.href) promoClickUtagLinkEvent(analyticsTrackingData)
  }
  return (
    <Box
      data-promotion-name={analyticsTrackingData}
      maxW="900px"
      m="auto"
      height="fit-content"
      __css={{
        ...(isMobile ? DEFAULT_CSS_MOBILE : DEFAULT_CSS_DESKTOP),
        'h1, h2, h3, h4, h5, h6': {
          fontFamily: headerFontFamily,
        },
      }}
      onClick={handleClick}
      dangerouslySetInnerHTML={{
        __html: styledHtmlContent,
      }}
    />
  )
}
