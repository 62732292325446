import { Variant } from '@Types/product/Variant'
import {
  LegacyHorizontalProductCardEditable,
  LegacyHorizontalProductCardEditableProps,
} from './legacy-horizontal-product-card-editable'
import {
  LegacyHorizontalProductCardOutOfStockOrNotEnough,
  LegacyHorizontalProductCardOutOfStockOrNotEnoughProps,
} from './legacy-horizontal-product-card-out-of-stock-or-not-enough'
import {
  LegacyHorizontalProductCardReadOnly,
  LegacyHorizontalProductCardReadOnlyProps,
} from './legacy-horizontal-product-card-read-only'

export type LegacyHorizontalProductCardProps = (
  | LegacyHorizontalProductCardEditableProps
  | LegacyHorizontalProductCardReadOnlyProps
  | LegacyHorizontalProductCardOutOfStockOrNotEnoughProps
) & {
  editable?: boolean
  variant?: Variant
  displayOutOfStock: boolean
  masterProductData?: any
}

export const LegacyHorizontalProductCard = (props: LegacyHorizontalProductCardProps) => {
  const { editable, variant, quantity, displayOutOfStock = false } = props
  const isInStock = (variant?.isOnStock && variant?.availableQuantity >= quantity) ?? false

  return !isInStock && displayOutOfStock ? (
    <LegacyHorizontalProductCardOutOfStockOrNotEnough {...props} />
  ) : editable ? (
    <LegacyHorizontalProductCardEditable {...props} />
  ) : (
    <LegacyHorizontalProductCardReadOnly {...props} />
  )
}
