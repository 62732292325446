import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertDescriptionProps,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  CloseButton,
  CloseButtonProps,
  Flex,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

interface AlertBoxProps {
  alertDescriptionProps?: AlertDescriptionProps
  closeButtonProps?: CloseButtonProps
  description?: string | JSX.Element
  onClick?: () => void
  rootProps?: AlertProps
  title?: string
}

export const AlertBox = (props: AlertBoxProps) => {
  const { alertDescriptionProps, closeButtonProps, description, onClick, rootProps, title } = props

  const bgValue = useColorModeValue('info.100', 'gray.700')
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  return isVisible ? (
    <Alert
      status="info"
      mt={{ base: 4, md: 6 }}
      borderRadius={'6px'}
      bg={bgValue}
      display="flex"
      justifyContent="space-between"
      {...rootProps}
    >
      <Flex>
        {rootProps?.status === 'error' ? (
          <WarningTwoIcon aria-hidden={'true'} w={6} h={6} color={'danger.600'} mr={3} />
        ) : (
          <AlertIcon aria-hidden={'true'} w={6} h={6} mr={3} />
        )}
        <Box>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription color={'text'} onClick={() => onClick?.()} {...alertDescriptionProps}>
            {description}
          </AlertDescription>
        </Box>
      </Flex>
      <CloseButton alignSelf="center" position="relative" onClick={onClose} {...closeButtonProps} />
    </Alert>
  ) : null
}
