import {
  Center,
  CenterProps,
  Flex,
  FlexProps,
  FormControl,
  FormControlProps,
  FormLabel,
  IconButton,
  IconButtonProps,
  Text,
  useControllableState,
  UseControllableStateProps,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FiMinus, FiPlus } from 'react-icons/fi'

export interface QuantityPickerProps extends UseControllableStateProps<number> {
  isDisabled?: boolean
  max?: number
  min?: number
  rootProps?: FormControlProps
  isLoading?: boolean
  hideLabel?: boolean
  label?: string
  buttonProps?: Partial<IconButtonProps>
  quantityProps?: Partial<CenterProps>
  wrapperProps?: Partial<FlexProps>
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const {
    isDisabled = false,
    min = 0,
    max,
    rootProps,
    isLoading,
    hideLabel,
    label = 'Quantity',
    buttonProps,
    quantityProps,
    wrapperProps,
    ...rest
  } = props

  const [value, setValue] = useControllableState(rest)
  const handleDecrement = () => setValue(value === min ? value : value - 1)
  const handleIncrement = () => setValue(value === max ? value : value + 1)

  return (
    <FormControl aria-label={`${label} selected is ${value}`} {...rootProps}>
      {hideLabel ? (
        <VisuallyHidden>
          <FormLabel>{label}</FormLabel>
        </VisuallyHidden>
      ) : (
        <FormLabel fontSize="sm" fontWeight="medium">
          {label}
        </FormLabel>
      )}

      {!isDisabled ? (
        <Flex borderRadius="base" borderWidth="1px" justifyContent="space-between" {...wrapperProps}>
          <QuantityPickerButton
            onClick={handleDecrement}
            icon={<FiMinus />}
            isDisabled={value <= min || isLoading}
            aria-label="Decrement"
            {...buttonProps}
          />
          <Center {...quantityProps}>
            <Text as="span" userSelect="none">
              {value}
            </Text>
          </Center>
          <QuantityPickerButton
            onClick={handleIncrement}
            icon={<FiPlus />}
            isDisabled={value >= max || isLoading}
            aria-label="Increment"
            {...buttonProps}
          />
        </Flex>
      ) : (
        <Flex
          borderRadius="base"
          borderWidth="1px"
          justifyContent="space-between"
          {...wrapperProps}
          border="1px solid var(--core-shading-shading-500, #9DA2A8)"
          bgColor="var(--semantic-surface-muted, #F3F4F6)"
          color="var(--semantic-text-muted, #76797E)"
        >
          <QuantityPickerButton icon={<FiMinus />} isDisabled aria-label="Decrement" {...buttonProps} />
          <Center {...quantityProps}>
            <Text as="span" userSelect="none">
              {value}
            </Text>
          </Center>
          <QuantityPickerButton icon={<FiPlus />} isDisabled aria-label="Increment" {...buttonProps} />
        </Flex>
      )}
    </FormControl>
  )
}

const QuantityPickerButton = (props: IconButtonProps) => (
  <IconButton size="xs" fontSize="md" variant="ghost" color={'text-primary'} {...props} />
)
