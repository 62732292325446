import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { Address } from '@Types/account/Address'
import { Order } from '@Types/cart/Order'
import {
  ExperianAddressType,
  convertShippingToCTAddress,
  getCartSummaryAmounts,
  getCheckoutAddress,
  isBillingDifferentThanShipping,
} from 'composable'
import useLocalStorage from 'composable/components/hooks/useLocalStorage'
import { useAccount } from 'frontastic'
import { useCart } from 'frontastic/provider/frontastic'
import { usePaymentHandler } from './use-payment-handler'
import {
  CheckoutContextInterface,
  CheckoutStateType,
  CheckoutValidationFn,
  CheckoutValidationHandler,
  CheckoutValidationList,
  StripeData,
} from '../../types'
import { useAtgLegacyCart } from 'frontastic/contexts'

const CheckoutContext = createContext<CheckoutContextInterface | undefined>(undefined)

const checkoutStateInitial = {
  config: {
    billingIsShipping: true,
    saveNewAddressToMyAccount: false,
  },
  customer: {
    email: '',
  },
  shipping_address: {
    id: 'new',
    additional_address_info: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    street_name: '',
    street_number: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    additional_street_info: '',
  },
  billing_address: {
    id: 'new',
    additional_address_info: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    street_name: '',
    street_number: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    additional_street_info: '',
  },
  flow: {},
  shipping_method: {
    id: '',
    name: '',
    description: '',
    zoneRates: [],
    custom: null,
  },
}

export interface CheckoutProviderProps {
  children: JSX.Element
}

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const { formattedCart: cart } = useAtgLegacyCart()
  const { account } = useAccount()
  // const { createPaymentIntent } = useStripe()
  const paymentHandler = usePaymentHandler()

  const [isLoading, __setIsLoading] = useState(false)
  const [isLoadingStep1, setIsLoadingStep1] = useState(false)
  const [validationResults, setValidationResults] = useState<Record<string, boolean>>({})
  const [validationList, setValidationList] = useState<CheckoutValidationList>({})
  const [checkoutState, setCheckoutState] = useState<CheckoutStateType>(checkoutStateInitial)
  const [stripeData, setStripeData] = useState<StripeData>()
  const [order, setOrder] = useState<Order>()
  const validationListRef = useRef(validationList)

  const [addressConfirmationModal, setAddressConfirmationModal] = useLocalStorage('addressConfirmationModal', false)

  const [addressConfirmationDecision, setAddressConfirmationDecision] = useLocalStorage(
    'addressConfirmationDecision',
    false,
  )

  const [recommendedAddress, setRecommendedAddress] = useState<ExperianAddressType | false>(false)

  const [savedShippingAddressSelected, setSavedShippingAddressSelected] = useState<Address['addressId']>(
    cart?.shippingAddress?.addressId ??
      account?.addresses?.find((address) => address.isDefaultShippingAddress)?.addressId ??
      'new',
  )

  validationListRef.current = validationList

  const validationHandler = useRef<CheckoutValidationHandler>({
    register: (key: string, validation: CheckoutValidationFn) => {
      setValidationList((list) => {
        return {
          ...list,
          [key]: validation,
        }
      })
    },
    unregister: (key: string) => {
      setValidationList((list) => {
        const _list = { ...list }
        delete _list[key]
        return _list
      })
    },
    run: async (key?: string) => {
      if (key) {
        const isValid = await validationListRef.current[key]?.()
        return isValid ?? true
      }

      let isValid = true
      const keys = Object.keys(validationListRef.current)

      for (const key of keys) {
        const validationFn = validationListRef.current[key]
        if (validationFn) {
          isValid = isValid && (await validationFn())
        }
      }

      return isValid
    },
    list: validationList,
  })

  const savedShippingAddressesHandler = {
    setSelected: (id: string) => setSavedShippingAddressSelected(id),
    clearSelection: () => setSavedShippingAddressSelected(''),
    selected: savedShippingAddressSelected
      ? account?.addresses?.find((address) => address.addressId === savedShippingAddressSelected)
      : undefined,
  }

  // Keep checkoutState in sync with the customer auth state
  useEffect(() => {
    setCheckoutState((state) => {
      return {
        ...state,
        customer: {
          ...state.customer,
          id: account?.accountId ?? '',
          email: account?.email ?? '',
          name: account?.firstName && account?.lastName ? `${account.firstName} ${account.lastName}` : '',
        },
      }
    })
  }, [account, setCheckoutState])
  // Keep checkoutState.customer in sync with the cart
  useEffect(() => {
    if (cart?.email) {
      setCheckoutState((state) => {
        return {
          ...state,
          customer: {
            ...state.customer,
            email: cart?.email ?? '',
          },
        }
      })
    }
  }, [cart?.email])
  // Keep checkoutState.shipping_address in sync with the cart
  useEffect(() => {
    if (cart?.shippingAddress?.addressId) {
      setSavedShippingAddressSelected(cart?.shippingAddress?.addressId)
      setCheckoutState((state) => {
        return {
          ...state,
          shipping_address: getCheckoutAddress(cart?.shippingAddress),
        }
      })
    }
    if (cart?.shippingAddress) {
      setCheckoutState((state) => {
        return {
          ...state,
          shipping_address: getCheckoutAddress(cart?.shippingAddress),
        }
      })
    }
  }, [cart?.shippingAddress])

  useEffect(() => {
    if (cart?.billingAddress) {
      const isBillingAndShippingDifferent = isBillingDifferentThanShipping(cart?.shippingAddress, cart?.billingAddress)

      if (isBillingAndShippingDifferent) {
        checkoutState.config.billingIsShipping = false
      }
      setCheckoutState((state) => {
        return {
          ...state,
          billing_address: getCheckoutAddress(
            isBillingAndShippingDifferent ? cart?.billingAddress : cart?.shippingAddress,
          ),
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.billingAddress])

  // Context value
  const checkoutContext: CheckoutContextInterface = {
    checkoutState,
    checkoutStateInitial,
    setCheckoutState,
    validation: validationHandler.current,
    validationResults,
    setValidationResults,
    isLoading,
    __setIsLoading,
    savedShippingAddresses: savedShippingAddressesHandler,
    payment: {
      stripeData,
      setStripeData,
    },
    paymentHandler,
    isLoadingStep1,
    setIsLoadingStep1,
    // __createPaymentIntent: createPaymentIntent,
    order,
    setOrder,
    addressConfirmationModal,
    setAddressConfirmationModal,
    addressConfirmationDecision,
    setAddressConfirmationDecision,
    recommendedAddress,
    setRecommendedAddress,
  }

  return <CheckoutContext.Provider value={checkoutContext}>{children}</CheckoutContext.Provider>
}

// Function for handling stripe payment
const handleStripePayment = async (context, cart, addPaymentByInvoice) => {
  const { total } = getCartSummaryAmounts(cart)

  const { data: paymentIntent } = await context.__createPaymentIntent({
    confirm: true,
    return_url: `${window.location.origin}/cart`,
    customer: context.payment.stripeData?.customerId,
    payment_method: context.payment.stripeData.paymentMethodId,
    amount: total,
    currency: 'USD', // TODO: get from cart
  })

  const transactions = paymentIntent.charges.data?.map((charge) => {
    return {
      timestamp: new Date(paymentIntent.created * 1000).toISOString(),
      type: 'Charge',
      state: charge.status === 'succeeded' ? 'Success' : 'Pending',
      amount: {
        currencyCode: charge.currency?.toUpperCase(),
        centAmount: charge.amount,
      },
      interactionId: charge.id,
    }
  })

  await addPaymentByInvoice({
    paymentId: paymentIntent.id,
    paymentStatus: paymentIntent.status === 'succeeded' ? 'paid' : 'pending',
    paymentMethod: 'card',
    amountPlanned: {
      currencyCode: paymentIntent.currency?.toUpperCase(),
      centAmount: paymentIntent.amount ?? 0,
      fractionDigits: 2,
    },
    paymentProvider: 'stripe',
    transactions: transactions,
    id: cart.cartId,
  })
}

export const useCheckout = () => {
  const context = useContext(CheckoutContext)
  if (context === undefined) {
    throw new Error('useCheckout must be used within a CheckoutProvider')
  }
  const { account, addAddress } = useAccount()
  const { data: cart } = useCart()
  const { checkout, addPaymentByInvoice } = useCart()

  const placeOrder = async () => {
    const { __setIsLoading, paymentHandler, setOrder } = context
    try {
      __setIsLoading(true)
      if (account && context.checkoutState.config.saveNewAddressToMyAccount) {
        await addAddress(convertShippingToCTAddress(context.checkoutState.shipping_address))
      }

      // if (paymentHandler.selected?.key === PAYMENT_METHOD_STRIPE) {
      //   await handleStripePayment(context, cart, addPaymentByInvoice)
      // }
      const { order } = await checkout()
      setOrder(order)
      return order
    } catch (e) {
      __setIsLoading(false)
      throw e
    }
  }

  return {
    ...context,
    placeOrder,
  }
}
