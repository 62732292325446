import React, { createContext, useState, useEffect } from 'react'
import atgAxiosInstance from '../../../helpers/axios-atg-helper'
import { utagLink } from '../../../helpers/tealiumHelper'

const AtgUserContext = createContext(undefined)

export const AtgUserProvider = ({ children }) => {
  const [userSessionData, setUserSessionData] = useState(null)
  const [userDetails, setUserDetails] = useState(null)

  useEffect(() => {
    fetchUserSessionData()
    fetchUserData()
  }, [])

  const fetchUserData = async () => {
    try {
      const response = await atgAxiosInstance.get('currentUser/dxlUser', {
        withCredentials: true,
      })
      setUserDetails(response.data)
    } catch (error) {
      console.error('Error fetching user session details:', error)
    }
  }

  const fetchUserSessionData = async () => {
    try {
      const response = await atgAxiosInstance.get('currentUser/header', {
        withCredentials: true,
      })
      setUserSessionData(response.data)
      if (userDetails?.profile?.firstName) {
        const utagData = [
          'link',
          {
            tealium_event: 'user_detected',
            crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : '',
            customer_id: userDetails?.profile?.['customerId'] || '',
            user_loginstatus: true,
            signin_source: userSessionData?.socialLogin ? userSessionData?.socialLoginProvider : 'site',
          },
        ]

        utagLink(utagData)
      }
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  return (
    <AtgUserContext.Provider value={{ userDetails, userSessionData, fetchUserData, fetchUserSessionData }}>
      {children}
    </AtgUserContext.Provider>
  )
}

export const useAtgUser = () => {
  const userDetails = React.useContext(AtgUserContext)

  if (!userDetails) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return userDetails
}
