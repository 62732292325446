import { useState } from 'react'
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Address } from '@Types/account/Address'
import { getCheckoutAddress } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { ShippingAddressForm } from './Forms/shipping-address-form'
import { FormStatus, SavedShippingAddress } from '../../types'
import { useCheckout } from '../checkout-provider'

export interface ShippingAddressOptionProps {
  address?: Address
  isNewAddress?: boolean
  onEdit?: () => any
  onSelect: () => void
  title: string
}

export const ShippingAddressOption = ({
  address,
  isNewAddress = false,
  onEdit,
  onSelect,
  title,
}: ShippingAddressOptionProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const [status, setStatus] = useState<FormStatus>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { validation, savedShippingAddresses, addressConfirmationModal } = useCheckout()

  const content = {
    title: formatMessage({
      id: 'checkout.shippingAddressForm.title.editShippingAddress',
    }),
    button: {
      cancel: formatMessage({ id: 'action.cancel' }),
      delete: formatMessage({ id: 'action.delete' }),
      edit: formatMessage({ id: 'action.edit' }),
      submit: formatMessage({ id: 'action.update' }),
    },
    alert: {
      error: formatMessage({ id: 'account.dashboard.submit.error' }),
      success: formatMessage({ id: 'account.dashboard.submit.success' }),
    },
  }

  const StatusAlert = ({ status, alertProps }: { status: FormStatus; alertProps?: AlertProps }) => {
    if (status === undefined) <></>

    return status === 'success' ? (
      <Alert status={'success'} borderRadius={'6px'} mt={'6px'} p={'6px 12px'} {...alertProps}>
        {content.alert.success}
      </Alert>
    ) : (
      <Alert status={'error'} borderRadius={'6px'} mt={'6px'} p={'6px 12px'} {...alertProps}>
        {content.alert.error}
      </Alert>
    )
  }

  return (
    <Box>
      {address && (
        <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <HStack justifyContent={'space-between'}>
                <Text as={'h3'} textStyle={'heading-desktop-200'}>
                  {content.title}
                </Text>
                <ModalCloseButton size={'md'} fontSize={'sm'} top={3} right={3} />
              </HStack>
            </ModalHeader>
            <ModalBody pb={6}>
              {status && <StatusAlert status={status} alertProps={{ mb: 3, p: 3 }} />}
              <ShippingAddressForm
                addressConfirmationModal={addressConfirmationModal}
                showSubmitButton
                setStatus={setStatus}
                initialValues={getCheckoutAddress(address)}
                onClose={onClose}
                onChange={({ isValid }) => {
                  if (!isValid) return
                  validation.run('shippingAddressForm')
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Flex alignItems="flex-start" cursor="pointer">
        <Flex flex="1" alignItems="flex-start" onClick={onSelect}>
          <Box>
            <Radio
              isChecked={savedShippingAddresses.selected?.addressId === address?.addressId}
              value={isNewAddress ? 'new' : address?.addressId}
              size="lg"
            />
          </Box>
          <Box flex="1" textAlign="left" fontSize="sm" px={2}>
            <Text fontWeight="extrabold">{title}</Text>
            {address && !isNewAddress && (
              <>
                <Text>{`${address.streetNumber} ${address.streetName}`}</Text>
                {address.city && address.state && address.postalCode && (
                  <Text>{`${address.city}, ${address.state}, ${address.postalCode}`}</Text>
                )}
                <Text>{address.phone}</Text>
                {status === 'success' && <StatusAlert status={status} />}
              </>
            )}
          </Box>
        </Flex>
        {onEdit && (
          <Button
            size={'sm'}
            color={'text'}
            variant={'ghost'}
            textDecoration={'underline'}
            onClick={() => {
              setStatus(undefined)
              onOpen()
              onEdit()
            }}
          >
            {content.button.edit}
          </Button>
        )}
      </Flex>
    </Box>
  )
}
