import React, { FC, useEffect, useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Box, Spinner } from '@chakra-ui/react'
import AtgLoginPage from 'composable/components/account/atg-login-page'
import SignUpForm, { SignUpFormFields } from 'composable/components/account/signup-form'
import { useFormat } from 'helpers/hooks/useFormat'
import atgAxiosInstance from 'helpers/axios-atg-helper'

interface Props {
  isOpen: boolean
  openSignInModal: () => void
  onOpen?: () => void
  onClose?: () => void
}

const SignUpModal: FC<Props> = ({ isOpen, openSignInModal, onClose, onOpen }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [user, setUser] = useState<any>({})

  const handleSignInModalToggle = () => {
    onClose?.()
    openSignInModal()
  }

  const scrollToError = () => {
    const errorElement = document.getElementById('#error')
    errorElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const handleRegister = async (values: SignUpFormFields) => {
    setIsLoading(true)
    const {
      address,
      city,
      state,
      zipcode,
      address2,
      birthMonth,
      confirmPassword,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      country,
      eventsEmail,
      eventsUSMail,
      rewardsClubEmails,
    } = values
    const searchString = `${address}||${city}||${state}||${zipcode}`
    const params = {
      country,
      addLayout: 'Cmale2',
      searchString,
      promptSet: 'Default',
    }
    try {
      const res = await atgAxiosInstance.post('qas/adderessSearch', params)
      const data = res.data
      const resultData = {
        verifylevel: data.verifylevel,
        address: data.addrLineItem,
      }

      const createAccountParams = {
        profile: {
          email,
          firstName,
          lastName,
          birthMonth,
        },
        credentials: {
          userId: email,
          password,
          confirmPassword,
        },
        addressList: {
          firstName,
          lastName,
          addressLine1: address,
          addressLine2: address2,
          city,
          state,
          postalCode: zipcode,
          phone: phoneNumber,
          addressType: getAddressType(values),
          addressValidated: resultData.verifylevel === 'Verified' && isValidZipCode(zipcode),
          useAddrAsEntered: false,
        },
        communicationPreferences: {
          dxlMail: eventsEmail,
          dxlEmail: eventsUSMail,
          rewardsProgram: rewardsClubEmails,
          cmMail: false,
          rcMail: false,
          lxlMail: false,
          lxlEmail: false,
          cmEmail: false,
          rcEmail: false,
        },
      }

      const response = await atgAxiosInstance.post('currentUser/create', removeEmptyProperties(createAccountParams))
      const result = response.data
      setUser(result)
      onClose?.()
      const url = '/myaccount/rewards/benefits'
      window.location.href = url
    } catch (error) {
      setError(error?.response?.data?.title)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const isValidZipCode = (zipcode) => {
    var regex = /^\d{5}-\d{4}$/
    return regex.test(zipcode)
  }

  const removeEmptyProperties = (obj) => {
    const newObj = {}
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        if (typeof obj[key] === 'object') {
          const nestedObj = removeEmptyProperties(obj[key])
          if (Object.keys(nestedObj).length !== 0) {
            newObj[key] = nestedObj
          }
        } else {
          newObj[key] = obj[key]
        }
      }
    }
    return newObj
  }

  const onSubmit = (data: SignUpFormFields) => {
    handleRegister(data)
  }

  const getAddressType = (address: any) => {
    let formType = 'US'
    if (address && address.address && address.city && address.state) {
      const city = address.city.toLowerCase()
      const state = address.state
      let addressLine1 = address.address.replace(/[^A-Z0-9]+/gi, '')
      addressLine1 = addressLine1.toLowerCase()
      let addressLine2 = address.address2.replace(/[^A-Z0-9]+/gi, '')
      addressLine2 = addressLine2.toLowerCase()

      if (
        (city === 'apo' || city === 'fpo' || city === 'dpo') &&
        (state === 'AA' || state === 'AP' || state === 'AE')
      ) {
        formType = 'APO/FPO/DPO'
      } else if (addressLine1.indexOf('pobox') !== -1 || addressLine2.indexOf('pobox') !== -1) {
        formType = 'POBox'
      } else {
        formType = 'US'
      }
    }

    return formType
  }

  useEffect(() => {
    let timer
    if (error) {
      scrollToError()
      timer = setTimeout(() => {
        setError('')
      }, 10000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [error])

  useEffect(() => {
    if (!isOpen) {
      setError('')
    }
  }, [isOpen])

  const handleModalClose = () => {
    onClose?.()
  }

  return (
    <Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleModalClose} size={'sm'}>
        <ModalOverlay />
        <ModalContent mx={2} my={4}>
          <ModalCloseButton
            zIndex={11111}
            size={'lg'}
            _hover={{ backgroundColor: 'none !important', opacity: '0.7' }}
          />
          <ModalBody padding={'unset'} margin={'unset'} position="relative">
            {isLoading && (
              <Box
                position="fixed"
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={9999}
                top={0}
                left={0}
                width="100%"
                height="100%"
                background={'rgba(0, 0, 0, 0.5)'}
              >
                <Spinner size="xl" />
              </Box>
            )}
            <SignUpForm
              error={error}
              handleSignInModalToggle={handleSignInModalToggle}
              handleModalToggle={handleModalClose}
              onSubmit={onSubmit}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default SignUpModal
