import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Skeleton, Stack } from '@chakra-ui/react'
import { convertBillingToCTAddress, convertShippingToCTAddress, PAYMENT_METHOD_CASH } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import Toast from 'react-hot-toast'
import { useCart } from 'frontastic'
import { Section, SectionHeader } from '../../section'
import { useCheckout } from '../checkout-provider'
import { DeliveryOptionsDetails } from '../delivery-options-details'
import { PaymentMethodSection } from './payment-method-section'

export interface Step2Props {
  onSubmit: () => void
  stripeError?: boolean
  isloading?: boolean
}

const Step2 = ({ onSubmit }: Step2Props) => {
  const intl = useFormat({ name: 'common' })

  const {
    checkoutState,
    isLoading,
    __setIsLoading,
    validation,
    validationResults,
    payment,
    paymentHandler,
    setCheckoutState,
  } = useCheckout()

  const { updateCart, data: cart } = useCart()

  const router = useRouter()

  const onCapturePaymentDetails = async () => {
    switch (paymentHandler.selected?.key) {
      case PAYMENT_METHOD_CASH:
        break
      default:
        throw new Error('Invalid payment method')
    }
  }

  useEffect(() => {
    if (router?.query.error) {
      Toast.error(
        intl.formatMessage({
          id: 'checkout.error.payment.toastMessage',
        }),
      )
    }
  }, [router?.query])

  const {
    shipping_address,
    billing_address,
    config: { billingIsShipping },
  } = checkoutState

  const formsToValidate = billingIsShipping ? [] : ['billingAddressForm']

  const formIsInvalid = formsToValidate.some((form) => !validationResults[form]!)

  const step2Complete = useMemo(() => !formIsInvalid, [formIsInvalid])

  const handleSubmit = async () => {
    if (!checkoutState.config.billingIsShipping) {
      if (!(await validation.run('billingAddressForm'))) {
        Toast.error(
          intl.formatMessage({
            id: 'checkout.validation.missingRequiredFields',
          }),
        )
        return
      }
    }

    try {
      __setIsLoading(true)
      await setBillingAddress()
      await onCapturePaymentDetails()
    } catch (err: any) {
      Toast.error(err?.message ?? 'Error trying to collect billing address or payment information')
      return
    } finally {
      __setIsLoading(false)
    }
    onSubmit()
  }

  const setBillingAddress = async () => {
    await updateCart({
      billing: convertBillingToCTAddress(billing_address),
      shipping: convertShippingToCTAddress(shipping_address),
    })
  }

  return (
    <Stack spacing={{ base: 2, lg: 6 }}>
      <Section padding={6}>
        <SectionHeader title={intl.formatMessage({ id: 'checkout.customerSection.title' })} />
        <DeliveryOptionsDetails />
      </Section>

      <Section padding={6}>
        <SectionHeader
          title={intl.formatMessage({
            id: 'checkout.success.orderDetails.paymentMethod',
          })}
        />
        <PaymentMethodSection />
      </Section>

      <Box textAlign="right" px={{ base: 4, md: 0 }}>
        <Button
          px={4}
          py={2.5}
          fontSize="base"
          w={{ base: 'full', md: 'fit-content' }}
          minW={'205px'}
          variant={'solid'}
          onClick={() => handleSubmit()}
          isDisabled={!step2Complete || isLoading}
          isLoading={isLoading}
        >
          {intl.formatMessage({ id: 'action.reviewOrder' })}
        </Button>
      </Box>
    </Stack>
  )
}

const Step2PayOffLine = function Step2PayOffLine(props: Step2Props) {
  const {
    payment,
    checkoutState: { customer },
  } = useCheckout()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  /* 
  TODO: Improve by move elements to child component stripe payment currently if stripe fails to load, the whole page fails to load and can't proceed with offline payment
  */
  if (isLoading) {
    return <Skeleton minH="300px" height="full" />
  }

  return <Step2 isloading={isLoading} {...props} />
}

export { Step2PayOffLine as Step2 }
