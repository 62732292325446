import React, { useEffect, useState } from 'react'
import { useDisclosure, Text, Icon, HStack, useBreakpointValue } from '@chakra-ui/react'
import { useIsBrowser } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoLocationOutline } from 'react-icons/io5'
import { getStore } from 'frontastic/actions/channel'
import StoreFinderDrawer from './store-finder-drawer'
import StoreFinderPopup from './store-finder-popup'
import { getCookieByName } from './utils'
import { Channel } from '@Types/channel/Channel'

interface MyStoreProps {
  viewType: string
  showMyStoreTitle: boolean
  openStoreDrawer?: any
  shippingType: any
  callBack?: Function
  pageName?: string
}

const MyStore = ({ viewType, openStoreDrawer, showMyStoreTitle, shippingType, callBack, pageName }: MyStoreProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const selectedStoreData = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedStoreData') : null
  const [selectedStore, setSelectedStore] = useState<Channel>()
  const { formatMessage } = useFormat({ name: 'common' })
  const [location, setLocation] = useState('Set Location')
  const [guestLocation, setGuestLocation] = useState(null)
  const isBrowser = useIsBrowser()

  useEffect(() => {
    let selectedStoreData = typeof localStorage !== 'undefined' ? localStorage.getItem('selectedStoreData') : null
    if (selectedStoreData) {
      setSelectedStore(JSON.parse(selectedStoreData))
    }
  }, [selectedStoreData])

  let selectedCity = ''

  useEffect(() => {
    if (selectedStore) {
      setLocation(selectedStore?.address?.city)
    } else if (guestLocation) {
      if (guestLocation && guestLocation !== null && guestLocation !== undefined) {
        let addressLocation = {
          lat: guestLocation.split('|')[1],
          lng: guestLocation.split('|')[2],
          radius: '25',
        }

        getStore(addressLocation).then((res) => {
          if (res?.results.length > 0) {
            setLocation(res?.results[0].address.city)
            const storeData = { ...res?.results[0], addressLocation }
            localStorage.setItem('selectedStoreData', JSON.stringify(storeData))
          }
        })
      }
    } else {
      selectedCity = 'Set Location'
    }
  }, [selectedStore, guestLocation])

  useEffect(() => {
    const myCookieValue = getCookieByName('GuestLocation')
    if (myCookieValue) {
      setGuestLocation(myCookieValue)
    }
  }, [openStoreDrawer])

  selectedCity = selectedStore?.address ? selectedStore?.address?.city : 'Set Location'

  const handelDrawer = () => {
    onOpen()
  }

  const setStoreLocation = (store) => {
    if (store?.address) {
      localStorage.setItem('selectedStoreData', JSON.stringify(store))
    }
  }

  const onCloseHandel = (obj) => {
    if (shippingType?.shipToStore && pageName !== 'checkout' && pageName !== 'pdp') {
      setStoreLocation(obj)
    }
    if (callBack) {
      callBack(obj)
    }
    return onClose()
  }

  return (
    <>
      {showMyStoreTitle && (
        <HStack
          maxW="container.2xl"
          m="auto"
          px={{ base: 0, lg: 0 }}
          py={0}
          spacing={0}
          bg="brand.muted"
          justifyContent={isMobile ? 'space-between' : ' '}
        >
          <HStack spacing={1} onClick={() => handelDrawer()} cursor="pointer">
            <Icon as={IoLocationOutline} boxSize={4} cursor="pointer" />
            <Text display={{ base: 'none', md: 'block', lg: 'block' }} textStyle="body-75">
              {formatMessage({ id: 'megamenu.details.myStore' })}
            </Text>
            <Text
              mt={'1px'}
              textStyle={{ base: 'heading-mobile-75', lg: 'heading-desktop-75' }}
              color={`${selectedStore === undefined && location == 'Set Location' ? '' : 'text.success'}`}
            >
              {location}
            </Text>
          </HStack>
        </HStack>
      )}
      {viewType == 'drawer' && <StoreFinderDrawer isOpen={isOpen} onClose={onCloseHandel}></StoreFinderDrawer>}

      {viewType == 'popup' && (
        <StoreFinderPopup shippingType="" isOpen={isOpen} onClose={onCloseHandel}></StoreFinderPopup>
      )}
    </>
  )
}

export default MyStore
