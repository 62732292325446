import Image from 'next/image'
import { Box, Button, Flex, Text, Tooltip, useToken } from '@chakra-ui/react'
import { IoImagesOutline } from 'react-icons/io5'
import { SwatchSelectorProps } from '../../types'
export type ProductSwatchSelectorSize = 'small' | 'large'

export const SwatchSelector = ({ option, setSelection, isDisabled, isSelected }: SwatchSelectorProps) => {
  const [textPrimary, brandSecondary] = useToken('colors', ['text.primary', 'brand.secondary'])

  const { swatch, value, colorDisplayName } = option

  return (
    <Tooltip
      placement="top"
      rounded="md"
      maxW={{ base: '90vw', sm: 'auto' }}
      mr={{ base: 2, sm: 0 }}
      hasArrow
      label={
        <Text textStyle={'body-75'} textTransform={'capitalize'}>
          {colorDisplayName ? colorDisplayName.toLocaleLowerCase() : value?.toLocaleLowerCase()}
        </Text>
      }
    >
      <Button
        key={value}
        p={0}
        minW={'auto'}
        w={12}
        h={12}
        borderRadius={'50%'}
        overflow={'hidden'}
        display={'inline-block'}
        position={'relative'}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        outline={'2px solid'}
        outlineColor={'white'}
        outlineOffset={'-4px'}
        border={'2px solid'}
        borderColor={isSelected ? 'secondary.900' : 'secondary.100'}
        backgroundColor={!swatch ? 'brand.muted' : 'transparent'}
        backgroundImage={option.swatchUrl}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          setSelection(value)
        }}
        isDisabled={isDisabled}
        _hover={{
          borderColor: isSelected ? 'secondary.900' : 'primary.300',
        }}
        _active={{
          borderColor: 'primary.400',
        }}
        _disabled={{
          borderColor: 'secondary.100',
        }}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          w="full"
          h="full"
          zIndex={2}
          bg={
            isDisabled
              ? `linear-gradient(to left top, transparent 47.75%, ${textPrimary} 49.5%, ${textPrimary} 50.5%, transparent 52.25%)`
              : undefined
          }
        />
        {option.swatchUrl ? (
          <Image
            src={option.swatchUrl}
            alt={value ?? ''}
            width={46}
            height={46}
            style={{ opacity: isDisabled ? 0.6 : 1, objectFit: 'cover', width: '100%', height: '100%' }}
          />
        ) : (
          <Flex
            role={'img'}
            aria-label={value ?? ''}
            justify={'center'}
            align={'center'}
            width={'full'}
            height={'full'}
            bgColor={'transparent'}
          >
            <IoImagesOutline size={'24px'} color={brandSecondary} opacity={isDisabled ? 0.6 : 1} />
          </Flex>
        )}
      </Button>
    </Tooltip>
  )
}
