import NextImage from 'next/image'
import { Box, Container, Grid, GridItem, HStack, Link, VStack, useBreakpointValue } from '@chakra-ui/react'
import { NextLinkNoPrefetch } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { CheckoutSteps } from './checkout-steps'

export const CheckoutHeader = ({ headerLogoUrl }: { headerLogoUrl?: string }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <Box as="header" bg={'background'} borderBottomWidth={{ base: '0', md: '1px' }}>
      <Container maxW="container.2xl" py={{ base: 4, md: 8 }} px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}>
        {isMobile ? (
          <VStack width="100%">
            <HStack width="100%" justifyContent="space-between" py={2}>
              <Link as={NextLinkNoPrefetch} href={'/'} passHref>
                <NextImage src={headerLogoUrl} alt="logo" height={25} width={50} />
              </Link>
              <Link
                as={NextLinkNoPrefetch}
                href={'/cart'}
                fontSize="sm"
                color="text"
                fontWeight="extrabold"
                textDecoration="underline"
              >
                {formatMessage({ id: 'action.backToCart' })}
              </Link>
            </HStack>
            <HStack width="100%" justifyContent="space-around">
              <CheckoutSteps />
            </HStack>
          </VStack>
        ) : (
          <Grid
            gridTemplateAreas={{
              base: `"logo links" "steps steps"`,
              md: `"logo steps links"`,
            }}
            justifyContent="space-between"
            alignItems="center"
          >
            <GridItem area="logo">
              {headerLogoUrl && (
                <Link as={NextLinkNoPrefetch} href={'/'} passHref>
                  <NextImage src={headerLogoUrl} alt="logo" height={25} width={50} />
                </Link>
              )}
            </GridItem>
            <GridItem area="steps">
              <CheckoutSteps />
            </GridItem>
            <GridItem area="links">
              <Box alignSelf={'center'} display={'block'}>
                <Link
                  as={NextLinkNoPrefetch}
                  href={'/cart'}
                  fontSize="sm"
                  color="text"
                  fontWeight="extrabold"
                  textDecoration="underline"
                >
                  {formatMessage({ id: 'action.backToCart' })}
                </Link>
              </Box>
            </GridItem>
          </Grid>
        )}
      </Container>
    </Box>
  )
}
