export const parseAttributeArrayIntoString = (productAttributes: Array<{ name: string; value: string }>) => {
  const formattedArray = []

  productAttributes?.forEach((item, index) => {
    if (!item?.name || !item?.value) return

    const { name, value } = item

    const formattedKey = name.charAt(0).toUpperCase() + name.slice(1)
    const formattedValue = name === 'color' ? value.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) : value

    formattedArray.push(`${formattedKey}: ${formattedValue}`)

    if (index < productAttributes.length - 1) {
      formattedArray.push(', ')
    }
  })

  const resultString = formattedArray.join('')

  return resultString.endsWith(', ') ? resultString.slice(0, -2) : resultString
}

export const parseSkuLabel = (sku: string | null | undefined) => {
  if (!sku) return ''
  return `Item #: ${sku}`
}
