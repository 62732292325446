declare global {
  interface Window {
    Yo: any
  }
}

export const invokeYottaaPubSubEvent = async (pageType: string, path: string) => {
  const cleanEvent = async (pageType: string) => {
    try {
      window.Yo.pubsub.publish({
        topic: 'rum/spa/rendered',
        message: {
          viewType: 'CLEAN',
          pageType: pageType,
        },
      })
    } catch (e) {
      console.error(`error occurred while publishing clean event: ${JSON.stringify(e)}`)
    }
  }
  /* Currently transitionEvent won't be invoked since all the invocations are only clean - no SSR is implemented.
  Once our app becomes an SPA, fire the transition event similar to clean event based on the page type and path values
  */
  const transitionEvent = async (pageType: string) => {
    try {
      window.Yo.pubsub.publish({
        topic: 'rum/spa/rendered',
        message: {
          viewType: 'TRANSITION',
          pageType: pageType,
        },
      })
    } catch (e) {
      console.error(`error occurred while publishing transition event: ${JSON.stringify(e)}`)
    }
  }

  if (path == '/') {
    return await cleanEvent(pageType)
  }

  return await transitionEvent(pageType)
}
