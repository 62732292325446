import { Divider, Stack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { CheckoutDetailItem } from './checkout-detail-item'
import { useCheckoutSteps } from './checkout-provider'

export const DeliveryOptionsDetails = () => {
  const intl = useFormat({ name: 'common' })
  const { goTo } = useCheckoutSteps()
  const { data: cart } = useCart()

  const firstName = cart?.shippingAddress?.firstName ?? ''
  const lastName = cart?.shippingAddress?.lastName ?? ''
  const streetNumber = cart?.shippingAddress?.streetNumber ?? ''
  const streetName = cart?.shippingAddress?.streetName ?? ''
  const city = cart?.shippingAddress?.city ?? ''
  const state = cart?.shippingAddress?.state ?? ''
  const postalCode = cart?.shippingAddress?.postalCode ?? ''
  const country = cart?.shippingAddress?.country ?? ''

  return (
    <Stack spacing={4} divider={<Divider />}>
      {cart?.email && (
        <CheckoutDetailItem
          title={intl.formatMessage({
            id: 'checkout.guestSection.title',
          })}
          details={[cart.email]}
          onClickEdit={() => goTo('')}
        />
      )}
      {Object.values(cart?.shippingAddress ?? {}).some((val) => !!val) && (
        <CheckoutDetailItem
          title={intl.formatMessage({
            id: 'checkout.shippingForm.title',
          })}
          details={[`${firstName} ${lastName}`, `${streetNumber} ${streetName}`, `${city}, ${state}, ${postalCode}`]}
          onClickEdit={() => goTo('')}
        />
      )}
    </Stack>
  )
}
