import Image from 'next/image'
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { BaseProps, ObjectFit } from './types'

export interface BannerImageProps extends BaseProps {
  priority?: boolean
  root?: BoxProps
  imageBox?: BoxProps
  imageDesktop?: {
    src?: string
    alt?: string
    objectFit?: ObjectFit
  }

  imageMobile?: {
    src?: string
    alt?: string
    objectFit?: ObjectFit
  }
  overlayBackground?: string
  objectFit?: ObjectFit
}

const imageBase: BoxProps = {
  position: 'relative',
  width: '100%',
  minHeight: '400px',
  overflow: 'hidden',
}

export const BannerImage = ({
  priority = false,
  imageDesktop,
  imageMobile,
  imageBox,
  overlayBackground,
  root,
  objectFit = 'cover',
  isMobileDevice,
}: BannerImageProps) => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  if (!imageDesktop?.src && !imageMobile?.src) {
    return null
  }

  const objectFitConfig: ObjectFit = isMobile
    ? imageMobile?.objectFit || objectFit
    : imageDesktop?.objectFit || objectFit

  return (
    <Box display="flex" alignItems="stretch" {...root}>
      <Box display={['none', 'none', 'block']} {...imageBase} {...imageBox}>
        <Image
          src={isMobile ? imageMobile?.src : imageDesktop?.src || ''}
          alt={isMobile ? imageMobile?.alt : imageDesktop?.alt || ''}
          fill
          style={{ objectFit: objectFitConfig }}
          priority={priority}
        />
        {overlayBackground && (
          <Box position="absolute" top="0" left="0" width="100%" height="100%" background={overlayBackground} />
        )}
      </Box>

      <Box display={['block', 'block', 'none']} {...imageBase} {...imageBox}>
        <Image
          src={isMobile ? imageMobile?.src : imageDesktop?.src || ''}
          alt={isMobile ? imageMobile?.alt : imageDesktop?.alt || ''}
          fill
          style={{ objectFit: objectFitConfig }}
          priority={priority}
        />
        {overlayBackground && (
          <Box position="absolute" top="0" left="0" width="100%" height="100%" background={overlayBackground} />
        )}
      </Box>
    </Box>
  )
}
