import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Container,
  ContainerProps,
  Heading,
  Skeleton,
  SkeletonText,
  Stack,
  StackProps,
  Text,
  Flex,
  useBreakpointValue,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import { Category } from '@Types/product/Category'
import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { Accordion } from 'composable'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import keys from 'lodash/keys'
import { Toaster } from 'react-hot-toast'
import { PdpBreadcrumb } from './components/breadcrumb'
import { Scene7ViewerScript } from './components/scene-7/scene-7-script'
import { SwatchSelector } from './components/swatch-selector'
import { TextSelector } from './components/text-selector'
import { accordionItem } from './types'
import { FlatPrice } from '../flat-price'
import { Gallery, ImagesAndVideos } from '../gallery'
import { SwatchSelectorConfiguration, SwatchSelectorGrouped, TextSelectorOption } from '../types'
import CompleteTheLook from './components/complete-the-look'
import { FaAngleDown } from 'react-icons/fa'
import { CustomerReviews } from './customer-reviews/customer-reviews'
import { ReviewTabs } from './customer-reviews/reviews-tabs'
import { useAtgUser } from 'frontastic/contexts'
import { useRouter } from 'next/router'
import { utagLink, utagView } from 'helpers/tealiumHelper'
import { CertonaSlider } from 'frontastic/tastics/composable/certona-slider'
import useContentstack, { Entry } from '../hooks/useContentstack'

declare const window: any
export interface PdpLayoutProps {
  productId: string
  description: string
  isLoaded: boolean
  main: JSX.Element
  price: JSX.Element
  title: string
  accordions?: accordionItem[]
  mainStackProps?: StackProps
  rootProps?: ContainerProps
  stackProps?: StackProps
  childProducts?: Product[]
  currentChildProduct?: Product
  currentVariant?: Variant
  textSelectors?: {
    [key: string]: textSelectorProps
  }
  textSelectorsLabels?: {
    [key: string]: string
  }
  swatchSelector?: SwatchSelectorConfiguration
  swatchSelectorGroupedByPrice?: SwatchSelectorGrouped | any[]
  selectedSwatch?: string
  setSelectedSwatch?: (swatch: string) => void
  setSelectedAttribute?: (attribute: string, value: string) => void
  setSelectedSwatchUrl?: (swatch: string) => void
  selectedSwatchUrl?: string
  showMissingSelections?: boolean
  isProductInStock?: boolean
  category?: Category
  slug?: string
  product?: Product
}

type textSelectorProps = {
  attribute: {
    label: string
    value: string
  }
  options: TextSelectorOption[]
}

const ImageSection = ({ productId, images, videos, onClick, updateCurrentImageIndex, selectedSwatch }) => {
  const imagesAndVideos = [...images, ...videos]

  const scrollToCTLSection = () => {
    const element = document.getElementById('findmine-app-container')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Container position="relative" display="inline-block">
      <Button
        position="absolute"
        top="0"
        right="0"
        color="white"
        textDecoration="none"
        zIndex="1"
        _hover={{ bg: 'rgba(0, 0, 0, 0.7)' }}
        height={{ base: '70px', sm: '70px', md: '100px' }}
        width={{ base: '70px', sm: '70px', md: '100px' }}
        minWidth={{ base: '70px', sm: '70px', md: '100px' }}
        borderRadius="50%"
        bgColor="black"
        textAlign={'center'}
        opacity=".75"
        onClick={scrollToCTLSection}
        fontWeight={500}
      >
        <Box display="block">
          <Text textAlign="center" height={'19px'} marginBottom={'12px'} fontSize={'0.85rem'}>
            How To <br /> Wear It
          </Text>
          <Box display={'block'} marginLeft={'12px'}>
            <FaAngleDown fontSize={'20px'} />
          </Box>
        </Box>
      </Button>

      <Gallery
        key={productId}
        aspectRatio={1}
        rootProps={{
          overflow: 'hidden',
          width: '100%',
          borderRadius: 'base',
        }}
        imagesAndVideos={imagesAndVideos}
        onClick={onClick}
        selectedSwatch={selectedSwatch}
        notifyCurrentSlideIndex={updateCurrentImageIndex}
      />
    </Container>
  )
}

export const PdpLayout = ({
  title,
  description,
  price,
  main,
  productId,
  isLoaded,
  accordions,
  rootProps,
  stackProps,
  mainStackProps,
  currentVariant,
  swatchSelector,
  swatchSelectorGroupedByPrice,
  textSelectors,
  textSelectorsLabels,
  selectedSwatch,
  setSelectedSwatch,
  setSelectedAttribute,
  setSelectedSwatchUrl,
  selectedSwatchUrl,
  showMissingSelections,
  isProductInStock,
  category,
  slug,
  product,
}: PdpLayoutProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const intl = useFormat({ name: 'common' })

  const { userDetails, userSessionData } = useAtgUser()
  const [isUtagViewCalled, setIsUtagViewCalled] = useState(false)
  const router = useRouter()
  const path = router.asPath

  const CONTENT_TYPE_ID = 'certona_slider'
  const [youMayAlsoLike, setYouMayAlsoLike] = useState(null)
  const [peopleAlsoPurchased, setPeopleAlsoPurchased] = useState(null)
  const [recentlyViewedItems, setRecentlyViewedItems] = useState(null)
  const { getEntryByUid, isLoading } = useContentstack()
  const [certonaRecommendationsData, setCertonaRecommendationsData] = useState(null)

  const updateCurrentImageIndex = (index: number) => {
    setCurrentImageIndex(index)
  }

  const productVideos: ImagesAndVideos[] = extractVideosFromParentProduct(product)

  const currentImages = useMemo(
    () =>
      currentVariant?.images?.map((img: string, id: number) => ({
        id: `${currentVariant?.sku}-${id}`,
        src: img,
        alt: currentVariant?.sku,
        type: 'image',
      })),
    [currentVariant],
  )

  const pdpAccordionsDefaultOpenList = accordions.reduce(
    (arr: number[], item, idx) => (item.defaultOpen ? [...arr, idx] : arr),
    [],
  )

  const certonaRecommendations = (data) => {
    setCertonaRecommendationsData(data)
  }

  useEffect(() => {
    window.certonaRecommendations = certonaRecommendations
    fetchCSCertonaData('blt6ce21408d91fed1a', setYouMayAlsoLike)
    fetchCSCertonaData('blt7603ace0b3387b27', setPeopleAlsoPurchased)
    fetchCSCertonaData('blte2792250076e25cc', setRecentlyViewedItems)

    return () => {
      window.certonaRecommendations = null
    }
  }, [])

  const fetchCSCertonaData = async (uid, setter) => {
    try {
      const results = await getEntryByUid(CONTENT_TYPE_ID, uid)
      setter(results)
    } catch (err) {
      console.log('Error processing contentStack data', err)
    }
  }

  useEffect(() => {
    if (userDetails && userSessionData) {
      if (!isUtagViewCalled) {
        constructUtagData()
      }
    }
  }, [userDetails, userSessionData])

  const constructUtagData = () => {
    const utag_data = [
      'view',
      {
        page_category: 'product',
        page_url: path,
        cm_page_id: path,
        cm_category_id: 'product',
        in_iframe: 0,
        isThirdParty: 'true',
        is_confirmation: 0,
        page_type: 'product',
        page_name: 'PDP Page',
        site_id: 'dxl',
        customer_email: userDetails?.profile?.['email'] || undefined,
        customer_postal_code: userDetails?.addressList?.[0]?.postalCode || undefined,
        customer_id: userDetails?.profile?.['customerId'] || undefined,
        crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : undefined,
        site_section: 'product',
        site_sub_section: undefined,
        site_sub_section_level3: undefined,
        site_sub_section_level4: undefined,
        user_loginstatus: userDetails?.firstName ? true : false,
        site_breadcrumb: 'Home',
        available_points: userSessionData?.firstName ? userSessionData?.userAvailablePoints : '',
        current_tier: userSessionData?.firstName ? userSessionData?.userCurrentTier : '',
        user_mystore: userSessionData?.store ? userSessionData?.store.displayStoreNumber : '',
        user_sizeprofile: userDetails?.profileSizes
          ? userDetails?.profileSizes?.alwaysSbs == true
            ? 'on'
            : 'off'
          : 'off',
      },
    ]
    if (path.includes('/p/')) {
      utagView(utag_data, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
    }
    setIsUtagViewCalled(true)
  }

  return (
    <>
      <Box>
        <Toaster />
        <Container
          maxW="container.2xl"
          mx="auto"
          py={{ base: 6, md: 8, lg: 16 }}
          px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}
          {...rootProps}
        >
          <Stack flexDirection={'column'} gap={6}>
            <PdpBreadcrumb category={category} title={title} slug={slug} />
            <Stack
              direction={{ base: 'column-reverse', lg: 'row' }}
              spacing={{ base: '10', lg: '16', xl: '24' }}
              {...stackProps}
            >
              <Stack
                maxH={{ base: 'initial', lg: '95vh' }}
                maxW={{ lg: 'md' }}
                overflow={'hidden'}
                justify="flex-start"
                width="100%"
                layerStyle={'no-scroll-bar'}
                {...mainStackProps}
              >
                <Stack m={0} overflowY={'scroll'} gap={{ base: 5, md: 6 }}>
                  <Stack gap={2}>
                    <SkeletonText textStyle={'eyebrow-75'} color={'text.muted'} isLoaded={isLoaded}>
                      {product?.attributes?.brand}
                    </SkeletonText>
                    <Heading>
                      <Skeleton textStyle={'M'} minHeight="1em" isLoaded={isLoaded}>
                        {title}
                      </Skeleton>
                    </Heading>
                    {product && (
                      <Skeleton isLoaded={isLoaded}>
                        <Box paddingLeft={0} marginLeft={0}>
                          <CustomerReviews product={product}></CustomerReviews>
                        </Box>
                      </Skeleton>
                    )}
                    <SkeletonText isLoaded={isLoaded} textStyle={'body-100-300'}>
                      {price}
                    </SkeletonText>
                  </Stack>

                  {isMobile && (
                    <ImageSection
                      productId={productId}
                      images={currentImages}
                      videos={productVideos}
                      onClick={onOpen}
                      updateCurrentImageIndex={updateCurrentImageIndex}
                      selectedSwatch={selectedSwatch}
                    />
                  )}

                  {isProductInStock && (
                    <>
                      <Skeleton isLoaded={isLoaded}>
                        <Flex h={5} mb={3} alignItems={'center'}>
                          <Text textStyle={'heading-desktop-75'} pe={2}>
                            {swatchSelector?.attribute?.label}
                          </Text>
                          <Text textStyle={'body-75'} textTransform={'capitalize'}>
                            {swatchSelector?.attribute?.colorDisplayName
                              ? swatchSelector?.attribute?.colorDisplayName.toLowerCase()
                              : swatchSelector?.attribute?.value.toLowerCase()}
                          </Text>
                        </Flex>
                        <Flex gap={5} flexDirection={'column'}>
                          {swatchSelectorGroupedByPrice &&
                            keys(swatchSelectorGroupedByPrice)?.map((swatchDisucount) => {
                              const swatch = swatchSelectorGroupedByPrice[swatchDisucount]
                              return (
                                <Flex flexDirection={'column'} key={`price-group-${swatchDisucount}`} gap={2}>
                                  {keys(swatchSelectorGroupedByPrice).length > 1 && (
                                    <FlatPrice
                                      priceProps={{ textStyle: 'body-100' }}
                                      price={CurrencyHelpers.formatForCurrency(
                                        swatch[0]?.priceGroup?.price?.centAmount,
                                      )}
                                      discountedPrice={
                                        swatch[0]?.priceGroup?.discountedPrice
                                          ? CurrencyHelpers.formatForCurrency(swatch[0]?.priceGroup?.discountedPrice)
                                          : undefined
                                      }
                                      discountPercent={swatch[0]?.priceGroup.discountPercent}
                                    />
                                  )}
                                  <Flex gap={2.5} flexWrap={'wrap'}>
                                    {swatch?.map((option, index) => {
                                      return (
                                        <Box w={12} h={12} key={`sweatch-${index}`}>
                                          <SwatchSelector
                                            option={option}
                                            isSelected={option.value === swatchSelector?.attribute?.value}
                                            isDisabled={!option?.inStock}
                                            setSelection={() => {
                                              setSelectedSwatch(option.value)
                                            }}
                                          />
                                        </Box>
                                      )
                                    })}
                                  </Flex>
                                </Flex>
                              )
                            })}
                        </Flex>
                      </Skeleton>
                      {Object.values(textSelectors).map((textSelector, i) => {
                        const { attribute, options } = textSelector
                        return (
                          <Skeleton key={`text-selector-${i}`} isLoaded={isLoaded}>
                            <Flex h={5} mb={3} alignItems={'center'}>
                              <Text textStyle={'heading-desktop-75'} pe={2} textTransform={'capitalize'}>
                                {textSelectorsLabels[attribute.label]}
                              </Text>
                              {attribute?.value ? (
                                <Text textStyle={'body-75'}>{attribute.value}</Text>
                              ) : (
                                showMissingSelections && (
                                  <Text textStyle={'body-75'} color={'text.danger'}>
                                    {intl.formatMessage({
                                      id: 'product.pleaseSelect',
                                      values: { attribute: textSelectorsLabels[attribute.label] },
                                    })}
                                  </Text>
                                )
                              )}
                            </Flex>

                            <Flex gap={2.5} flexWrap={'wrap'}>
                              {options.map((option, index) => {
                                return (
                                  <Box key={`sweatch-${index}`}>
                                    <TextSelector
                                      option={option}
                                      isSelected={option.value === attribute.value}
                                      isDisabled={!option?.inStock}
                                      setSelection={() => {
                                        setSelectedAttribute(attribute.label, option.value)
                                      }}
                                    />
                                  </Box>
                                )
                              })}
                            </Flex>
                          </Skeleton>
                        )
                      })}
                    </>
                  )}
                  <SkeletonText isLoaded={isLoaded}>{main}</SkeletonText>
                  <SkeletonText isLoaded={isLoaded}>
                    <Text textStyle={'body-75'}>Item #{product?.key}</Text>
                    <Box textStyle={'body-75'} color={'text'} dangerouslySetInnerHTML={{ __html: description }}></Box>
                  </SkeletonText>
                  <SkeletonText isLoaded={isLoaded}>
                    <Accordion
                      size="medium"
                      items={accordions}
                      accordionProps={{
                        mt: 8,
                        allowToggle: false,
                        allowMultiple: true,
                        defaultIndex: pdpAccordionsDefaultOpenList,
                      }}
                      accordionItemProps={{ border: 'none' }}
                      accordionPanelProps={{ px: 1 }}
                      accordionButtonProps={{
                        px: 0,
                        borderBottomWidth: '1px',
                      }}
                    />
                  </SkeletonText>
                </Stack>
              </Stack>
              {!isMobile && (
                <ImageSection
                  productId={productId}
                  images={currentImages}
                  videos={productVideos}
                  onClick={onOpen}
                  updateCurrentImageIndex={updateCurrentImageIndex}
                  selectedSwatch={selectedSwatch}
                />
              )}
            </Stack>
          </Stack>

          <Box>
            {currentVariant.price.currencyCode === 'USD' ? (
              <CompleteTheLook
                productInStock={isProductInStock}
                productColorName={currentVariant.attributes.ecommColor}
                productId={currentVariant.attributes.itemNumber}
                productPrice={(currentVariant.price.centAmount / 100).toFixed(2)}
                productOnSale={!!currentVariant?.discountedPrice}
              />
            ) : null}
          </Box>

          {youMayAlsoLike?.uid && certonaRecommendationsData && (
            <CertonaSlider certonaRes={certonaRecommendationsData} {...youMayAlsoLike} />
          )}
          {peopleAlsoPurchased?.uid && certonaRecommendationsData && (
            <CertonaSlider certonaRes={certonaRecommendationsData} {...peopleAlsoPurchased} />
          )}
          {product && (
            <Box maxWidth={'100%'} id="reviewTabs" mt={6}>
              <ReviewTabs product={product}></ReviewTabs>
            </Box>
          )}
          {recentlyViewedItems?.uid && certonaRecommendationsData && (
            <CertonaSlider certonaRes={certonaRecommendationsData} {...recentlyViewedItems} />
          )}
        </Container>
      </Box>
      <Scene7ViewerScript
        isOpen={isOpen}
        onClose={onClose}
        productVideos={productVideos}
        currentImages={currentImages}
        currentImageIndex={currentImageIndex}
      />
    </>
  )
}

const extractVideosFromParentProduct = (product: Product) => {
  const videoPattern = /_vid\d+$/ // Videos will end with _vid1, _vid2, etc.
  const videos: ImagesAndVideos[] = []

  if (product?.variants?.[0]?.images) {
    // Product videos are only stored in the Master variant
    product.variants[0].images.forEach((image) => {
      if (videoPattern.test(image)) {
        videos.push({ src: image, type: 'video' })
      }
    })
  }

  return videos
}
