import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { isEqual } from 'lodash'
import { useAccount, useCart } from 'frontastic'
import { ShippingAddressForm } from './Forms/shipping-address-form'
import { ShippingAddressSelector } from './shipping-address-selector'
import { ShippingOptions } from './shipping-options'
import { Section, SectionHeader } from '../../section'
import { useCheckout } from '../checkout-provider'
import { ShopToStoreAddress } from './ship-to-store-address'
import { CheckoutAddressType } from '../../../helpers/utils/experian-address-utils'
import { Address } from '@Types/account/Address'

export interface ShippingSectionProps {
  addressConfirmationModal: boolean
  modalOnClose: () => void
  checkoutAddress: CheckoutAddressType
  experianAddress: any
  handleStep1Submit: (overwriteShippingFromModal?: Address) => void
  setAddressSetByPosBroker: (value: boolean) => void
}

export const ShippingSection = ({
  addressConfirmationModal,
  modalOnClose,
  checkoutAddress,
  experianAddress,
  handleStep1Submit,
  setAddressSetByPosBroker,
}: ShippingSectionProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { account } = useAccount()
  const { setCheckoutState, setAddressConfirmationModal, setRecommendedAddress, setAddressConfirmationDecision } =
    useCheckout()

  const { data: cart } = useCart()
  const [deliveryType, setDeliveryType] = useState('')
  const initialValObj = {
    id: '',
    additional_address_info: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    street_name: '',
    street_number: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
    additional_street_info: '',
  }

  const [initialFormValues, setInitialFormValues] = useState(initialValObj)

  const handleOnChange = ({ data }) => {
    function cleanAddress(address) {
      return Object.entries(address).reduce((acc, [key, value]) => {
        if (value && key !== 'id') {
          acc[key] = value
        }
        return acc
      }, {})
    }
    const cleanData = cleanAddress(data)

    const cleanCheckoutAddress = cleanAddress(checkoutAddress)

    const addressChanged = !isEqual(cleanData, cleanCheckoutAddress)
    const isDataUndefined = Object.values(data).every((value) => value === undefined)

    if (isDataUndefined) {
      return
    }

    if (addressChanged) {
      setRecommendedAddress(false)
      setAddressSetByPosBroker(false)
    }
    let streetName = ''
    if (data.street_name !== undefined) {
      streetName = data.street_name
        .replaceAll(/[^a-zA-Z0-9]/g, '')
        .trim()
        .toUpperCase()
    }
    if (
      data.city !== undefined &&
      (data.city.includes('APO') || data.city.includes('FPO') || data.city.includes('DPO'))
    ) {
      data.additional_address_info = 'MILITARY'
    } else if (streetName.includes('POBOX')) {
      data.additional_address_info = 'POBOX'
    } else {
      data.additional_address_info = 'STANDARD'
    }
    setCheckoutState((state) => {
      return {
        ...state,
        shipping_address: data,
      }
    })
    if (addressChanged) {
      setAddressConfirmationDecision(false)
      setAddressConfirmationModal(true)
    }
  }

  useEffect(() => {
    setInitialFormValues({
      id: cart?.cartId,
      additional_address_info: cart?.shippingAddress?.additionalAddressInfo,
      additional_street_info: cart?.shippingAddress?.additionalStreetInfo,
      first_name: cart?.shippingAddress?.firstName,
      last_name: cart?.shippingAddress?.lastName,
      phone_number: cart?.shippingAddress?.phone,
      street_name: cart?.shippingAddress?.streetName,
      street_number: cart?.shippingAddress?.streetNumber,
      city: cart?.shippingAddress?.city,
      state: cart?.shippingAddress?.state,
      postcode: cart?.shippingAddress?.postalCode,
      country: cart?.shippingAddress?.country,
    })
  }, [cart])

  const shipToStoreCallBack = (deliveryType) => {
    setDeliveryType(deliveryType)
  }

  const getInitialFormValuse = () => {
    if (initialFormValues?.additional_address_info == 'shipToStore') {
      return initialValObj
    } else {
      return initialFormValues
    }
  }

  return (
    <>
      <Section>
        <SectionHeader
          hasRequiredFields={!account}
          title={formatMessage({
            id: 'checkout.shippingForm.title',
          })}
          marginBottom={4}
        />
        <Box mb={0}>
          {account ? (
            <ShippingAddressSelector />
          ) : (
            <>
              <ShopToStoreAddress initialValues={initialFormValues} callBack={shipToStoreCallBack}></ShopToStoreAddress>
              {deliveryType == 'shipToAddress' && (
                <ShippingAddressForm
                  initialValues={getInitialFormValuse()}
                  onChange={handleOnChange}
                  addressConfirmationModal={addressConfirmationModal}
                  modalOnClose={modalOnClose}
                  checkoutAddress={checkoutAddress}
                  experianAddress={experianAddress}
                  handleStep1Submit={handleStep1Submit}
                />
              )}
            </>
          )}
        </Box>
      </Section>
      <Section>
        <SectionHeader
          title={formatMessage({
            id: 'checkout.shippingForm.shippingAndGiftOptions.title',
          })}
          marginBottom={4}
        />
        <ShippingOptions />
      </Section>
    </>
  )
}
