import { useState } from 'react'
import { Box, Button, useBreakpointValue } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

export interface PaginationProps {
  variant?: PaginationVariant
  totalRecords: number
  itemsPerPage: number
  activePage: number
  showText: boolean
  onPageChange?: (newPage: number) => void
}

export type PaginationVariant = 'solid' | 'outline' | 'ghost'

export const Pagination = ({
  variant = 'solid',
  totalRecords = 0,
  itemsPerPage = 10,
  activePage = 1,
  showText = false,
  onPageChange = () => {},
}: PaginationProps) => {
  const numberOfPages = Math.ceil(totalRecords / itemsPerPage)
  const initialPageValue = activePage <= numberOfPages ? activePage : 1

  const [selectedPage, setSelectedPage] = useState(initialPageValue)
  const isMobile = useBreakpointValue({ base: true, md: false })

  if (!totalRecords || !itemsPerPage) {
    return null
  }

  const handlePageChange = (newPage: number) => {
    onPageChange(newPage)
    setSelectedPage(newPage)
  }

  const shouldRenderButton = (page: number, isLeftSideTruncated: boolean, isRightSideTruncated: boolean) => {
    const sideItemsToDisplay = isMobile ? 1 : 2
    const leftItemsTilTrucate = isMobile ? 3 : 5
    const rightItemsTilTruncate = isMobile ? numberOfPages - 2 : numberOfPages - 4

    return (
      page < 2 ||
      (page <= leftItemsTilTrucate && !isLeftSideTruncated) ||
      (page >= rightItemsTilTruncate && !isRightSideTruncated) ||
      page > numberOfPages - 1 ||
      Math.abs(selectedPage - page) < sideItemsToDisplay
    )
  }

  const Ellipsis = () => (
    <Box px={4} lineHeight="loose">
      &hellip;
    </Box>
  )

  const renderButtons = () => {
    const items = []

    const pagesThresholdForTruncation = isMobile ? 6 : 8
    const leftSideLimitForTruncation = isMobile ? selectedPage >= 3 : selectedPage >= 4
    const rightSideLimitForTruncation = isMobile ? selectedPage <= numberOfPages - 2 : selectedPage <= numberOfPages - 3

    const isLeftSideTruncated = numberOfPages >= pagesThresholdForTruncation && leftSideLimitForTruncation
    const isRightSideTruncated = numberOfPages >= pagesThresholdForTruncation && rightSideLimitForTruncation

    for (let page = 1; page <= numberOfPages; page++) {
      const isPageActive = selectedPage === page
      if (page === 2 && isLeftSideTruncated) {
        items.push(<Ellipsis />)
      }

      if (shouldRenderButton(page, isLeftSideTruncated, isRightSideTruncated)) {
        items.push(
          <Button
            key={page}
            variant={variant}
            isDisabled={isPageActive} // Temporarily Leaving isDisabled instead of isActive since theming should be updated
            onClick={() => handlePageChange(page)}
            size={{ base: 'sm', md: 'md' }}
          >
            {page}
          </Button>,
        )
      }

      if (page === numberOfPages - 1 && isRightSideTruncated) {
        items.push(<Ellipsis />)
      }
    }

    return items
  }

  const renderLeftArrowButton = () => {
    return (
      <Button
        variant={variant}
        disabled={selectedPage === 1}
        onClick={() => handlePageChange(selectedPage - 1)}
        size={{ base: 'sm', md: 'md' }}
      >
        <ChevronLeftIcon />
      </Button>
    )
  }

  const renderRightArrowButton = () => {
    return (
      <Button
        variant={variant}
        disabled={selectedPage === numberOfPages}
        onClick={() => handlePageChange(selectedPage + 1)}
        size={{ base: 'sm', md: 'md' }}
      >
        <ChevronRightIcon />
      </Button>
    )
  }

  const renderText = () => {
    const lowerValue = 1 + (selectedPage - 1) * itemsPerPage
    const higherValue = Math.min(selectedPage * itemsPerPage, totalRecords)

    return (
      <Box pt={4} display="flex" justifyContent="center">
        Viewing {lowerValue}-{higherValue} of {totalRecords}
      </Box>
    )
  }

  return (
    <>
      {showText && renderText()}
      <Box py={4} display="flex" flexDirection="row" justifyContent="center" gap={3}>
        {renderLeftArrowButton()}
        {renderButtons()}
        {renderRightArrowButton()}
      </Box>
    </>
  )
}
