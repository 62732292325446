import { SmallCloseIcon } from '@chakra-ui/icons'
import { Button, Flex } from '@chakra-ui/react'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import {
  CurrentRefinementsConnectorParamsItem,
  CurrentRefinementsConnectorParamsRefinement,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements'
import { useClearRefinements, useCurrentRefinements, useRange } from 'react-instantsearch-hooks-web'

interface RefinementChipProps {
  label: string
  clearRefinement: () => void
}

const RefinementChip = ({ label, clearRefinement }: RefinementChipProps) => {
  return (
    <Button variant="outline" size="md" rightIcon={<SmallCloseIcon />} onClick={clearRefinement}>
      {label}
    </Button>
  )
}
export const CurrentRefinements = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { items: refinementAttributes, refine: clearSingleRefinement } = useCurrentRefinements()
  const { canRefine: isRefined, refine: clearAllRefinements } = useClearRefinements()
  const { range: currentRange } = useRange({
    attribute: 'price.centAmount',
  })

  if (!isRefined) {
    return null
  }

  const labelBy = (
    attribute: CurrentRefinementsConnectorParamsItem,
    refinement: CurrentRefinementsConnectorParamsRefinement,
  ): string => {
    if (attribute.attribute === 'price.centAmount') {
      if (attribute.refinements.length === 1) {
        if (refinement.operator === '>=') {
          return `
            ${CurrencyHelpers.formatForWholeNumberCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} -
            ${CurrencyHelpers.formatForWholeNumberCurrency({
              centAmount: currentRange.max as number,
              currencyCode: 'USD' as string,
            })}
          `
        } else {
          return `
           ${CurrencyHelpers.formatForWholeNumberCurrency({
             centAmount: currentRange.min as number,
             currencyCode: 'USD' as string,
           })} 
           -
           ${CurrencyHelpers.formatForWholeNumberCurrency({
             centAmount: refinement.value as number,
             currencyCode: 'USD' as string,
           })}  
          `
        }
      } else {
        return refinement.operator === '>='
          ? `${formatMessage({
              id: 'category.results.min',
            })}  ${CurrencyHelpers.formatForWholeNumberCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} `
          : `${formatMessage({
              id: 'category.results.max',
            })}  ${CurrencyHelpers.formatForWholeNumberCurrency({
              centAmount: refinement.value as number,
              currencyCode: 'USD' as string,
            })} `
      }
    } else {
      return refinement.value.toString()
    }
  }

  return (
    <Flex gap="8px" wrap="wrap" pt={'sm'} pb={'sm'} mb={'md'}>
      {refinementAttributes?.map((attribute) =>
        attribute?.refinements?.map((refinement, index) => (
          <RefinementChip
            key={`${attribute.label}_${index}`}
            label={labelBy(attribute, refinement)}
            clearRefinement={() => clearSingleRefinement(refinement)}
          />
        )),
      )}
      <Button variant="ghost" size="md" onClick={clearAllRefinements}>
        {formatMessage({ id: 'category.filters.action.clear' })}
      </Button>
    </Flex>
  )
}
