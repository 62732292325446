import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { VStack } from '@chakra-ui/react'
import { useFormat } from '../../../helpers_composable/hooks/useFormat'
import useHash from '../../../helpers_composable/hooks/useHash'
import { contactUsItems, menuItems, SidebarAccordion, SidebarNav } from '../sidebar'

export interface AccountDashboardNavProps {
  state?: 'Expanded' | 'Collapsed'
  logout: () => void
}

export const DEFAULT_ACCOUNT_DASHBOARD_PATH = '/account/dashboard'

const getActiveItemData = (pathName: string) => {
  return contactUsItems.find((item) => item.path === pathName) ?? menuItems.find((item) => pathName.includes(item.path))
}

export const AccountDashboardNav = ({ state = 'Collapsed', logout }: AccountDashboardNavProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const [activeItem, setActiveItem] = useState(null)
  const [hash, _setHash] = useHash()
  const router = useRouter()

  useEffect(() => {
    const active = getActiveItemData(hash.split('#')[1] || 'profile')
    setActiveItem(active)
  }, [])

  return (
    <>
      {/* Desktop */}
      <VStack spacing={'0'} width={'full'} alignItems={'stretch'} display={{ base: 'none', md: 'flex' }}>
        <SidebarNav
          size="Large"
          logout={() => {
            logout()
            router.push('/')
          }}
          activeItem={activeItem}
        />
      </VStack>

      {/* Mobile */}
      <VStack spacing={'0'} width={'full'} alignItems={'stretch'} display={{ base: 'flex', md: 'none' }}>
        <SidebarAccordion
          expanded={state === 'Expanded'}
          icon={activeItem?.icon}
          label={formatMessage({ id: activeItem?.intlId ?? 'order' })}
        >
          <SidebarNav
            size="Small"
            logout={() => {
              logout()
              router.push('/')
            }}
            activeItem={activeItem}
          />
        </SidebarAccordion>
      </VStack>
    </>
  )
}
