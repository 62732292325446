import { Icon, IconProps } from '@chakra-ui/react'

export const BagIcon = (props: IconProps) => (
  <Icon
    id="icon-bag"
    aria-label="Bag Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1.4em"
    height="1.4em"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 25 32"
    strokeWidth="0"
    {...props}
  >
    <path d="M22.063 29.282h-19.5v-19.502h19.5v19.502zM12.313 2.718c2.767 0.005 5.075 1.96 5.625 4.563l0.007 0.038h-11.257c0.534-2.623 2.853-4.601 5.625-4.601zM20.423 7.319c-0.562-3.984-3.98-7.062-8.111-7.062-4.103 0-7.503 3.039-8.096 7.062h-4.116v24.425h24.423v-24.425h-4.101z"></path>
  </Icon>
)
