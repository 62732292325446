import { useFormat } from 'helpers/hooks/useFormat'
import { CheckoutLayout } from './checkout-layout'
import { CheckoutProvider, CheckoutStepsProvider } from './checkout-provider'
import { CheckoutSteps } from './checkout-steps'

export const CheckoutPage = ({ headerLogoUrl }: { headerLogoUrl?: string }) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <CheckoutProvider>
      <CheckoutStepsProvider
        initialSteps={[
          {
            id: 1,
            key: 'step1',
            hash: '',
            isAllowed: true,
            name: formatMessage({ id: 'checkout.customerSection.title' }),
            title: formatMessage({ id: 'checkout.customerSection.title' }),
          },
          {
            id: 2,
            key: 'step2',
            hash: 'billing',
            isAllowed: false,
            name: formatMessage({ id: 'checkout.paymentSection.title' }),
            title: formatMessage({ id: 'checkout.paymentSection.title' }),
          },
          {
            id: 3,
            key: 'review',
            hash: 'review',
            isAllowed: false,
            name: formatMessage({ id: 'checkout.review.title' }),
            title: formatMessage({ id: 'checkout.review.title' }),
          },
        ]}
      >
        <CheckoutLayout headerLogoUrl={headerLogoUrl}>
          <CheckoutSteps />
        </CheckoutLayout>
      </CheckoutStepsProvider>
    </CheckoutProvider>
  )
}
