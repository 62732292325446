import { Icon, IconProps } from '@chakra-ui/react'

export const UserIcon = (props: IconProps) => (
  <Icon
    id="icon-user"
    aria-label="User Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1.4em"
    height="1.4em"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 32 32"
    strokeWidth="0"
    {...props}
  >
    <path d="M15.886 3.388c3.381 0 6.132 2.757 6.132 6.148s-2.751 6.148-6.132 6.148-6.132-2.758-6.132-6.148 2.751-6.148 6.132-6.148zM15.886 18.054c4.688 0 8.502-3.823 8.502-8.518s-3.813-8.518-8.502-8.518-8.502 3.822-8.502 8.518 3.814 8.518 8.502 8.518zM2.836 28.612c0.533-3.995 3.718-7.076 7.556-7.076h11.216c3.839 0 7.024 3.081 7.557 7.076h-26.329zM21.607 19.166h-11.216c-5.515 0-10.004 4.769-10.004 10.631v1.185h31.225v-1.185c0-5.862-4.489-10.631-10.005-10.631z" />
  </Icon>
)
