import { Container, Tabs, TabList, Tab, TabPanels, TabPanel, Box } from '@chakra-ui/react'
import {
  POWERREVIEWS_API_KEY,
  POWERREVIEWS_MERCHANT_GROUP_ID,
  POWERREVIEWS_MERCHANT_ID,
} from 'composable/components/general'
import React, { useEffect, useState } from 'react'
declare const window: any
export const ReviewTabs = (props) => {
  useEffect(() => {
    try {
      if (typeof (window as any)?.POWERREVIEWS == 'undefined') {
        let script = document.createElement('script')
        script.src = '//ui.powerreviews.com/stable/4.1/ui.js'
        script.id = 'powerReviewScript'
        document.head.appendChild(script)
      }
    } catch (e) {
      console.log(e)
    }
  }, [])
  useEffect(() => {
    initializePowerReviewsOnBottomScroll()
  })

  let powerReviewsCommonObj = {
    api_key: POWERREVIEWS_API_KEY,
    locale: 'en_US',
    merchant_group_id: POWERREVIEWS_MERCHANT_GROUP_ID,
    Enable_Content_Collection_Modal: true,
    merchant_id: POWERREVIEWS_MERCHANT_ID,
    page_id: props.product.key,
    REVIEW_DISPLAY_SNAPSHOT_TYPE: 'SIMPLE',
    ENABLE_REVIEW_FILTERING: 1,
    REVIEW_FILTERING_TYPE: 'DROPDOWNS',
  }

  const initializePowerReviewsOnBottomScroll = () => {
    //if (!utilityService.inStore) {
    if (typeof (window as any).POWERREVIEWS !== 'undefined' && (window as any).POWERREVIEWS.display) {
      ;(window as any).pwr('unmount', 'pr-reviewdisplay')
    } else {
      let script = document.createElement('script')
      script.src = '//ui.powerreviews.com/stable/4.1/ui.js'
      script.id = 'powerReviewScript'
      document.head.appendChild(script)
    }
    //reviewCount = undefined;
    ;(window as any).pwr =
      (window as any).pwr ||
      function () {
        ;((window as any).pwr.q = (window as any).pwr.q || []).push(arguments)
      }
    const powerReviewsCheckInterval = setInterval(() => {
      if (typeof (window as any).POWERREVIEWS !== 'undefined') {
        clearInterval(powerReviewsCheckInterval)
        const finalObj = Object.assign(
          {
            components: {
              //ReviewSnippet: 'pr-reviewsnippet',
              ReviewList: 'pr-reviewdisplay',
              ReviewImageDisplay: 'pr-stars',
              ReviewDisplay: 'pr-reviewSnapShot',
            },
            on_render: (config, data) => {
              const starContainer = document.querySelector('#pr-stars .p-w-r')
              const getRec = document.querySelector<HTMLElement>('.pr-review-snapshot-recomend .pr-reco-value')
              let cusRec
              if (getRec) {
                let ele = document.querySelector<HTMLElement>(
                  '#pr-reviewSnapShot .pr-review-snapshot-snippets-headline',
                )
                if (ele) {
                  ele.innerHTML =
                    '<span>' + getRec.innerText + '</span> <span class="recText">Customer Recommended</span>'
                  cusRec = getRec.innerText
                  ele.setAttribute('tabindex', '0')
                  ele.setAttribute('aria-label', getRec.innerText + 'Customer Recommended')
                }
              }

              let rnLinkSnapshot = document.querySelector<HTMLElement>('#pr-reviewSnapShot a.pr-snippet-review-count')
              document.querySelector('a.pr-snippet-review-count')?.addEventListener('click', () => {
                // setTimeout(()=>{
                //     setFocusOnReviewTab();
                // }, 1000);
              })
              if (rnLinkSnapshot) {
                rnLinkSnapshot.setAttribute('tabindex', '0')
                rnLinkSnapshot.setAttribute('aria-label', rnLinkSnapshot.innerText)
              }
              let rnLinkSnapshotSpan = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot span.pr-snippet-review-count',
              )
              let reviewNum
              if (rnLinkSnapshotSpan) {
                rnLinkSnapshotSpan.setAttribute('tabindex', '0')
                let text = rnLinkSnapshotSpan.innerText
                let rcNum = parseInt(text.replace(/\D/g, ''))
                if (rcNum > 0) {
                  reviewNum = rcNum
                  rnLinkSnapshotSpan.setAttribute('aria-label', text)
                } else {
                  rnLinkSnapshotSpan.setAttribute('aria-label', 'No Review')
                }

                // rnLinkSnapshotSpan.setAttribute("aria-label","Write the first review");
              }
              let rnStarRating = document.querySelector(
                '#pr-reviewSnapShot .pr-snippet-stars-container .pr-snippet-stars',
              )
              if (rnStarRating) {
                rnStarRating.setAttribute('tabindex', '0')
              }
              const reviewHistogramCount5starEle = document.querySelector(
                '#pr-reviewSnapShot .pr-ratings-histogram-list-item .pr-histogram-5Stars',
              )
              const reviewHistogramCount4starEle = document.querySelector(
                '#pr-reviewSnapShot .pr-ratings-histogram-list-item .pr-histogram-4Stars',
              )
              const reviewHistogramCount3starEle = document.querySelector(
                '#pr-reviewSnapShot .pr-ratings-histogram-list-item .pr-histogram-3Stars',
              )
              const reviewHistogramCount2starEle = document.querySelector(
                '#pr-reviewSnapShot .pr-ratings-histogram-list-item .pr-histogram-2Stars',
              )
              const reviewHistogramCount1starEle = document.querySelector(
                '#pr-reviewSnapShot .pr-ratings-histogram-list-item .pr-histogram-1Stars',
              )
              //.pr-histogram-count
              if (
                reviewHistogramCount5starEle &&
                reviewHistogramCount4starEle &&
                reviewHistogramCount3starEle &&
                reviewHistogramCount2starEle &&
                reviewHistogramCount1starEle
              ) {
                let ele5 = reviewHistogramCount5starEle.querySelector<HTMLElement>('.pr-ratings-histogram-barValue')
                let e5
                let num5
                if (ele5) {
                  e5 = ele5?.style.width
                  e5 = e5.replace('%', ' ')
                  num5 = Math.round(e5)
                  if (num5) {
                    num5 = num5
                  } else {
                    num5 = 0
                  }
                } else {
                  num5 = 0
                }
                let reviewCountEle5 = reviewHistogramCount5starEle.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle5) {
                  reviewCountEle5.innerText = num5 + '%'
                }

                let ele4 = reviewHistogramCount4starEle.querySelector<HTMLElement>('.pr-ratings-histogram-barValue')
                let e4
                let num4
                if (ele4) {
                  e4 = ele4.style.width
                  e4 = e4.replace('%', ' ')
                  num4 = Math.round(e4)
                  if (num4) {
                    num4 = num4
                  } else {
                    num4 = 0
                  }
                } else {
                  num4 = 0
                }
                let reviewCountEle4 = reviewHistogramCount4starEle.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle4) {
                  reviewCountEle4.innerText = num4 + '%'
                }

                let ele3 = reviewHistogramCount3starEle.querySelector<HTMLElement>('.pr-ratings-histogram-barValue')
                let e3
                let num3
                if (ele3) {
                  e3 = ele3.style.width
                  e3 = e3.replace('%', ' ')
                  num3 = Math.round(e3)
                  if (num3) {
                    num3 = num3
                  } else {
                    num3 = 0
                  }
                } else {
                  num3 = 0
                }

                let reviewCountEle3 = reviewHistogramCount3starEle.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle3) {
                  reviewCountEle3.innerText = num3 + '%'
                }

                let ele2 = reviewHistogramCount2starEle.querySelector<HTMLElement>('.pr-ratings-histogram-barValue')
                let e2
                let num2
                if (ele2) {
                  e2 = ele2.style.width
                  e2 = e2.replace('%', ' ')
                  num2 = Math.round(e2)
                  if (num2) {
                    num2 = num2
                  } else {
                    num2 = 0
                  }
                } else {
                  num2 = 0
                }

                let reviewCountEle2 = reviewHistogramCount2starEle.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle2) {
                  reviewCountEle2.innerText = num2 + '%'
                }

                let ele1 = reviewHistogramCount1starEle.querySelector<HTMLElement>('.pr-ratings-histogram-barValue')
                let e1
                let num1
                if (ele1) {
                  e1 = ele1.style.width
                  e1 = e1.replace('%', ' ')
                  num1 = Math.round(e1)
                  if (num1) {
                    num1 = num1
                  } else {
                    num1 = 0
                  }
                } else {
                  num1 = 0
                }

                let reviewCountEle1 = reviewHistogramCount1starEle.querySelector<HTMLElement>('.pr-histogram-count')
                if (reviewCountEle1) {
                  reviewCountEle1.innerText = num1 + '%'
                }
              }

              const eleSec = document.querySelector('#pr-reviewSnapShot .pr-rd-search-container')
              if (eleSec) {
                let e1 = document.querySelector('#pr-reviewSnapShot .pr-rd-main-header-search .reviewedBy')
                if (!e1) {
                  eleSec.insertAdjacentHTML(
                    'beforebegin',
                    "<div class='reviewedBy'><h2>Reviewed by " + reviewNum + ' customers</h2></div>',
                  )
                }
              }

              const size = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot .pr-multiselect-button-whichsizedidyoupurchase .pr-multiselect-button-label',
              )
              if (size) {
                size.innerText = 'Size'
              }

              const inseam = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot .pr-multiselect-button-whichinseamsizedidyoupurchase .pr-multiselect-button-label',
              )
              if (inseam) {
                inseam.innerText = 'Inseam'
              }

              const waist = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot .pr-multiselect-button-whichwaistsizedidyoupurchase .pr-multiselect-button-label',
              )
              if (waist) {
                waist.innerText = 'Waist'
              }
              const heightTop = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot .pr-multiselect-button-heighttops',
              )
              const height = document.querySelector<HTMLElement>('#pr-reviewSnapShot .pr-multiselect-button-height')
              if (heightTop) {
                heightTop.style.display = 'inline-block'
                if (height) {
                  height.style.display = 'none'
                }
              } else if (height) {
                height.style.display = 'inline-block'
                if (heightTop) {
                  heightTop.style.display = 'none'
                }
              }
              const weightTop = document.querySelector<HTMLElement>(
                '#pr-reviewSnapShot .pr-multiselect-button-weighttops',
              )
              const weight = document.querySelector<HTMLElement>('#pr-reviewSnapShot .pr-multiselect-button-weight')
              const weightt = document.querySelector<HTMLElement>('#pr-reviewSnapShot .pr-multiselect-button-weightt')

              if (weightTop) {
                weightTop.style.display = 'inline-block'
              } else if (weight) {
                weight.style.display = 'inline-block'
              } else if (weightt) {
                weightt.style.display = 'inline-block'
              }

              if (!heightTop && !height && !weightTop && !weight && !weightt) {
                let contentEle = document.querySelector('#pr-reviewSnapShot .pr-rd-review-header-contents')
                if (contentEle) {
                  contentEle.classList.add('nofilters')
                }
              }
            },
          },
          { ...powerReviewsCommonObj },
        )

        // window.POWERREVIEWS.display.render({...finalObj});
        window.pwr('render', { ...finalObj })
        //  setTimeout(()=>{
        //     setFocusOnReviewTab();
        // }, 2000);
      }
      const starContainer = document.querySelector<HTMLElement>('#pr-stars .p-w-r')
      const getRec = document.querySelector<HTMLElement>('.pr-review-snapshot-recomend .pr-reco-value')
      if (starContainer && !starContainer.querySelector('.pr-rating-stars')) {
        let reviewCount = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-snippet-review-count')
        let reviewStars = document.querySelector<HTMLElement>('#pr-reviewsnippet .pr-rating-stars')
        if (reviewCount && reviewStars) {
          //reviewCount = parseInt(reviewCount?.innerText, 10)
          //productReviewStars = parseInt(reviewStars.innerText, 10)
          starContainer.innerHTML = ''
          starContainer.appendChild(reviewStars.cloneNode(true))
        }
      }
      if (getRec) {
        let ele = document.querySelector<HTMLElement>('#pr-reviewSnapShot .pr-review-snapshot-snippets-headline')
        if (ele) {
          ele.innerHTML = '<span>' + getRec.innerText + '</span> <span class="recText">Customer Recommended</span>'
        }
      }
    }, 1000)
  }
  const [qaRendered, setQaRendered] = useState(false)
  const reviewsTabChange = (ev) => {
    if (!qaRendered && ev === 1) {
      ;(window as any).POWERREVIEWS.display.render(
        Object.assign(
          {
            components: {
              QuestionDisplay: 'pr-qadisplay',
            },
          },
          { ...powerReviewsCommonObj },
        ),
      )
      setQaRendered(true)
    }
  }
  return (
    <Container maxWidth={'100%'}>
      <Box>
        <Tabs
          onChange={(event) => {
            reviewsTabChange(event)
          }}
        >
          <TabList borderBottom={'1px solid rgba(0,0,0,.12)'} mb={6}>
            <Tab fontSize={'1.188rem'} fontWeight={'700'} height={'48px'} padding={'0 24px 0 0'}>
              Reviews
            </Tab>
            <Tab fontSize={'1.188rem'} fontWeight={'700'} height={'48px'}>
              Q & A
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding={0}>
              <Box id="pr-reviewSnapShot"></Box>
            </TabPanel>
            <TabPanel>
              <Box id="pr-qadisplay"></Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  )
}
