import { Alert, AlertIcon, Box, Collapse, HStack, Stack, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SectionHeader, InputField, CheckboxField, SelectField } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { EditableContentCard } from './editable-content-card'
import { PaymentMethodDisplayOnly } from './payment-method-display-only'

export type AddCardFormItems = {
  // add card
  creditCardNickname: string
  creditCardNumber: string
  expiry: string
  nameOnCard: string
  securityCode: number
  // billing address
  address1: string
  city: string
  country: string
  defaultPayment: boolean
  firstName: string
  lastName: string
  phoneNumber: string
  state: string
  zipCode: string
}

interface PaymentMethodFormProps {
  isEditOpen?: boolean
  isOpen: boolean
  onToggle?: () => void
}

export const PaymentMethodForm = ({ isEditOpen, isOpen, onToggle }: PaymentMethodFormProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  const {
    register,
    reset,
    formState: { errors },
  } = useForm<AddCardFormItems>({
    resolver: yupResolver(PaymentMethodFormSchema()),
    mode: 'all',
  })

  return (
    <Stack mb={6}>
      <Collapse in={isOpen} animateOpacity unmountOnExit>
        <EditableContentCard
          size="Large"
          onCancel={onToggle}
          editModeOn={isEditOpen}
          editTitle={formatMessage({
            id: 'account.dashboard.paymentMethod.addCard',
          })}
          onSubmit={undefined}
          resetEditForm={reset}
          updateButtonLabel={formatMessage({
            id: 'account.dashboard.paymentMethod.addCard',
          })}
          edit={
            <VStack width="full" alignItems={'stretch'} spacing={10} padding={1}>
              <Box>
                <InputField
                  label={formatMessage({
                    id: 'account.dashboard.paymentMethod.creditCardNickname',
                  })}
                  inputProps={{
                    ...register('creditCardNickname'),
                  }}
                  error={errors.creditCardNickname}
                />
                <InputField
                  label={formatMessage({
                    id: 'account.dashboard.paymentMethod.creditCardNumber',
                  })}
                  inputProps={{
                    ...register('creditCardNumber'),
                  }}
                  error={errors.creditCardNumber}
                />
                <HStack paddingTop={2} paddingBottom={4} gap={1}>
                  {/*<VISA width={'24px'} height={'24px'} />*/}
                  {/*<MasterCard width={'24px'} height={'24px'} />*/}
                  {/*<UnionPay width={'24px'} height={'24px'} />*/}
                  {/*<AmericanExpress width={'24px'} height={'24px'} />*/}
                </HStack>
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <InputField
                    label={formatMessage({
                      id: 'account.dashboard.paymentMethod.expiry',
                    })}
                    inputProps={{
                      ...register('expiry'),
                    }}
                    error={errors.expiry}
                  />
                  <InputField
                    label={formatMessage({
                      id: 'account.dashboard.paymentMethod.securityCode',
                    })}
                    inputProps={{
                      ...register('securityCode'),
                    }}
                    error={errors.securityCode}
                  />
                </Stack>
                <InputField
                  label={formatMessage({
                    id: 'account.dashboard.paymentMethod.nameOnCard',
                  })}
                  inputProps={{
                    ...register('nameOnCard'),
                  }}
                  error={errors.nameOnCard}
                />
              </Box>
              <Box>
                <SectionHeader
                  boxProps={{ height: 8, marginBottom: 4 }}
                  hasRequiredFields
                  title={formatMessage({
                    id: 'account.dashboard.paymentMethod.billingAddress',
                  })}
                  textProps={{
                    as: 'h5',
                    width: 'fit-content',
                  }}
                />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <InputField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.firstName',
                    })}
                    inputProps={register('firstName')}
                    error={errors.firstName}
                    isRequired
                  />
                  <InputField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.lastName',
                    })}
                    inputProps={register('lastName')}
                    error={errors.lastName}
                    isRequired
                  />
                </Stack>

                <InputField
                  label={formatMessage({
                    id: 'checkout.shippingAddressForm.label.streetName',
                  })}
                  inputProps={register('address1')}
                  error={errors.address1}
                  isRequired
                />

                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                  <SelectField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.country',
                    })}
                    selectProps={{
                      background: 'background',
                      ...register('country'),
                    }}
                    error={errors.country}
                    isRequired
                  >
                    <option value="CA">Canada</option>
                    <option value="US">United States</option>
                  </SelectField>
                  <InputField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.postcode',
                    })}
                    inputProps={register('zipCode')}
                    error={errors.zipCode}
                    isRequired
                  />
                </Stack>

                <Stack spacing={4} direction={{ base: 'column', md: 'row' }}>
                  <InputField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.state',
                    })}
                    inputProps={register('state')}
                    error={errors.state}
                    isRequired
                  />
                  <InputField
                    label={formatMessage({
                      id: 'checkout.shippingAddressForm.label.city',
                    })}
                    inputProps={register('city')}
                    error={errors.city}
                    isRequired
                  />
                </Stack>

                <InputField
                  label={formatMessage({
                    id: 'checkout.shippingAddressForm.label.phoneNumber',
                  })}
                  inputProps={register('phoneNumber')}
                  error={errors.phoneNumber}
                />

                <Box py={4}>
                  <CheckboxField
                    content={formatMessage({
                      id: 'account.dashboard.paymentMethod.useAsDefaultPaymentCard',
                    })}
                    checkboxProps={{
                      ...register('defaultPayment'),
                    }}
                    error={errors.defaultPayment}
                  />
                </Box>
                <Alert status="warning">
                  <AlertIcon />
                  {formatMessage({ id: 'featureNotImplemented' })}
                </Alert>
              </Box>
            </VStack>
          }
        />
      </Collapse>

      <Box opacity={isOpen ? 0.2 : 1}>
        <PaymentMethodDisplayOnly />
      </Box>
    </Stack>
  )
}

const PaymentMethodFormSchema = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  return yup.object().shape({
    // add card section
    creditCardNickname: yup.string().required(formatMessage({ id: 'validation.required' })),
    creditCardNumber: yup.number().required(formatMessage({ id: 'validation.required' })),
    expiry: yup.string().required(formatMessage({ id: 'validation.required' })),
    securityCode: yup.number().required(formatMessage({ id: 'validation.required' })),
    nameOnCard: yup.string().required(formatMessage({ id: 'validation.required' })),
    // billing address section
    firstName: yup.string().required(formatMessage({ id: 'validation.firstNameRequired' })),
    lastName: yup.string().required(formatMessage({ id: 'validation.firstNameRequired' })),
    phoneNumber: yup.string().required(formatMessage({ id: 'validation.required' })),
    address1: yup.string().required(formatMessage({ id: 'validation.required' })),
    zipCode: yup.string().required(formatMessage({ id: 'validation.required' })),
    state: yup.string().required(formatMessage({ id: 'validation.required' })),
    city: yup.string().required(formatMessage({ id: 'validation.required' })),
    country: yup.string().required(formatMessage({ id: 'validation.required' })),
  })
}
