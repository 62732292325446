import { Divider, Stack, Box } from '@chakra-ui/react'
import { getCartSummaryAmounts } from 'composable'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { SummaryLineItem } from '../../summary-line-item'
import { useAtgLegacyCart } from 'frontastic/contexts'

export const CartDrawerSummary = () => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { formattedCart: cart } = useAtgLegacyCart()
  const { subtotal, discounts, total, shipping, isShippingMethodSelected } = getCartSummaryAmounts(cart)

  return (
    <Box>
      <Stack spacing={1}>
        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.subtotal' })}
          value={CurrencyHelpers.formatForCurrency(subtotal)}
          textStyle="body-50"
        />

        {isShippingMethodSelected ? (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={CurrencyHelpers.formatForCurrency(shipping)}
            textStyle="body-50"
          />
        ) : (
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
            value={formatMessage({ id: 'cart.summary.calculatedAtCheckout' })}
            textStyle="body-50"
          />
        )}

        <SummaryLineItem
          label={formatMessage({ id: 'cart.summary.tax' })}
          value={formatMessage({
            id: 'cart.summary.tax.calculatedAtPOS',
          })}
          textStyle="body-50"
        />

        {!!discounts && (
          <SummaryLineItem
            isDiscount
            label={formatMessage({ id: 'cart.summary.discount' })}
            value={CurrencyHelpers.formatForCurrency(discounts)}
            textStyle="body-50"
          />
        )}
      </Stack>
      <Divider mt="2" mb="3" />

      <SummaryLineItem
        label={formatMessage({ id: 'cart.summary.estimatedTotal' })}
        value={CurrencyHelpers.formatForCurrency(total) ?? CurrencyHelpers.formatForCurrency(0)}
        textStyle="heading-desktop-100"
      />
    </Box>
  )
}
