import { useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { CheckboxField, InputField } from 'composable'
import * as EmailValidator from 'email-validator'
import { useFormat } from 'helpers/hooks/useFormat'
import * as yup from 'yup'
import { useAccount } from 'frontastic'
import { CheckoutStateType } from '../../../types'
import { useCheckoutForm, UseCheckoutFormProps } from '../../use-checkout-form'

type FormDataType = Omit<CheckoutStateType['customer'], 'id'>

type GuestFormProps = Pick<UseCheckoutFormProps<FormDataType>, 'onChange' | 'initialValues'>

export const GuestForm = ({ initialValues, onChange }: GuestFormProps) => {
  const intl = useFormat({ name: 'common' })
  const { account } = useAccount()

  const { form } = useCheckoutForm<FormDataType>({
    onChange,
    formKey: 'guestForm',
    yupSchema: guestFormSchema({ intl }),
    initialValues,
  })

  const {
    register,
    formState: { errors },
    setValue,
  } = form

  useEffect(() => {
    if (account) {
      setValue('email', account.email)
    }
  }, [account])

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <InputField
          label={intl.formatMessage({
            id: 'checkout.guestForm.label.email',
          })}
          inputProps={{
            ...register('email'),
            onChange: (e) => setValue('email', e.target.value.toString().trim()),
            // defaultValue: initialValues.email ?? '',
            placeholder: intl.formatMessage({
              id: 'checkout.guestForm.placeholder.email',
            }),
          }}
          caption={intl.formatMessage({ id: 'checkout.guestForm.label.info' })}
          error={errors.email}
          isRequired
        />
      </form>
    </>
  )
}

const guestFormSchema = (deps: { intl: { formatMessage: (params: any) => string } }) => {
  const { intl } = deps
  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: 'validation.emailRequired' }))
      .test({
        name: 'email-is-valid',
        test: (value, ctx) => {
          if (!EmailValidator.validate(value)) {
            return ctx.createError({ message: intl.formatMessage({ id: 'validation.emailValid' }) })
          }
          return true
        },
      }),
  })
}
