import { YOTTAA_URL } from 'composable/components/general'
import Script from 'next/script'

export const YottaaSnippet = () => {
  const id = YOTTAA_URL
  return (
    <>
      <Script src={id} type="text/javascript" strategy="beforeInteractive" id="yottaScript"></Script>
    </>
  )
}
