import { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  Box,
  VStack,
  Center,
  HStack,
  Stack,
  ModalCloseButton,
} from '@chakra-ui/react'
import { CoreRadio } from 'components/core-ui-controls/RadioButton'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCheckout } from './checkout-provider'
import {
  ExperianAddressType,
  CheckoutAddressType,
  parseCheckoutAddressToString,
  parseExperianAddressToString,
  CheckoutAddressFormType,
} from '../../helpers/utils/experian-address-utils'
import { convertShippingToCTAddress } from 'composable/helpers/utils/checkout-utils'
import { Address } from '@Types/account/Address'

interface AddressConfirmationModalProps {
  addressConfirmationModal: boolean
  modalOnClose: () => void
  checkoutAddress?: CheckoutAddressType
  experianAddress?: ExperianAddressType
  setFormValues?: (values: ExperianAddressType) => CheckoutAddressFormType
  handleStep1Submit?: (overwriteShippingFromModal?: Address) => void
}

export const AddressConfirmationModal = ({
  addressConfirmationModal,
  modalOnClose,
  checkoutAddress,
  experianAddress,
  setFormValues,
  handleStep1Submit,
}: AddressConfirmationModalProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  const [selectedAddress, setSelectedAddress] = useState('recommended')
  const { setAddressConfirmationModal, setAddressConfirmationDecision } = useCheckout()

  const parsedCheckoutAddress = parseCheckoutAddressToString(checkoutAddress)
  const parsedRecommendedAddress = parseExperianAddressToString(experianAddress)

  const handleUseSelectedAddress = async () => {
    const updateAddress = () => {
      if (selectedAddress === 'recommended') {
        return setFormValues(experianAddress)
      } else {
        return undefined
      }
    }

    const updatedAddress = updateAddress()
    const overwriteAddress = updatedAddress && convertShippingToCTAddress(updatedAddress)

    try {
      handleStep1Submit(overwriteAddress)
    } catch (e) {
      console.error('Error updating address from Address Suggestion Modal', e)
    } finally {
      setAddressConfirmationModal(false)
      setAddressConfirmationDecision(true)
    }
  }

  return (
    <Modal
      isOpen={addressConfirmationModal}
      onClose={modalOnClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={{ base: 'xs', lg: 'sm' }}
    >
      <ModalOverlay />
      <ModalContent mx={{ base: 16, lg: 0 }}>
        <VStack width="100%" spacing={0}>
          <Box width="100%" p={2} borderBottom="1px solid" borderBottomColor="surface.border">
            <Center py={1}>
              <Text textStyle={'heading-desktop-100'} textAlign="center">
                {formatMessage({
                  id: 'checkout.step1.validationModal.title',
                })}
              </Text>
              <ModalCloseButton />
            </Center>
          </Box>

          <VStack width="100%" py={4} px={{ base: 4, lg: 6 }} spacing={4}>
            <Text width="100%" textStyle={'body-100'} color="text.muted" textAlign="left">
              {formatMessage({
                id: 'checkout.step1.validationModal.message',
              })}
            </Text>
            <Stack width="100%" direction={{ base: 'column', lg: 'row' }}>
              <HStack
                p={4}
                alignItems="flex-start"
                cursor="pointer"
                border="1px  solid"
                borderRadius="base"
                borderColor={selectedAddress === 'recommended' ? 'primary.text' : 'surface.border'}
                onClick={() => setSelectedAddress('recommended')}
                flex={1}
              >
                <CoreRadio
                  value="recommended"
                  size="md"
                  onClick={() => setSelectedAddress('recommended')}
                  isChecked={selectedAddress === 'recommended'}
                />
                <VStack flex={1} alignItems="flex-start">
                  <Text textStyle={'heading-desktop-75'}>
                    {formatMessage({
                      id: 'checkout.step1.validationModal.recommended',
                    })}
                  </Text>
                  <Text>{parsedRecommendedAddress}</Text>
                </VStack>
              </HStack>

              <HStack
                p={4}
                alignItems="flex-start"
                border="1px solid"
                borderRadius="base"
                cursor="pointer"
                borderColor={selectedAddress === 'youEntered' ? 'primary.text' : 'surface.border'}
                onClick={() => setSelectedAddress('youEntered')}
                flex={1}
              >
                <CoreRadio
                  value="youEntered"
                  size="md"
                  onClick={() => setSelectedAddress('youEntered')}
                  isChecked={selectedAddress === 'youEntered'}
                />
                <VStack flex={1} alignItems="flex-start">
                  <Text textStyle={'heading-desktop-75'}>
                    {formatMessage({
                      id: 'checkout.step1.validationModal.entered',
                    })}
                  </Text>
                  <Text>{parsedCheckoutAddress}</Text>
                </VStack>
              </HStack>
            </Stack>
          </VStack>

          <HStack width="100%" py={4} px={{ base: 4, lg: 6 }}>
            <Button
              variant="outline"
              size={{ base: 'sm', lg: 'lg' }}
              textStyle={{ base: 'ui-button-75', lg: 'ui-button-200' }}
              py={4}
              px={{ base: 4, lg: 6 }}
              flex={1}
              onClick={modalOnClose}
            >
              {formatMessage({
                id: 'checkout.step1.validationModal.action.editAddress',
              })}
            </Button>
            <Button
              variant="solid"
              size={{ base: 'sm', lg: 'lg' }}
              textStyle={{ base: 'ui-button-75', lg: 'ui-button-200' }}
              py={4}
              px={{ base: 4, lg: 6 }}
              flex={2}
              onClick={async () => await handleUseSelectedAddress()}
            >
              {formatMessage({
                id: 'checkout.step1.validationModal.action.useSelected',
              })}
            </Button>
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  )
}
