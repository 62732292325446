import { useEffect, useRef, useState } from 'react'
import { Skeleton, Box, Button, Flex, Table, Tbody, Td, Thead, Tr, Text, useBreakpointValue } from '@chakra-ui/react'
import { ContentstackLink } from '@Types/contentstack'
import { BaseProps } from './types'
import useContentstack, { Entry } from '../hooks/useContentstack'
import { NextLinkNoPrefetch } from '../link'

export interface BrandsListProps extends BaseProps {
  cta_list: ContentstackLink[]
}

export const BrandsList = ({ cta_list, isMobileDevice }: BrandsListProps) => {
  const [contentTable, setContentTable] = useState<any>({})
  const [tableRows, setTableRows] = useState<any>({})
  const { getEntryByUid, isLoading } = useContentstack()
  const alphaCodeRefs = useRef([])
  const [alphaKeys, setAlphaKeys] = useState([])
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  useEffect(() => {
    getContent(cta_list)
  }, [isMobile])

  useEffect(() => {
    alphaCodeRefs.current = alphaCodeRefs.current.slice(0, Object.keys(contentTable).length + 1)
    setAlphaKeys(Object.keys(contentTable))
  }, [contentTable])

  const buildTable = (list) => {
    const table = {
      '#': [[]],
      A: [[]],
      B: [[]],
      C: [[]],
      D: [[]],
      E: [[]],
      F: [[]],
      G: [[]],
      H: [[]],
      I: [[]],
      J: [[]],
      K: [[]],
      L: [[]],
      M: [[]],
      N: [[]],
      O: [[]],
      P: [[]],
      Q: [[]],
      R: [[]],
      S: [[]],
      T: [[]],
      U: [[]],
      V: [[]],
      W: [[]],
      X: [[]],
      Y: [[]],
      Z: [[]],
    }

    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        continue
      }

      const firstChar = list[i]?.link_label.charAt(0)
      const category = firstChar && isNaN(firstChar) ? firstChar : '#'

      let brandsArr: {
        link_label: string
        link_href: string
      }[]

      if (table[category][table[category].length - 1].length < (isMobile ? 2 : 5)) {
        brandsArr = table[category][table[category].length - 1]
        table[category][table[category].length - 1].push({
          link_label: list[i].link_label,
          link_href: list[i].link_href,
        })
      } else {
        brandsArr = []
        brandsArr.push({
          link_label: list[i].link_label,
          link_href: list[i].link_href,
        })
        table[category].push(brandsArr)
      }
    }

    return table
  }

  const tableRow = (
    rowData: {
      link_label: string
      link_href: string
    }[],
  ) => {
    return (
      <Tr display="flex" gap={6} paddingTop={3} paddingBottom={3} width="96%" borderBottom={'none'} marginRight={'6px'}>
        {rowData?.map((brand) => {
          return (
            <Td
              minWidth={'12vw'}
              textStyle={{ base: 'body-100', md: 'body-100' }}
              padding="0"
              borderBottom={'none'}
              key={brand.link_label}
            >
              <NextLinkNoPrefetch href={brand.link_href}>{brand.link_label}</NextLinkNoPrefetch>
            </Td>
          )
        })}
      </Tr>
    )
  }

  const getContent = async (items) => {
    try {
      const itemsResults = items?.map((item) => getEntryByUid('custom_link', item.uid))
      const results = await Promise.all(itemsResults)
      const table = buildTable(results)
      setContentTable(table)
      setTableRows({
        '#': table['#'].map((row) => tableRow(row)),
        A: table['A'].map((row) => tableRow(row)),
        B: table['B'].map((row) => tableRow(row)),
        C: table['C'].map((row) => tableRow(row)),
        D: table['D'].map((row) => tableRow(row)),
        E: table['E'].map((row) => tableRow(row)),
        F: table['F'].map((row) => tableRow(row)),
        G: table['G'].map((row) => tableRow(row)),
        H: table['H'].map((row) => tableRow(row)),
        I: table['I'].map((row) => tableRow(row)),
        J: table['J'].map((row) => tableRow(row)),
        K: table['K'].map((row) => tableRow(row)),
        L: table['L'].map((row) => tableRow(row)),
        M: table['M'].map((row) => tableRow(row)),
        N: table['N'].map((row) => tableRow(row)),
        O: table['O'].map((row) => tableRow(row)),
        P: table['P'].map((row) => tableRow(row)),
        Q: table['Q'].map((row) => tableRow(row)),
        R: table['R'].map((row) => tableRow(row)),
        S: table['S'].map((row) => tableRow(row)),
        T: table['T'].map((row) => tableRow(row)),
        U: table['U'].map((row) => tableRow(row)),
        V: table['V'].map((row) => tableRow(row)),
        W: table['W'].map((row) => tableRow(row)),
        X: table['X'].map((row) => tableRow(row)),
        Y: table['Y'].map((row) => tableRow(row)),
        Z: contentTable['Z'].map((row) => tableRow(row)),
      })
    } catch (err) {
      console.log('Error processing Mega Menu data', err)
    }
  }

  const handleScrollToRef = (e, ref) => {
    e.preventDefault()
    e.stopPropagation()
    ref.scrollIntoView({ behavior: 'smooth' })
  }

  if (isLoading) return <Skeleton />

  return (
    <Box mt={8} pt={12} ref={(el) => (alphaCodeRefs.current[0] = el)} scrollMarginTop={'-125px'}>
      <Text marginLeft={'24px'} mb={5} textStyle={{ base: 'heading-desktop-300', md: 'heading-desktop-300' }}>
        Brands A-Z
      </Text>
      <Flex
        zIndex={5}
        position="sticky"
        top="-1px"
        bgColor="white"
        mb={5}
        borderBottom={'md'}
        borderColor="surface.border"
        marginLeft="24px"
        marginRight={'6px'}
        width="96%"
        overflow="auto"
        flex="1"
        css={{
          '&::-webkit-scrollbar': {
            color: 'surface.primary',
          },
        }}
      >
        {alphaKeys?.map((alphaCode, i) => {
          return (
            <Button
              marginLeft={i === 0 ? '-14px' : '12px'}
              marginRight={'4px'}
              _hover={{
                backgroundColor: 'white',
                textDecoration: 'none',
                color: 'text.primary !important',
                fontWeight: 'semibold',
              }}
              padding="0"
              size="sm"
              minWidth="35px"
              textDecoration={'none'}
              fontWeight="normal"
              textColor={'text.primary'}
              color={'text'}
              variant={'ghost'}
              aria-label={`control-${alphaCode}`}
              key={`control-${alphaCode}`}
              onClick={(e) => handleScrollToRef(e, alphaCodeRefs.current[i])}
            >
              {alphaCode}
            </Button>
          )
        })}
      </Flex>
      {alphaKeys?.length && (
        <Box>
          <Table>
            <Thead>
              <Box
                borderBottom="2px"
                borderColor="surface.border"
                paddingBottom="sm"
                marginLeft="24px"
                marginRight="24px"
                scrollMarginTop={'36px'}
              >
                <Text textStyle={{ base: 'heading-desktop-300', md: 'heading-desktop-300' }} marginTop="sm">
                  {'#'}
                </Text>
                {contentTable['#'].map((row) => tableRow(row))}
              </Box>
            </Thead>
            <Tbody>
              {alphaKeys
                .sort()
                .filter((alphaCode) => alphaCode !== '#')
                .map((alphaCode, i) => {
                  return (
                    <Box
                      key={alphaCode}
                      scrollMarginTop={'36px'}
                      ref={(el) => (alphaCodeRefs.current[i + 1] = el)}
                      borderBottom="2px"
                      borderColor="surface.border"
                      paddingBottom="sm"
                      marginLeft="24px"
                      marginRight="24px"
                    >
                      <Text marginTop="sm" textStyle={{ base: 'heading-desktop-300', md: 'heading-desktop-300' }}>
                        {alphaCode}
                      </Text>
                      {contentTable[alphaCode].map((row) => tableRow(row))}
                    </Box>
                  )
                })}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  )
}
