import { Box, HStack, StackProps, Text, TextProps, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

interface PriceProps {
  priceProps?: TextProps
  price: string
  rootProps?: StackProps
  discountedPrice?: string
  discountPercent?: number
  discountedPriceProps?: TextProps
  promoPrice?: string
  priceRange?: any
  priceGroupQuantity?: number
  priority?: 'PROMO' | 'RANGE' | 'REGULAR'
}

const PromoPriceLayout = ({ price, priceProps, promoPrice, discountedPrice, priceGroupQuantity }) => {
  const intl = useFormat({ name: 'common' })

  return (
    <VStack gap={0} alignItems={'flex-start'}>
      <Text as="span" textStyle={'blockquote'} color={'text'} {...priceProps}>
        {price}
      </Text>

      <Box display={'flex'} alignItems={'center'} textStyle={'body-75'}>
        <Text textStyle={'heading-desktop-75'}>{promoPrice}</Text>
        <Text>&nbsp;{intl.formatMessage({ id: 'product.promotion.label' })}</Text>
      </Box>
      {priceGroupQuantity > 1 && (
        <Text textStyle={'body-75'} color="var(--semantic-text-danger, #C42D3A)">
          {intl.formatMessage({ id: 'product.discount.disclaimer' })}
        </Text>
      )}
    </VStack>
  )
}

const RangePriceLayout = ({ priceRange }) => {
  return (
    <>
      <Text as="span" textStyle={'blockquote'} color={'text'}>
        {priceRange?.minPrice}
      </Text>
      {'-'}
      <Text as="span" textStyle={'blockquote'} color={'text'}>
        {priceRange?.maxPrice}
      </Text>
    </>
  )
}

const PriceLayout = ({ discountedPrice, discountPercent, priceProps, discountedPriceProps, price }) => {
  const intl = useFormat({ name: 'common' })

  return (
    <VStack gap={0} alignItems={'flex-start'}>
      <HStack>
        {discountedPrice && (
          <Text
            as="span"
            textStyle={'blockquote'}
            color={'var(--semantic-text-muted, #76797E)'}
            textDecoration={'line-through'}
            {...discountedPriceProps}
          >
            {discountedPrice}
          </Text>
        )}
        <Text as="span" textStyle={'blockquote'} color={'text'} {...priceProps}>
          {price}
        </Text>
      </HStack>
      {discountedPrice && discountPercent && (
        <Text textStyle={'body-100'} color="danger.500">
          {intl.formatMessage({ id: 'product.discount.label', values: { discountPercent } })}
        </Text>
      )}
    </VStack>
  )
}

enum PiceLayouts {
  PROMO,
  RANGE,
  REGULAR,
}

const defineLayout = ({ promoPrice, priceRange, priceGroupQuantity }): PiceLayouts => {
  if (promoPrice) {
    return PiceLayouts.PROMO
  }

  if (priceRange && priceGroupQuantity > 1) {
    return PiceLayouts.RANGE
  }

  return PiceLayouts.REGULAR
}

export const Price = (props: PriceProps) => {
  const {
    priceProps,
    price,
    rootProps,
    discountedPrice,
    discountedPriceProps,
    discountPercent,
    promoPrice,
    priceRange,
    priceGroupQuantity,
  } = props

  if (!price) return null

  const priceLayout = defineLayout({ promoPrice, priceRange, priceGroupQuantity })

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <HStack spacing="1" {...rootProps}>
        {priceLayout === PiceLayouts.PROMO && (
          <PromoPriceLayout
            price={price}
            priceProps={priceProps}
            promoPrice={promoPrice}
            discountedPrice={discountedPrice}
            priceGroupQuantity={priceGroupQuantity}
          />
        )}
        {priceLayout === PiceLayouts.RANGE && <RangePriceLayout priceRange={priceRange} />}
        {priceLayout === PiceLayouts.REGULAR && (
          <PriceLayout
            discountedPrice={discountedPrice}
            discountedPriceProps={discountedPriceProps}
            discountPercent={discountPercent}
            priceProps={priceProps}
            price={price}
          />
        )}
      </HStack>
    </Box>
  )
}
