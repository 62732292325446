export const ALGOLIA_BASE_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_BASE_INDEX ?? ''

export const IMAGE_PLACEHOLDER = '/img/image-placeholder.svg'

export const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

export const APP_CONTEXT = process.env.NEXT_PUBLIC_APP_CONTEXT ?? 'STS'
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION ?? 'R1'

/* Brand name determines what contentful content will be pulled
for ex:
- single site can set BRAND_NAME to '',
- multi-site: set BRAND_A, BRAND_B etc
*/
export const BRAND_NAME = process.env.NEXT_PUBLIC_BRAND_NAME ?? ''

export const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE

export const PRODUCT_CHANNEL =
  process.env.NEXT_PUBLIC_COMMERCETOOLS_INVENTORY_CHANNEL ?? (BRAND_NAME ? `${BRAND_NAME}-channel` : undefined)

export const countries = [
  {
    name: 'Canada',
    code: 'CA',
    intlId: 'text.canada',
  },
  {
    name: 'United States',
    code: 'US',
    intlId: 'text.unitedStates',
  },
]

export const RICHTEXT_SCHEMA = {
  //SCHEMA need to be match with CMS to fetch content
  pdp: {
    shipingAndReturn: 'product/category/{{category}}/shipping_and_return',
  },
  account: {
    shipingAndReturn: 'account/shipping_and_return',
  },
}

export const ENABLE_BOLD_CHECKOUT = process.env.NEXT_PUBLIC_CHECKOUT
  ? process.env.NEXT_PUBLIC_CHECKOUT === 'bold'
  : false

export const BOLD_CHECKOUT_HOST = process.env.NEXT_PUBLIC_BOLD_CHECKOUT_HOST ?? ''

export const BOLD_COMMERCE_SHOP_ALIAS = process.env.NEXT_PUBLIC_BOLD_COMMERCE_SHOP_ALIAS ?? ''

export const SITEMAP_CONFIG = {
  LIMIT: 99,
  LOCALE: 'en-US',
  CURRENCY: 'USD',
  SITEMAP_INDEX_SLUG: 'server.xml',
  SITEMAP_PRODUCT_PATH: 'sitemap/products-{page}.xml',
  SITEMAP_CATEGORY_PATH: 'sitemap/categories-{page}.xml',
  PRODUCT_REGEX: /^products-*-(\d+)\.xml$/,
  CATEGORY_REGEX: /^categories-*-(\d+)\.xml$/,
} as const

// DATADOG
export const DATADOG_APPLICATION_ID = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? ''
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? ''
export const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV ?? ''
export const DATADOG_SITE = process.env.NEXT_PUBLIC_DATADOG_SITE ?? ''
export const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? ''
export const DATADOG_SAMPLE_RATE = parseInt(process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE ?? '0')
export const DATADOG_REPLAY = parseInt(process.env.NEXT_PUBLIC_DATADOG_REPLAY ?? '0')

export const NOT_MATCH_PAGE_TITLE = '[DXL] - 404 Not Found'
export const NOT_MATCH_PAGE_CONTENT_TYPE = 'component_banner_split'

export const SITE_CONFIG_NAME = '[DXL STS] Site Config'
export const SITE_CONFIG_CONTENT_TYPE = 'site_config'

export const MAXIMUM_MEGA_MENU_GROUP_CHILDREN = 5

export const COOKIE_PRO_VERSION_ID = process.env.NEXT_PUBLIC_COOKIE_PRO_VERSION_ID ?? ''
export const COMPOSABLE_ENV = process.env.NEXT_PUBLIC_COMPOSABLE_ENV ?? ''
export const IS_PREVIEW_MODE = process.env.NEXT_PUBLIC_IS_PREVIEW_MODE ?? ''
export const LOCALE = 'en-US'
export const STRICTLY_NECESSARY_COOKIE_CATEGORY = 'optanon-category-C0001'
export const PERFORMANCE_COOKIE_CATEGORY = 'optanon-category-C0002'
export const FUNCTIONAL_COOKIE_CATEGORY = 'optanon-category-C0003'
export const TARGETING_COOKIE_CATEGORY = 'optanon-category-C0004'
export const SOCIAL_MEDIA_COOKIE_CATEGORY = 'optanon-category-C0005'

export const ZETA_UAT_URL = process.env.NEXT_PUBLIC_ZETA_UAT_URL ?? ''
export const ZETA_PROD_URL = process.env.NEXT_PUBLIC_ZETA_PROD_URL ?? ''
export const MERKLE_URL = process.env.NEXT_PUBLIC_MERKLE_URL ?? ''
export const ATG_BACKEND_ENDPOINT_DOMAIN = process.env.NEXT_PUBLIC_ATG_BE_DOMAIN ?? ''
export const FORTER_SITE_ID = process.env.NEXT_PUBLIC_FORTER_SITE_ID ?? ''
export const BORDERFREE_ORDER_TRACKING_URL = process.env.NEXT_PUBLIC_BORDERFREE_ORDER_TRACKING_URL ?? ''
export const GOOGLE_SIGNIN_ACCOUNT_ID = process.env.NEXT_PUBLIC_GOOGLE_SIGNIN_ACCOUNT_ID ?? ''
export const EPSILON_SCRIPT_URL = process.env.NEXT_PUBLIC_EPSILON_SCRIPT_URL ?? ''
export const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? ''
export const YOTTAA_URL = process.env.NEXT_PUBLIC_YOTTAA_SCRIPT_URL ?? ''
export const BORDER_FREE_WELCOME_SCRIPT_URL = process.env.NEXT_PUBLIC_BORDER_FREE_WELCOME_SCRIPT_URL

export const POWERREVIEWS_MERCHANT_GROUP_ID = process.env.NEXT_PUBLIC_POWERREVIEWS_MERCHANT_GROUP_ID ?? ''
export const POWERREVIEWS_MERCHANT_ID = process.env.NEXT_PUBLIC_POWERREVIEWS_MERCHANT_ID ?? ''
export const POWERREVIEWS_API_KEY = process.env.NEXT_PUBLIC_POWERREVIEWS_API_KEY ?? ''

export const FIND_MINE_APPLICATION_ID = process.env.NEXT_PUBLIC_FINDMINE_APPLICATION_ID ?? ''
export const BRANCH_IO_SCRIPT_KEY = process.env.NEXT_PUBLIC_BRANCH_IO_SCRIPT_KEY ?? ''
export const EXPERIAN_API_TOKEN_KEY = process.env.NEXT_PUBLIC_EXPERIAN_API_TOKEN_KEY ?? ''
export const TEALIUM_SITE_ID = 'dxl'
