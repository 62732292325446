import { FunctionComponent, useState } from 'react'
import Image from 'next/image'
import { Box, Text, VStack, useBreakpointValue, Flex, useToken } from '@chakra-ui/react'
import { Badge } from 'composable/components/badge'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoImagesOutline } from 'react-icons/io5'
import { ProductSwatchSelectorSize, SwatchSelector } from './swatch-selector'
import { CurrencyHelpers } from '../../../../../helpers_composable/currencyHelpers'
import { StarterKitAlgoliaProduct } from '../../types'
import { useRouter } from 'next/router'
import { Thumbnails } from '../../../types'
import { PrRatings } from 'composable/components/powerreview/pr-ratings'

interface CategoryProductCardProps {
  product: Partial<StarterKitAlgoliaProduct>
  priority?: boolean
  idx: any
}

enum PLPPriceLayouts {
  SINGLE,
  RANGE,
  SALE_SINGLE,
  SALE_RANGE,
  SALE_EVENT,
  PROMO,
}

const defineLayout = ({ price, edsPrice, discountAmount }): PLPPriceLayouts => {
  if (edsPrice) return PLPPriceLayouts.PROMO

  if (!discountAmount && Array.isArray(price) && price?.length > 1) return PLPPriceLayouts.RANGE

  if (discountAmount && !edsPrice && (!Array.isArray(price) || price?.length === 1)) return PLPPriceLayouts.SALE_SINGLE

  if (discountAmount && Array.isArray(price) && !edsPrice) return PLPPriceLayouts.SALE_RANGE

  return PLPPriceLayouts.SINGLE
}

const SinglePrice = ({ price }) => {
  return (
    <Box display={'flex'} flexDirection={'row'} gap={1}>
      <Text textStyle={'body-75'}>{CurrencyHelpers.formatForCurrency(price.centAmount)}</Text>
    </Box>
  )
}

const RangePrice = ({ prices }) => {
  if (!Array.isArray(prices) && prices.length < 1) return null

  return (
    <Box display={'flex'} flexDirection={'row'} gap={1}>
      <Text textStyle={'body-75'}>{CurrencyHelpers.formatForCurrency(prices[0].centAmount)}</Text>
      <Text textStyle={'body-75'}>{` - `}</Text>
      <Text textStyle={'body-75'}>{CurrencyHelpers.formatForCurrency(prices[1].centAmount)}</Text>
    </Box>
  )
}

const SaleSinglePrice = ({ price, discountedPrice, discountAmount, saleEvent }) => {
  const intl = useFormat({ name: 'common' })

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box display={'flex'} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
        {!saleEvent && (
          <Text textStyle={'figma-strikethrough-75'}>
            {CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount)}
          </Text>
        )}
        <Text textStyle={'body-75'} color={saleEvent ? 'primary' : 'danger.600'}>
          {CurrencyHelpers.formatForCurrency(price.centAmount)}
        </Text>
        <Text textStyle={'body-75'} color={'danger.600'}>
          {intl.formatMessage({ id: 'product.discount.label', values: { discountPercent: discountAmount } })}
        </Text>
      </Box>

      {saleEvent && (
        <Text textStyle={'body-75'} color={'danger.600'}>
          {saleEvent}
        </Text>
      )}
    </Box>
  )
}

const SaleRangePrice = ({ prices, maxDiscountAmount }) => {
  const intl = useFormat({ name: 'common' })

  if (prices?.length < 1) return null

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Text textStyle={'body-75'}>{CurrencyHelpers.formatForCurrency(prices[0].centAmount)}</Text>
        <Text textStyle={'body-75'}>{` - `}</Text>
        <Text textStyle={'body-75'}>{CurrencyHelpers.formatForCurrency(prices[1].centAmount)}</Text>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Text textStyle={'body-75'} color={'danger.600'}>
          {intl.formatMessage({ id: 'product.discountMax.label', values: { discountPercent: maxDiscountAmount } })}
        </Text>
      </Box>
    </Box>
  )
}

const EdsPromoPrice = ({ price, edsPrice }) => {
  const intl = useFormat({ name: 'common' })

  return (
    <Box display={'flex'} flexDirection={'row'} gap={1}>
      <Text textStyle={'body-75'}>
        {CurrencyHelpers.formatForCurrency(price.centAmount)}
        {` `}
        {intl.formatMessage({
          id: 'product.prmotion.plp.label',
          values: { edsPrice: CurrencyHelpers.formatForCurrency(edsPrice.centAmount) },
        })}
      </Text>
    </Box>
  )
}

const PriceSection = ({ product }) => {
  if (!product?.price) return null

  const discountPrice = Array.isArray(product.basePrice) ? product.basePrice[0] : product.basePrice
  const price = Array.isArray(product.price) ? product.price[0] : product.price
  const edsPrice = product.edsPrice
  const discountAmount = product.discountAmount
  const saleEvent = product.saleEvent

  const priceLayout = defineLayout({
    price: product.price,
    edsPrice,
    discountAmount,
  })

  if (priceLayout === PLPPriceLayouts.SINGLE) {
    return <SinglePrice price={price} />
  }
  if (priceLayout === PLPPriceLayouts.RANGE) {
    return <RangePrice prices={product?.price} />
  }
  if (priceLayout === PLPPriceLayouts.SALE_SINGLE) {
    return (
      <SaleSinglePrice
        price={price}
        discountedPrice={discountPrice}
        discountAmount={discountAmount}
        saleEvent={saleEvent}
      />
    )
  }
  if (priceLayout === PLPPriceLayouts.SALE_RANGE) {
    return <SaleRangePrice prices={product.price} maxDiscountAmount={discountAmount} />
  }
  if (priceLayout === PLPPriceLayouts.PROMO) {
    return <EdsPromoPrice price={price} edsPrice={edsPrice} />
  }
}

export const CategoryProductCard: FunctionComponent<CategoryProductCardProps> = ({
  product,
  priority = false,
  idx,
}) => {
  const router = useRouter()
  const [brandSecondary] = useToken('colors', ['brand.secondary'])
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [selectedImage, setSelectedImage] = useState<Thumbnails | undefined>(
    product?.thumbnails?.length ? product?.thumbnails[0] : undefined,
  )

  const intl = useFormat({ name: 'common' })

  // TODO: define the corect logic to show bardge and
  // discounted price once the discounts are implemented
  const showBadge = product.discounted_percent || product.discountAmount
  const watcherSize: ProductSwatchSelectorSize = useBreakpointValue({
    '2xs': 'small',
    xs: 'small',
    sm: 'small',
    md: 'small',
    lg: 'small',
    xl: 'large',
    '2xl': 'large',
    '3xl': 'large',
  })

  const imageSrc = selectedImage ? selectedImage.image_url : product.image?.url !== '' ? product.image?.url : undefined
  const imageAlt = selectedImage ? selectedImage.alt : product.image?.label ?? product.name

  const handleCategoryProductCardClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const productUrl = `/p/${product.slug}${selectedImage ? `?swatch=${selectedImage.color_name}` : ''}`

    router.push(productUrl)
  }

  return (
    <VStack as="article" w="100%" spacing={3} alignItems="initial">
      <Box onClick={handleCategoryProductCardClick} cursor="pointer" position="relative">
        <Flex aspectRatio={3 / 4} width={'full'} height={'full'}>
          {imageSrc ? (
            <Image src={imageSrc} alt={imageAlt} layout="fill" objectFit="contain" />
          ) : (
            <Flex
              role={'img'}
              aria-label={product.name}
              justify={'center'}
              align={'center'}
              width={'full'}
              height={'full'}
              bgColor={'brand.muted'}
            >
              <IoImagesOutline size={'40px'} color={brandSecondary} />
            </Flex>
          )}
        </Flex>
        {showBadge && (
          <Box position="absolute" top="0" left="0" padding={'0.5rem'}>
            <Badge
              value={intl.formatMessage({ id: 'category.search.sale' })}
              variant={'solidRed'}
              size={{ base: 'small', lg: 'large' }}
              verticalAlign={'baseline'}
            />
          </Box>
        )}
      </Box>
      {product?.thumbnails?.length && (
        <SwatchSelector
          thumbnails={product?.thumbnails}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          size={isMobile ? 'small' : watcherSize}
          showText
          productSlug={`/p/${product.slug}`}
        />
      )}
      <VStack gap={0.5} alignItems="initial">
        <Text textStyle="body-75" color={'text-muted'}>
          {product.brand}
        </Text>
        <Text onClick={handleCategoryProductCardClick} cursor="pointer" textStyle={'body-100'}>
          {product.name}
        </Text>
        <PriceSection product={product} />
      </VStack>
      <PrRatings hitss={product} index={idx}></PrRatings>
    </VStack>
  )
}
