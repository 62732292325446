import React from 'react'
import { Text } from '@chakra-ui/react'
import { Section, SectionHeader } from 'composable'

interface SuccessSectionProps {
  title: string
  children: React.ReactNode
}

export const SuccessSection = ({ title, children }: SuccessSectionProps) => {
  return (
    <Section
      boxProps={{
        width: '100%',
        py: { base: 6, lg: 10 },
        px: { base: 4, lg: 10 },
      }}
    >
      <SectionHeader title={<Text fontWeight="extrabold">{title}</Text>} />
      {children}
    </Section>
  )
}
