import { FunctionComponent } from 'react'
import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { UseInfiniteHitsProps, useInfiniteHits } from 'react-instantsearch-hooks-web'
import { StarterKitAlgoliaProduct } from '../../types'

type ItemCountProps = {
  flexProps?: FlexProps
  infiniteHitsProps: UseInfiniteHitsProps<StarterKitAlgoliaProduct>
}

export const ItemCount: FunctionComponent<ItemCountProps> = ({ infiniteHitsProps, flexProps }) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { results } = useInfiniteHits(infiniteHitsProps)

  return (
    <Flex direction="column" {...flexProps}>
      <Text fontSize={{ base: 'xs', lg: 'base' }} fontWeight="normal">
        {formatMessage({
          id: 'category.results.displaying',
        })}
      </Text>

      <Text fontSize={{ base: 'base', lg: 'lg' }} fontWeight="normal" color="gray.500">
        {`${formatMessage({
          id: 'category.results.itemCount',
          values: { itemCount: results.nbHits },
        })}`}
      </Text>
    </Flex>
  )
}
