import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5'
import { getTodayTiming, filterTime } from './utils'

const StoreTiming = ({ storeTiming, index }) => {
  const [show, setShow] = useState(false)
  const onClickShow = () => {
    setShow(!show)
  }
  const style = {
    marginLeft: '10px',
    marginTop: '0.25px',
  }

  return (
    <>
      <Box fontSize={14}>
        <Box cursor={'pointer'}>
          <Flex onClick={onClickShow} marginBottom={!show ? '0px' : '12px'}>
            <span dangerouslySetInnerHTML={{ __html: getTodayTiming(storeTiming) }}></span>{' '}
            <span style={style}>
              {!show ? <IoChevronDownOutline fontSize={16} /> : <IoChevronUpOutline fontSize={16} />}
            </span>{' '}
          </Flex>
        </Box>
        <Box>{show && <div dangerouslySetInnerHTML={{ __html: filterTime(storeTiming) }}></div>}</Box>
      </Box>
    </>
  )
}

export default StoreTiming
