import { RouterProps } from 'instantsearch.js/es/middlewares/createRouterMiddleware'
import { UiState } from 'instantsearch.js/es/types'
import { DEFAULT_SORTBY } from './constants'
import { getAlgoliaStateFilters, getRouteFilters, getSortByFromIndex } from './utils'

interface CustomRouteSearchState {
  query?: string
  sort?: string
  page?: number
  [filters: string]: any
}

interface GetSingleIndexRouterProps {
  historyFn: (props: any) => any
  indexName: string
  locale: string
  isSearchPage: boolean
  ignorePaging: boolean
  url: string
  indexNameResolver?: (props: { locale: string; sortBy?: string; isLoggedIn?: boolean }) => string
}

type GetSingleIndexRouter = (props: GetSingleIndexRouterProps) => RouterProps<UiState, UiState>

export const getSingleIndexRouter: GetSingleIndexRouter = (props) => {
  return {
    router: props.historyFn({
      getLocation: () => {
        if (typeof window === 'undefined') {
          return new URL(props.url!) as unknown as Location
        }
        return window.location
      },
    }),
    stateMapping: {
      stateToRoute: (uiState) => stateToRoute(props, uiState) as UiState,
      routeToState: (routeState) => routeToState(props, routeState),
    },
  }
}

export const getStateMapping = (props: Omit<GetSingleIndexRouterProps, 'historyFn' | 'url'>) => {
  return {
    stateToRoute: (uiState: UiState) => stateToRoute(props, uiState) as UiState,
    routeToState: (routeState: CustomRouteSearchState) => routeToState(props, routeState),
  }
}

const stateToRoute = (
  {
    indexName,
    locale,
    isSearchPage,
    ignorePaging,
    indexNameResolver,
  }: Omit<GetSingleIndexRouterProps, 'historyFn' | 'url'>,
  uiState: UiState,
): CustomRouteSearchState => {
  const indexUiState = uiState[indexName]
  const sort = getSortByFromIndex(indexUiState.sortBy || indexName, locale, indexNameResolver)
  const refinementList = getRouteFilters(indexUiState.refinementList)
  const numericMenu = getRouteFilters(indexUiState.numericMenu)
  return {
    ...refinementList,
    ...numericMenu,
    query: isSearchPage ? indexUiState.configure?.query : undefined,
    sort: sort !== DEFAULT_SORTBY ? sort : undefined,
    page: !ignorePaging ? indexUiState.page : undefined,
  }
}

const routeToState = (
  { indexName, locale, ignorePaging, indexNameResolver }: Omit<GetSingleIndexRouterProps, 'historyFn' | 'url'>,
  routeState: CustomRouteSearchState,
): UiState => {
  const { query, sort, page, ...rawFilters } = routeState
  const filters = getAlgoliaStateFilters(rawFilters)
  return {
    [indexName]: {
      ...filters,
      query: query,
      sortBy: indexNameResolver?.({ locale, sortBy: sort }),
      page: !ignorePaging ? page : undefined,
    },
  }
}
