import { Box, Link as ChakraLink, Container, LinkProps, List, ListItem, SimpleGrid, Text } from '@chakra-ui/react'
import { LayoutConfig } from './MegaMenuItem'
import { ContentstackCoverCard, MEGA_MENU_COVER_CARD, MEGA_MENU_ITEM } from '../contentstack'
import { Entry } from '../hooks/useContentstack'

export const MegaMenuContentColumns = (props: {
  items?: Entry[]
  linkProps: LinkProps
  layoutConfig: LayoutConfig
  onClose?: (e: any) => void
}) => {
  return (
    <Container maxW={1360} p={0} justifyContent={'space-between'}>
      <SimpleGrid width="100%" columns={props.layoutConfig?.columns} gap={4}>
        {props.items?.map((el, idx) => {
          const key = el?.uid

          if (!el?.children?.length) return null

          return (
            <MegaMenuContentColumnsColumn
              key={`${idx}-${key}`}
              item={el}
              level={0}
              linkProps={props.linkProps}
              isLast={props.items?.length === idx + 1}
              layoutConfig={props.layoutConfig}
              onClose={props.onClose}
            />
          )
        })}
      </SimpleGrid>
    </Container>
  )
}

const MegaMenuContentColumnsColumn = (props: {
  item: any
  linkProps: LinkProps
  level?: number
  isLast: boolean
  layoutConfig: LayoutConfig
  onClose?: (e: any) => void
}) => {
  const label = props.item?.label ?? ''
  const href = props.item?.href ?? ''
  const level = props.level ?? 1

  const children = props.item?.children
  const hasChildren = children?.length > 0

  const isDoubleCoverCard = children?.length === 2 && children[0]?._content_type_uid === MEGA_MENU_COVER_CARD

  return (
    <Box>
      {hasChildren ? (
        <Box p={0}>
          <Text
            mb={1}
            mt={level === 1 && props.isLast ? 8 : 0}
            textStyle={'heading-desktop-100'}
            color={'var(--semantic-text-muted, #76797E)'}
          >
            {label}
          </Text>
          <List aria-hidden={false} aria-expanded={true} aria-label={`${label} submenu`}>
            <SimpleGrid columns={1} gap={isDoubleCoverCard ? 4 : 0}>
              {children?.map((el: any, idx: number) => {
                const href = el?.href

                if (el?._content_type_uid === MEGA_MENU_COVER_CARD) {
                  return (
                    <ContentstackCoverCard
                      key={el.uid}
                      {...el}
                      root={
                        isDoubleCoverCard
                          ? { minHeight: ['217px'] }
                          : {
                              minHeight:
                                props.layoutConfig.type === 'cardsOnly'
                                  ? [isDoubleCoverCard ? ['217px'] : ['450px']]
                                  : ['309px'],
                            }
                      }
                      onClickHandler={(e) => props.onClose(e)}
                    />
                  )
                }

                if (el?._content_type_uid === MEGA_MENU_ITEM) {
                  return (
                    <ListItem key={`${idx}-${href}`}>
                      <MegaMenuContentColumnsColumn
                        item={el}
                        level={idx}
                        linkProps={props.linkProps}
                        isLast={children.length === idx + 1}
                        layoutConfig={props.layoutConfig}
                        onClose={props.onClose}
                      />
                    </ListItem>
                  )
                }
              })}
            </SimpleGrid>
          </List>
        </Box>
      ) : (
        <Box height={'45px'} py={3}>
          <ChakraLink
            tabIndex={0}
            href={href}
            textStyle={'body-75'}
            color={'var(--semantic-text-primary, #15191C)'}
            {...props.linkProps}
          >
            {label}
          </ChakraLink>
        </Box>
      )}
    </Box>
  )
}
