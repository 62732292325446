import { useEffect } from 'react'
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Icon, Text } from '@chakra-ui/react'
import { PAYMENT_METHOD_CASH, SectionHeader } from 'composable'
import { useFormat } from 'helpers/hooks/useFormat'
import { BsCashCoin } from 'react-icons/bs'
import { MdOutlineStore } from 'react-icons/md'
import { BillingAddressForm } from './billing-address-form'
import { OfflinePayment } from './offline-payment'
import { useCheckout } from '../checkout-provider'

export const PaymentMethodSection = () => {
  const intl = useFormat({ name: 'common' })
  const { paymentHandler } = useCheckout()

  useEffect(() => {
    paymentHandler.register({
      key: PAYMENT_METHOD_CASH,
      title: intl.formatMessage({
        id: 'checkout.paymentSection.offlinePayment',
      }),
      icon: BsCashCoin,
    })
  }, [])

  const handleSelectPaymentMethod = (isSelected: boolean, key: string) => {
    if (isSelected) {
      paymentHandler.select(key)
    } else {
      paymentHandler.select()
    }
  }

  const defaultPanelIndex = paymentHandler.list.findIndex(
    (pmtMethod) => pmtMethod.key === paymentHandler?.selected?.key,
  )

  return (
    <Box>
      <Accordion defaultIndex={defaultPanelIndex !== -1 ? [defaultPanelIndex] : [0]}>
        {paymentHandler.list.map((pmtMethod, index) => (
          <AccordionItem
            key={index}
            borderTop={0}
            borderBottomWidth={index < paymentHandler.list.length - 1 ? '1px' : '0px !important'}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  fontSize="base"
                  // onClick={() => handleSelectPaymentMethod(!isExpanded, pmtMethod.key)}
                  px={0}
                  py={4}
                  gap={2}
                  _hover={{ bg: 'none', cursor: 'auto' }}
                >
                  {/* {pmtMethod.icon && <Icon as={pmtMethod.icon} />} */}
                  <Icon as={MdOutlineStore} boxSize={4} />
                  <Text textAlign="left" textStyle={'body-100'} fontSize="md">
                    {pmtMethod.title}
                  </Text>
                  {/* {isExpanded ? <Icon as={IoClose} /> : <Icon as={FiPlus} />} */}
                </AccordionButton>
                <AccordionPanel px={0} pb={0}>
                  <Box bg="shading.100" p={6}>
                    {isExpanded && (
                      <>
                        {pmtMethod.key === PAYMENT_METHOD_CASH && <OfflinePayment />}
                        <BillingAddressSubsection />
                      </>
                    )}
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

const BillingAddressSubsection = () => {
  const intl = useFormat({ name: 'common' })
  const { checkoutState, setCheckoutState } = useCheckout()
  const {
    config: { billingIsShipping },
  } = checkoutState

  return (
    <Box>
      <SectionHeader
        title={intl.formatMessage({
          id: 'checkout.billingSection.title',
        })}
        hasRequiredFields={!billingIsShipping}
        boxProps={{
          mb: 'sm',
        }}
      />

      <BillingAddressForm
        initialValues={checkoutState.billing_address}
        onChange={({ data, isValid }) => {
          if (!isValid) return
          setCheckoutState((state) => {
            return {
              ...state,
              billing_address: data,
            }
          })
        }}
      />
    </Box>
  )
}
