import { ShippingRate } from '@Types/cart/ShippingRate'
import { HStack, Radio, Text, VStack } from '@chakra-ui/react'
import { semantic } from 'composable/chakra/figma-tokens'
import { CurrencyHelpers } from 'helpers/currencyHelpers'

interface ShippingOptionProps {
  shippingMethodId: string
  shippingMethodName: string
  shippingMethodRates: ShippingRate[]
  shippingMethodDescription?: string
  selected?: boolean
  disabled?: boolean
  onSelect: () => void
}

export const ShippingOption = ({
  shippingMethodId,
  shippingMethodName,
  shippingMethodDescription,
  shippingMethodRates,
  selected = false,
  disabled = false,
  onSelect,
}: ShippingOptionProps) => {
  const shippingRate = shippingMethodRates.find((shippingRate) => shippingRate.price.currencyCode === 'USD')?.price

  if (!shippingRate === undefined) return null

  return (
    <HStack
      p={4}
      gap={2}
      alignItems="flex-start"
      border="1px solid"
      borderColor={selected && !disabled ? 'primary' : 'muted'}
      justifyContent="space-between"
      onClick={onSelect}
      cursor="pointer"
    >
      <HStack alignItems="flex-start">
        <Radio value={shippingMethodId} size="md" isDisabled={disabled} />

        <Text textStyle={'heading-desktop-75'}>{shippingMethodName}</Text>
      </HStack>

      <Text textStyle={'heading-desktop-75'} justifySelf="flex-end">
        {CurrencyHelpers.formatForCurrency(shippingRate)}
      </Text>
    </HStack>
  )
}
