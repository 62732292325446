import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'

interface PriceProps {
  priceProps?: TextProps
  price: string
  rootProps?: StackProps
  discountedPrice?: string
  discountPercent?: number
  discountedPriceProps?: TextProps
  promoPrice?: string
  priceRange?: any
}

export const FlatPrice = (props: PriceProps) => {
  const { priceProps, price, discountedPrice, discountedPriceProps, discountPercent } = props

  const intl = useFormat({ name: 'common' })

  if (!price) return null

  return (
    <HStack gap={1}>
      {discountedPrice && (
        <Text
          as="span"
          textStyle={'blockquote'}
          color={'text-muted'}
          textDecoration={'line-through'}
          {...discountedPriceProps}
        >
          {discountedPrice}
        </Text>
      )}
      <Text as="span" textStyle={'blockquote'} color={'text'} {...priceProps}>
        {price}
      </Text>

      {discountedPrice && discountPercent && (
        <Text textStyle={'body-100'} color="danger.500" ml={2.5}>
          {intl.formatMessage({ id: 'product.discount.label', values: { discountPercent } })}
        </Text>
      )}
    </HStack>
  )
}
