import { useEffect, useMemo, useRef } from 'react'
import { Alert, AlertIcon, Box, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { semantic } from 'composable/chakra/figma-tokens'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { ShippingOption } from './shipping-option'
import { useCheckout } from '../checkout-provider'

export const ShippingOptions = () => {
  const prevAvailableShippingMethodsRef = useRef(null)
  const { formatMessage } = useFormat({ name: 'common' })
  const { data: cart, setShippingMethod } = useCart()
  const { isLoadingStep1, setIsLoadingStep1 } = useCheckout()
  const handleSelectShippingMethod = async (shippingMethodId: string) => {
    setIsLoadingStep1(true)
    try {
      await setShippingMethod(shippingMethodId)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingStep1(false)
  }
  useEffect(() => {
    const selectDefaultShippingMethod = () => {
      if (!cart?.shippingInfo && cart?.availableShippingMethods?.length > 0) {
        handleSelectShippingMethod(cart.availableShippingMethods[0].shippingMethodId)
      } else if (cart?.shippingInfo && cart?.availableShippingMethods?.length > 0) {
        const prevShippingMethods = prevAvailableShippingMethodsRef.current || []
        const currentShippingMethods = cart.availableShippingMethods || []
        if (!compareAvailableShippingMethods(prevShippingMethods, currentShippingMethods)) {
          prevAvailableShippingMethodsRef.current = currentShippingMethods
          handleSelectShippingMethod(currentShippingMethods[0].shippingMethodId)
        }
      }
    }
    const compareAvailableShippingMethods = (oldShippingMethod, newShippingMethods) => {
      if (oldShippingMethod.length !== newShippingMethods.length) return false
      for (let i = 0; i < newShippingMethods.length; i++) {
        if (oldShippingMethod[i].shippingMethodId !== newShippingMethods[i].shippingMethodId) return false
      }
      return true
    }
    selectDefaultShippingMethod()
  }, [cart])

  const defaultShippingMethod = useMemo(() => {
    return cart?.availableShippingMethods?.find((shippingMethod) => {
      return shippingMethod.isDefault === true
    })
  }, cart?.availableShippingMethods)

  if (!cart?.availableShippingMethods) {
    return (
      <Alert status="warning">
        <AlertIcon />
        {formatMessage({ id: 'checkout.shippingForm.setShippingAddressBeforeShippingMethod' })}
      </Alert>
    )
  }

  return (
    <RadioGroup name="shipping-option" value={cart?.shippingInfo?.shippingMethodId}>
      <Stack spacing={3}>
        {cart?.availableShippingMethods?.map((shippingOption, index) => {
          return (
            <ShippingOption
              key={index}
              shippingMethodId={shippingOption.shippingMethodId}
              shippingMethodName={shippingOption.name}
              shippingMethodRates={shippingOption.rates}
              shippingMethodDescription={shippingOption.description}
              onSelect={() => {
                if (isLoadingStep1) return
                handleSelectShippingMethod(shippingOption.shippingMethodId)
              }}
              selected={cart?.shippingInfo?.shippingMethodId === shippingOption.shippingMethodId}
              disabled={isLoadingStep1}
            />
          )
        })}
        {cart?.shippingInfo?.shippingMethodId !== defaultShippingMethod?.shippingMethodId && (
          <Box>
            <Text textStyle={'body-50'} color={semantic.text.muted}>
              {formatMessage({ id: 'checkout.shippingForm.shippingMethodDisclaimer' })}
            </Text>
          </Box>
        )}
      </Stack>
    </RadioGroup>
  )
}
