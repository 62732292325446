import { useEffect, useState } from 'react'
import { COUNTRIES } from 'composable/helpers'
import { fetchExperianSuggestions, formatExperianAddress } from 'frontastic/actions/experian'

const useExperian = ({ setFormValues, streetNameRef }) => {
  const [suggestions, setSuggestions] = useState([])
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)

  const getCountryCode = (countryName: string) => {
    const country = COUNTRIES.find((c) => c.name === countryName)
    return country ? country.code : countryName
  }

  const onStreetNameChange = async (value: string) => {
    try {
      const suggestions = await fetchExperianSuggestions(value)

      setSuggestions(suggestions)
    } catch (error) {
      console.error('Error fetching address suggestions:', error)
    }
  }

  const handleSuggestionClick = async (suggestedAddressGlobalKey: string) => {
    try {
      const formattedAddress = await formatExperianAddress(suggestedAddressGlobalKey)
      const countryCode = getCountryCode(formattedAddress.country)

      formattedAddress.country = countryCode
      setFormValues(formattedAddress)
    } catch (error) {
      console.error('Error formatting address:', error)
    } finally {
      setSuggestions([])
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (streetNameRef.current && !streetNameRef.current.contains(event.target)) {
        setSuggestions([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [streetNameRef])

  return {
    onStreetNameChange,
    suggestions,
    handleSuggestionClick,
    selectedSuggestionIndex,
    setSelectedSuggestionIndex,
  }
}

export default useExperian
