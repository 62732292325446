import React from 'react'
import { Button } from '@chakra-ui/react'

interface CheckoutStepLinksProps {
  title: string
  isAllowed: boolean
  active: boolean
  onClick?: () => void
  isPrevious: boolean
}

export const CheckoutStepLink = ({ isPrevious, isAllowed, active, title, onClick }: CheckoutStepLinksProps) => {
  return (
    <Button
      p={{ base: '10px', md: '20px' }}
      paddingLeft={{ base: isPrevious ? '10px' : '20px', md: isPrevious ? '10px' : '20px' }}
      borderBottom={active ? '2px' : '0'}
      borderRadius={'0'}
      color={'primary'}
      disabled={!isAllowed}
      fontSize={{ base: 'xs', md: 'sm' }}
      onClick={() => isAllowed && onClick !== undefined && onClick()}
      textDecoration="none"
      variant="link"
      _hover={{
        textDecoration: 'none',
      }}
    >
      {title}
    </Button>
  )
}
