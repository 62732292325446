import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink, useBreakpointValue } from '@chakra-ui/react'
import { useComposable } from 'composable/components/composable-provider'
import { NextLinkNoPrefetch } from 'composable/components/link'
import { useFormat } from 'helpers/hooks/useFormat'

export interface BreadcrumbItem {
  sort_order: number
  node_key: string
  name: string
}

interface BreadcrumbProps {
  query: string
  isSearchPage?: boolean
  items?: BreadcrumbItem[]
}

const HOME_NODE_KEY = 'home'

const SearchBreadcrumb = ({ query }) => {
  const { formatMessage } = useFormat({ name: 'common' })

  return (
    <ChakraBreadcrumb textStyle={{ base: 'body-75', md: 'body-100' }} color={'text-muted'}>
      <BreadcrumbItem>
        <BreadcrumbLink
          href="/"
          _hover={{
            textStyle: { base: 'link-75', md: 'link-100' },
          }}
        >
          {formatMessage({
            id: 'category.breadcrumb.root',
            values: { query },
          })}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {query && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#" textTransform={'none'}>
            {formatMessage({
              id: 'category.breadcrumb.resultsFor',
              values: { query },
            })}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </ChakraBreadcrumb>
  )
}

const PlpBreadcrumb = ({ items }) => {
  const { path } = useComposable()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (!items || items?.length < 1) return null

  const breadcrumbs = isMobile ? items.filter((cat) => cat.node_key !== HOME_NODE_KEY) : items
  const sortedList = breadcrumbs.sort((a, b) => a.sort_order - b.sort_order)

  return (
    <ChakraBreadcrumb
      overflowX={isMobile ? 'scroll' : undefined}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      color={'text-muted'}
    >
      {sortedList?.map((item, idx) => {
        const isCurrentPage = idx + 1 === sortedList.length
        return (
          <BreadcrumbItem
            as={NextLinkNoPrefetch}
            href={item.node_key !== 'home' ? path.getPLP(item.node_key) : path.getHome()}
            key={item.node_key}
            pointerEvents={isCurrentPage ? 'none' : undefined}
            aria-current={isCurrentPage ? 'page' : undefined}
            textStyle={{ base: 'body-75', md: 'body-100' }}
          >
            <BreadcrumbLink
              href="/"
              whiteSpace={'nowrap'}
              _hover={{
                textStyle: { base: 'link-75', md: 'link-100' },
              }}
            >
              {item.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </ChakraBreadcrumb>
  )
}

export const Breadcrumb = ({ query, isSearchPage = false, items }: BreadcrumbProps) => {
  return isSearchPage ? <SearchBreadcrumb query={query} /> : <PlpBreadcrumb items={items} />
}
