import { Icon, IconProps } from '@chakra-ui/react'

export const LocPinIcon = (props: IconProps) => (
  <Icon
    id="icon-loc-pin"
    aria-label="Location Pin Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1.4em"
    height="1.4em"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 23 32"
    strokeWidth="0"
    {...props}
  >
    <path d="M11.688 13.87c-1.356 0-2.455-1.099-2.455-2.455s1.099-2.455 2.455-2.455v0c1.35 0 2.448 1.102 2.448 2.455s-1.098 2.455-2.449 2.455zM11.687 6.497c-2.711 0.006-4.907 2.205-4.908 4.917v0c0.001 2.712 2.198 4.911 4.909 4.917h0.001c2.708 0 4.91-2.206 4.91-4.917-0.001-2.713-2.199-4.912-4.91-4.916h-0zM19.3 15.895l-7.474 12.506-7.67-12.384c-0.835-1.325-1.331-2.937-1.334-4.664v-0.001c0-4.903 3.979-8.891 8.87-8.891s8.862 3.988 8.862 8.89c-0 1.677-0.466 3.246-1.275 4.584l0.022-0.040zM11.692-0c-6.259 0.016-11.327 5.091-11.332 11.351v0c0.002 2.207 0.636 4.267 1.731 6.006l-0.028-0.047 8.734 14.106c0.22 0.352 0.606 0.583 1.046 0.583 0 0 0 0 0 0h0.010c0.43-0.004 0.825-0.231 1.046-0.599l8.513-14.244c1.006-1.658 1.602-3.662 1.602-5.805v-0c0.001-6.258-5.079-11.351-11.323-11.351z" />
  </Icon>
)
