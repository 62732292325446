import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Grid, GridItem, VStack } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { useInfiniteHits, UseInfiniteHitsProps } from 'react-instantsearch-hooks-web'
import { useGridLayout } from '../../hooks'
import { StarterKitAlgoliaProduct } from '../../types'
import { CategoryProductCard } from '../parts/category-product-card'

export const InfiniteHits = (props: UseInfiniteHitsProps<StarterKitAlgoliaProduct>) => {
  const scrollRef = useRef(null)
  const router = useRouter()
  const [shouldScroll, setshouldScroll] = useState(false)
  const { gridTemplateColumns } = useGridLayout()
  const { hits, isLastPage, showMore, results } = useInfiniteHits(props)
  const { formatMessage } = useFormat({ name: 'common' })

  useEffect(() => {
    if (!router.query['page']) {
      setshouldScroll(true)
    }

    if (router.query['page'] && results.nbHits > 0 && !shouldScroll) {
      setshouldScroll(true)
      scrollRef.current?.scrollIntoView()
    }
  }, [results.nbHits, router.query, shouldScroll])

  return (
    <VStack w="full">
      <Grid w="full" gridTemplateColumns={gridTemplateColumns} ref={scrollRef}>
        {hits.map((product: Partial<StarterKitAlgoliaProduct>, index) => (
          <GridItem
            key={product.objectID}
            id={`algolia-result-${index}`}
            margin={{
              base: '8px',
              md: '16px',
            }}
          >
            <CategoryProductCard product={product} priority={index < 3} idx={index} />
          </GridItem>
        ))}
      </Grid>
      {!isLastPage && (
        <Box p={25}>
          <Button variant="outline" colorScheme="blue" onClick={() => showMore()}>
            {formatMessage({ id: 'search.showMore' })}
          </Button>
        </Box>
      )}
    </VStack>
  )
}
