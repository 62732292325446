import { ReactNode, useState } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, Divider, Flex, Stack, StackProps, Text, useBreakpointValue } from '@chakra-ui/react'
import { Cart } from '@Types/cart/Cart'
import { getCartSummaryAmounts } from 'composable'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { useCart } from 'frontastic'
import { CartPromotion, CartPromotionAccordion } from './cart-promotion'
import { APP_CONTEXT } from '../general'
import { HorizontalProductCard, HorizontalProductCardProps } from '../horizontal-product-card'
import { SummaryLineItem } from '../summary-line-item'
import { useAtgLegacyCart } from 'frontastic/contexts'

interface CartSummaryItemProps {
  label: string
  value?: string
  textStyle?: string
  children?: ReactNode
  isDiscount?: boolean
  borderTop?: string
  mt?: string
  pt?: string
}

const CartSummaryItem = (props: CartSummaryItemProps) => {
  const { label, value, textStyle, children, isDiscount, ...others } = props
  return (
    <Flex justify="space-between" mt={{ base: '4px', md: '5px' }} fontSize="sm" {...others}>
      <Text textStyle={textStyle}>{label}</Text>
      {value ? (
        <Text textStyle={textStyle} color={isDiscount ? 'red' : undefined}>
          {isDiscount && '-'}
          {value}
        </Text>
      ) : (
        children
      )}
    </Flex>
  )
}

export interface CartSummaryProps {
  cartData?: Partial<Cart>
  disablePromotion?: boolean
  displayTitle?: boolean
  hideCheckoutButton?: boolean
  orderSummaryProps?: StackProps
  rootProps?: StackProps
  showCartItems?: boolean
  textOverride?: {
    total: string
  }
  isButtonProcessing?: boolean
}

export const CartSummary = ({
  hideCheckoutButton,
  rootProps,
  orderSummaryProps,
  disablePromotion = false,
  displayTitle = true,
  showCartItems = false,
  cartData,
  textOverride,
  isButtonProcessing,
}: CartSummaryProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()
  const { formattedCart: cart } = useAtgLegacyCart()

  const [isProcessing, setIsProcessing] = useState(false)
  const initializedByStoreMobileDevice = cart?.initializedByStoreMobileDevice

  const productCartSize = useBreakpointValue({ base: 'sm', md: 'lg' })
  const { subtotal, discounts, total, isShippingMethodSelected, shipping } = getCartSummaryAmounts(cart)

  const showPromotion = APP_CONTEXT !== 'STS' && !disablePromotion
  const displayText = {
    total: textOverride?.total ?? 'cart.summary.estimatedTotal',
  }

  const handleProceedToCheckout = async () => {
    //await recalculateCart()
    router.push('/checkout')
  }

  return (
    <Stack spacing={{ base: '4', md: '6' }} width="full" pb={10} {...rootProps}>
      <Box bg={'shading.100'} p={'2rem 1.5rem'}>
        {displayTitle && (
          <Text as={'h2'} textStyle={'heading-desktop-200'} mb={3}>
            {formatMessage({ id: 'cart.summary.title' })}
          </Text>
        )}
        {showCartItems && (
          <Stack spacing={4} divider={<Divider />}>
            {cart?.lineItems?.map((item) => {
              const { variant, price, discountedPrice, name, count, lineItemId, _url, isDiscounted } = item

              const masterProductData = item?.masterData?.current?.masterVariant?.attributes

              return (
                <HorizontalProductCard
                  key={lineItemId}
                  url={_url}
                  columns={2}
                  image={{
                    src: variant?.images?.[0],
                    alt: name ?? '',
                    onClickImage: () => router.push(_url),
                  }}
                  name={name}
                  quantity={count}
                  priceCentAmount={price.centAmount * count}
                  price={CurrencyHelpers.formatForCurrency((price.centAmount || 0) * count)}
                  discountedPrice={
                    isDiscounted ? CurrencyHelpers.formatForCurrency(discountedPrice?.centAmount * count) : undefined
                  }
                  size={productCartSize as HorizontalProductCardProps['size']}
                  variant={variant}
                  displayOutOfStock={false}
                  masterProductData={masterProductData}
                />
              )
            })}
          </Stack>
        )}

        <Stack spacing={0} textStyle={'body-75'}>
          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.subtotal' })}
            value={CurrencyHelpers.formatForCurrency(subtotal)}
          />

          {isShippingMethodSelected ? (
            <SummaryLineItem
              label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
              value={CurrencyHelpers.formatForCurrency(shipping)}
            />
          ) : (
            <SummaryLineItem
              label={formatMessage({ id: 'cart.summary.estimatedShipping' })}
              value={formatMessage({ id: 'cart.summary.calculatedAtCheckout' })}
            />
          )}

          <SummaryLineItem
            label={formatMessage({ id: 'cart.summary.tax' })}
            value={formatMessage({
              id: 'cart.summary.tax.calculatedAtPOS',
            })}
          />

          {!!discounts && (
            <SummaryLineItem
              isDiscount
              label={formatMessage({ id: 'cart.summary.discount' })}
              value={CurrencyHelpers.formatForCurrency(discounts)}
            />
          )}

          {showPromotion && (
            <CartPromotionAccordion>
              <CartPromotion />
            </CartPromotionAccordion>
          )}

          <CartSummaryItem
            borderTop="1px solid var(--chakra-colors-shading-200)"
            mt={'4'}
            pt={'4'}
            textStyle={'heading-desktop-100'}
            label={formatMessage({ id: displayText.total })}
            value={CurrencyHelpers.formatForCurrency(total)}
          />
        </Stack>
      </Box>

      <Button colorScheme="blue" size="lg" textStyle={'ui-button-200'} onClick={handleProceedToCheckout}>
        {formatMessage({ id: 'action.proceed.checkout' })}
      </Button>
    </Stack>
  )
}
