import { Box, HStack, useBreakpointValue, Link } from '@chakra-ui/react'
import { MegaMenuDetailsBar } from './MegaMenuDetailsBar'
import { MegaMenuItem } from './MegaMenuItem'
import { Entry } from '../hooks/useContentstack'
export interface MegaMenuProps {
  items: Entry[]
}

export const MegaMenu = ({ items }: MegaMenuProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <>
      <Box
        as="nav"
        id="mega-menu"
        aria-label="Mega Menu"
        position="relative"
        zIndex="3"
        display={{ base: 'none', lg: 'flex' }}
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100%" maxW="container.2xl" px={{ base: 4, lg: 10 }}>
          <HStack width="100%" maxW="container.2xl" alignItems="stretch">
            {items?.map((item, idx) => (
              <MegaMenuItem key={`${idx}-${item?.uid}`} item={item} />
            ))}
          </HStack>
        </Box>
      </Box>
      <Box width="100%" bg="brand.muted">
        <MegaMenuDetailsBar />
      </Box>
      {isMobile ? (
        <Box>
          <Link href="/order-search">Order Status </Link>
        </Box>
      ) : null}
    </>
  )
}
